import React, { useEffect, useMemo, useState } from 'react';

import { API_RETURN_FIELDS, SKILL_TEMPLATE_SORT_OPTIONS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { AddSkillSuperAdminModal } from '~/components/Modals/AddSkillModal';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';

import { SkillTemplateTable } from './components/SkillTemplatesTable';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Header, Description, StyledSkillLevelsDropdown } from './design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { listSkillTemplatesSuperAdmin } from '~/services/skillTemplates';
import { COLORS } from '~/styles';
import { isNotNil } from '~/utils/typePredicates';

import type { IGeneralForm } from './types';
import type { ISkillCategory, ISkillTemplate } from '@learned/types';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  skillCategories: ISkillCategory[];
};

function StepSkillCategory({ sectionState, formMethods, skillCategories }: StepDetailsProps) {
  const { handleSubmit, register, control, watch } = formMethods;
  const { i18n } = useLingui();
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplate[]>([]);
  const [skillTemplate, setSkillTemplate] = useState<ISkillTemplate>();

  const { skillCategoryId, skillCategoryName, skillLevels } = useMemo(() => {
    const skillCategoryId = sectionState.sections[sectionState.currentSection].id;
    const skillCategoryName = sectionState.sections[sectionState.currentSection].title;
    const skillCategory = skillCategories.find(({ id }) => id === skillCategoryId);
    const skillLevels = skillCategory?.skillLevels.map((_, index) => ++index) || [];

    return { skillCategoryId, skillCategoryName, skillLevels };
  }, [sectionState.currentSection, sectionState.sections, skillCategories]);

  const watchSkillCategory = watch(`skills.${skillCategoryId}`);

  useEffect(() => {
    const fetchData = async () => {
      const skillTemplateIds = watchSkillCategory.skills
        .map((skill) => skill.skillTemplate)
        .filter(isNotNil);

      const result: { data: { skillTemplates: ISkillTemplate[] } } =
        await listSkillTemplatesSuperAdmin(
          {
            skillTemplateIds,
          },
          { skip: 0, limit: skillTemplateIds.length, sortBy: SKILL_TEMPLATE_SORT_OPTIONS.NAME_A_Z },
        );

      setSkillTemplates(result.data[API_RETURN_FIELDS.SKILL_TEMPLATES]);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchSkillCategory)]);

  const onSubmit = () => sectionState.setCurrentSection(1);

  useEffect(() => {
    if (skillTemplate) {
      setIsAddSkillModalVisible(true);
    }
  }, [skillTemplate]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Header>
        <Title>{skillCategoryName}</Title>
        <div className="buttons">
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            label={i18n._(t`Add skill or KPI`)}
            onClick={() => {
              setIsAddSkillModalVisible(true);
            }}
          />
        </div>
      </Header>
      <Header marginBottom="20px">
        <div className="switchWrapper">
          <Controller
            {...register(`skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`)}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch onChange={onChange} checked={value} className="switch" />
            )}
          />
          <Description color={COLORS.TEXT_HOVER} width="316px">
            <Trans>Automatically add the focus areas for the selected skill level</Trans>
          </Description>
        </div>
        <Controller
          {...register(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          control={control}
          render={({ field: { onChange } }) => (
            <StyledSkillLevelsDropdown
              selectedItem={skillLevels.find(
                (value, _) => value === watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`),
              )}
              items={skillLevels}
              onChange={onChange}
              stringifyItem={(item) => i18n._(t`Level ${item}`)}
              isSingleSelect
            />
          )}
        />
      </Header>
      <SkillTemplateTable
        skillCategoryId={skillCategoryId}
        formMethods={formMethods}
        skillTemplates={skillTemplates}
        setSkillTemplate={setSkillTemplate}
        skillCategories={skillCategories}
        isEditMode
      />
      <StepFooter
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
      {isAddSkillModalVisible && skillCategoryId && (
        <AddSkillSuperAdminModal
          onClose={() => {
            setIsAddSkillModalVisible(false);
            setSkillTemplate(undefined);
          }}
          skillCategoryName={skillCategoryName}
          defaultFocusAreaLevel={watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          isDefaultFocusAreaLevelEnabled={watch(
            `skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`,
          )}
          formMethods={formMethods}
          skillCategoryId={skillCategoryId}
          initSource={skillTemplate}
          skillLevels={skillLevels}
          skillCategoryTemplateId={skillCategoryId}
        />
      )}
    </Form>
  );
}

export { StepSkillCategory };
