export type GraphDataType = {
  key: string;
  value: number;
  deviation: number;
};

export interface IGraphPoint {
  data: GraphDataType[];
}

export interface ILineGraphPoint extends IGraphPoint {
  colors: string[];
  label: string;
  type: ELabelType;
  key: string;
}

export interface IGraphProps {
  bgColor?: string | null;
  onPointClick?: (d: any) => void;
}

export interface ILineGraphProps extends IGraphProps {
  min?: number;
  max?: number;
  height?: number;
  stepSize: number;
  width?: number;
  colorRatio?: number;
  timeData?: ILineGraphPoint[];
  tooltipTitle?: string;
}

export interface ISpiderGraphConfigOverrides {
  // this is used to configure tooltips, when ratingLabels are provided we should display values as a fraction of the rating label
  // also controlls how many radial lines should be displayed
  ratingLabels?: number;
  beginAtZero?: boolean;
  stepSize?: number;
}

export interface ISpiderGraphProps extends IGraphProps {
  height?: number;
  width?: number;
  dataGroups?: {
    data: GraphDataType[];
    key: 'primary' | string;
    colors: string[];
    label: string;
  }[];
  tooltipTitle?: string;
  colors?: string[];
  configOverrides?: ISpiderGraphConfigOverrides;
}

export enum ELabelType {
  FILL = 'fill',
  BORDER = 'border',
}

export type TTotalGraphData = {
  id: string;
  name: string;
  value: number | null;
  team?: number | null;
  self?: number | null;
  peer?: number | null;
  benchmark?: number | null;
  deviation?: number | null;
  selfDeviation?: number | null;
  peerDeviation?: number | null;
  ratingLabels?: number;
};
