import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import CleanUpRolesModal from '~/components/CleanUpRolesModal';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ImportRolesModal from '~/components/ImportRolesModal';
import SyncRolesModal from '~/components/SyncRolesModal';
import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { JobCreateModal } from './components/JobCreateModal';
import PopOver, { PopOverPlacement } from './components/PopOver';
import { MODAL_TYPE } from './constants';
import {
  ContextWrapper,
  Options,
  TitleContainer,
  MainTitle,
  Description,
  OptionItem,
  OptionsBtn,
  BoxBtnContainer,
} from './JobMatrix.design';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { checkModuleIntegrations } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { deleteJobProfiles, getJobProfiles } from '~/services/jobProfiles';
import { updateJobProfiles, setJobProfiles } from '~/store/jobProfiles/actions';
import { COLORS } from '~/styles';

type TProps = {
  setOpened: Dispatch<SetStateAction<boolean>>;
  opened: boolean;
  setOpenedModal: Dispatch<SetStateAction<MODAL_TYPE | null>>;
  openedModal: MODAL_TYPE | null;
  jobLevelGroupId?: string | null;
  jobFamilyId?: string | null;
  levelTo?: number;
};
function JobOptionMenu({
  setOpened,
  opened,
  setOpenedModal,
  openedModal,
  jobLevelGroupId,
  jobFamilyId,
  levelTo,
}: TProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const currentCompany = useSelector(getCurrentCompany);
  const isIntegrationModuleEnabled = useSelector(checkModuleIntegrations);
  const { addToast } = useToasts();
  const [isFromCreateBtn, setIsFromCreateBtn] = useState(false);

  const fetchJobProfiles = async () => {
    try {
      const jobProfiles = await getJobProfiles(
        { status: JOB_PROFILE_STATUSES.ACTIVE.key },
        {
          projection: {
            name: 1,
            jobFamily: 1,
            jobLevelGroup: 1,
            meta: 1,
            status: 1,
            externalId: 1,
            externalSource: 1,
            company: 1,
          },
        },
      );
      const jobProfileData = Object.values(jobProfiles || {});
      if (jobProfileData.length > 0) {
        dispatch(setJobProfiles(jobProfileData));
      }
    } catch (error) {
      addToast({
        title: i18n._(t`Something went wrong`),
        subtitle: i18n._(t`Something went wrong while fetching the data. Please try again later.`),
        type: TOAST_TYPES.ERROR,
      });
    }
  };

  const syncJobProfiles = async (
    jobProfilesToUpdate: { jobProfileId: string; newValue: string }[],
  ) => {
    if (isEmpty(jobProfilesToUpdate)) {
      setOpenedModal(null);
      return;
    }

    const preparedJobProfiles: Array<[string, object]> = [];
    jobProfilesToUpdate.forEach(({ jobProfileId, newValue }) =>
      preparedJobProfiles.push([
        jobProfileId,
        { [`name.${currentCompany.primaryLang.locale || 'en_GB'}`]: newValue },
      ]),
    );

    // update DB + redux-store
    dispatch(
      updateJobProfiles(preparedJobProfiles, () => {
        fetchJobProfiles();
        setOpenedModal(null);
      }),
    );
  };

  const removeJobProfiles = async (jobProfilesToDelete: { id: string }[]) => {
    if (isEmpty(jobProfilesToDelete)) {
      setOpenedModal(null);
      return;
    }

    await deleteJobProfiles(jobProfilesToDelete.map((jp) => jp.id));
    await fetchJobProfiles();
    setOpenedModal(null);
  };

  const onAddJob = () => {
    setOpenedModal(MODAL_TYPE.NEW_JOB_PROFILE);
  };

  const onModalClose = () => {
    setIsFromCreateBtn(false);
    setOpenedModal(null);
  };

  const getIcon = (type: 'blue' | 'purple', iconType: ICONS) => {
    let colorPair = {
      firstColor: COLORS.CAREER,
      secondColor: COLORS.CAREER_LIGHT,
    };

    if (type === 'blue') {
      colorPair = {
        firstColor: COLORS.PERFORMANCE,
        secondColor: COLORS.PERFORMANCE_LIGHT,
      };
    }

    return (
      <BoxBtnContainer firstColor={colorPair.firstColor} secondColor={colorPair.secondColor}>
        <Icon icon={iconType} size={ICON_SIZES.SMALL} width={18} height={18} />
      </BoxBtnContainer>
    );
  };

  const CtxMenuItems = [
    {
      label: i18n._(t`Create new job`),
      description: i18n._(t`Start from a template or scratch`),
      icon: getIcon('purple', ICONS.ADD_PLUS),
      onClick: () => {
        setOpened(false);
        onAddJob();
        setIsFromCreateBtn(true);
      },
    },
    ...(isIntegrationModuleEnabled
      ? [
          {
            label: i18n._(t`Import via API`),
            description: i18n._(t`Create job available in HRIS`),
            icon: getIcon('blue', ICONS.UPLOAD),
            onClick: () => {
              setOpened(false);
              setOpenedModal(MODAL_TYPE.IMPORT_ROLES);
            },
          },
          {
            label: i18n._(t`Synchronise via API`),
            description: i18n._(t`Synchronise jobs from HRIS`),
            icon: getIcon('blue', ICONS.REFRESH),
            onClick: () => {
              setOpened(false);
              setOpenedModal(MODAL_TYPE.SYNC_ROLES);
            },
          },
          {
            label: i18n._(t`Cleanup via API`),
            description: i18n._(t`Remove jobs based on HRIS`),
            icon: getIcon('blue', ICONS.DELETE_BIN),
            onClick: () => {
              setOpened(false);
              setOpenedModal(MODAL_TYPE.CLEANUP_ROLES);
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <ContextWrapper>
        <PopOver
          placement={PopOverPlacement.BOTTOM_END}
          arrow={false}
          interactive={true}
          visible={opened}
          onClickOutside={() => {
            setOpened(false);
          }}
          offset={[0, 5]}
          reference={ref}
          content={
            <Options>
              {CtxMenuItems.map((item, index) => (
                <OptionItem key={index} onClick={item.onClick}>
                  {item.icon}
                  <TitleContainer>
                    <MainTitle>{item.label}</MainTitle>
                    <Description>{item.description}</Description>
                  </TitleContainer>
                </OptionItem>
              ))}
            </Options>
          }
        >
          <OptionsBtn
            onClick={() => {
              setOpened(!opened);
            }}
          >
            <Icon
              icon={ICONS.ADD_PLUS}
              size={ICON_SIZES.SMALL}
              width={14}
              height={14}
              className="icon"
            />
            {i18n._(t`New Job`)}
          </OptionsBtn>
        </PopOver>
      </ContextWrapper>
      {openedModal === MODAL_TYPE.NEW_JOB_PROFILE && (
        <JobCreateModal
          onClose={onModalClose}
          jobLevelGroupId={jobLevelGroupId}
          jobFamilyId={jobFamilyId}
          levelTo={levelTo}
        />
      )}
      {openedModal === MODAL_TYPE.NEW_JOB_PROFILE && !isFromCreateBtn && (
        <JobCreateModal
          onClose={onModalClose}
          jobLevelGroupId={jobLevelGroupId}
          jobFamilyId={jobFamilyId}
          levelTo={levelTo}
        />
      )}

      {openedModal === MODAL_TYPE.NEW_JOB_PROFILE && isFromCreateBtn && (
        <JobCreateModal onClose={onModalClose} />
      )}

      {openedModal === MODAL_TYPE.IMPORT_ROLES && <ImportRolesModal onClose={onModalClose} />}
      {openedModal === MODAL_TYPE.CLEANUP_ROLES && (
        <CleanUpRolesModal onClose={onModalClose} onSubmit={removeJobProfiles} />
      )}
      {openedModal === MODAL_TYPE.SYNC_ROLES && (
        <SyncRolesModal onClose={onModalClose} onSubmit={syncJobProfiles} />
      )}
    </>
  );
}

export default JobOptionMenu;
