import React, { useMemo } from 'react';

import { JOB_TEMPLATE_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import { ImageWithAlt } from '~/components/ImageWithAlt';
import RickTextView from '~/components/RickTextView';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import { ActionItemBlock, ActionsWrap } from '~/pages/SurveyUpdate/design';

import {
  Description,
  Details,
  Divider,
  Header,
  IconsSection,
  Image,
  SkillsWrapper,
  SmallTitle,
  StyledButton,
  Title,
  Wrapper,
} from './design';
import { SkillTemplateTable } from './SkillTemplatesTable';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { SUPERADMIN_JOB_TEMPLATE_EDIT } from '~/constants/routes/superadmin';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';
import { getSuperAdminMultiLangStringForSingleSelect } from '~/utils/superAdmin';

import { IconInfo } from '../SuperAdminJobTemplateForm/components/IconInfo';
import { useJobFamilies } from '../SuperAdminJobTemplateForm/hooks/useJobFamilies';
import { useJobTemplate } from '../SuperAdminJobTemplateForm/hooks/useJobTemplate';
import { useSkillCategories } from '../SuperAdminJobTemplateForm/hooks/useSkillCategories';
import { useSkillTemplates } from '../SuperAdminJobTemplateForm/hooks/useSkillTemplates';
import { convertSkillsTemplateAPIDataToFormData } from '../SuperAdminJobTemplateForm/utils';

function SuperAdminViewJobTemplate() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { jobTemplate, isLoading } = useJobTemplate();
  const { skillCategories } = useSkillCategories();
  const { jobFamilies } = useJobFamilies();

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
    isSingleSelect: true,
  });
  const { skillTemplates } = useSkillTemplates({ jobTemplate });

  const skills = useMemo(() => {
    if (jobTemplate) {
      return convertSkillsTemplateAPIDataToFormData(
        jobTemplate?.skills,
        skillCategories,
        skillTemplates,
      );
    }

    return {};
  }, [jobTemplate, skillCategories, skillTemplates]);

  const tabItems = useMemo(() => {
    return skillCategories.map((item) => ({
      key: item.id,
      label: item.name.en_GB,
      content: (
        <SkillTemplateTable
          skillTemplates={skillTemplates}
          skills={skills?.[item.id]?.skills}
          skillCategoryId={item.id}
          skillCategories={skillCategories}
        />
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ skillCategories, skillTemplates, skills })]);

  const jobFamily = useMemo(
    () =>
      jobFamilies.filter((jobFamily) => {
        if (jobFamily.id === jobTemplate?.jobFamily) {
          return jobFamily;
        }
      })?.[0],
    [jobFamilies, jobTemplate?.jobFamily],
  );

  return (
    <>
      <DashboardHeader
        title={<Trans>SA job library</Trans>}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <MultiLangComponent
              languageState={{
                companyLanguages: languageState.defaultLanguages,
                setLanguages: languageState.setLanguages,
                companyPrimaryLanguage: languageState.primaryLanguage,
                languages: languageState.languages,
              }}
              isSingleSelect
            />
            <ActionItemBlock>
              {jobTemplate?.status !== JOB_TEMPLATE_STATUS.ARCHIVED && (
                <StyledButton
                  type="button"
                  label={<Trans>Edit</Trans>}
                  variant={ButtonVariant.TEXT_PRIMARY}
                  icon={ICONS.EDIT_PENCIL}
                  size={ButtonSize.MEDIUM}
                  onClick={() =>
                    SUPERADMIN_JOB_TEMPLATE_EDIT.go(
                      { role: 'super-admin' },
                      { jobTemplateId: jobTemplate?.id, isBackPath: true },
                    )
                  }
                />
              )}
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper>
        <ShowSpinnerIfLoading loading={isLoading}>
          <>
            {jobTemplate && (
              <>
                <Details>
                  <Header>
                    <Title>
                      {getSuperAdminMultiLangStringForSingleSelect(jobTemplate.name, languageState)}
                    </Title>
                  </Header>
                  {jobTemplate.coverImage && (
                    <Image height="329px">
                      <ImageWithAlt
                        src={jobTemplate.coverImage}
                        alt={getSuperAdminMultiLangStringForSingleSelect(
                          jobTemplate.name,
                          languageState,
                        ).substring(0, 2)}
                      />
                    </Image>
                  )}

                  <Description>
                    <SmallTitle>
                      <Trans>Description</Trans>
                    </SmallTitle>
                    <RickTextView
                      html={convertMarkdownStarsToStrong(
                        getSuperAdminMultiLangStringForSingleSelect(
                          jobTemplate?.description.default || '',
                          languageState,
                        ),
                      )}
                      color={COLORS.ICONS_PRIMARY}
                      isHtmlWithoutStyles={undefined}
                      className={undefined}
                    />
                  </Description>
                </Details>

                <Divider />
                <SmallTitle>
                  <Trans>Expected behavior and knowledge</Trans>
                </SmallTitle>
                <SkillsWrapper>
                  {!isEmpty(tabItems) && (
                    <TabSlider
                      items={tabItems}
                      tabTitleAlignment="left"
                      noPadding
                      paddingWrapper="23px 0 0"
                    />
                  )}
                </SkillsWrapper>
                <Divider />
                <IconsSection>
                  <SmallTitle>
                    <Trans>Job matrix</Trans>
                  </SmallTitle>
                  <IconInfo
                    title={i18n._(t`Job Group`)}
                    subtitle={jobFamily?.name?.en_GB}
                    icon={ICONS.JOB_FAMILY}
                  />
                </IconsSection>
              </>
            )}
          </>
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
}

export { SuperAdminViewJobTemplate };
