import React, { useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import { uniq } from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import {
  IQuestionForm,
  IRatingLabel,
  ISelectedSkill,
  ISkill,
  ISkillCategory,
} from '~/pages/ReviewThemeSetup/types';

import { QuestionDescription } from './design';
import { SkillTable } from './SkillTable';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getSkillsById } from '~/services/skills';

import { Options } from '../Options';

interface IProps {
  skills?: ISkill[];
  formMethods: UseFormReturn<IQuestionForm>;
  selectedSkills?: ISelectedSkill[];
  setSelectedSkill?: (skill: ISkill) => void;
  setIsSkillModalOpen: (open: boolean) => void;
  skillCategories: ISkillCategory[];
  setCurrentStep?: (step: number) => void;
  setSelectedSkills: (skills: ISelectedSkill[]) => void;
  ratingLabels: IRatingLabel[];
}

const CustomSkill = ({
  formMethods,
  selectedSkills,
  skillCategories,
  setSelectedSkill,
  setIsSkillModalOpen,
  setCurrentStep,
  setSelectedSkills,
  ratingLabels,
}: IProps) => {
  const languageState = useLanguageState();
  const [skills, setSkills] = useState<ISkill[]>([]);

  useEffect(() => {
    const fetchSkills = async () => {
      const selectedSkillIds = selectedSkills?.map((skill) => skill.skill) || [];
      const skills = await getSkillsById(uniq(selectedSkillIds));
      setSkills(skills);
    };
    fetchSkills();
  }, [selectedSkills]);

  return (
    <>
      {selectedSkills?.length ? (
        <>
          <QuestionDescription>
            <Trans>A seperate question will be generated for each selected skill.</Trans>
          </QuestionDescription>
          <SkillTable
            formMethods={formMethods}
            skillCategories={skillCategories}
            skills={skills}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            setSelectedSkill={setSelectedSkill}
            setIsSkillModalOpen={setIsSkillModalOpen}
            setCurrentStep={setCurrentStep}
          />
        </>
      ) : null}
      <Options
        languageState={languageState}
        formMethods={formMethods}
        ratingLabels={ratingLabels}
      />
    </>
  );
};

export { CustomSkill };
