import React, { ReactNode, useEffect, useState } from 'react';

import { SKILL_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiSelectMultiLangualDropdown } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { Wrapper } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/design';
import { StepDetails } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/StepDetails';
import { StepFocusAreas } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/StepFocusAreas';
import { StepSummary } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/StepSummary';
import type { IGeneralForm } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/types';
import { ActionItemBlock, ActionsWrap } from '~/pages/SurveyUpdate/design';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';

import type { ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

function SuperAdminSkillTemplateForm({
  languageState,
  formMethods,
  title,
  onSubmit,
  goBack,
  onDelete,
  skillCategory,
  setSkillCategory,
  currentStatus = SKILL_TEMPLATE_STATUS.DRAFT,
}: {
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  title: ReactNode;
  onSubmit: (data: IGeneralForm & { status: SKILL_TEMPLATE_STATUS }) => void;
  goBack: () => void;
  onDelete?: () => void;
  skillCategory: ISkillCategory | undefined;
  setSkillCategory: (skillCategory?: ISkillCategory) => void;
  currentStatus?: SKILL_TEMPLATE_STATUS;
}) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();

  const [isLoading, _setIsLoading] = useState(false);

  const { handleSubmit, formState } = formMethods;

  useEffect(() => {
    const nameError = formState.errors?.name;
    const categoryError = formState.errors?.skillCategory;

    if (nameError || categoryError) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setErrorSection(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const sectionState = useSectionState([
    { title: i18n._(t`Skill or KPI details`) },
    { title: i18n._(t`Levels and focus areas`) },
    { title: i18n._(t`Final check`) },
  ]);

  const onFail = () => {
    sectionState.setTriedToSubmit();

    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    sectionState.goToFirstErrorSection();
  };

  const onSave = async (data: IGeneralForm) => {
    return onSubmit({ ...data, status: currentStatus });
  };

  const onPublish = async (data: IGeneralForm) => {
    return onSubmit({ ...data, status: SKILL_TEMPLATE_STATUS.PUBLISHED });
  };

  return (
    <>
      <DashboardHeader
        title={title}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <ActionItemBlock>
              <MultiSelectMultiLangualDropdown
                languages={languageState.defaultLanguages}
                setLanguages={languageState.setLanguages}
                primaryLanguage={languageState.primaryLanguage}
                preSelectedLanguages={languageState.languages}
                hideAction
              />
            </ActionItemBlock>

            {(onDelete || currentStatus === SKILL_TEMPLATE_STATUS.DRAFT) && (
              <ActionItemBlock>
                {onDelete && (
                  <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Delete`)}>
                    <span>
                      <Button
                        icon={ICONS.DELETE_BIN}
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON}
                        isLoading={isLoading}
                        onClick={onDelete}
                      />
                    </span>
                  </Tooltip>
                )}
                {currentStatus === SKILL_TEMPLATE_STATUS.DRAFT && (
                  <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Save as Draft`)}>
                    <span>
                      <Button
                        icon={ICONS.SAVE}
                        size={ButtonSize.MEDIUM}
                        variant={ButtonVariant.ICON}
                        isLoading={isLoading}
                        onClick={handleSubmit(onSave, onFail)}
                      />
                    </span>
                  </Tooltip>
                )}
              </ActionItemBlock>
            )}
            <ActionItemBlock>
              <Button
                label={
                  currentStatus === SKILL_TEMPLATE_STATUS.DRAFT
                    ? i18n._(t`Publish`)
                    : i18n._(t`Save`)
                }
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                onClick={handleSubmit(onPublish, onFail)}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isLoading}>
          {sectionState.currentSection === 0 && (
            <StepDetails
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              skillCategory={skillCategory}
              setSkillCategory={setSkillCategory}
            />
          )}

          {sectionState.currentSection === 1 && (
            <StepFocusAreas
              formMethods={formMethods}
              setCurrentSection={sectionState.setCurrentSection}
              skillCategory={skillCategory}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === 2 && (
            <StepSummary
              formMethods={formMethods}
              setCurrentSection={sectionState.setCurrentSection}
              onPublish={onPublish}
              onSave={onSave}
              onFail={onFail}
              skillCategory={skillCategory}
              languageState={languageState}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
}

export { SuperAdminSkillTemplateForm };
