import React from 'react';

import styled from 'styled-components';

import { ColorByPercentage } from '~/components/TableGrid';

const CellBlock = styled.div<{ onClick?: () => void }>`
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover {
    font-weight: bold;
  }
`;

const NoColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

type TProps = {
  value: number;
  children?: React.ReactNode;
  noColor?: boolean;
  onClick?: () => void;
};

function Cell({ value, noColor = false, onClick, children }: TProps) {
  return (
    <CellBlock onClick={onClick}>
      {noColor ? (
        <NoColor>{children}</NoColor>
      ) : (
        <ColorByPercentage value={value}>{children}</ColorByPercentage>
      )}
    </CellBlock>
  );
}

export { Cell };
