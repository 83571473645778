import ConversationIcon from '~/assets/main-menu-icons/conversation.svg';
import FeedbackIcon from '~/assets/main-menu-icons/feedback.svg';
import ReviewIcon from '~/assets/main-menu-icons/star.svg';
import GoalIcon from '~/assets/main-menu-icons/target.svg';

export const HEADER = {
  height: 65,
};

export const FOOTER = {
  height: 88,
};

export const MENU = {
  width: 280,
};

export const BASE_LAYOUT = {
  width: 1140,
};

// old color palette
/**
 * @deprecated since version 3.0
 */
export const COLOR_SET = {
  TOTAL_BLACK: '#000000',
  BLACK: '#3b3b3b',
  BLACK2: '#434343',
  DARK_GRAY: '#70747f',
  DARK_GRAY2: '#767e94',
  GRAY: '#a1a6b4',
  LIGHT_GREY: '#c7cfe0',
  DARK_RED: '#bf3121',
  WARNING_RED: '#ff0f0f',
  RED: '#f81654',
  RED_2: '#ff0000',
  RED_3: '#ff5252',
  LIGHT_RED: '#f27171',
  PURE_RED: '#f4dfdc',
  GREEN: '#217d6a',
  PURE_GREEN: '#e5f4f1',
  CYAN_GREEN2: '#44b29c',
  BLUE: '#3971e9',
  BLUE2: '#2e81ef',
  BLUE3: '#2160e7',
  YELLOW: '#fbd961',
  BRIGHT_SUN: '#FFD838',
  AMBER_YELLOW: '#ffc000',
  PURPLE: '#797ddf',
  ORANGE: '#f26c5d',
  ORANGE2: '#e93c3c',
  ORANGE3: '#faab4b',
  PINK: '#f245c9',
  CORAL: '#ff845c', // 1-1 main color
  ROSE_WHITE: '#fff4f0', // 1-1 sub color
  MIDNICHT_BLUE: '#1e0863', // review main color
  PURPLE_LIGHT: '#f6f4fd', // review sub color
  FUCHSIA: '#e93cc2', // goal main color
  ROSE_LIGHT: '#fef0fb', // goal sub color
  CYAN_GREEN: '#29ccab', // rt fb main color
  CYAN_LIGHT: '#e3faf6', // rt fb sub color
  CORNFLOWER_BLUE: '#5C76FF', // learning main color
  LAVENDER: '#ecefff', // learning sub color
  TURQUOISE: '#29c5cc', // career main color
  TURQUOISE_LIGHT: '#d6f9fb', // career sub color
  CYAN_SHADE: '#3dbdda',
  GOLDENROD: '#f9d87a',
  VAGUELY_VIOLET: '#dbe1ef',
  BUSINESS_DARK_GRADIENT: '#bfc7f3',
  BUSINESS_LIGHT_GRADIENT: '#eff2ff',
  LEARNING_DARK_GRADIENT: '#f4b6e6',
  LEARNING_LIGHT_GRADIENT: '#feedfa',
  REVIEW_DARK_GRADIENT: '#e1d9f8',
  REVIEW_LIGHT_GRADIENT: '#f6f3ff',
  RTFB_DARK_GRADIENT: '#b0f7e9',
  RTFB_LIGHT_GRADIENT: '#f1fffc',
  GOAL_DARK_GRADIENT: '#f4b6e6',
  GOAL_LIGHT_GRADIENT: '#feedfa',
  ONE_ON_ONE_DARK_GRADIENT: '#f6cac1',
  ONE_ON_ONE_LIGHT_GRADIENT: '#fff2ef',
  WELLBEING_DARK_GRADIENT: '#edeac7',
  WELLBEING_LIGHT_GRADIENT: '#fffef9',
  AMBITIONS_DARK_GRADIENT: '#6df9ff',
  AMBITIONS_LIGHT_GRADIENT: '#f5ffff',
  SKILLS_DARK_GRADIENT: '#e1d9f8',
  SKILLS_LIGHT_GRADIENT: '#f6f3ff',
  CYBER_YELLOW: '#fed000',
  DEV_CYCLE_DARK_GRADIENT: '#e1d9f8',
  DEV_CYCLE_LIGHT_GRADIENT: '#f6f3ff',
  ULTRAMARINE_BLUE: '#5c76ff',
  PALE_SKY: '#b8f8fc',
  VIOLET_HEAVEN: '#c8b8fc',
  GRAY_BLUE: '#c8cfe0',
  MIDDLE_GRAY_BLUE: '#6d7aa6',
  LIGHT_BLUE: '#f3f7fd',
};

/**
 * @deprecated since version 3.0
 */
export const COLOR_PALETTE = {
  BLACK: '#000000',
  DARK_GRAY_2: '#454c66',
  DARK_GRAY: '#6b78a4', // Secondary text
  GRAY_MIDDLE: '#b6c5e4', // Inactive Icons, Border main component
  GRAY_SEMI_SOFT: '#dae4f7', // First horizontal border in main component
  GRAY_SOFT: '#f2f6fc', // almost invisible border
  GRAY_LIGHT: '#99a5be',
  WHITE: '#ffffff',
  RED_LIGHT: '#fbd0c7',
  BLUE_DARK: '#1E0863',
};

/**
 * @deprecated since version 3.0
 */
export const COLOR_COLLECTION = [
  '#9055ee',
  '#D9E3F0',
  '#F47373',
  '#697689',
  '#37D67A',
  '#2CCCE4',
  '#555555',
  COLOR_SET.CYAN_GREEN,
  '#ff8a65',
  COLOR_SET.BLUE,
];

export const PRIMARY_COLOR = '#9055ee';

export const COLORS = {
  /**
   * @deprecated since version 3.0
   */
  BACKGROUND_SECONDARY: '#f4f7ff',
  /**
   * @deprecated since version 3.0
   */
  TEXT_PRIMARY: COLOR_PALETTE.BLACK,
  /**
   * @deprecated since version 3.0
   */
  TEXT_ACTIVE: 'var(--company-color)',
  /**
   * @deprecated since version 3.0
   */
  TEXT_BLACK: COLOR_PALETTE.BLACK,
  /**
   * @deprecated since version 3.0
   */
  TEXT_WHITE: COLOR_PALETTE.WHITE,
  /**
   * @deprecated since version 3.0
   */
  TEXT_SECONDARY: COLOR_PALETTE.DARK_GRAY,
  /**
   * @deprecated since version 3.0
   */
  TEXT_PRIMARY_2: COLOR_PALETTE.BLACK,
  /**
   * @deprecated since version 3.0
   */
  SUCCESS: COLOR_SET.CYAN_GREEN,
  /**
   * @deprecated since version 3.0
   */
  DISABLED: COLOR_SET.GRAY,
  /**
   * @deprecated since version 3.0
   */
  BORDER: COLOR_SET.LIGHT_GREY,
  /**
   * @deprecated since version 3.0
   */
  BORDER_LIGHT: COLOR_PALETTE.GRAY_SEMI_SOFT,
  /**
   * @deprecated since version 3.0
   */
  BORDER_SOFT: COLOR_PALETTE.GRAY_SOFT,
  /**
   * @deprecated since version 3.0
   */
  ICON: COLOR_PALETTE.GRAY_MIDDLE,
  /**
   * @deprecated since version 3.0
   */
  PLACEHOLDER: COLOR_SET.GRAY,
  /**
   * @deprecated since version 3.0
   */
  LINK: COLOR_SET.BLUE2,
  /**
   * @deprecated since version 3.0
   */
  HOVER_ACTIVE: COLOR_PALETTE.GRAY_SEMI_SOFT,
  /**
   * @deprecated since version 3.0
   */
  SELF_COVERAGE_BG: '#f6f8fc',
  /**
   * @deprecated since version 3.0
   */
  SELF_COVERAGE_STATUS: '#3971e9',
  /**
   * @deprecated since version 3.0
   */
  PEER_COVERAGE_BG: '#fce1b6',
  /**
   * @deprecated since version 3.0
   */
  PEER_COVERAGE_STATUS: '#fe9800',
  /**
   * @deprecated since version 3.0
   */
  COACH_COVERAGE_BG: '#edb4e0',
  /**
   * @deprecated since version 3.0
   */
  COACH_COVERAGE_STATUS: '#f245c9',
  /**
   * @deprecated since version 3.0
   */
  ORANGE_RED_CRAYOLA: '#FF5252',
  /**
   * @deprecated since version 3.0
   */
  AZTEC_PURPLE: '#683bf6',
  /**
   * @deprecated since version 3.0
   */
  MIST_GREY: '#dcdcdc',
  /**
   * @deprecated since version 3.0
   */
  BLACK: '#000000',
  /**
   * @deprecated since version 3.0
   */
  ERROR: '#ff5252',
  /**
   * @deprecated since version 3.0
   */
  ERROR_HOVER: '#feebeb',
  /**
   * @deprecated since version 3.0
   */
  WARNING: '#ff8252',
  /**
   * @deprecated since version 3.0
   */
  INACTIVE_HARD: '#b7bcc7',
  /**
   * @deprecated since version 3.0
   */
  GREEN: '#47c615',
  /**
   * @deprecated since version 3.0
   */
  GREEN_LIGHT: '#b8eae5',
  /**
   * @deprecated since version 3.0
   */
  ORANGE: '#ffa700',
  /**
   * @deprecated since version 3.0
   */
  LIGHT_GRAY: '#c2c9dd',
  /**
   * @deprecated since version 3.0
   */
  MIDDLE_GRAY: '#6d7aa6',
  /**
   * @deprecated since version 3.0
   */
  DARK_GRAY: '#6b78a4',
  /**
   * @deprecated since version 3.0
   */
  BORDER_HARD: '#dae4f7',
  /**
   * @deprecated since version 3.0
   */
  DARK_HOVER: '#b6c2db',
  /**
   * @deprecated since version 3.0
   */
  STAR: '#ffd838',
  /**
   * @deprecated since version 3.0
   */
  BLUE_DARK: '#1E0863',
  /**
   * @deprecated since version 3.0
   */
  GRAVEL: '#46474e',
  /**
   * @deprecated since version 3.0
   */
  PURPLE: '#683bf6',

  // -------------------------------------------------------------------------------
  // ✨ ✨ ✨ ✨ ✨ ✨ ✨
  // ✨ 🎨 Color Schema ✨
  // ✨ ✨ ✨ ✨ ✨ ✨ ✨
  COMPANY: 'var(--company-color)',
  COMPANY_HOVER: 'var(--company-color-hover)',
  // Fill
  ICONS_SECONDARY: '#B6C2DB',
  ICONS_PRIMARY: '#46474E',
  DROPDOWN_ICON: '#6B78A4',
  BORDERS: '#E5E7EC',
  BG_ELEMENTS: '#EAECF2',
  BG_PAGE: '#F0F4F7',
  HOVER: '#F5F7FD',
  BG_LIST: '#FBFBFB',
  // Text
  TEXT_MAIN: '#46474E',
  TEXT_HOVER: '#000000',
  SUBTEXT: '#8389A0',
  PLACEHOLDERS: '#C2C9DD',
  INACTIVE: '#D6DEEB',
  WHITE: '#FFFFFF',
  // Primary
  SECONDARY: '#F173E5',
  // Products
  CAREER_LIGHT: '#D5BAFF',
  CAREER: '#9055EE',
  PERFORMANCE_LIGHT: '#D9E4FD',
  PERFORMANCE: '#88A8EF',
  ENGAGEMENT_LIGHT: '#FEDEDE',
  ENGAGEMENT: '#E88686',
  LEARNING_LIGHT: '#C8EBED',
  LEARNING: '#5BAFB4',
  TALENT_LIGHT: '#F9FDC8',
  TALENT: '#E4EF65',
  GOALS_OKRS_LIGHT: '#F9C6F4',
  GOALS: '#E4EF65',
  GOALS_PINK: '#F173E5',
  ANY_OTHER_PRODUCTS: '#EAECF2',
  // Signal
  ACCENT_ERROR: '#FF5252',
  ERROR_LIGHT: '#FEEBEB',
  ACCENT_WARNING: '#FF8252',
  WARNING_LIGHT: '#FFE8DF',
  ACCENT_SUCCESS: '#29CCAB',
  SUCCESS_LIGHT: '#D0FFF5',
  ACCENT_INFO: '#7782CC',
  INFO_LIGHT: '#EBF1FE',
  ACTIVE: '#3d57e2',
  PREVIOUS: '#7782cc',
  DISABLED_COLOR: '#d6deeb',
  DISABLED_BACKGROUND: 'rgba(245, 247, 253, 0.5)',
  // Table
  TABLE_SUCCESS: '#29CCAB',
  TABLE_ICE_COLD: '#ADEEE3',
  TABLE_HALF_DUTCH: '#FEF8DE',
  TABLE_ROMANTIC: '#FED0C4',
  TABLE_WARNING: '#FF8252',
  TABLE_SUCCESS_GRADIENT: '#B9EFE4-#C6F7F1',
  TABLE_ICE_COLD_GRADIENT: '#D8FFF7-#ECFAF8',
  TABLE_HALF_DUTCH_GRADIENT: '#FFFBE9-#FFF5DE',
  TABLE_ROMANTIC_GRADIENT: '#FFECDF-#FED4AF',
  TABLE_WARNING_GRADIENT: '#FDD6C9-#FEC6B8',
  // Reviews
  REVIEW_SELF: '#7b95ff',
  REVIEW_COACH: '#f144c9',

  // Tippy tooltip
  TIPPY_BACKGROUND: '#6a6e7c',
  // Tooltip
  TOOLTIP_BACKGROUND: '#6a6e7c',
  // Menu
  DOT_MENU_ITEM: '#c6cad7',

  // Goals
  PERSONAL: '#FF845C',
  INPUT_TITLE: '#626c8b',
  LIST_ITEM: '#F7F7F7',
  ACTIVITY_BORDER: '#E5E7EC',
  ACTIVITY_GREY: '#6B78A4',
  TODO_BLUE: '#5C76FF',
  TODO_BLUE_LIGHT: '#E8ECFF',
  IN_PROGRESS: '#95A100',
  IN_PROGRESS_LIGHT: '#F2F4D5',
  ACCENT_ERROR_LIGHT: '#F6D0D0',
  ACCENT_SUCCESS_LIGHT: '#CEF1EA',
  TAB_WRAPPER_GRAY: '#DAE4F7',
  DRAFT_LABEL: '#7782CC4D',
  GRAY_MIDDLE: '#B6C5E4',
  GOAL_HIDDEN: '#EBEBEB',
  PROGRESSBAR_BACKGROUND: '#D6DEEB',

  // CONFIRMATION-MODALS
  CONFIRMATION_MODAL_DELETE: '#ff5252',
  CONFIRMATION_MODAL_WARNING: '#ff8252',
  CONFIRMATION_MODAL_INFO: '#9055ee',
  CONFIRMATION_MODAL_SUCCESS: '#29ccab',

  // LEARNING & PATH
  ACTIVITY: '#b8f8fc',
  PATH: '#c8b8fc',

  // STATUS COLORS
  STATUS_DRAFT: '#7782CC',
  STATUS_PUBLISHED: '#9055ee',
  STATUS_IN_PROGRESS: '#95a100',
  STATUS_ACTIVE: '#3D57e2',
  STATUS_ARCHIVED: '#8389A0',
  STATUS_SIGNING: '#95a100',
  STATUS_COMPLETED: '#29CCAB',
  STATUS_UPCOMING: '#FFA43A',
  STATUS_EXPIRED: '#ff8252',
  STATUS_DECLINED: '#ff8252',

  BORDER_GRAY: '#6d7aa6',
  LABEL_GRAY: '#8389a0',
  BG_SWITCH: '#fbfbfb',
  BOX_SHADOW_COLOR: '#959da5',
  WARNING_COLOR: '#f29d41',

  TRANSPARENT: 'transparent',
  SHADOW: '#3c374a40',
  PLACEHOLDER_LIGHT: '#909cc2',
  DISABLED_ICON: '#dae4f7',
  SEPARATOR: '#f1f1f1',
  CYAN: '#5bafb4',
  SKY_BLUE: '#88a8ef',
  // -------------------------------------------------------------------------------
  SELF_REVIEW: '#7b95ff80',
  SELF_REVIEW_LIGHT: '#7b95ff1a',
  PEER_REVIEW: '#ffc00080',
  PEER_REVIEW_DARK: '#ffc000',
  PEER_REVIEW_LIGHT: '#ffc0001a',
  COACH_REVIEW: '#f144c980',
  COACH_REVIEW_LIGHT: '#f144c91a',
};

/**
 * @deprecated since version 3.0
 */
export const COLOR_UNIT = {
  GOAL_BUSINESS: {
    primary: COLOR_SET.CORNFLOWER_BLUE,
    secondary: COLOR_SET.LAVENDER,
  },
  GOAL_LEARNING: {
    primary: COLOR_SET.FUCHSIA,
    secondary: COLOR_SET.ROSE_LIGHT,
  },
  GOAL_TEAM: {
    primary: COLOR_SET.CORAL,
    secondary: '#ffefea',
  },
  GOAL_COMPANY: {
    primary: COLOR_SET.MIDNICHT_BLUE,
    secondary: '#f8f5ff',
  },
  REVIEW: {
    primary: COLOR_SET.MIDNICHT_BLUE,
    secondary: COLOR_SET.PURPLE_LIGHT,
    icon: ReviewIcon,
  },
  CONVERSATION: {
    primary: COLOR_SET.CORAL,
    secondary: COLOR_SET.ROSE_WHITE,
    icon: ConversationIcon,
  },
  FEEDBACK: {
    primary: COLOR_SET.CYAN_GREEN,
    secondary: COLOR_SET.CYAN_LIGHT,
    icon: FeedbackIcon,
  },
  GOAL: {
    primary: COLOR_SET.FUCHSIA,
    secondary: COLOR_SET.ROSE_LIGHT,
    icon: GoalIcon,
  },
  ACTIVITY: {
    primary: COLOR_SET.PALE_SKY,
  },
  PATH: {
    primary: COLOR_SET.VIOLET_HEAVEN,
  },
};
