import React from 'react';

import { KPI_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IconButton from '~/components/IconButton';
import SvgIcon from '~/components/SvgIcon';
import AvatarCard from '~/components/UI/AvatarCard';
import AvatarGroup from '~/components/UI/AvatarGroup';
import { TableRow, TableCol } from '~/components/UI/Table';

import DownLoadIcon from '~/assets/download.svg';

import routes from '~/constants/routes';
import { downloadUserReviewPDFReport } from '~/services/reviewsOld';

const AvgText = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const ReportLink = styled(Link)`
  text-decoration: none;
  color: var(--company-color);
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserReviewReportItem = ({ reviewName, subReview, reviewKpis }) => {
  const renderKPICol = (kpi) => {
    const values = subReview.avg[kpi.id] || [];

    return kpi.type === KPI_TYPES.SKILLS ? (
      <TableCol>{values.length === 1 && values[0] > 0 && <AvgText>{values[0]}%</AvgText>}</TableCol>
    ) : (
      <TableCol>
        {values.length > 0 && values[0] > 0 && (
          <AvgText>
            {values[0]}/{values[1]}
          </AvgText>
        )}
      </TableCol>
    );
  };

  return (
    <TableRow>
      <TableCol>
        <AvatarCard userId={subReview.createdFor} type={AvatarCard.TYPES.SMALL} size={26} />
      </TableCol>
      <TableCol>
        {(subReview.coaches || []).length === 1 ? (
          <AvatarCard userId={subReview.coaches[0]} type={AvatarCard.TYPES.SMALL} size={26} />
        ) : (
          <AvatarGroup users={subReview.coaches} size={26} />
        )}
      </TableCol>
      {reviewKpis.length > 0 && reviewKpis.map((kpi) => renderKPICol(kpi))}
      <TableCol>
        <Row>
          <ReportLink
            to={routes.REVIEW_SUPERVISION_USER.build(
              {},
              {
                reviewId: subReview.id,
                isBackPath: true,
              },
            )}
          >
            <Trans>Report</Trans>
          </ReportLink>
          <IconButton
            size={24}
            onClick={async () => await downloadUserReviewPDFReport(subReview.id, reviewName)}
            noBorder
          >
            <SvgIcon width="16px" height="16px" url={DownLoadIcon} />
          </IconButton>
        </Row>
      </TableCol>
    </TableRow>
  );
};

export default React.memo(UserReviewReportItem);
