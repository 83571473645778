import styled, { css } from 'styled-components';

import { StyledIconButton, StyledSecondaryButton } from '~/components/Buttons/design';
import { Dropdown } from '~/components/Dropdown';
import { Select } from '~/components/Dropdown/design';

import { COLORS } from '~/styles';

import type { ISkillCategory } from '@learned/types';

export const Wrapper = styled.div`
  display: flex;
  gap: 151px;
  margin-top: 49px;
`;

export const Section = styled.div<{ $marginTop: number }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.$marginTop}px;
`;

export const CombinedSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 35px;
`;

export const SummarySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;

  border-bottom: solid 1px ${COLORS.BORDERS};
  justify-content: space-between;

  ${StyledSecondaryButton} {
    margin-bottom: 8px;
  }
`;

export const SkillTitleAndCategory = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SkillDetails = styled.div`
  margin-bottom: 46px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: end;

  color: ${COLORS.SUBTEXT};
  line-height: 1.2;

  svg {
    margin-bottom: 2px;
  }
`;

export const StyledDropdown = styled(Dropdown<ISkillCategory>)<{ $error: boolean }>`
  min-width: 318px;
  min-height: 38px;

  ${Select} {
    min-width: 318px;
    min-height: 38px;
    ${(props) =>
      props.$error &&
      css`
        border: solid 1px ${COLORS.ACCENT_ERROR};
      `}
  }
`;

export const Form = styled.form`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Card = styled.div`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
  box-sizing: border-box;
`;

export const Label = styled.label`
  color: ${COLORS.TEXT_HOVER};
  font-size: 14px;
  letter-spacing: -0.16px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: normal;
  margin-top: 0;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 32px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 6px;
  background-color: ${COLORS.HOVER};
  padding: 6px 12px;
  box-sizing: border-box;

  border-radius: 6px;

  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.ICONS_PRIMARY};
  cursor: pointer;

  gap: 12px;

  ${StyledIconButton} {
    width: 14px;
    height: 14px;
    color: ${COLORS.INACTIVE};

    svg {
      width: 14px;
      height: 14px;
    }

    :hover {
      color: ${COLORS.ACCENT_ERROR};
    }
  }
`;

export const Tags = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`;

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    margin: 0;
    margin-bottom: 7px;
    color: ${COLORS.TEXT_HOVER};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: center;
`;

export const SkillDescriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 17px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  ${Label} {
    margin-bottom: -5px;
  }
`;
