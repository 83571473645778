import type { ICreateServerRoute, IServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

import type { ICreateMultipleTaskStatusRequest, ITask } from '@learned/types';

/**
 *
 * @param [filters]
 * @param [filters.taskStatus] - get tasks according to active tab 'active/completed'
 * @param [filters.limit] - max return items count
 */

export function getTasks(filters = { taskStatus: '', limit: 0 }) {
  return cloudRequest(serverRoutes.tasks.getTasks as IServerRoute, filters);
}

export function getReviewTask(reviewTaskId: string) {
  return cloudRequest((serverRoutes.tasks.getReviewTask as IUpdateServerRoute)(reviewTaskId));
}

export function getReviewTaskOutside(token: string) {
  const tokenEncoded = encodeURIComponent(token);
  return cloudRequest(
    (serverRoutes.tasks.getReviewTaskOutside as IUpdateServerRoute)(tokenEncoded),
    {},
    {},
    // @ts-ignore
    { noLogin: true },
  );
}

export function createTasks(body: ICreateMultipleTaskStatusRequest) {
  return cloudRequest(serverRoutes.tasks.createTasks as IServerRoute, {}, body);
}

export function changeTaskStatus(id: ITask['id'], status: ITask['status']) {
  return cloudRequest((serverRoutes.tasks.updateStatus as ICreateServerRoute)(id), {}, { status });
}

export function changeTaskStatusOutside(status: ITask['status'], token: string) {
  const tokenEncoded = encodeURIComponent(token);
  return cloudRequest(
    (serverRoutes.tasks.updateStatusOutside as ICreateServerRoute)(tokenEncoded),
    {},
    { status },
    // @ts-ignore
    { noLogin: true },
  );
}
