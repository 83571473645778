import React from 'react';

import { TableOfContents } from '~/components/TableOfContents';
import { ReviewOutro } from '~/pages/ReviewGiveFeedback/components/ReviewOutro';

import { Footer } from './Footer';
import { MainView, QuestionWrapper, TableAndQuestions } from './QuestionsView.design';
import { QuestionView } from './QuestionView';
import { ReviewConfirmation } from './ReviewConfirmation';

import { ReturnTypeUseReviewRatingsState } from '../components/GiveReview.hooks';
import { IPopulatedReviewTask, type IPopulatedUserReview } from '../types';

function QuestionsView({
  reviewRatingsState,
  reviewTask,
  userReview,
  isCoach,
  userFrom,
  useMultiLangString,
}: {
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  isCoach?: boolean;
  userFrom?: string;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}) {
  const {
    hasError,
    hasCommentError,
    sectionState,
    showOutro,
    nextSection,
    previousSection,
    setShowOutro,
    languageState,
    formData,
    onChange,
    onExternalPublish,
    onPublish,
    onSave,
    onSaveCalibrate,
    showExternalConfirmation,
  } = reviewRatingsState;

  return (
    <>
      {showExternalConfirmation.value ? (
        <ReviewConfirmation />
      ) : (
        <MainView>
          <TableAndQuestions>
            <TableOfContents
              sections={sectionState.sections}
              currentSection={sectionState.currentSection}
              hideErrorState={!sectionState.triedToSubmit}
              setCurrentSection={(i) => {
                sectionState.setCurrentSection(i);
                setShowOutro(false);
              }}
              noNextSection={showOutro}
              nextSection={nextSection}
              previousSection={previousSection}
            />
            <QuestionWrapper>
              {!showOutro && sectionState.sections.at(sectionState.currentSection)?.data && (
                <QuestionView
                  questionData={sectionState.sections.at(sectionState.currentSection)!.data!}
                  languageState={languageState}
                  ratings={formData.getValues('ratings')}
                  onChange={onChange}
                  reviewTask={reviewTask}
                  hasError={hasError}
                  hasCommentError={hasCommentError}
                  showOtherRatings={isCoach}
                  useMultiLangString={useMultiLangString}
                />
              )}
              {showOutro && (
                <ReviewOutro
                  onPublish={reviewTask.userTo?.email ? onExternalPublish : onPublish}
                  onSave={onSave}
                  taskType={reviewTask.type}
                  taskStatus={reviewTask.status}
                  isExternalPeer={!!reviewTask.userTo?.email}
                  onSaveCalibrate={onSaveCalibrate}
                  userReview={userReview}
                  userFrom={userFrom}
                />
              )}
            </QuestionWrapper>
          </TableAndQuestions>
          <Footer onNext={showOutro ? undefined : nextSection} onBack={previousSection} />
        </MainView>
      )}
    </>
  );
}

export { QuestionsView };
