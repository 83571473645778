import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  border-radius: 6px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.CYAN};
  color: ${COLORS.WHITE};
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 9px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  & .header {
    display: flex;
    justify-content: space-between;

    & .date {
      font-size: 12px;
      color: ${COLORS.SUBTEXT};
    }
  }

  &:not(:last-child) {
    margin-bottom: 34px;
  }
`;

export const Content = styled.div<{ color?: string }>`
  color: ${({ color }) => color || 'unset'};
  position: relative;
  margin-top: 7px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 14px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  align-items: center;

  & .arrow {
    position: absolute;
    top: -5px;
    left: 32px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid ${COLORS.BG_LIST};
  }
`;
