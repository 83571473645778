import React, { useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_THEME_STATUS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { size } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { QUESTION_TYPES } from 'src/constants/questionsTypes';

import { IconOld } from '~/components/IconOld';
import Dot6Icon from '~/components/Icons/Dot6';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { PreviewQuestionCard } from '~/components/Question';
import { Footer as PreviewQuestionCardFooter } from '~/components/Question/Footer';

import { QuestionModal } from './QuestionModal';
import { SkillModal } from './SkillModal';

import emptyState from '~/assets/no-result.webp';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import {
  copyReviewQuestion,
  createReviewQuestion,
  deleteReviewQuestion,
  updateReviewQuestion,
} from '~/services/reviewQuestions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import {
  AddQuestion,
  BackButton,
  ButtonContainer,
  CardWrapper,
  CreateNewQuestion,
  CreateNewQuestionWrapper,
  Description,
  HoverIcon,
  ImportQuestionButton,
  List,
  Placeholder,
  QuestionStepFooter,
  QuestionStepTitle,
  ThemePublishButton,
  Wrapper,
} from '../design';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getQuestionTypeObject,
  getRatingLabels,
} from '../utils';

import type {
  IGeneralForm,
  IQuestionForm,
  ISelectedSkill,
  ISkill,
  IThemeQuestionDefaultData,
} from '../types';

interface IThemeProps {
  themeId?: string;
  setCurrentSection: (index: number) => void;
  generalFormMethods: UseFormReturn<IGeneralForm>;
  questionsFormMethods: UseFieldArrayReturn<IGeneralForm, 'questions', 'key'>;
  languageState: ILanguageStateReturn;
  reviewThemeId: string;
  onSubmit: (status?: React.BaseSyntheticEvent) => void;
  onPublish: () => void;
  isPublishDisabled: boolean;
  updateLastSavedTime: () => void;
  handleUpdateTheme: () => void;
  triedToSubmit: boolean;
  onBack: () => void;
  skillCategories: {
    value: string;
    label: Record<string, string>;
    levels: number;
  }[];
}

const CreateThemeStepTwo = ({
  onPublish,
  onBack,
  questionsFormMethods,
  generalFormMethods,
  languageState,
  reviewThemeId,
  updateLastSavedTime,
  handleUpdateTheme,
  skillCategories,
}: IThemeProps) => {
  const { i18n } = useLingui();

  const { fields, move, remove, insert, replace } = questionsFormMethods;

  const $isSkillModalOpen = useBoolState(false);
  const $isPublishDisabled = useBoolState(false);
  const $showWarningModal = useBoolState(false);
  const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
  const [indexItemToAdd, setIndexItemToAdd] = useState<number>(0);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
  const [selectedQuestionToDelete, setSelectedQuestionToDelete] = useState<{
    questionId: string;
    index: number;
  } | null>(null);
  const [selectedQuestionToEdit, setSelectedQuestionToEdit] = useState<
    IQuestionForm | null | undefined
  >(null);

  const createQuestion = async (value: IQuestionForm) => {
    if (reviewThemeId) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);
      const {
        data: { reviewQuestion },
      } = await createReviewQuestion({
        position: indexItemToAdd,
        // @ts-ignore
        question: {
          theme: reviewThemeId,
          type: value.type.key as REVIEW_QUESTION_TYPES,
          name: convertLanguageValue(value.name),
          description: convertLanguageValue(value.description),
          settings: {
            options: value?.settings?.isManualScale
              ? getRatingLabels(languageState, value?.options)
              : null,
            evaluators:
              value.type.key === REVIEW_QUESTION_TYPES.GOAL_PLAN
                ? [REVIEW_QUESTION_EVALUATORS.EMPLOYEE, REVIEW_QUESTION_EVALUATORS.COACH] // for plan-goal - always employee and coach (not editable)
                : evaluators,
            isCommentsAllowed: value.settings.isCommentsAllowed,
            isCommentsObligated: value.settings.isCommentsObligated,
            isAnswerObligated: !value.settings.isAnswerObligated,
            isMeasurementReversed: value.settings.isMeasurementReversed,
            isManualScale: value.settings.isManualScale,
            skillCategory:
              value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && value.type?.id,
            skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
          },
        },
      });
      insert(indexItemToAdd, {
        ...value,
        id: reviewQuestion.id,
      });
      resetQuestionForm();
      updateLastSavedTime();
    }
  };

  const editQuestion = async (value: IQuestionForm) => {
    if (selectedQuestionToEdit) {
      const evaluators = Object.keys(value.settings.evaluators)
        .map((key: string) =>
          value.settings?.evaluators?.[key as keyof typeof value.settings.evaluators] ? key : null,
        )
        .filter(Boolean);

      const {
        data: { reviewQuestion },
      } = await updateReviewQuestion(selectedQuestionToEdit.id!, {
        type: value.type.key as REVIEW_QUESTION_TYPES,
        theme: reviewThemeId,
        name: convertLanguageValue(value.name),
        description: convertLanguageValue(value.description),
        settings: {
          options: value?.settings?.isManualScale
            ? getRatingLabels(languageState, value?.options)
            : null,
          evaluators:
            value.type.key === REVIEW_QUESTION_TYPES.GOAL_PLAN
              ? [REVIEW_QUESTION_EVALUATORS.EMPLOYEE, REVIEW_QUESTION_EVALUATORS.COACH] // for plan-goal - always employee and coach (not editable)
              : evaluators,
          isCommentsAllowed: value.settings.isCommentsAllowed,
          isCommentsObligated: value.settings.isCommentsObligated,
          isAnswerObligated: !value.settings.isAnswerObligated,
          isMeasurementReversed: value.settings.isMeasurementReversed,
          isManualScale: value.settings.isManualScale,
          skillCategory: value.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && value.type?.id,
          skills: value.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && value.settings.skills,
        },
      });
      replace(
        fields.map((question) => {
          if (question.id === reviewQuestion.id) {
            return {
              ...value,
              id: reviewQuestion.id,
            };
          }
          return question;
        }),
      );
      resetQuestionForm();
      updateLastSavedTime();
    }
  };

  const onSubmit = async (value: IQuestionForm) => {
    if (selectedQuestionToEdit) {
      editQuestion(value);
    } else {
      createQuestion(value);
    }
  };

  const resetQuestionForm = () => {
    setSelectedSkills([]);
    setSelectedSkill(null);
    setSelectedQuestionToEdit(null);
  };

  const createNewQuestion = () => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(size(fields));
  };

  const insertNewQuestion = (index: number) => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(index + 1);
  };

  const closeQuestionModal = () => {
    setIsCreateQuestionModalOpen(false);
    resetQuestionForm();
  };

  const duplicateQuestion = async (id: IQuestionForm['id'], index?: number) => {
    if (id) {
      const {
        data: { reviewQuestion },
      } = await copyReviewQuestion(id);

      const questionType = getQuestionTypeObject(reviewQuestion.type);

      if (!questionType) {
        return;
      }

      insert(index ?? questionsFormMethods.fields.length, {
        id: reviewQuestion.id,
        name: turnMultiLangIntoArray(reviewQuestion.name, languageState.companyLanguages),
        description: turnMultiLangIntoArray(
          reviewQuestion.description || {},
          languageState.companyLanguages,
        ),
        type: questionType,
        settings: {
          isCommentsAllowed:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
          isCommentsObligated:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
          isMeasurementReversed:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
          isManualScale:
            (reviewQuestion.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
          evaluators: getEvaluators(
            (reviewQuestion.settings as IThemeQuestionDefaultData)
              .evaluators as REVIEW_QUESTION_EVALUATORS[],
          ),
          isAnswerObligated: false,
        },
        options: convertQuestionOptions(reviewQuestion, languageState.companyLanguages),
      });

      setIsCreateQuestionModalOpen(false);
      updateLastSavedTime();
    }
  };

  const handleTitleClick = () => {};

  const handleEdit = (questionId: string) => {
    setIsCreateQuestionModalOpen(true);
    const question = fields.find(({ id }) => id === questionId);

    if (question?.type?.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills(question?.settings?.skills || []);
    }

    if (question) {
      setSelectedQuestionToEdit({
        ...question,
        name: getLabelsForAvailableLanguages(languageState, question.name),
        description: getLabelsForAvailableLanguages(languageState, question.description || []),
      });
    }
  };

  const isDraft = generalFormMethods.watch('status') === REVIEW_THEME_STATUS.DRAFT;

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen.off();
  };

  const handleDeleteConfirm = async () => {
    if (selectedQuestionToDelete) {
      await deleteReviewQuestion(selectedQuestionToDelete.questionId);
      remove(selectedQuestionToDelete.index);
      closeQuestionModal();
      updateLastSavedTime();
    }
  };

  const handleDeleteFromQuestionModal = (id: IQuestionForm['id']) => {
    const index = fields.findIndex((question) => question.id === id);

    if (!id || index === -1) {
      return;
    }
    setSelectedQuestionToDelete({
      questionId: id,
      index,
    });
    $showWarningModal.on();
  };

  return (
    <Wrapper>
      <QuestionStepTitle>{i18n._(t`Questions`)}</QuestionStepTitle>
      <div>
        {size(fields) ? (
          <div>
            <Description>
              <Trans>
                Add questions to your theme and import these questions into your reviews. You can
                also add, edit or delete questions later. Drag questions in the desired order.
              </Trans>
            </Description>
            <List>
              <DragDropContext
                onDragEnd={(result) => {
                  move(result.source.index, result?.destination?.index || 0);
                  handleUpdateTheme();
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map(({ id, name, type /* ...rest*/ }, idx) => {
                        const questionType = Object.values(REVIEW_QUESTION_TYPES).find(
                          (value) => value === type.key,
                        );

                        if (!id || !questionType) {
                          return null;
                        }
                        return (
                          <Draggable key={id} draggableId={id} index={idx}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <CardWrapper>
                                  <div className="question">
                                    <HoverIcon isVisible={snapshot.isDragging}>
                                      <Dot6Icon size={32} />
                                    </HoverIcon>
                                    <PreviewQuestionCard
                                      languageState={languageState}
                                      onClickTitle={handleTitleClick}
                                      index={idx}
                                      type={type.key as QUESTION_TYPES}
                                      name={name}
                                      isActive={snapshot.isDragging}
                                    >
                                      <PreviewQuestionCardFooter
                                        onDelete={() => {
                                          setSelectedQuestionToDelete({
                                            questionId: id,
                                            index: idx,
                                          });
                                          $showWarningModal.on();
                                        }}
                                        onDuplicate={() => duplicateQuestion(id, idx + 1)}
                                        onEdit={() => handleEdit(id)}
                                        isEditButtonVisible
                                      />
                                    </PreviewQuestionCard>
                                  </div>
                                  {size(fields) - 1 > idx && (
                                    <button
                                      className="newQuestion"
                                      onClick={() => insertNewQuestion(idx)}
                                    >
                                      <AddQuestion>
                                        <IconOld name="AddCircle" width={14} height={14} />
                                      </AddQuestion>
                                    </button>
                                  )}
                                </CardWrapper>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <CreateNewQuestionWrapper>
                <CreateNewQuestion onClick={createNewQuestion}>
                  <IconOld name="AddCircle" width={14} height={14} />
                  <span>
                    <Trans>Create new</Trans>
                  </span>
                </CreateNewQuestion>
              </CreateNewQuestionWrapper>
            </List>
          </div>
        ) : (
          <Placeholder>
            <div className="description">
              <span>
                <Trans>No questions yet…</Trans>
              </span>
              <span>
                <Trans>Start importing questions!</Trans>
              </span>
            </div>
            <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
            <ButtonContainer>
              <ImportQuestionButton type="button" onClick={createNewQuestion}>
                <Trans>Create first question</Trans>
              </ImportQuestionButton>
            </ButtonContainer>
          </Placeholder>
        )}
        <QuestionStepFooter>
          <BackButton type="button" onClick={onBack}>
            <IconOld name="ChevronBack" width={16} height={16} className="icon" />
            <span>{i18n._(t`Back`)}</span>
          </BackButton>
          {!!size(fields) && isDraft && (
            <ThemePublishButton
              onClick={onPublish}
              type="button"
              disabled={$isPublishDisabled.value}
            >
              <span>{i18n._(t`Publish`)}</span>
            </ThemePublishButton>
          )}
          <div />
        </QuestionStepFooter>
      </div>
      {isCreateQuestionModalOpen && (
        <QuestionModal
          languageState={languageState}
          onClose={closeQuestionModal}
          onDelete={handleDeleteFromQuestionModal}
          onSubmit={onSubmit}
          onDuplicate={duplicateQuestion}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategories}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          skills={skills}
          setSelectedSkill={setSelectedSkill}
          setCurrentStep={setCurrentStep}
          selectedQuestionToEdit={selectedQuestionToEdit}
        />
      )}

      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skills}
          setSkills={setSkills}
          selectedSkills={selectedSkills}
          currentStep={currentStep}
        />
      )}
      {$showWarningModal.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          title={i18n._(t`Delete?`)}
          description={i18n._(t`Are you sure you want to delete this question?`)}
          cancelButton={i18n._(t`Cancel`)}
          submitButton={i18n._(t`Delete`)}
          onClose={$showWarningModal.off}
          onSubmit={handleDeleteConfirm}
        />
      )}
    </Wrapper>
  );
};

export { CreateThemeStepTwo };
