import { pageDashboard, pageOverview, pageUpdate } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'superadmin';

// overview

export const SUPERADMIN_COMPANY_DASHBOARD = {
  url: `/${pageOverview(routeName)}/companies`,
};

export const SUPERADMIN_COMPANY_MEMBERS = {
  url: `/${pageOverview(routeName)}/company-members`,
};

export const SUPERADMIN_OVERVIEW = {
  url: `/${pageOverview(routeName)}/overview`,
};

export const SUPERADMIN_TEMPLATES_REVIEW = {
  url: `/${pageOverview(routeName)}/templates/review`,
};

export const SUPERADMIN_TEMPLATE_REVIEW_CREATE = {
  url: `/${pageOverview(routeName)}/templates/review-create`,
};

export const SUPERADMIN_TEMPLATE_REVIEW_UPDATE = {
  url: ({ reviewTemplateId = ':reviewTemplateId' }) =>
    pageUpdate(SUPERADMIN_TEMPLATES_REVIEW.url, { id: reviewTemplateId }),
};

export const SUPERADMIN_REQUESTS = {
  url: `/${pageOverview(routeName)}/requests`,
};

export const SUPERADMIN_SKILL_MATRIX = {
  url: `/${pageOverview(routeName)}/skill-matrix`,
};

export const SUPERADMIN_SKILL_TEMPLATE_CREATE = new LinkConstructor([], () =>
  pageOverview(`/${routeName}/skill-templates/create`),
);

export const SUPERADMIN_SKILL_TEMPLATE_EDIT = new LinkConstructor(
  [],
  ({ skillTemplateId = ':skillTemplateId' }) =>
    `/${pageOverview(routeName)}/skill-templates/${skillTemplateId}/edit`,
);

export const SUPERADMIN_SKILL_TEMPLATE_VIEW = new LinkConstructor(
  [],
  ({ skillTemplateId = ':skillTemplateId' }) =>
    pageDashboard(`/${routeName}/skill-templates`, { id: skillTemplateId }),
);

export const SUPERADMIN_INDUSTRIES = {
  url: `/${pageOverview(routeName)}/industries`,
};

export const SUPERADMIN_JOB_LIBRARY = {
  url: `/${pageOverview(routeName)}/job-library`,
};

export const SUPERADMIN_JOB_TEMPLATE_CREATE = new LinkConstructor([], () =>
  pageOverview(`/${routeName}/job-templates/create`),
);

export const SUPERADMIN_JOB_TEMPLATE_EDIT = new LinkConstructor(
  [],
  ({ jobTemplateId = ':jobTemplateId' }) =>
    `/${pageOverview(routeName)}/job-templates/${jobTemplateId}/edit`,
);

export const SUPERADMIN_JOB_TEMPLATE_VIEW = new LinkConstructor(
  [],
  ({ jobTemplateId = ':jobTemplateId' }) =>
    pageDashboard(`/${routeName}/job-templates`, { id: jobTemplateId }),
);
