import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { DashboardHeader } from '~/components/DashboardHeader';
import { MultiLangComponent } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { ActionItemBlock, ActionsWrapper } from './design';

import type { HeaderFocusModeProps } from './types';

const HeaderFocusMode = ({
  title,
  goBack,
  languageState,
  submitButton,
  iconButtons,
  subHeader,
  status,
  isBackIcon,
}: HeaderFocusModeProps) => {
  return (
    <div>
      <DashboardHeader
        title={title}
        titleMaxWidth="400px"
        status={status}
        onBack={goBack}
        actions={
          <ActionsWrapper>
            {languageState && (
              <>
                <MultiLangComponent languageState={languageState} />
              </>
            )}

            {!isEmpty(iconButtons) && (
              <ActionItemBlock>
                {iconButtons?.map((iconButton, index) => {
                  const content = (
                    <span>
                      <Button
                        icon={iconButton.icon}
                        size={
                          iconButton.icon === ICONS.DELETE_BIN
                            ? ButtonSize.MEDIUM
                            : ButtonSize.MEDIUM
                        }
                        variant={
                          iconButton.icon === ICONS.DELETE_BIN
                            ? ButtonVariant.ICON_DELETE
                            : ButtonVariant.ICON
                        }
                        isLoading={iconButton.isLoading}
                        disabled={iconButton.disabled}
                        onClick={iconButton.onClick}
                      />
                    </span>
                  );

                  return iconButton.tooltip ? (
                    <Tooltip key={index} size={TOOLTIP_SIZES.BIG} tooltip={iconButton.tooltip}>
                      {content}
                    </Tooltip>
                  ) : (
                    content
                  );
                })}
              </ActionItemBlock>
            )}
            {submitButton && (
              <ActionItemBlock>
                <Button
                  label={submitButton.title}
                  icon={submitButton.icon}
                  size={ButtonSize.MEDIUM}
                  variant={submitButton.type}
                  isLoading={submitButton.isLoading}
                  onClick={submitButton.onClick}
                  disabled={submitButton.disabled}
                  tooltip={submitButton.tooltip}
                />
              </ActionItemBlock>
            )}
          </ActionsWrapper>
        }
        subHeader={subHeader}
        isBackIcon={isBackIcon}
      />
    </div>
  );
};

export { HeaderFocusMode };
