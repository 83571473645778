import React, { useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { Trans } from '@lingui/macro';
import { Controller } from 'react-hook-form';

import { Icon, ICONS } from '~/components/Icon';
import OptionsIcon from '~/components/Icons/Options';
import Switch from '~/components/Switch';
import type { IQuestionForm } from '~/pages/ReviewThemeSetup/types';

import {
  Button,
  Description,
  ErrorText,
  GeneralSettings,
  SettingItem,
  Settings,
  SubTitle,
  Title,
  Wrapper,
} from './design';

import { QUESTION_TYPES } from '~/constants/questionsTypes';
import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

interface IFooterProps {
  onDelete?: (id: IQuestionForm['id']) => void;
  onDuplicate?: (id: IQuestionForm['id'], index?: number) => Promise<void>;
  formMethods?: UseFormReturn<IQuestionForm>;
  isSettingsVisible?: boolean;
  isLeftSideVisible?: boolean;
}

const Footer = ({
  isSettingsVisible = true,
  isLeftSideVisible = true,
  onDelete,
  onDuplicate,
  formMethods,
}: IFooterProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [disablePeerToggle, setDisablePeerToggle] = useState(false);

  const questionTypeWatch = formMethods?.watch('type');

  const RATING_QUESTION_TYPES = [
    QUESTION_TYPES.RATING,
    QUESTION_TYPES.CUSTOM_SKILL,
    QUESTION_TYPES.SKILL_CATEGORY,
  ];

  useEffect(() => {
    setDisablePeerToggle(questionTypeWatch?.key === REVIEW_QUESTION_TYPES.GOAL_PLAN);
  }, [questionTypeWatch]);

  return (
    <>
      {isSettingsVisible && isSettingsOpen && formMethods && (
        <>
          <Settings
            $showErrorBorder={!!formMethods.formState.errors.settings?.evaluators}
            className="full-width"
          >
            <Title>
              <Trans>Settings</Trans>
            </Title>
            <Description>
              <Trans>Select who will evaluate this question (90º/180º/360º feedback)</Trans>
            </Description>
            <Controller
              name="settings.evaluators.employee"
              control={formMethods.control}
              render={({ field: { onChange, value } }) => (
                <SettingItem isActive={value}>
                  <Switch
                    onChange={(switchValue: boolean) => {
                      if (!switchValue) {
                        formMethods.setValue('settings.evaluators.employee', false);
                      }
                      onChange(switchValue);
                    }}
                    checked={value}
                  />
                  <span className="item">
                    <Trans>Employee</Trans>
                  </span>
                </SettingItem>
              )}
            />
            <Controller
              name="settings.evaluators.peer"
              control={formMethods.control}
              disabled={disablePeerToggle}
              render={({ field: { onChange, value } }) => (
                <SettingItem isActive={value}>
                  <span className="item">
                    <Switch
                      disabled={disablePeerToggle}
                      onChange={onChange}
                      checked={disablePeerToggle ? false : value}
                    />
                    <Trans>Peers</Trans>
                  </span>
                </SettingItem>
              )}
            />
            <Controller
              name="settings.evaluators.coach"
              control={formMethods.control}
              render={({ field: { onChange, value } }) => (
                <SettingItem isActive={value}>
                  <Switch onChange={onChange} checked={value} />
                  <span className="item">
                    <Trans>Coaches</Trans>
                  </span>
                </SettingItem>
              )}
            />
          </Settings>
          {formMethods.formState.errors.settings?.evaluators && (
            <ErrorText>
              <Trans>At least one toggle should be enabled</Trans>
            </ErrorText>
          )}
          {formMethods.watch('type').key !== QUESTION_TYPES.GOAL_PLAN && (
            <GeneralSettings>
              <SubTitle>General settings</SubTitle>
              {formMethods.watch('type')?.key !== REVIEW_QUESTION_TYPES.TEXT && (
                <>
                  <Controller
                    name="settings.isCommentsAllowed"
                    control={formMethods.control}
                    render={({ field: { onChange, value } }) => (
                      <SettingItem isActive={value}>
                        <Switch
                          onChange={(v: boolean) => {
                            if (!v) {
                              formMethods.setValue('settings.isCommentsObligated', false);
                            }
                            onChange(v);
                          }}
                          checked={value}
                        />
                        <span className="item">
                          <Trans>Allow comments</Trans>
                        </span>
                      </SettingItem>
                    )}
                  />
                  <Controller
                    name="settings.isCommentsObligated"
                    control={formMethods.control}
                    render={({ field: { onChange, value } }) => (
                      <SettingItem isActive={value}>
                        <span className="item">
                          <Switch
                            disabled={!formMethods.watch('settings.isCommentsAllowed')}
                            onChange={onChange}
                            checked={value}
                          />
                          <Trans>Comments are obligated</Trans>
                        </span>
                      </SettingItem>
                    )}
                  />
                </>
              )}
              <Controller
                name="settings.isAnswerObligated"
                control={formMethods.control}
                render={({ field: { onChange, value } }) => (
                  <SettingItem isActive={value}>
                    <Switch onChange={onChange} checked={value} />
                    <span className="item">
                      <Trans>Allow participants to skip this question (N/A)</Trans>
                    </span>
                  </SettingItem>
                )}
              />
              {RATING_QUESTION_TYPES.includes(
                formMethods.watch('type')?.key as REVIEW_QUESTION_TYPES,
              ) && (
                <Controller
                  name="settings.isManualScale"
                  control={formMethods.control}
                  render={({ field: { onChange, value } }) => (
                    <SettingItem isActive={value}>
                      <Switch onChange={onChange} checked={value} />
                      <span className="item">
                        <Trans>Customize rating scale</Trans>
                      </span>
                    </SettingItem>
                  )}
                />
              )}
            </GeneralSettings>
          )}
        </>
      )}
      {(isSettingsVisible || (isLeftSideVisible && (onDelete || onDuplicate))) && (
        <Wrapper>
          <div className="section">
            {isLeftSideVisible && (onDelete || onDuplicate) && (
              <>
                {onDuplicate && (
                  <Button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      onDuplicate(formMethods?.getValues()?.id);
                    }}
                  >
                    <Icon icon={ICONS.DUPLICATE} />
                    <span>
                      <Trans id="Duplicate" />
                    </span>
                  </Button>
                )}
                {onDelete && (
                  <Button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      onDelete(formMethods?.getValues()?.id);
                    }}
                    hoverTextColor={COLORS.ERROR}
                    hoverBackgroundColor={COLORS.ERROR_HOVER}
                  >
                    <Icon icon={ICONS.DELETE_BIN} />
                    <span>
                      <Trans id="Delete" />
                    </span>
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="section">
            {isSettingsVisible && formMethods && (
              <Button onClick={() => setIsSettingsOpen(!isSettingsOpen)} type="button">
                {isSettingsOpen ? (
                  <span>
                    <Trans>Hide Settings</Trans>
                  </span>
                ) : (
                  <span>
                    <Trans>Show Settings</Trans>
                  </span>
                )}
                <OptionsIcon size={18} />
              </Button>
            )}
          </div>
        </Wrapper>
      )}
    </>
  );
};

export { Footer };
