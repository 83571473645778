import React, { useMemo } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';
import { MENU_SIZE } from '~/pages/Reports/options';
import type { IReviewDashboardCycleForm } from '~/pages/Reviews/DashboardCycle/ReviewDashboardForm/types';
import { ReviewEmployeeOverviewModal } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal';

import { Filters } from './components/Filters';
import { useReviewParticipationPerTeam } from './hooks/useReviewParticipationPerTeam';

import { COLORS } from '~/styles';

import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div<{ $isExpandMode: boolean }>`
  width: ${(props) => (props.$isExpandMode ? '1337px' : '1000px')};
  margin-top: 40px;
`;

const TableGridWrapper = styled.div<{ $isPaginationHidden: boolean }>`
  box-sizing: border-box;
  padding: ${({ $isPaginationHidden }) => `24px 31px ${$isPaginationHidden ? '38px' : '5px'} 31px`};
  border-radius: 6px;
  background: ${COLORS.WHITE};

  & th span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 172px; // min with of column
  }

  & th {
    position: relative;
  }
`;

interface IPerTeamSectionProps {
  isExpandMode: boolean;
  onToggleExpandMode: () => void;
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
}

const PerTeamSection = ({
  isExpandMode,
  onToggleExpandMode,
  formMethods,
}: IPerTeamSectionProps) => {
  const params: Record<string, string | undefined> = useParams();
  const { i18n } = useLingui();
  const reviewId = params.reviewId as string;
  const {
    items,
    columns,
    totalCount,
    isLoading,
    filters,
    onChangeFilters,
    onChangePagination,
    onColClick,
    onEmployeeClick,
    isAllTeamsOpened,
    onOpenAllTeams,
    onCloseAllTeams,
    selectedEmployee,
    onCloseEmployeeModal,
  } = useReviewParticipationPerTeam({
    reviewId,
  });
  const customPaginationList = useMemo(
    () => [
      { id: 5, label: i18n._(t`5 per page`) },
      { id: 10, label: i18n._(t`10 per page`) },
    ],
    [i18n],
  );

  return (
    <Wrapper $isExpandMode={isExpandMode}>
      <TableGridWrapper $isPaginationHidden={totalCount <= filters.pagination.limit}>
        <Filters
          formMethods={formMethods}
          filters={filters}
          onChangeFilters={onChangeFilters}
          isExpandMode={isExpandMode}
          onToggleExpandMode={onToggleExpandMode}
          isAllTeamsOpened={isAllTeamsOpened}
          onOpenAllTeams={onOpenAllTeams}
          onCloseAllTeams={onCloseAllTeams}
        />
        <TableGrid
          data={items}
          columns={columns}
          isLeftColumnsStriped
          isScrollbarVisible
          leftMinWidth={`${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`}
          isLoading={isLoading}
          onColClick={{
            column: 'name',
            onClick: onColClick,
          }}
          onRowClick={onColClick}
          onCellClick={onEmployeeClick}
          paginationProps={{
            totalCount,
            pagination: filters.pagination,
            changePagination: onChangePagination,
            customPaginationList,
          }}
          filtersProps={{
            // @ts-ignore
            filters: {
              setSearch: (value) => onChangeFilters('search', value), // reset filters
            },
            isFiltered: filters.search,
          }}
          placeholderProps={{
            noResultText: i18n._(t`No review participation data`),
          }}
          sortBy={filters.sortBy}
          setSortBy={(value) => onChangeFilters('sortBy', value)}
        />
      </TableGridWrapper>
      {selectedEmployee && (
        <ReviewEmployeeOverviewModal
          userReviewId={selectedEmployee}
          tasks={formMethods.watch('tasks')}
          onClose={onCloseEmployeeModal}
        />
      )}
    </Wrapper>
  );
};

export { PerTeamSection };
