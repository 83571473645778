import { REPORT_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { getCoachTeamsForUser, isUserWithCoachRole } from '../utils';

import type { IMenuItem } from '../types';
import type { UserWithConnections } from '../utils';
import type { ITeam, IUser, IReportCustomReport } from '@learned/types';
import type { I18n } from '@lingui/core';

const getPersonalReportMenuItem = (
  customReports: IReportCustomReport[],
  isEngagementModuleEnabled: boolean,
  isModulePerformanceEnabled: boolean,
) => {
  let yourEngagementMenu = {};
  let yourPerformanceMenu = {};
  const engagementMenuConfig = customReports.find(
    (report) => report.reportType === REPORT_TYPES.ENGAGEMENT,
  );
  if (isEngagementModuleEnabled && engagementMenuConfig) {
    yourEngagementMenu = {
      title: (i18n: I18n) => i18n._(t`Your engagement`),
      key: REPORT_TYPES.ENGAGEMENT,
      role: ROLES.USER,
      children: engagementMenuConfig.tabs
        .filter((tab) => tab.isPersonal)
        .map((tab) => ({
          title: (_i18n: I18n) => tab.name,
          url: routes.REPORTS_USER_CUSTOM_REPORT.build(
            // @ts-ignore
            { role: ROLES.USER },
            { reportId: tab.tabId, reportType: REPORT_TYPES.ENGAGEMENT },
          ),
          key: `${REPORT_TYPES.ENGAGEMENT}-${tab.tabId}`,
          role: ROLES.USER,
        })),
    };
  }

  const performanceMenuConfig = customReports.find(
    (report) => report.reportType === REPORT_TYPES.PERFORMANCE,
  );

  if (isModulePerformanceEnabled && performanceMenuConfig) {
    yourPerformanceMenu = {
      title: (i18n: I18n) => i18n._(t`Your performance`),
      key: REPORT_TYPES.PERFORMANCE,
      role: ROLES.USER,
      children: performanceMenuConfig.tabs
        .filter((tab) => tab.isPersonal)
        .map((tab) => ({
          title: (_i18n: I18n) => tab.name,
          url: routes.REPORTS_USER_CUSTOM_REPORT.build(
            // @ts-ignore
            { role: ROLES.USER },
            { reportId: tab.tabId, reportType: REPORT_TYPES.PERFORMANCE },
          ),
          key: `${REPORT_TYPES.PERFORMANCE}-${tab.tabId}`,
          role: ROLES.USER,
        })),
    };
  }
  const menu = {
    title: (i18n: I18n) => i18n._(t`Personal Reports`),
    key: 'reports_personal',
    children: [yourPerformanceMenu, yourEngagementMenu].filter(
      (c) => c && Object.keys(c).length > 0,
    ),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

/**
 * Return the route for the Report Icon in main menu
 * @param role the role of user
 * @param customReports all available custom reports
 * @returns IMenuItem object
 */
const getRouteForReportMainMenuIcon = (role: string, customReports: IReportCustomReport[]) => {
  switch (role) {
    case ROLES.ADMIN: {
      // Admin has the dashboard
      return {
        title: (i18n: I18n) => i18n._(t`Dashboard`),
        // @ts-ignore
        url: routes.REPORTS_ADMIN_DASHBOARD.build({ role: ROLES.ADMIN }),
        role: ROLES.ADMIN,
        key: 'reports_admin_dashboard',
      };
    }
    case ROLES.COACH:
      {
        // Picks the first available custom report for coach
        const report = customReports.find(() => true);
        const tab = report?.tabs.filter((tab) => !tab.isPersonal).find(() => true);
        if (report && tab) {
          return {
            url: routes.REPORTS_COACH_CUSTOM_REPORT.build(
              // @ts-ignore
              { role: ROLES.USER },
              { reportId: tab.tabId, reportType: report.reportType },
            ),
            role: ROLES.COACH,
            key: `${report.reportType}-${tab.tabId}`,
          };
        }
      }
      break;
    case ROLES.USER: {
      // Picks the first available custom report for user
      const report = customReports.find(() => true);
      const tab = report?.tabs.filter((tab) => tab.isPersonal).find(() => true);
      if (report && tab) {
        return {
          url: routes.REPORTS_USER_CUSTOM_REPORT.build(
            // @ts-ignore
            { role: ROLES.USER },
            { reportId: tab.tabId, reportType: report.reportType },
          ),
          role: ROLES.USER,
          key: `${report.reportType}-${tab.tabId}`,
        };
      }
    }
  }
  return {
    title: (i18n: I18n) => i18n._(t`Home`),
    url: '',
    role,
    key: '',
  };
};

const getAdminCustomReportMenuItem = (
  isAdmin: boolean,
  languageState: ILanguageStateReturn,
  customReports: IReportCustomReport[],
  isModuleLearningEnabled: boolean,
) => {
  if (!isAdmin || !customReports.length) {
    return false as const;
  }

  const menu = {
    title: (i18n: I18n) => i18n._(t`Reports`),
    key: 'reports_custom',
    children: [
      ...customReports.map((report) => ({
        title: (_i18n: I18n) => report.name[languageState.companyPrimaryLanguage.locale],
        key: report.reportType,
        role: ROLES.ADMIN,
        children: report.tabs
          .filter((tab) => !tab.isPersonal)
          .map((tab) => ({
            title: (_i18n: I18n) => tab.name,
            url: routes.REPORTS_ADMIN_CUSTOM_REPORT.build(
              // @ts-ignore
              { role: ROLES.ADMIN },
              { reportId: tab.tabId, reportType: report.reportType },
            ),
            key: `${report.reportType}-${tab.tabId}`,
            role: ROLES.ADMIN,
          }))
          .concat(
            ...(report.reportType === REPORT_TYPES.PERFORMANCE
              ? [
                  {
                    title: (i18n: I18n) => i18n._(t`9-grid`),
                    url: routes.NINE_GRID_REPORT.build(
                      // @ts-ignore
                      { role: ROLES.ADMIN },
                    ),
                    role: ROLES.ADMIN,
                    key: `${report.reportType}-nine-grid`,
                  },
                ]
              : []),
          )
          .filter((c) => c),
      })),
      {
        title: (_i18n: I18n) => _i18n._(t`Other`),
        key: 'report_old',
        role: ROLES.ADMIN,
        children: [
          isModuleLearningEnabled && {
            title: (i18n: I18n) => i18n._(t`Learning report`),
            // @ts-ignore
            url: routes.LEARNINGS_REPORT.build({ role: ROLES.ADMIN }),
            key: 'report_old-learnings',
            role: ROLES.ADMIN,
          },
          {
            title: (i18n: I18n) => i18n._(t`Employee report`),
            // @ts-ignore
            url: routes.MEMBERS_REPORT.build({ role: ROLES.ADMIN }),
            key: 'report_old-members',
            role: ROLES.ADMIN,
          },
          {
            title: (i18n: I18n) => i18n._(t`1:1's`),
            // @ts-ignore
            url: routes.CONVERSATION_REPORT.build({ role: ROLES.ADMIN }),
            key: 'report_old-conversations',
            role: ROLES.ADMIN,
          },
        ].filter((c) => c),
      },
    ].filter((c) => c),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

const getCoachCustomReportMenuItem = (
  isAdmin: boolean,
  isCoach: boolean,
  customReports: IReportCustomReport[],
  languageState: ILanguageStateReturn,
) => {
  if (isAdmin || !isCoach || !customReports.length) {
    return false as const;
  }

  const menu = {
    title: (i18n: I18n) => i18n._(t`Reports`),
    key: 'reports_custom',
    children: [
      ...customReports.map((report) => ({
        title: (_i18n: I18n) => report.name[languageState.companyPrimaryLanguage.locale],
        key: report.reportType,
        role: ROLES.COACH,
        children: report.tabs
          .filter((tab) => !tab.isPersonal)
          .map((tab) => ({
            title: (_i18n: I18n) => tab.name,
            url: routes.REPORTS_COACH_CUSTOM_REPORT.build(
              // @ts-ignore
              { role: ROLES.USER },
              { reportId: tab.tabId, reportType: report.reportType },
            ),
            key: `${report.reportType}-${tab.tabId}`,
            role: ROLES.COACH,
          })),
      })),
    ].filter((c) => c),
  };

  if (menu.children.length) {
    return menu;
  }

  return false as const;
};

const getCoachTeamMenuItem = (
  user: UserWithConnections,
  teams: Record<string, ITeam>,
  selectedCompany: string,
  isEngagementModuleEnabled: boolean,
) => {
  const storageItems = localStorage.getItem('OpenMenuItems');
  const previousOpenTeams = JSON.parse(storageItems as string) || [];
  const teamsForUser = getCoachTeamsForUser(user, teams, selectedCompany);
  const sortedTeams = teamsForUser
    .map((team) => ({ team: team.name, members: team.members, id: team.id }))
    .sort((a, b) => {
      const nameA = a.team.toLowerCase();
      const nameB = b.team.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      return 0;
    });
  let selectedTeam = sortedTeams.find((team) => previousOpenTeams.indexOf(team.id) !== -1);
  selectedTeam = selectedTeam ? selectedTeam : sortedTeams.length ? sortedTeams[0] : undefined;

  if (!selectedTeam || !selectedTeam.members.length) {
    return {
      title: (i18n: I18n) => i18n._(t`Teams`),
      icon: ICONS.TEAM,
      key: 'coach-menu',
      children: teamsForUser
        .map((team) => {
          return {
            title: () => team.name,
            // @ts-ignore
            url: routes.TEAM_COACH.build({
              role: ROLES.COACH,
              teamId: team?.id,
              companyId: selectedCompany,
            }),
            aliases: [
              routes.TEAM_COACH.build({
                role: ROLES.COACH,
                teamId: team.id,
                companyId: selectedCompany,
              }),
            ],
            role: ROLES.COACH,
            team: team.id,
          };
        })
        .filter((c) => c),
    };
  } else {
    const menu = {
      children: teamsForUser
        .map((team) => {
          return {
            title: () => team.name,
            // @ts-ignore
            url: routes.TEAM_COACH.build(
              { role: ROLES.COACH, teamId: selectedTeam?.id, companyId: selectedCompany },
              ...(isEngagementModuleEnabled
                ? []
                : [{ query: { memberId: selectedTeam?.members[0] } }]),
            ),
            aliases: [
              routes.TEAM_COACH.build({
                role: ROLES.COACH,
                teamId: team.id,
                companyId: selectedCompany,
              }),
            ],
            role: ROLES.COACH,
            team: team.id,
          };
        })
        .filter((c) => c),
      title: (i18n: I18n) => i18n._(t`Teams`),
      icon: ICONS.TEAM,
      key: 'coach-menu',
    };

    return menu;
  }
};

const footerMenuStructure = ({
  user,
  teams,
  languageState,
  selectedCompany,
  isModuleLearningEnabled,
  isUserHasAdminRole,
  isModuleEngagementEnabled,
  isModulePerformanceEnabled,
  isModuleJobMatrixEnabled,
  customReports,
}: {
  user: IUser;
  teams: Record<string, ITeam>;
  languageState: ILanguageStateReturn;
  selectedCompany: string;
  isModuleLearningEnabled: boolean;
  isUserHasAdminRole: boolean;
  isModuleEngagementEnabled: boolean;
  isModulePerformanceEnabled: boolean;
  isModuleJobMatrixEnabled: boolean;
  customReports: IReportCustomReport[];
}): IMenuItem[] =>
  [
    (isUserHasAdminRole && isModuleJobMatrixEnabled
      ? {
          title: (i18n: I18n) => i18n._(t`Job matrix`),
          key: 'jobMatrix',
          icon: ICONS.JOB_MATRIX,
          children: [
            {
              title: (i18n: I18n) => i18n._(t`Job profiles`),
              // @ts-ignore
              url: routes.JOB_PROFILES_ADMIN.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.JOB_PROFILES_ADMIN.build({ role: ROLES.ADMIN })],
            },
            {
              title: (i18n: I18n) => i18n._(t`Job matrix`),
              // @ts-ignore
              url: routes.JOB_MATRIX_ADMIN.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.JOB_MATRIX_ADMIN.build({ role: ROLES.ADMIN })],
            },
            {
              title: (i18n: I18n) => i18n._(t`Skills and KPIs`),
              // @ts-ignore
              url: routes.SKILLS.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.SKILLS.build({ role: ROLES.ADMIN })],
            },
            {
              title: (i18n: I18n) => i18n._(t`Skill and KPI matrix`),
              // @ts-ignore
              url: routes.SKILL_MATRIX.build({ role: ROLES.ADMIN }),
              role: ROLES.ADMIN,
              // @ts-ignore
              aliases: [routes.SKILL_MATRIX.build({ role: ROLES.ADMIN })],
            },
          ],
        }
      : null) as unknown as IMenuItem,

    // as of now: don't show report menu for coach and users when engagement module is not available
    ...(isUserHasAdminRole || isModuleEngagementEnabled || isModulePerformanceEnabled
      ? [
          {
            title: (i18n: I18n) => i18n._(t`Reports`),
            key: 'reports',
            icon: ICONS.REPORTS,
            children: [
              {
                // Top level IMenuItem for the Reports icon on main menu
                ...(isUserHasAdminRole
                  ? getRouteForReportMainMenuIcon(ROLES.ADMIN, customReports) // when the user is an admin role
                  : isUserWithCoachRole(user, selectedCompany)
                  ? getRouteForReportMainMenuIcon(ROLES.COACH, customReports) // when the user is an coach role
                  : getRouteForReportMainMenuIcon(ROLES.USER, customReports)), // when the user is an user role
                children: [
                  getAdminCustomReportMenuItem(
                    isUserHasAdminRole,
                    languageState,
                    customReports,
                    isModuleLearningEnabled,
                  ),
                  getCoachCustomReportMenuItem(
                    isUserHasAdminRole,
                    isUserWithCoachRole(user, selectedCompany),
                    customReports,
                    languageState,
                  ),
                  getPersonalReportMenuItem(
                    customReports,
                    isModuleEngagementEnabled,
                    isModulePerformanceEnabled,
                  ),
                ].filter((c) => c),
              },
            ].filter((c) => c),
            isDivider: true,
          },
        ]
      : []),
    ...(getCoachTeamsForUser(user, teams, selectedCompany).length > 0
      ? [
          {
            ...getCoachTeamMenuItem(user, teams, selectedCompany, isModuleEngagementEnabled),
          },
        ]
      : []
    ).filter((c) => c.children.length),
  ].filter((c) => c);

export default footerMenuStructure;
