import { SKILL_CATEGORIES_ID } from '@learned/constants';
import { IMultiLangString } from '@learned/types';
import { I18n } from '@lingui/core';

import type { ICareerPlan, IJobProfile, ILanguageValue, IMeta } from '@learned/types';

export type TValue = (trans: I18n) => string;

export type TCareerGroup = {
  [key: string]: Pick<ICareerPlan, 'id' | 'jobProfile' | 'createdFor'>[];
};

export type TOptions = {
  key: string;
  title: (trans: I18n) => string;
};

export type TSelected = {
  selectedInputs: IMultiLangString | null;
  selectedName: string;
};

export type TInputs = Record<string, string | null>;

export type TFlagOptions = {
  key: string;
  title: React.ReactNode;
  country: string;
  language: string;
};

export type TDestination = {
  droppableId: string;
  index: number;
};

export type TItemToMove = TCell | null;

export type TTableMeta = {
  title?: string;
  skillId?: null | string;
  categoryId?: null | string;
  values?: IMultiLangString | null;
  color?: string;
  skillCategoryLevelIndex?: number;
  id?: string;
};

export type TCell = {
  id: string;
  name: IMultiLangString;
};

export type TFamilyData = {
  categoryId: string;
  name: IMultiLangString;
  id: string;
};

export type TCellData = {
  id: string;
  values: TCell[];
  skillId?: string;
  categoryId?: string;
};

export type TColumnData = {
  id?: string;
  name?: IMultiLangString;
  columns?: TCellData[];
};

export type TTableData = {
  id: string;
  name?: IMultiLangString;
  category?: IMultiLangString;
  company?: string | null;
  levels?: TColumnData[];
  meta?: IMeta;
  description?: IMultiLangString;
  skillCategory?: string;
  skillTemplate?: string;
  skillCategoryName?: IMultiLangString;
  published?: boolean;
  focusAreas?: {
    level: number;
    values: {
      id: string;
      name: IMultiLangString;
    }[];
  }[];
};

export type TOtherData = {
  selectedColor?: string;
  id?: string;
};

// Remove following types when old types are changed correctly

export interface IEditors {
  user: string;
  canEdit: boolean;
}

export type TExportAreaValues = {
  id: string;
  name: IMultiLangString;
};

export type TFocusAreas = {
  level: number;
  values: TExportAreaValues[];
};

export interface INewSkill {
  id: string;
  meta: IMeta;
  company: string;
  name: IMultiLangString;
  description: IMultiLangString;
  skillCategory: string;
  skillTemplate?: string;
  published: boolean;
  editors: IEditors[];
  focusAreas: TFocusAreas[];
  skillCategories: IMultiLangString[];
}

export interface INewSkillSubset {
  id: INewSkill['id'];
  company: INewSkill['company'] | null;
  name: INewSkill['name'];
  category: INewSkillCategory | string | null;
  editors: INewSkill['editors'];
  last_updated: string;
}

export type TSkillsObj = {
  [key: string]: INewSkill;
};

export type TGetSkills = {
  search: string;
  sortBy: string;
  limit: number;
  skip: number;
  categories: string[];
};

export interface INewSkillCategory {
  id: string;
  meta: IMeta;
  company: string;
  name: IMultiLangString;
  skillLevels: IMultiLangString[];
  skillCategoryTemplate: string | null;
  type?: SKILL_CATEGORIES_ID;
}

export type TCategoriesObj = {
  [key: string]: INewSkillCategory;
};

export type TCategories = {
  id?: string;
  label: string;
  category?: INewSkillCategory;
  customLabels: ILanguageValue[];
  updated: boolean;
  created: boolean;
};

export interface ISkills {
  id: string;
  name: IMultiLangString;
  category: INewSkillCategory | null;
  editors: string[];
  last_updated: string;
}

export interface IUser {
  id: string;
  name: string;
}

export type TMeta = {
  title?: string;
  values?: IMultiLangString | null;
  color?: string;
  id?: string;
};

export interface TJobProfileExtended extends IJobProfile {
  jobGroupNameWithLevel?: string;
  members?: {
    avatarUrl: string;
  }[];
  jobFamilyName?: string;
  createdByName?: string;
}

export type TIntegrationSettings = {
  id: string;
  company: string;
  name: string;
};

export type TMultiSelect = {
  key: string;
  title: IMultiLangString;
};

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  LEVEL_ASC = 'level_asc',
  LEVEL_DESC = 'level_desc',
}

export interface IJobProfileColumn {
  id: string;
  name: string;
  requiredSkillLevel: string;
  level: number;
}
