import React, { useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Button from '~/components/Button';
import { Dropdown } from '~/components/Dropdown';
import Modal from '~/components/Modal';

import { JOB_PROFILE_STATUSES } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getCareerPlans } from '~/services/careerPlans';
import { getJobProfiles } from '~/services/jobProfiles';
import sortCollectionByName from '~/utils/sortCollectionByName';
import './styles.scss';

const JobsDropdown = styled(Dropdown)`
  width: 100%;

  & > div:first-child {
    height: 48px;
  }
`;

const JobModal = styled(Modal)`
  & > div:nth-child(3) {
    overflow: visible;
  }
`;

const SelectJobProfileModal = ({
  onSubmit,
  selectedUserId,
  onModalClose,
  title,
}: {
  onSubmit: Function;
  selectedUserId: string;
  onModalClose: Function;
  title: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedJobProfile, setSelectedJobProfile] = useState([]);
  const [jobProfiles, setJobProfiles] = useState({});
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();

  useEffect(() => {
    Promise.all([
      getCareerPlans({ createdFor: selectedUserId, status: CAREER_PLAN_STATUSES.CURRENT }),
      getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }),
    ]).then(([userCareerPlans, jobProfiles]) => {
      // Override correct multi lang string for name
      Object.values(jobProfiles).forEach((jp: any) => {
        jp.name = getMultiLangString(jp.name);
      });

      const connectedJobProfiles = Object.values(userCareerPlans).map((c: any) => c.jobProfile);
      const notConnectedJobProfiles = Object.values(jobProfiles).filter(
        (jp: any) => !connectedJobProfiles.includes(jp.id),
      );
      const sortedJobProfiles = sortCollectionByName(notConnectedJobProfiles);
      setJobProfiles(sortedJobProfiles);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(selectedJobProfile);
    setLoading(false);
    onModalClose();
  };

  const companyJobProfiles = Object.values(jobProfiles);

  return (
    <JobModal
      className="new-team-modal"
      title={title}
      onClose={onModalClose}
      footerRight={
        <Button
          label={i18n._(t`Save`)}
          disabled={isEmpty(selectedJobProfile)}
          onClick={handleSubmit}
          loading={loading}
          type="primary"
        />
      }
      width={500}
      minWidth={500}
    >
      <div>
        <div className="modal_subtitle">
          <Trans>Select Job Profile</Trans>
        </div>
        <JobsDropdown
          items={companyJobProfiles}
          placeholder={i18n._(t`Jobs`)}
          onChange={(value: any) => setSelectedJobProfile(value)}
          selectedItems={selectedJobProfile}
          stringifyItem={(item: any) => item.name}
          skipSort
        />
      </div>
    </JobModal>
  );
};

export default SelectJobProfileModal;
