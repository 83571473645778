import React, { ReactNode, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_LAST_STATUS_METHOD,
  REVIEW_TYPES,
  USER_REVIEW_REQUEST_TYPE,
  USER_REVIEW_SORT_OPTIONS,
  USER_REVIEW_STATUS,
} from '@learned/constants';
import { IUserReviewPopulated, POPULATE_USER_REVIEW_ITEMS } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import { COLUMNS } from './columns';
import { Filters, IFilters } from './filters';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { IMultiSelectOption, USER_REVIEW_STATUS_OPTIONS } from '~/constants/userReviews';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { checkModuleCoachCreateReview, getSelectedRole, getUser } from '~/selectors/baseGetters';
import { createReview } from '~/services/reviews';
import {
  getUserReviews,
  deleteUserReviewById,
  archiveUserReviewById,
  unarchiveUserReviewById,
} from '~/services/userReviews';

import { Wrapper } from '../../design';

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_ALL;

const initialFilters: IFilters = {
  isShowFilters: false,
  search: '',
  statuses: USER_REVIEW_STATUS_OPTIONS.filter(
    (option) => option.key !== USER_REVIEW_STATUS.ARCHIVED,
  ),
  sortBy: USER_REVIEW_SORT_OPTIONS.NAME_A_Z,
  selectedDateOption: undefined,
  selectedCoaches: [],
  pagination: DEFAULT_PAGINATION,
  createdBy: [],
};

const AllTab = () => {
  const { addToast } = useToasts();
  const { i18n } = useLingui();
  const user = useSelector(getUser);
  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const isModuleCoachCreateConversation = useSelector(checkModuleCoachCreateReview);
  const [items, setItems] = useState<IUserReviewPopulated[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage<IFilters>(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const $isCreating = useBoolState(false);
  const $isReviewCycle = useBoolState(false);

  const fetchData = async (signal?: AbortSignal) => {
    setIsLoading(true);

    const result = await getUserReviews(
      {
        filters: {
          search: currentFilters.search,
          status: currentFilters.statuses.map(({ key }: IMultiSelectOption) => key),
          ...(!isEmpty(currentFilters.createdBy) && {
            createdIn: currentFilters.createdBy.map(({ key }: IMultiSelectOption) => key),
          }),
          timeframe: currentFilters.selectedDateOption
            ? [currentFilters.selectedDateOption.fromDate, currentFilters.selectedDateOption.toDate]
            : undefined,
          employees: currentFilters.selectedCoaches.map(({ id }: IType) => id),
        },
        options: {
          skip: currentFilters.pagination.skip,
          limit: currentFilters.pagination.limit,
          sortBy: currentFilters.sortBy,
        },
        type: USER_REVIEW_REQUEST_TYPE.ALL,
        populate: [POPULATE_USER_REVIEW_ITEMS.CREATED_FOR],
      },
      { ...(signal && { signal }) },
    );

    if (result) {
      const {
        data: { userReviews: items, total },
      } = result;

      setItems(Object.values(items));
      setTotalCount(total);
      setIsLoading(false);
    }
  };

  // change filters fetch
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  // only display is option in module is enabled
  // and is user has COACH role
  const actionButton = {
    label: t`Create review`,
    onClick: async () => {
      const reviewId = await createIndividualReview();
      routes.UPDATE_REVIEW_INDIVIDUAL.go(
        {
          role: ROLES.USER,
          companyId: undefined,
          teamId: undefined,
        },
        {
          isBackPath: true,
          // @ts-ignore
          reviewId,
          query: { isCreatingNew: true },
        },
      );
    },
  };

  const createIndividualReview = async () => {
    $isCreating.on();
    const result = await createReview({ type: REVIEW_TYPES.INDIVIDUAL });
    $isCreating.off();
    return result.data[API_RETURN_FIELDS.REVIEW].id;
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: IUserReviewPopulated) => {
      const path =
        item.status === USER_REVIEW_STATUS.DRAFT
          ? routes.UPDATE_REVIEW_INDIVIDUAL.build(
              { companyId: undefined, teamId: undefined, role: ROLES.USER },
              // @ts-ignore
              { reviewId: item.review, isBackPath: true },
            )
          : routes.USER_REVIEW_DASHBOARD.build(
              undefined,
              // @ts-ignore
              { userReviewId: item.id, isBackPath: true },
            );
      history.push(path as string);
    },
  };

  const onEdit = (item: IUserReviewPopulated) => {
    if (item.type === REVIEW_TYPES.REVIEW_CYCLE) {
      $isReviewCycle.on();
      return;
    }

    if (item.type === REVIEW_TYPES.SELF) {
      history.push(
        routes.UPDATE_REVIEW_SELF.build(
          { companyId: undefined, teamId: undefined, role: ROLES.USER },
          // @ts-ignore
          { reviewId: item.review, isBackPath: true },
        ),
      );
    } else {
      history.push(
        routes.UPDATE_REVIEW_INDIVIDUAL.build(
          { companyId: undefined, teamId: undefined, role: ROLES.USER },
          // @ts-ignore
          { reviewId: item.review, isBackPath: true },
        ),
      );
    }
  };

  const onDelete = async (item: IUserReviewPopulated) => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,

      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult && (user.isAdmin || item?.createdBy === user.id)) {
      await deleteUserReviewById(item?.id);
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      // re-fetch data
      await fetchData();
    }
  };

  const onArchive = async (userReview: IUserReviewPopulated) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Archive review?`),
      description: i18n._(
        t`Are you sure you want to archive this review? The review can no longer be edited.`,
      ),
    });

    if (isConfirmed) {
      await archiveUserReviewById(userReview.id);
      addToast({
        title: i18n._(t`Review archived`),
        type: TOAST_TYPES.SUCCESS,
      });
      // re-fetch data
      await fetchData();
    }
  };

  const onUnarchive = async (userReview: IUserReviewPopulated) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Unarchive review?`),
      description: i18n._(
        t`Are you sure you want to unarchive this review? The review can again be edited.`,
      ),
    });

    if (isConfirmed) {
      await unarchiveUserReviewById(userReview.id);
      addToast({
        title: i18n._(t`Review unarchived`),
        type: TOAST_TYPES.SUCCESS,
      });
      // re-fetch data
      await fetchData();
    }
  };

  const createMenuItems = (item: IUserReviewPopulated) => {
    const isAdmin = user.isAdmin;
    const isCreator = item?.createdBy === user.id;
    const isInputCoach = item.coaches.includes(user.id);
    const isReviewCycleType = item.type === REVIEW_TYPES.REVIEW_CYCLE;
    const isLastStatusMethodAuto =
      item.lastStatusChangeMethod && item.lastStatusChangeMethod === REVIEW_LAST_STATUS_METHOD.AUTO;
    const isEditable = [
      USER_REVIEW_STATUS.DRAFT,
      USER_REVIEW_STATUS.PUBLISHED,
      USER_REVIEW_STATUS.ACTIVE,
    ].includes(item.status);

    // p.s. we do validation per option, because they have diff permission
    return [
      (isAdmin || isCreator) &&
        isEditable && {
          label: i18n._(t`Edit`),
          action: () => onEdit(item),
          icon: ICONS.EDIT_PENCIL,
        },
      (isAdmin || isCreator || (isReviewCycleType && isInputCoach)) &&
        [
          USER_REVIEW_STATUS.ACTIVE,
          USER_REVIEW_STATUS.SIGNING,
          USER_REVIEW_STATUS.COMPLETED,
        ].includes(item.status) && {
          label: i18n._(t`Archive`),
          action: () => onArchive(item),
          icon: ICONS.ARCHIVE,
        },
      (isAdmin || isCreator || (isReviewCycleType && isInputCoach)) &&
        [USER_REVIEW_STATUS.ARCHIVED].includes(item.status) && {
          label: i18n._(t`Unarchive`),
          isDisabled: isReviewCycleType && isLastStatusMethodAuto,
          tooltip:
            isReviewCycleType &&
            isLastStatusMethodAuto &&
            i18n._(t`It is no longer possible to unarchive this review. Contact HR for support.`),
          action: () => onUnarchive(item),
          icon: ICONS.UNARCHIVE,
        },
      (isAdmin || isCreator) && {
        label: i18n._(t`Delete`),
        action: async () => {
          await onDelete(item);
        },
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ].filter((i) => i) as unknown as {
      label: string;
      action: () => void;
      icon: ICONS;
      isWarning?: boolean;
    }[];
  };

  const filterCounter = () => {
    const value = [
      currentFilters.search,
      currentFilters.statuses,
      currentFilters.selectedDateOption,
      currentFilters.selectedCoaches,
    ].filter((item) => !isEmpty(item)).length;
    return value || undefined;
  };

  const onChangeFilter = (key: string, value: IType[]) => {
    setCurrentFilters((state: any) => ({
      ...state,
      pagination: DEFAULT_PAGINATION,
      [key]: value,
    }));
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: USER_REVIEW_SORT_OPTIONS) =>
            setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No reviews found`),
          emptyStateText: i18n._(t`No reviews yet… Let's create one! `),
        }}
        actionButton={
          ((isModuleCoachCreateConversation && user.isCoach) || user.isAdmin) && actionButton
        }
        filtersProps={{
          filters: {
            setSearch: (value: string) =>
              setCurrentFilters((prevState: IFilterType) => ({
                ...prevState,
                search: value,
                pagination: DEFAULT_PAGINATION, // reset pagination
              })),
            search: currentFilters.search,
            filterCount: filterCounter(),
          },
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: () => setCurrentFilters(initialFilters),
          filterComponents: (
            <Filters
              filters={currentFilters}
              selectedRole={selectedRole}
              onChangeFilter={onChangeFilter}
            />
          ),
        }}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
      />
      {$isCreating.value && (
        <CreationInProcessModal title={i18n._(t`Creating a new individual review…`)} />
      )}
      {$isReviewCycle.value && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.WARNING}
          title={i18n._(t`Not possible to edit`)}
          description={i18n._(
            t`This review is part of a review cycle. To make changes, go to the related review cycle`,
          )}
          onClose={$isReviewCycle.off}
          cancelButton={i18n._(t`OK`)}
          isNoAction
        />
      )}
    </Wrapper>
  );
};

export { AllTab };
