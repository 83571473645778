import React, { ReactNode, TextareaHTMLAttributes } from 'react';

import { InputWrapper, Label, Wrapper, Error } from './design';

import type { UseFormRegisterReturn } from 'react-hook-form';

export interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  label?: ReactNode;
  error?: string | boolean;
  width?: string;
  height?: string;
  className?: string;
  register?: UseFormRegisterReturn;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const TextArea = ({
  name,
  label,
  error,
  disabled,
  width,
  height,
  register,
  leftIcon,
  rightIcon,
  className,
  ...rest
}: ITextAreaProps) => {
  return (
    <Wrapper
      error={error}
      disabled={disabled}
      width={width}
      height={height}
      isLeftIcon={!!leftIcon}
      isRightIcon={!!rightIcon}
      className={className}
    >
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        {leftIcon && <span className="leftIcon">{leftIcon}</span>}
        <textarea name={name} disabled={disabled} {...register} {...rest} />

        {rightIcon && <span className="rightIcon">{rightIcon}</span>}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export { TextArea };
