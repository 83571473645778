import React from 'react';

import Modal from '~/components/Modal';
import { SearchCompanySkills } from '~/components/Modals/AddSkillModal/SearchCompanySkills';

import { useAddSkillModal } from './hooks/useAddSkillModal';
import { SelectLevelAndFocusAreas } from './SelectLevelAndFocusAreas';
import { type AddSkillModalProps, type IForm } from './types';

import type { ISelectedSkillTemplate } from '~/@types/job';

function AddSkillModal<T extends IForm>({
  onClose,
  skillCategoryName,
  defaultFocusAreaLevel,
  isDefaultFocusAreaLevelEnabled,
  formMethods,
  skillCategoryId,
  skillCategoryTemplateId,
  initSource,
  setSkillTemplates,
  setSkills,
  skillLevels,
  jobTemplateId,
  skillTemplates,
  selectedSkills = [],
}: AddSkillModalProps<T>) {
  const { watch } = formMethods;
  // @ts-ignore
  const watchSkills = watch(`skills.${skillCategoryId}.skills`) as ISelectedSkillTemplate['skills'];

  const {
    searchInputValue,
    setSearchInputValue,
    skillNames,
    companySkills,
    templateSkills,
    isSelectLevelAndFocusAreasVisible,
    setIsSelectLevelAndFocusAreasVisible,
    source,
    setSource,
  } = useAddSkillModal({
    skillCategoryId,
    skillCategoryTemplateId,
    initSource,
    jobTemplateId,
    jobSkillTemplates: skillTemplates,
    watchSkills,
    selectedSkills,
  });

  return (
    <Modal
      width={isSelectLevelAndFocusAreasVisible ? 800 : 750}
      contentStyles={{ padding: '30px' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
    >
      {!isSelectLevelAndFocusAreasVisible ? (
        <SearchCompanySkills
          onClose={onClose}
          skillCategoryName={skillCategoryName}
          skillCategoryId={skillCategoryId}
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
          skillNames={skillNames}
          companySkills={companySkills}
          templateSkills={templateSkills}
          defaultFocusAreaLevel={defaultFocusAreaLevel}
          isDefaultFocusAreaLevelEnabled={isDefaultFocusAreaLevelEnabled}
          setIsSelectLevelAndFocusAreasVisible={setIsSelectLevelAndFocusAreasVisible}
          setSource={setSource}
          formMethods={formMethods}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
        />
      ) : (
        <SelectLevelAndFocusAreas
          onClose={onClose}
          skillCategoryName={skillCategoryName}
          skillCategoryId={skillCategoryId}
          setIsSelectLevelAndFocusAreasVisible={setIsSelectLevelAndFocusAreasVisible}
          source={source!}
          formMethods={formMethods}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
          skillLevels={skillLevels}
        />
      )}
    </Modal>
  );
}

export { AddSkillModal };
