import React from 'react';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { ModalDimensionsRow } from './DimensionsStrip.design';

import { RATING_PROVIDERS, TTimeFrame } from '../../types';

const getRatingProviderText = (ratingProvider: RATING_PROVIDERS): string => {
  switch (ratingProvider) {
    case RATING_PROVIDERS.COACH:
      return 'Coach';
    case RATING_PROVIDERS.SELF:
      return 'Self';
    case RATING_PROVIDERS.PEER:
      return 'Peer';
    default:
      return '';
  }
};

interface IDimensionDisplayProps {
  primaryDimensionName?: string;
  secondaryDimensionName?: string;
  additionalDimensionNames?: string[];
  ratingProvider?: RATING_PROVIDERS;
  measureName?: string;
  timeFrame: TTimeFrame;
}

const DimensionsStrip: React.FC<IDimensionDisplayProps> = ({
  primaryDimensionName,
  secondaryDimensionName,
  additionalDimensionNames = [],
  measureName,
  ratingProvider,
  timeFrame,
}) => {
  const MAX_DIMENSION_LENGTH = 15;
  const dimensions = [primaryDimensionName, secondaryDimensionName, ...additionalDimensionNames]
    .map((name) => name?.trim())
    .filter((name) => name && name !== '');

  return (
    <ModalDimensionsRow>
      {dimensions.map((dimension, i) => {
        const separator =
          i + 1 < dimensions.length ? (
            <span> &nbsp;&gt;&nbsp; </span>
          ) : (
            <span> &nbsp;/&nbsp; </span>
          );
        if (dimension) {
          return (
            <React.Fragment key={dimension}>
              <Tooltip disabled={false} tooltip={dimension} size={TOOLTIP_SIZES.BIG}>
                <span>
                  {dimension.length > MAX_DIMENSION_LENGTH
                    ? `${dimension.slice(0, MAX_DIMENSION_LENGTH)}...`
                    : dimension}

                  {separator}
                </span>
              </Tooltip>
            </React.Fragment>
          );
        }
        return null;
      })}
      {measureName || (
        <>
          [{timeFrame.start} - {timeFrame.end}]
        </>
      )}
      {ratingProvider && (
        <>
          &nbsp;/&nbsp;
          <span>{getRatingProviderText(ratingProvider)}</span>
        </>
      )}
    </ModalDimensionsRow>
  );
};

export default DimensionsStrip;
