import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import styled from 'styled-components';

import { SkillQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion';
import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IQuestionData,
  IQuestionDefaultData,
} from '~/pages/ReviewGiveFeedback/types';

import { PlanGoalQuestion } from './Questions/PlanGoalQuestion';
import { RatingQuestion } from './Questions/RatingQuestion';
import { TextAreaQuestion } from './Questions/TextAreaQuestion';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { COLORS } from '~/styles';

interface IQuestionView {
  questionData: IQuestionData;
  languageState: ILanguageStateReturn;
  className?: string;
  ratings: AnswerReviewForm['ratings'];
  reviewTask?: IPopulatedReviewTask;
  onChange?: (data: {
    questionId: string;
    answer?: string | number;
    comment?: string;
    isNotApplicable?: boolean;
  }) => void;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export interface WithComments {
  comment?: string;
}

const Container = styled.div`
  margin-top: 66px;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-content: center;
`;

const AnswerView = styled.div`
  width: fit-content;
  min-width: 750px;
  padding: 23px 33px;
  background: ${COLORS.WHITE};
  overflow: auto;
  margin-bottom: 88px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 1.18;
  letter-spacing: -0.24px;
  color: ${COLORS.ICONS_PRIMARY};
  margin-bottom: 10px;
`;

const Description = styled.div`
  width: 750px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.SUBTEXT};
`;

const ThemeTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 2.17;
  letter-spacing: -0.13px;
  color: ${COLORS.SUBTEXT};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const QuestionView = ({
  languageState,
  className,
  questionData,
  ratings,
  onChange,
  reviewTask,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
}: IQuestionView) => {
  const getMultiLangString = useMultiLangString();
  const isExternalPeer = reviewTask?.userTo?.email;

  const prepareQuestion = (questionData: IQuestionData) => {
    switch (questionData.type) {
      case REVIEW_QUESTION_TYPES.TEXT: {
        const relevantRating = ratings.find(
          (rating) => rating.question === questionData.question.id,
        );
        return (
          <TextAreaQuestion
            key={questionData.question.id}
            onChange={onChange}
            question={questionData as IQuestionDefaultData}
            languageState={languageState}
            questionData={questionData.question}
            canAnswer={questionData.canAnswer}
            defaultValues={{
              answer: (relevantRating?.answer as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            showOtherRatings={showOtherRatings}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.RATING: {
        const relevantRating = ratings.find(
          (rating) => rating.question === questionData.question.id,
        );
        return (
          <RatingQuestion
            key={questionData.question.id}
            onChange={onChange}
            question={questionData as IQuestionDefaultData}
            canAnswer={questionData.canAnswer}
            languageState={languageState}
            questionData={questionData.question}
            defaultValues={{
              answer: relevantRating?.answer?.toString() ?? '',
              comment: (relevantRating?.comment as string | null) ?? '',
              isNotApplicable: relevantRating?.isNotApplicable ?? false,
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
            useMultiLangString={useMultiLangString}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
      case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
        const relevantRatings = ratings.filter((rating) =>
          questionData.subQuestions.map((sq) => sq.question.id).includes(rating.question),
        );

        return (
          <SkillQuestion
            useMultiLangString={useMultiLangString}
            key={questionData.questionNumber}
            onChange={onChange}
            languageState={languageState}
            canAnswer={questionData.canAnswer}
            focusAreaQuestions={questionData.subQuestions}
            defaultValues={{
              answers: relevantRatings.map((rating) => ({
                value: (rating.answer as number) ?? undefined,
              })),
              isNotApplicable: relevantRatings[0]?.isNotApplicable ?? false,
              comment: relevantRatings[0]?.comment ?? '',
            }}
            hasError={hasError}
            hasCommentError={hasCommentError}
            showOtherRatings={showOtherRatings}
          />
        );
      }
      case REVIEW_QUESTION_TYPES.GOAL_PLAN: {
        return (
          !isExternalPeer && (
            <PlanGoalQuestion
              key={questionData.question.id}
              onChange={onChange}
              languageState={languageState}
              questionData={questionData.question}
              reviewTask={reviewTask}
            />
          )
        );
      }
      default:
        return null;
    }
  };

  return (
    <Container className={className}>
      <HeaderWrapper>
        <ThemeTitle>{`${questionData.themeName} | Question: ${questionData.questionNumber}`}</ThemeTitle>
        <Title>{questionData.questionTitle}</Title>
        {questionData.type === REVIEW_QUESTION_TYPES.GOAL_PLAN && (
          <Description>{getMultiLangString(questionData.question?.description || '')}</Description>
        )}
      </HeaderWrapper>
      <AnswerView>{prepareQuestion(questionData)}</AnswerView>
    </Container>
  );
};

export { QuestionView };
