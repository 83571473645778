import { ROLES } from '~/constants';
import { pageOverview, pageOverviewEdit } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'skill-matrix';

// overview
export const SKILL_MATRIX = new LinkConstructor([ROLES.ADMIN], () => pageOverview(routeName));

export const SKILL_MATRIX_EDIT = new LinkConstructor([ROLES.ADMIN], () =>
  pageOverviewEdit(routeName),
);
