import React from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { LeftSection, Wrapper, Title, SubTitle, IconContainer } from './design';
import { BANNER_TYPES, IBannerProps } from './types';

import { COLORS } from '~/styles';

const STYLES = {
  [BANNER_TYPES.INFO]: {
    backgroundImage:
      'linear-gradient(266deg, rgba(175, 184, 232, 0.1) 100%, rgba(86, 107, 250, 0.1) 0%)',
    icon: ICONS.INFO_3,
    color: COLORS.ACCENT_INFO,
  },
  [BANNER_TYPES.DELETE]: {
    backgroundImage:
      'linear-gradient(264deg, rgba(255, 138, 138, 0.1) 100%, rgba(255, 82, 82, 0.2) 0%)',
    icon: null,
    color: null,
  },
};

const Banner = ({ type = BANNER_TYPES.INFO, actions, title, subTitle }: IBannerProps) => {
  const { icon, color, backgroundImage } = STYLES[type];

  return (
    <Wrapper $backgroundImage={backgroundImage}>
      <LeftSection>
        {icon && (
          <IconContainer $backgroundColor={color}>
            <Icon icon={icon} size={ICON_SIZES.SMALL} color={COLORS.WHITE} />
          </IconContainer>
        )}

        <div>
          {title && <Title>{title}</Title>}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </div>
      </LeftSection>

      {actions}
    </Wrapper>
  );
};

export { Banner };
