import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Controller, Path, UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';

import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { COLORS } from '~/styles';

import { WithComments } from '../../QuestionView';

interface IProps<T extends WithComments> {
  formMethods: UseFormReturn<T>;
  isRequired?: boolean;
  onChange?: (comment: string) => void;
  hasError?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
`;

const PrimaryButton = styled(Button)`
  justify-self: right;
`;

const Label = styled.div``;

const PrimaryText = styled.div<{ hasError?: boolean }>`
  align-self: center;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.TEXT_MAIN};
  ${(props) =>
    props.hasError
      ? css`
          color: ${COLORS.ACCENT_ERROR};
        `
      : css`
          color: ${COLORS.ICONS_PRIMARY};
        `}

  & > span {
    color: ${COLORS.PLACEHOLDERS};
  }
`;

export const CommentSection = <T extends WithComments>({
  formMethods,
  isRequired = false,
  onChange,
  hasError,
}: IProps<T>) => {
  const $isHidden = useBoolState(true);

  const { control, watch } = formMethods;
  const commentWatch = watch('comment' as Path<T>);
  const debouncedComment = useDebounce(commentWatch, 200);

  useEffect(() => {
    onChange?.(debouncedComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedComment]);

  return (
    <Wrapper>
      <Header>
        <Label>
          {(isRequired || !$isHidden.value) && (
            <PrimaryText hasError={hasError}>
              <Trans>
                Comment <span>({isRequired ? 'Mandatory' : 'Optional'})</span>
              </Trans>
            </PrimaryText>
          )}
        </Label>
        {!isRequired && (
          <PrimaryButton
            type="button"
            variant={ButtonVariant.SECONDARY}
            label={$isHidden.value ? i18n._(t`+ Comment`) : i18n._(t`Hide comment`)}
            onClick={() => $isHidden.toggle()}
          />
        )}
      </Header>
      {(isRequired || !$isHidden.value) && (
        <Controller
          name={'comment' as Path<T>}
          control={control}
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Editor
              error={hasError}
              value={field.value}
              compact
              onChange={(value: string) => {
                field.onChange(value);
              }}
              placeholder={i18n._(t`Type here...`)}
              minHeight="150px"
              big
            />
          )}
        />
      )}
    </Wrapper>
  );
};
