import React from 'react';

import { DebouncedFunc } from 'lodash';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import { SideBar } from '~/components/SideBar';
import { ISectionState } from '~/components/SideBar/SectionStateHook';

import { CreateThemeStepOne } from './CreateThemeStepOne';
import { CreateThemeStepTwo } from './CreateThemeStepTwo';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { StepNavigationWrapper } from '../design';
import { IGeneralForm } from '../types';

interface IProps {
  setLastSaveSuccess: () => void;
  formMethods: UseFormReturn<any>;
  questionsFormMethods: UseFieldArrayReturn<IGeneralForm, 'questions', 'key'>;
  languageState: ILanguageStateReturn;
  sectionState: ISectionState;
  reviewThemeId: string;
  skillCategories: {
    value: string;
    label: Record<string, string>;
    levels: number;
  }[];
  debounceUpdateTheme: DebouncedFunc<() => Promise<void>>;
  onSave: (e?: React.BaseSyntheticEvent) => Promise<void>;
  onPublish: (onSave?: boolean) => Promise<void>;
  onAutosave: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

const StepNavigation = ({
  setLastSaveSuccess,
  formMethods,
  questionsFormMethods,
  languageState,
  sectionState,
  reviewThemeId,
  onSave,
  onPublish,
  onAutosave,
  debounceUpdateTheme,
  skillCategories,
}: IProps) => {
  const $isLoading = useBoolState(false);

  const onNextClicked = async () => {
    sectionState.setCurrentSection(sectionState.currentSection + 1);
    await formMethods.trigger(['icon', 'iconColor', 'questions']);
  };

  const onBackClicked = async () => {
    sectionState.setCurrentSection(sectionState.currentSection - 1);
    await formMethods.trigger(['icon', 'iconColor', 'questions']);
  };

  return (
    <>
      <StepNavigationWrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
          hideErrorState={!sectionState.triedToSubmit}
        />
        {sectionState.currentSection === 0 && (
          <CreateThemeStepOne
            debounceUpdateTheme={debounceUpdateTheme}
            onNext={onNextClicked}
            isLoading={$isLoading.value}
            languageState={languageState}
            formMethods={formMethods}
            setCurrentSection={sectionState.setCurrentSection}
            handleUpdateTheme={onAutosave}
            triedToSubmit={sectionState.triedToSubmit}
          />
        )}
        {sectionState.currentSection === 1 && (
          <CreateThemeStepTwo
            reviewThemeId={reviewThemeId}
            languageState={languageState}
            generalFormMethods={formMethods}
            questionsFormMethods={questionsFormMethods}
            onBack={onBackClicked}
            setCurrentSection={sectionState.setCurrentSection}
            onSubmit={onSave}
            onPublish={onPublish}
            handleUpdateTheme={onAutosave}
            isPublishDisabled={$isLoading.value}
            updateLastSavedTime={setLastSaveSuccess}
            triedToSubmit={sectionState.triedToSubmit}
            skillCategories={skillCategories}
          />
        )}
      </StepNavigationWrapper>
    </>
  );
};

export { StepNavigation };
