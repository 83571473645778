import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

import { List } from '../../components/List';
import { ListRowOthers } from '../../components/ListRowOthers';
import { ListRowPlaceholder } from '../../components/ListRowPlaceholder';
import { SidebarSection } from '../../design';

import type { IReviewDashboardUserForm } from '../../../types';
import type { ITask } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const SubTitle = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  margin: 20px 17px 0 36px;
  display: flex;
  align-items: center;
`;

const CircleWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Circle = styled.div<{ $color: string }>`
  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => props.$color};
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
  margin-left: 36px;
`;

interface IOthersTasksSidebarSectionProps {
  tasksPeers: ITask[];
  tasksCoaches: ITask[];
  isPeerInputTasks: boolean;
  isCoachInputTasks: boolean;
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isAllowToDeletePeers: boolean;
  isAllowToDeleteCoaches: boolean;
  isAllowToAddPeers: boolean;
  isAllowToAddCoaches: boolean;
  onDeletePeer: (task: ITask) => void;
  onDeleteCoach: (task: ITask) => void;
  onAddPeers: () => void;
  onAddCoaches: () => void;
}

const OthersTasksSidebarSection = ({
  tasksPeers,
  tasksCoaches,
  isPeerInputTasks,
  isCoachInputTasks,
  formMethods: _formMethods,
  isAllowToDeletePeers,
  isAllowToDeleteCoaches,
  isAllowToAddCoaches,
  isAllowToAddPeers,
  onDeletePeer,
  onDeleteCoach,
  onAddPeers,
  onAddCoaches,
}: IOthersTasksSidebarSectionProps) => {
  const { i18n } = useLingui();

  const data = [
    ...(isCoachInputTasks
      ? [
          {
            tasks: tasksCoaches,
            subTitle: i18n._(t`Coach`),
            circleColor: 'linear-gradient(to bottom, #f979e6, #f144c9);',
            button: isAllowToAddCoaches && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Add coaches`)}
                onClick={onAddCoaches}
                icon={ICONS.ADD_USER}
                iconSize={ICON_SIZES.SMALL}
              />
            ),
            placeholder: i18n._(t`No input by others required.`),
            onDelete: isAllowToDeleteCoaches ? onDeleteCoach : undefined,
          },
        ]
      : []),
    ...(isPeerInputTasks
      ? [
          {
            tasks: tasksPeers,
            subTitle: i18n._(t`Peers`),
            circleColor: 'linear-gradient(to bottom, #ffe100, #ffc000)',
            button: isAllowToAddPeers && (
              <Button
                variant={ButtonVariant.TEXT_PRIMARY}
                size={ButtonSize.MEDIUM}
                label={i18n._(t`Add peers`)}
                onClick={onAddPeers}
                icon={ICONS.ADD_USER}
                iconSize={ICON_SIZES.SMALL}
              />
            ),
            placeholder: i18n._(t`No input by others required.`),
            onDelete: isAllowToDeletePeers ? onDeletePeer : undefined,
          },
        ]
      : []),
  ];

  return (
    <SidebarSection>
      {data.map((item, i) => (
        <div key={i}>
          <SubTitle>
            <CircleWrapper>
              <Circle $color={item.circleColor} />
            </CircleWrapper>

            <div>{item.subTitle}</div>
          </SubTitle>
          <List>
            {isEmpty(item.tasks) ? (
              <ListRowPlaceholder text={item.placeholder} />
            ) : (
              item.tasks.map((task, key) => (
                <ListRowOthers key={key} task={task} onDelete={item.onDelete} />
              ))
            )}
          </List>
          {item.button && <ButtonWrapper>{item.button}</ButtonWrapper>}
        </div>
      ))}
    </SidebarSection>
  );
};

export { OthersTasksSidebarSection };
