import React from 'react';

import { SKILL_SORTING } from '@learned/constants';
import { IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';
import Tippy from '@tippyjs/react';

import {
  BoldMissingText,
  SkillItem,
} from '~/pages/SkillSetsOverview/AdminSkillMatrix/AllSkills.design';
import type { ISkills } from '~/pages/SkillSetsOverview/types';

import type { IColumnTable } from '~/@types/table';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { I18n } from '@lingui/core';

export const createSkillColumns = (
  getMultiLangString: ReturnType<typeof useMultiLangString>,
  primaryLanguage: { locale: string },
  missingLanguageText: string,
): IColumnTable<ISkills>[] => {
  const evaluateCharLength = (text?: string) => !!(text && text.length < 24);
  const isPrimaryLanguage = (
    name: IMultiLangString = {},
    jsxReturned = false,
  ): string | JSX.Element => {
    const languages = Object.keys(name || {});
    const isTruthy = (value: string | undefined) => !!value && value.trim().length > 0;
    if (!languages.includes(primaryLanguage.locale) || !isTruthy(name[primaryLanguage.locale])) {
      return jsxReturned ? (
        <>
          <BoldMissingText>{`"${missingLanguageText}" - `}</BoldMissingText>
          {`${getMultiLangString(name)}`}
        </>
      ) : (
        `${missingLanguageText} - ${getMultiLangString(name)}`
      );
    }
    return jsxReturned ? <>{getMultiLangString(name)}</> : getMultiLangString(name);
  };

  return [
    {
      name: (i18n: I18n) => i18n._(t`Skill`),
      accessor: 'skill',
      maxWidth: '600px',
      renderCell: (skill: ISkills) => {
        return (
          <SkillItem>
            <Tippy
              content={isPrimaryLanguage(skill?.name) as string}
              offset={[18, 10]}
              disabled={evaluateCharLength(isPrimaryLanguage(skill?.name) as string)}
            >
              <span>{isPrimaryLanguage(skill?.name, true)}</span>
            </Tippy>
          </SkillItem>
        );
      },
      sortBy: {
        asc: {
          key: SKILL_SORTING.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SKILL_SORTING.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Category`),
      accessor: 'category',
      maxWidth: '600px',
      renderCell: (skill: ISkills) => {
        return (
          <SkillItem>
            <Tippy
              content={isPrimaryLanguage(skill?.category?.name) as string}
              offset={[18, 10]}
              disabled={evaluateCharLength(
                skill?.category?.name && (isPrimaryLanguage(skill?.category?.name) as string),
              )}
            >
              <span>{isPrimaryLanguage(skill?.category?.name, true)}</span>
            </Tippy>
          </SkillItem>
        );
      },
      sortBy: {
        asc: {
          key: SKILL_SORTING.CATEGORY_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SKILL_SORTING.CATEGORY_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Last Updated`),
      accessor: 'last_updated',
      maxWidth: '600px',
      renderCell: (skill: ISkills) => {
        return (
          <SkillItem>
            <Tippy
              content={getMultiLangString(skill?.last_updated)}
              offset={[18, 10]}
              disabled={evaluateCharLength(getMultiLangString(skill?.last_updated))}
            >
              <span>{getMultiLangString(skill?.last_updated)}</span>
            </Tippy>
          </SkillItem>
        );
      },
      sortBy: {
        asc: {
          key: SKILL_SORTING.UPDATED_NEW_OLD,
          label: (i18n: I18n) => i18n._(t`New - Old`),
        },
        desc: {
          key: SKILL_SORTING.UPDATED_OLD_NEW,
          label: (i18n: I18n) => i18n._(t`Old - new`),
        },
      },
    },
  ];
};
