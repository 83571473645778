import React from 'react';

import { IGoal } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { TableList } from '~/components/TableList';

import { GOALS_GRID_COLUMNS } from './columns';

interface IProps {
  goals: IGoal[];
  isLoading: boolean;
  handleRowClick: (item: IGoal) => void;
}

const GoalsGridContainer = styled.div`
  width: 100%;

  & > div {
    padding: unset;
  }
`;

export const GoalsGrid = ({ goals, isLoading, handleRowClick }: IProps) => {
  const { i18n } = useLingui();

  return (
    <GoalsGridContainer>
      <TableList
        data={goals}
        columns={GOALS_GRID_COLUMNS}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        placeholderProps={{
          noResultText: i18n._(t`No items found for you`),
          emptyStateText: i18n._(t`No goals planned yet`),
        }}
      />
    </GoalsGridContainer>
  );
};
