import React from 'react';

import { IReviewInvitationTemplate } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import { Label } from '~/pages/ReviewTemplateSetupNew/sections/General/design';

import { Footer, MessageContainer, MessageItemContainer } from './design';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

type InvitationMessagesPreviewProps = {
  closeModal?: () => void;
  value?: IReviewInvitationTemplate;
  onEditClick?: () => void;
};

const InvitationMessagesPreview = ({
  closeModal,
  value,
  onEditClick,
}: InvitationMessagesPreviewProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const languageState = useLanguageState();

  return value ? (
    <Modal
      title={getMultiLangString(value?.name)}
      showDivider={false}
      centerModal
      header
      contentStyles={{ padding: '0px 32px 24px 32px', overflow: 'visible' }}
      headerStyles={{ padding: '24px 32px 0px 32px', fontSize: '26px', fontWeight: 'normal' }}
      borderRadius={6}
      hideFooter
      onClose={closeModal}
    >
      <MessageContainer>
        <Label>{i18n._(t`Message`)}</Label>

        {languageState.languages &&
          languageState.languages.map((lang, index) => {
            return (
              <MessageItemContainer key={`invitation-${lang.locale}-${index}`}>
                {getUnicodeFlagIcon(lang.locale.substring(lang.locale.indexOf('_') + 1))}
                <RickTextView
                  html={value.description[lang.locale]}
                  color={COLORS.ICONS_PRIMARY}
                  isHtmlWithoutStyles={undefined}
                  className={undefined}
                />
              </MessageItemContainer>
            );
          })}
      </MessageContainer>

      <Footer>
        <Button
          label={i18n._(t`On Edit`)}
          type="button"
          variant={ButtonVariant.TEXT_PRIMARY}
          size={ButtonSize.MEDIUM}
          icon={ICONS.EDIT_PENCIL}
          onClick={onEditClick}
        />
        <Button
          label={i18n._(t`Cancel`)}
          onClick={closeModal}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
        />
      </Footer>
    </Modal>
  ) : null;
};

export { InvitationMessagesPreview };
