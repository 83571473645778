import React from 'react';

import { ITask } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { List } from '../components/List';
import { ListRowSelf } from '../components/ListRowSelf';
import { Section } from '../components/Section';

import type { IReviewEmployeeOverviewForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface ISelfReviewTasksSectionProps {
  tasks: ITask[];
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
}

const SelfReviewTasksSection = ({ tasks, formMethods }: ISelfReviewTasksSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');

  const userFirstName = employee?.firstName;

  return (
    <Section title={i18n._(t`${userFirstName}’s self review tasks`)}>
      <List>
        {tasks.map((task, key) => (
          <ListRowSelf key={key} task={task} />
        ))}
      </List>
    </Section>
  );
};

export { SelfReviewTasksSection };
