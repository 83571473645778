import React, { useEffect, useMemo } from 'react';

import {
  REVIEW_STATUS,
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
} from '@learned/constants';
import { IUser } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import difference from 'lodash/difference';
import { type UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { SearchSelect } from '~/components/SearchSelect';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { StepFooter } from './components/StepFooter';
import { Form, Title, Header, ParticipantSection, UserAvatarWrapper, Label } from './design';
import { useEditEmployee } from './hooks/useEditEmployee';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import getUserFullName from '~/utils/getUserFullName';

import type { IReviewSelfForm } from './types';

interface StepParticipantsProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewSelfForm>;
}

const Wrapper = styled.div`
  padding-bottom: 2px;
  margin-bottom: -4px;
`;

const StepParticipants = ({ sectionState, formMethods }: StepParticipantsProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = formMethods;
  const watchEmployees = watch('employees');
  const { formMethods: employeeFormMethods } = useEditEmployee({
    employee: watchEmployees[0],
  });
  const isDraft = watch('status') === REVIEW_STATUS.DRAFT;

  useEffect(() => {
    const formData = employeeFormMethods.getValues();
    const selectedData = {
      ...watchEmployees[0],
      careerPlans: formData.careerPlans.selected,
      coaches: formData.coaches.selected?.map((item) => item.id),
      guests: formData.guests.selected?.map((item) => item.id),
    };

    setValue('employees', [selectedData], { shouldDirty: true });
    if (sectionState.triedToSubmit) {
      trigger('employees');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(employeeFormMethods?.getValues())]);

  const onCoachesChange = async (selected: IUser[]) => {
    const removedItems = difference(employeeFormMethods.watch('coaches.selected'), selected);

    if (removedItems.length > 0 && !isDraft) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });
      if (!isConfirmed) {
        return;
      }
    }

    employeeFormMethods.setValue('coaches.selected', selected);
  };

  const options = useMemo(() => {
    return {
      showCoaches: !!watch('evaluators')
        ?.map((item) => item.value)
        ?.includes(REVIEW_QUESTION_EVALUATORS.COACH),
      showJobs: !!watch('reviewQuestionTypes')?.includes(REVIEW_QUESTION_TYPES.SKILL_CATEGORY),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([watch('evaluators'), watch('reviewQuestionTypes')])]);

  return (
    <>
      <Form>
        <Header>
          <Title>
            <Trans>Select job and participants</Trans>
          </Title>
        </Header>

        <Wrapper>
          <ParticipantSection>
            <div className="title">
              <Trans>Employee</Trans>
            </div>
            <UserAvatarWrapper>
              <UserAvatar userId={watchEmployees[0].id} />
            </UserAvatarWrapper>
          </ParticipantSection>
          {options.showJobs && (
            <ParticipantSection>
              <div className="title">
                <Trans>Jobs</Trans>
              </div>
              <ToolTip
                tooltip={i18n._(
                  t`After a review has been published, the selected job(s) cannot be changed.`,
                )}
                disabled={isDraft}
              >
                <div className="inputWrapper">
                  <Label>
                    <Trans>Select job(s) to review</Trans>
                  </Label>
                  <SearchSelect
                    error={errors?.employees?.[0]?.careerPlans?.message?.split('|')}
                    selectedItems={employeeFormMethods.watch('careerPlans.selected')}
                    onChange={(selected) =>
                      employeeFormMethods.setValue('careerPlans.selected', selected)
                    }
                    onSearchChange={(search) =>
                      employeeFormMethods.setValue('careerPlans.search', search)
                    }
                    items={employeeFormMethods.watch('careerPlans.filtered')}
                    stringifyItem={(careerPlan) => getMultiLangString(careerPlan.jobProfile.name)}
                    placeholder={i18n._(t`Search job(s)`)}
                    isExpandable
                    disabled={!isDraft}
                  />
                </div>
              </ToolTip>
            </ParticipantSection>
          )}
          {options.showCoaches && (
            <ParticipantSection>
              <div className="title">
                <Trans>Coaches</Trans>
              </div>
              <div className="inputWrapper">
                <Label>
                  <Trans>Select coach(es) who will provide</Trans>
                </Label>
                <SearchSelect
                  error={errors?.employees?.[0]?.coaches?.message}
                  selectedItems={employeeFormMethods.watch('coaches.selected')}
                  onChange={onCoachesChange}
                  onSearchChange={(search) =>
                    employeeFormMethods.setValue('coaches.search', search)
                  }
                  items={employeeFormMethods.watch('coaches.filtered')}
                  stringifyItem={(user) => getUserFullName(user)}
                  placeholder={i18n._(t`Search coach(es)`)}
                  isExpandable
                  showAvatar
                />
              </div>
            </ParticipantSection>
          )}
          <ParticipantSection>
            <div className="title">
              <Trans>Guests</Trans>
            </div>
            <div className="inputWrapper">
              <Label>
                <Trans>Select guests invited to the review</Trans>
              </Label>
              <SearchSelect
                selectedItems={employeeFormMethods.watch('guests.selected')}
                onChange={(selected) => employeeFormMethods.setValue('guests.selected', selected)}
                onSearchChange={(search) => employeeFormMethods.setValue('guests.search', search)}
                items={employeeFormMethods.watch('guests.filtered')}
                stringifyItem={(user) => getUserFullName(user)}
                placeholder={i18n._(t`Search guests`)}
                minCharsToShowDropdown={2}
                isExpandable
                showAvatar
              />
            </div>
          </ParticipantSection>
        </Wrapper>

        <StepFooter
          onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        />
      </Form>
    </>
  );
};

export { StepParticipants };
