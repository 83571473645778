import React, { useEffect, useState } from 'react';

import filter from 'lodash/filter';
import get from 'lodash/get';

import PDFLayout from '~/layouts/PDFLayout';
import ReviewNotesPDF from '~/pages/ReviewUserPage/components/ReviewNotesPDF';

import Header from './components/Header';
import ReviewContent from './components/ReviewContent';

import { REQUEST_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getReview } from '~/services/reviewsOld';
import { getUsers } from '~/services/users';

const ReviewReportPDF = ({ match, location }) => {
  const [review, setReview] = useState({});
  const [users, setUsers] = useState({});
  const $loading = useBoolState(true);
  const urlParams = new URLSearchParams(location.search);
  const userId = urlParams.get('userId');

  const fetchData = async () => {
    const [review, users] = await Promise.all([
      getReview(match.params.reviewId, {
        isQuestions: true,
        join: ['requests', 'ratings', 'comments'],
      }),
      getUsers(),
    ]);

    const reviewRequestWithoutRejected = get(review, 'requests', []).filter((r) => {
      return r.status !== REQUEST_STATUSES.REJECTED.key;
    });

    const reviewsWithoutRejected = {
      ...review,
      requests: reviewRequestWithoutRejected,
      comments: filter(review.comments || [], (c) => !c.isPrivate),
    };

    setReview(reviewsWithoutRejected);
    setUsers(users);
    $loading.off();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isYourReview = review?.createdFor && review?.createdFor === userId;
  const userFirstName =
    users[review.createdFor]?.firstName || users[review.createdFor]?.email || '';
  return (
    <PDFLayout loading={$loading.value}>
      {review ? (
        <>
          <Header review={review} users={users} userId={userId} />
          <ReviewContent
            review={review}
            users={users}
            userId={userId}
            userFirstName={userFirstName}
            isYourReview={isYourReview}
          />
          <ReviewNotesPDF review={review} />
        </>
      ) : (
        <div />
      )}
    </PDFLayout>
  );
};

export default ReviewReportPDF;
