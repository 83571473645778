import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { AddSkillModal } from '~/components/Modals/AddSkillModal';
import { AddSkillModalResource } from '~/components/Modals/AddSkillModal/types';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';

import { SkillTemplateTable } from './components/SkillTemplatesTable';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Header, Description, StyledSkillLevelsDropdown } from './design';

import { COLORS } from '~/styles';

import type { IGeneralForm } from './types';
import type { ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategories: ISkillCategory[];
  skillTemplates: ISkillTemplate[];
  skills: ISkill[];
  setSkillTemplates: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills: Dispatch<SetStateAction<ISkill[]>>;
  jobTemplateId?: string;
};

function StepSkillCategory({
  sectionState,
  formMethods,
  skillCategories,
  skillTemplates,
  skills,
  setSkillTemplates,
  setSkills,
  jobTemplateId,
}: StepDetailsProps) {
  const { handleSubmit, register, control, watch } = formMethods;
  const { i18n } = useLingui();
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [source, setSource] = useState<ISkillTemplate | ISkill>();

  const { skillCategoryId, skillCategoryTemplateId, skillCategoryName, skillLevels } =
    useMemo(() => {
      const skillCategoryId = sectionState.sections[sectionState.currentSection].id;
      const skillCategoryName = sectionState.sections[sectionState.currentSection].title;
      const skillCategory = skillCategories.find(({ id }) => id === skillCategoryId);
      const skillLevels = skillCategory?.skillLevels.map((_, index) => ++index) || [];
      const skillCategoryTemplateId = skillCategory?.skillCategoryTemplate || undefined;

      return { skillCategoryId, skillCategoryName, skillLevels, skillCategoryTemplateId };
    }, [sectionState.currentSection, sectionState.sections, skillCategories]);

  const onSubmit = () => sectionState.setCurrentSection(1);

  useEffect(() => {
    if (source) {
      setIsAddSkillModalVisible(true);
    }
  }, [source]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Header>
        <Title>{skillCategoryName}</Title>
        <div className="buttons">
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            label={i18n._(t`Add skill or KPI`)}
            onClick={() => {
              setIsAddSkillModalVisible(true);
            }}
          />
        </div>
      </Header>
      <Header marginBottom="20px">
        <div className="switchWrapper">
          <Controller
            {...register(`skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`)}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch onChange={onChange} checked={value} className="switch" />
            )}
          />
          <Description color={COLORS.TEXT_HOVER} width="316px">
            <Trans>Automatically add the focus areas for the selected skill level</Trans>
          </Description>
        </div>
        <Controller
          {...register(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          control={control}
          render={({ field: { onChange } }) => (
            <StyledSkillLevelsDropdown
              selectedItem={skillLevels.find(
                (value) => value === watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`),
              )}
              items={skillLevels}
              onChange={onChange}
              stringifyItem={(item) => i18n._(t`Level ${item}`)}
              isSingleSelect
            />
          )}
        />
      </Header>
      <SkillTemplateTable
        skillCategoryId={skillCategoryId}
        formMethods={formMethods}
        skillTemplates={skillTemplates}
        skills={skills}
        setSource={setSource}
        skillCategories={skillCategories}
        isEditMode
      />
      <StepFooter
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
      {isAddSkillModalVisible && skillCategoryId && (
        <AddSkillModal
          onClose={() => {
            setIsAddSkillModalVisible(false);
            setSource(undefined);
          }}
          skillCategoryName={skillCategoryName}
          defaultFocusAreaLevel={watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          isDefaultFocusAreaLevelEnabled={watch(
            `skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`,
          )}
          formMethods={formMethods}
          skillCategoryId={skillCategoryId}
          skillCategoryTemplateId={skillCategoryTemplateId}
          initSource={source}
          resource={AddSkillModalResource.SKILLS}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
          skillLevels={skillLevels}
          jobTemplateId={jobTemplateId}
          skillTemplates={skillTemplates}
          selectedSkills={skills}
        />
      )}
    </Form>
  );
}

export { StepSkillCategory };
