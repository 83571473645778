import React from 'react';

import { Trans } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';

import { IconWithGradientBlock } from '~/components/IconWithGradientBlock';
import RickTextView from '~/components/RickTextView';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import type { PopulatedSurveyTemplate } from '~/pages/SurveyTemplateUpdate/types';

import { ILanguage } from '~/constants/languages';

import { DASHBOARD_TYPE } from '../constants';
import { MainInfoWrap, NameRow, Name, Description } from '../design';
import { PopulatedReviewTheme } from '../types';

interface IMainInfoBlock {
  dashboardType: DASHBOARD_TYPE;
  item: PopulatedReviewTheme | PopulatedSurveyTemplate | PopulatedReviewTemplate;
  locale: ILanguage['locale'];
}

const MainInfoBlock = ({ dashboardType, item, locale }: IMainInfoBlock) => {
  const name = item.name[locale] || <Trans>No data in selected language</Trans>;
  const description = item.description[locale];
  return (
    <MainInfoWrap>
      <NameRow>
        {dashboardType === DASHBOARD_TYPE.THEME && (
          <IconWithGradientBlock
            icon={(item as PopulatedReviewTheme).icon}
            iconColor={(item as PopulatedReviewTheme).iconColor}
          />
        )}
        <Name withLeftMargin={dashboardType === DASHBOARD_TYPE.THEME}>{name}</Name>
      </NameRow>
      {!isEmpty(description) && (
        <Description>
          {/* @ts-ignore */}
          <RickTextView html={description} />
        </Description>
      )}
    </MainInfoWrap>
  );
};

export { MainInfoBlock };
