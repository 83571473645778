import styled from 'styled-components';

import Label from '~/components/Label';

import { generateChildStyles } from './utils';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  // this color was in the design file, and many other components use it as well
  box-shadow: 0 0 9px 0 rgba(60, 55, 74, 0.24);
  padding: 16px;
`;

export const JobCardWrapper = styled.div<{ $isSaved?: boolean }>`
  box-sizing: border-box;
  width: 312px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0 0 9px 0 rgba(60, 55, 74, 0.1);
  padding: 19px 19px 12px 20px;
  transform: translateY(6px);
  transition: all 300ms ease-out;
  z-index: 1;

  & .like-btn {
    opacity: 0;
    transform: translateY(6px);
    transition: all 300ms ease-out;
  }

  .btn-text {
    opacity: 0;
  }

  & .delete-btn {
    opacity: 0;
    transform: translateY(6px);
    transition: all 300ms ease-out;
  }

  &:hover {
    transition: box-shadow;
    box-shadow: 0 0 9px 0 rgba(60, 55, 74, 0.1);
    transform: translateY(0);
    transition: all 300ms ease-out;

    & .like-btn {
      opacity: 1;
      display: flex;
      transform: translateY(0);
      transition: all 300ms ease-out;
    }

    & .like-btn:hover {
      .btn-text {
        opacity: 1;
        color: ${({ $isSaved }) => ($isSaved ? COLORS.SUBTEXT : COLORS.ACCENT_SUCCESS)};
        text-decoration: ${({ $isSaved }) => ($isSaved ? 'line-through' : 'none')};
      }
    }

    & .delete-btn {
      opacity: 1;
      display: flex;
      transform: translateY(0);
      transition: all 300ms ease-out;
    }

    .btn-text {
      opacity: ${({ $isSaved }) => ($isSaved ? 1 : 0)};
      color: ${({ $isSaved }) => ($isSaved ? COLORS.ACCENT_SUCCESS : COLORS.SUBTEXT)};
    }
  }
`;

export const JobCardImage = styled.div`
  width: 273px;
  height: 153px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const DeleteButtonWrapper = styled.div`
  position: relative;
  z-index: 1;

  & > button {
    height: 32px;
    width: 32px;
    background: ${COLORS.ERROR_LIGHT};
    color: ${COLORS.ACCENT_ERROR};
    position: absolute;
    margin: 11px;
    top: 0;
    right: 0;
  }
`;

export const JobCardInfo = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const LikeButtonWrapper = styled.div`
  width: fit-content;
`;

export const PlaceholderWrapper = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0 0 9px 0 rgba(60, 55, 74, 0.24);
  padding: 20px;
`;

export const InformationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
  align-items: flex-start;
`;

export const PlaceholderInformationRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 32px;
  align-items: flex-start;
`;

export const ImagePlaceholder = styled.div<{ backgroundColor?: string; height: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.1;
  height: ${({ height }) => height};
  width: 100%;
`;

export const JobImage = styled.div`
  height: 160px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    object-fit: cover;
  }
`;

export const PlaceholderImage = styled.div`
  height: 160px;
  width: 285px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.BG_LIST};

  & > img {
    margin: 11px 58px 5px 68px;
    height: 144px;
  }
`;

export const PlaceholderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const PlaceholderFooterWrapper = styled.div`
  display: flex;
`;

export const PlaceholderRectangle = styled.div<{ width: number; marginRight?: number }>`
  width: ${(props) => props.width}px;
  height: 8px;
  margin: 21px ${(props) => props.marginRight ?? 0}px 8px 0;
  border-radius: 5.5px;
  background-color: ${COLORS.BG_PAGE};
`;

export const JobTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

export const JobCardTitle = styled.div`
  min-height: 32px;
  margin-top: 11px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  color: ${COLORS.TEXT_HOVER};
`;

export const JobCardSkillMatchWrapper = styled.div`
  margin-top: 4px;
  font-size: 12px;
`;

export const JobCardDivider = styled.div`
  background: ${COLORS.BORDERS};
  margin: 10px 0;
  height: 2px;
`;

export const Title = styled.div`
  width: 400px;
  overflow: hidden;
  flex: auto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${COLORS.TEXT_HOVER};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Labels = styled.div`
  width: 125px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

export const RoleLabel = styled(Label)<{ opacity?: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: ${({ opacity }) => opacity || '1'};
`;

export const Description = styled.div`
  max-height: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  padding-top: 12px;
`;

export const SkillMatch = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const JobInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 14px;

  & > span {
    color: ${COLORS.TEXT_MAIN};
  }

  & > .percentage {
    color: ${COLORS.COMPANY};
  }
`;

export const JobFamily = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const JobLevel = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
  display: flex;
  align-items: center;
  gap: 8px;
  flex: auto;
`;

export const JobStackGroup = styled.div<{ count: number }>`
  position: relative;
  height: ${(props) => (props.count - 1) * 55 + 240}px;

  & > div {
    height: 240px;
    position: absolute;
  }

  & > div:nth-child(n) {
    position: relative;
    ${(props) => generateChildStyles(props.count)}
  }

  & > div:nth-child(n):hover {
    opacity: 1;
    cursor: pointer;
  }

  & > div:first-child {
    position: relative !important;
    top: 0px !important;
    width: 896px;
  }

  & > div:last-child {
    position: ${(props) => (props.count === 1 ? 'relative' : 'absolute')} !important;
    top: ${(props) => 55 * (props.count - 1)}px !important;
    opacity: 1 !important;
  }

  & > .transformThis {
    animation: scaleDown 300ms;
  }

  & > .transformPrev {
    animation: scaleUp 100ms;
    display: none;
  }

  @keyframes scaleUp {
    0% {
      transform: scale(1.05) translateY(50px);
      opacity: 0.5;
    }
    20% {
      transform: scale(1.04) translateY(40px);
      opacity: 0.6;
    }
    40% {
      transform: scale(1.03) translateY(30px);
      opacity: 0.7;
    }
    60% {
      transform: scale(1.02) translateY(20px);
      opacity: 0.8;
    }
    80% {
      transform: scale(1.01) translateY(10px);
      opacity: 0.9;
    }
    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
    20% {
      transform: scale(1.01) translateY(20px);
      opacity: 0.5;
    }
    40% {
      transform: scale(1.02) translateY(40px);
      opacity: 0.3;
    }
    60% {
      transform: scale(1.03) translateY(60px);
      opacity: 0.2;
    }
    80% {
      transform: scale(1.04) translateY(80px);
      opacity: 0.1;
    }
    100% {
      transform: scale(1.05) translateY(100px);
      opacity: 0;
    }
  }
`;
