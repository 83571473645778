import React from 'react';

import { REVIEW_STATUS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { size } from 'lodash';
import moment from 'moment';
import { Controller, UseFormReturn } from 'react-hook-form';

import { DateInput } from '~/components/DateInput';
import Editor from '~/components/Editor';
import { ICONS, Icon } from '~/components/Icon';
import ToolTip from '~/components/Tooltip';

import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';

import { InputContainer, InputContainerGeneral, Separator, SubTitle } from '../../../design';
import { IReviewCycleForm } from '../../../types';
import { DateRow, ExplanationText, SubTitleRow } from '../design';

interface NominatePeersSectionProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  languageState: ILanguageStateReturn;
}

const NominatePeersSection = ({ formMethods, languageState }: NominatePeersSectionProps) => {
  const { i18n } = useLingui();
  const { control, unregister, register, trigger, watch, setValue, formState } = formMethods;

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'tasks.reviewPeerNominate.description',
    control,
    unregister,
    languageState,
  });

  const onChangeDate = (date: Date, isStartDate?: boolean) => {
    const key = isStartDate
      ? 'tasks.reviewPeerNominate.startDate'
      : 'tasks.reviewPeerNominate.endDate';
    setValue(key, date, { shouldDirty: true });
    setValue('settings.isAutoTimeline', false, { shouldDirty: true });
    formState.errors.tasks?.reviewPeerNominate?.startDate ||
    formState.errors.tasks?.reviewPeerNominate?.endDate
      ? trigger('tasks.reviewPeerNominate')
      : trigger(key);
  };

  const getFormattedDateToString = (isStartDate?: boolean) => {
    const key = isStartDate
      ? 'tasks.reviewPeerNominate.startDate'
      : 'tasks.reviewPeerNominate.endDate';
    const datePattern = 'DD-MM-YYYY';
    const watchDate = watch(key);

    if (watchDate) {
      return moment(watchDate).format(datePattern);
    }
    return '';
  };

  const fetchedCycle = watch('fetchedCycle');
  const startDate = fetchedCycle.tasks?.reviewPeerNominate.startDate
    ? new Date(fetchedCycle.tasks?.reviewPeerNominate.startDate)
    : null;

  const isPublished = watch('status') === REVIEW_STATUS.PUBLISHED;
  const isActive = watch('status') === REVIEW_STATUS.ACTIVE;

  const today = new Date();
  const isStartDateDisabled = (isPublished && startDate && startDate < today) || isActive;

  return (
    <>
      <Separator marginBottom="24px" marginTop="24px" />
      <SubTitleRow>
        <Icon icon={ICONS.NOMINATE_PEER} />
        <SubTitle>
          <Trans>Nominate peers for input</Trans>
        </SubTitle>
      </SubTitleRow>
      <ExplanationText>
        <Trans>
          Because the review template includes questions for peers, employees will be given the task
          of nominating peers for input.
        </Trans>
      </ExplanationText>
      <DateRow>
        <ToolTip
          tooltip={i18n._(t`It is not possible to change this date after the task has started`)}
          disabled={!isStartDateDisabled}
        >
          <InputContainerGeneral>
            <DateInput
              value={getFormattedDateToString(true)}
              label={i18n._(t`Start date`)}
              onChange={(date) => onChangeDate(date as Date, true)}
              error={formState.errors?.tasks?.reviewPeerNominate?.startDate?.message}
              disabled={isStartDateDisabled}
            />
          </InputContainerGeneral>
        </ToolTip>
        <DateInput
          value={getFormattedDateToString()}
          label={i18n._(t`Deadline`)}
          onChange={(date) => onChangeDate(date as Date)}
          error={formState.errors?.tasks?.reviewPeerNominate?.endDate?.message}
        />
      </DateRow>
      <ExplanationText marginTop="38px" marginBottom="6px">
        <Trans>Instruction for nominating peers (optional)</Trans>
      </ExplanationText>
      <InputContainer>
        {descriptionFieldArray.fields.map((field) => (
          <Controller
            key={field.id}
            {...register(`tasks.reviewPeerNominate.description.${field.index}.value`)}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Editor
                  value={value}
                  // @ts-ignore
                  onChange={(args) => {
                    onChange(args);
                    trigger('tasks.reviewPeerNominate.description');
                  }}
                  key={field.id}
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                  placeholder={i18n._(t`Example: Nominate at least 2 of your team members`)}
                  compact
                />
              );
            }}
          />
        ))}
      </InputContainer>
    </>
  );
};

export { NominatePeersSection };
