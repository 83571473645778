import React from 'react';

import { Trans, t } from '@lingui/macro';
import moment from 'moment';

import { ContextMenu, ContextOption } from '~/components/ContextMenu';
import { ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import type { IColumnTable } from '~/@types/table';

import { GoalName, Name, TypeName, TypeNameWithContextMenu } from '../../design';
import { SkillMatchProgressAndReport } from '../components/SkillMatchProgressAndReport';

import type { I18n } from '@lingui/core';

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  SAVED_DATE_SOON_LATER = 'savedDate_asc',
  SAVED_DATE_LATER_SOON = 'savedDate_desc',
  SKILL_MATCH_MIN_MAX = 'skillMatch_asc',
  SKILL_MATCH_MAX_MIN = 'skillMatch_desc',
}

const Columns = ({ onDelete, isAdmin }: { onDelete: (item: any) => void; isAdmin: boolean }) => {
  const COLUMNS: IColumnTable<any>[] = [
    {
      name: (i18n: I18n) => i18n._(t`Job`),
      accessor: 'job',
      minWidth: '200px',
      maxWidth: '200px',
      renderCell: (item) => {
        return (
          <Name>
            <ToolTip
              tooltip={item.jobProfileName}
              placement={TOOLTIP_PLACEMENTS.BOTTOM}
              arrow={false}
            >
              <GoalName>
                <span>
                  {item.jobProfileName.length > 20
                    ? item.jobProfileName.substring(0, 16) + '...'
                    : item.jobProfileName}
                </span>
              </GoalName>
            </ToolTip>
          </Name>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.NAME_A_Z,
          label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
        },
        desc: {
          key: SORT_OPTIONS.NAME_Z_A,
          label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Saved date`),
      accessor: 'savedDate',
      minWidth: '100px',
      maxWidth: '100px',
      renderCell: (item) => {
        return <TypeName>{moment(item.meta.createdDate).format('DD-MM-YYYY')}</TypeName>;
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.SAVED_DATE_SOON_LATER,
          label: (i18n: I18n) => i18n._(t`Soon - later`),
        },
        desc: {
          key: SORT_OPTIONS.SAVED_DATE_LATER_SOON,
          label: (i18n: I18n) => i18n._(t`Later - soon`),
        },
      },
    },
    {
      name: (i18n: I18n) => i18n._(t`Skill match`),
      accessor: 'skillMatch',
      minWidth: '200px',
      maxWidth: '200px',
      renderCell: (item) => {
        return (
          <TypeNameWithContextMenu>
            {/* ProgressBar value and viewReport props to be changed when skill match implemented */}
            <SkillMatchProgressAndReport value={0} viewReport={() => {}} />
            {isAdmin && (
              <ContextMenu>
                <ContextOption isWarning action={() => onDelete(item)} icon={ICONS.DELETE_BIN}>
                  <Trans>Delete</Trans>
                </ContextOption>
              </ContextMenu>
            )}
          </TypeNameWithContextMenu>
        );
      },
      sortBy: {
        asc: {
          key: SORT_OPTIONS.SKILL_MATCH_MIN_MAX,
          label: (i18n: I18n) => i18n._(t`Min - max`),
        },
        desc: {
          key: SORT_OPTIONS.SKILL_MATCH_MAX_MIN,
          label: (i18n: I18n) => i18n._(t`Max - min`),
        },
      },
    },
  ];

  return COLUMNS;
};

export { Columns };
