import React from 'react';

import { transparentize } from 'polished';
import * as PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';

import { COLORS } from '~/styles';

import 'react-circular-progressbar/dist/styles.css';

const ProgressWrap = styled.div`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

const CircularProgress = ({
  progress,
  size,
  strokeWidth,
  pathColor,
  trailColor,
  textColor,
  textSize,
  isHideText,
}) => (
  <ProgressWrap $size={size}>
    <CircularProgressbar
      value={progress}
      text={isHideText ? undefined : `${progress}%`}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        pathColor,
        trailColor,
        textColor: progress === 100 ? pathColor : textColor,
        textSize,
      })}
    />
  </ProgressWrap>
);

CircularProgress.propTypes = {
  progress: PropTypes.number,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  pathColor: PropTypes.string,
  trailColor: PropTypes.string,
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  isHideText: PropTypes.bool,
};

CircularProgress.defaultProps = {
  progress: 0,
  size: 64,
  strokeWidth: 12,
  textSize: '13,3px',
  pathColor: 'var(--company-color)',
  trailColor: transparentize(0.8, COLORS.SUBTEXT),
  textColor: COLORS.SUBTEXT,
};

export default CircularProgress;
