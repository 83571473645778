import React, { useEffect, useRef, useState } from 'react';

import { Chart as ChartJS, PointElement, LineElement } from 'chart.js';
import { Radar } from 'react-chartjs-2';

import { GraphCtr, GraphOuterCtr } from './AreaGraph.design';

import { COLORS, PRIMARY_COLOR } from '~/styles';
import { getRgba } from '~/utils/colorManipulator';
import { handleTooltipData } from '~/utils/reports';

import { GraphTooltip } from '../components/GraphTooltip';
import { ISpiderGraphProps } from '../types';

import type { ChartData, ChartOptions } from 'chart.js';

ChartJS.register(PointElement, LineElement);

function SpiderGraph({
  dataGroups = [],
  height,
  width,
  tooltipTitle,
  bgColor = null,
  onPointClick,
  configOverrides = {},
}: ISpiderGraphProps) {
  const filterMainData = dataGroups?.filter((a) => a.key === 'primary');
  const primaryData = filterMainData && filterMainData.length > 0 ? filterMainData[0].data : [];
  const primaryDataLabels = primaryData.length > 0 ? primaryData.map((a) => a.key) : [];

  const toolRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'radar'>>(null);
  const [chartDataSet, setChartDataSet] = useState<ChartData<'radar'>>({
    labels: [],
    datasets: [],
  });
  const baseColor = bgColor ? bgColor : PRIMARY_COLOR;

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const dataSets = dataGroups.map((dGroup, i) => {
      const data = dGroup.data.map((d) => d.value);
      const dataGroup = dataGroups[i];
      return {
        label: dGroup.key,
        data,
        fontColor: COLORS.DROPDOWN_ICON,
        fill: true,
        backgroundColor:
          i === 0 ? getRgba(baseColor, 0.3) : getRgba(dGroup.colors[0] || COLORS.TRANSPARENT, 0.3),
        borderWidth: 1,
        ...(i !== 0 && { borderDash: [2, 2] }),
        borderColor: dataGroup.colors[0] || COLORS.TEXT_HOVER,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointBackgroundColor: dataGroup.colors[0] || COLORS.WHITE,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 6,
        pointHoverBorderColor: 'rgba(0, 0, 0, 0.5)',
        pointHoverBackgroundColor: dataGroup.colors[0] || COLORS.WHITE,
        order: dataGroups.length - i,
      };
    });

    if (dataSets?.length) {
      setChartDataSet({
        labels: primaryDataLabels,
        datasets: dataSets,
      } as ChartData<'radar'>);
    }

    // eslint-disable-next-line
  }, [chartRef]);

  const options: ChartOptions = {
    onHover: (event, _chartElement) => {
      const element = event.native?.target as HTMLElement;
      element.style.cursor = 'pointer';
    },
    scales: {
      r: {
        beginAtZero: configOverrides.beginAtZero || undefined,
        pointLabels: {
          color: COLORS.TEXT_HOVER,
          font: {
            size: 16,
          },
        },
        ticks: {
          display: false, //  removes the radial axis labels
          stepSize: configOverrides.stepSize || undefined,
        },
        grid: {
          circular: true,
          color: (context) => {
            // Hide the last grid line
            return context.index === context.chart.scales.r.ticks.length - 1
              ? 'transparent'
              : 'rgba(0, 0, 0, 0.1)';
          },
        },
        max: configOverrides.ratingLabels || undefined,
      },
    },
    elements: {},
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          if (context.tooltip.dataPoints?.length > 0) {
            const dataPoint = context.tooltip.dataPoints[0];

            if (!dataPoint) {
              return;
            }

            const dataPointIndex = dataPoint.dataIndex;

            const ValueGroups = dataGroups.map((dataGroup) => ({
              value: dataGroup.data[dataPointIndex].value,
              deviation: dataGroup.data[dataPointIndex].deviation,
              label: dataGroup.label,
              color: dataGroup.colors[0],
            }));

            handleTooltipData(
              toolRef.current,
              {
                title: dataPoint.label,
                values: ValueGroups,
                ratingLabels: configOverrides.ratingLabels || undefined,
              },
              context,
            );
          }
        },
      },
    },
    interaction: {
      mode: 'index',
      axis: 'r',
      intersect: true,
      includeInvisible: true,
    },
    onClick(_event, elements, _chart) {
      const primaryDataIndex = dataGroups.findIndex((a) => a.key === 'primary');
      const elementData = elements.find((e) => e.datasetIndex === primaryDataIndex);

      if (!elementData) {
        return;
      }

      // find the correct element from the primaryData
      // TODO: refine those graph data types later GraphDataType[] doesn't has an Id element, but we know its there
      const element: any = dataGroups[primaryDataIndex].data[elementData.index];

      // Assume currently only y dimension for the area graph is the month,
      !!onPointClick &&
        onPointClick({
          key: element.key,
          value: element.value,
          id: element.id!,
          name: element.key,
        });
    },
    color: 'red',
  };

  return (
    <GraphOuterCtr>
      <GraphCtr>
        <Radar
          ref={chartRef}
          height={height}
          width={width}
          data={chartDataSet}
          options={options as any}
        />
        <GraphTooltip
          ref={toolRef}
          tooltipTitle={tooltipTitle}
          additionalDataGroupsLength={dataGroups.length - 1}
        />
      </GraphCtr>
    </GraphOuterCtr>
  );
}

export { SpiderGraph };
