import React, { ReactNode } from 'react';

import { Trans } from '@lingui/macro';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import ToolTip from '~/components/Tooltip';

import { Avatar } from './components/Avatar';
import { Name } from './components/Name';
import { Container, Link } from './design';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import { getDeletedUsers, getInactiveUsers, getUser, getUsers } from '~/selectors/baseGetters';
import getUserFullName from '~/utils/getUserFullName';

import type { IUser } from '@learned/types';

type UserAvatarProps = {
  options?: {
    anonymityIndex?: number;
    isAnonymous?: boolean;
    isDeleted?: boolean;
    isDeactivated?: boolean;
    customName?: ReactNode;
    customColor?: string;
    showTooltip?: boolean;
  };
  onClick?: (userId?: string) => void | undefined;
} & ({ userId: string; user?: never } | { user: IUser; userId?: never });

function tooltipMessage({
  options,
  isAdmin,
  name,
}: {
  options: UserAvatarProps['options'];
  isAdmin?: boolean;
  name?: string;
}) {
  if (options?.isAnonymous) {
    return <Trans>Participant anonymized</Trans>;
  }

  if (options?.isDeactivated) {
    return (
      <>
        <Trans>Account disabled by admin.</Trans>
        {isAdmin && (
          <Trans>
            {' '}
            Go to{' '}
            <Link onClick={() => routes.MEMBERS.go({ role: ROLES.ADMIN }, { isBackPath: true })}>
              member page
            </Link>{' '}
            to reactivate account.
          </Trans>
        )}
      </>
    );
  }

  if (options?.showTooltip) {
    return name;
  }

  return null;
}

function ConditionalTooltip({
  tooltip,
  interactive,
  children,
}: {
  tooltip: ReactNode;
  interactive?: boolean;
  children: ReactNode;
}) {
  return tooltip ? (
    <ToolTip delay={[500, 0]} interactive={interactive} tooltip={tooltip}>
      <Container>{children}</Container>
    </ToolTip>
  ) : (
    <>{children}</>
  );
}

function UserAvatar({ user, userId, options = {}, onClick }: UserAvatarProps) {
  const currentUser = useSelector(getUser);
  const isAdmin = currentUser?.isAdmin;
  const deletedUser = useSelector((state) => userId && getDeletedUsers(state)[userId]);
  const activeUser = useSelector((state) => userId && getUsers(state)[userId]);
  const inactiveUser = useSelector((state) => userId && getInactiveUsers(state)[userId]);
  if (deletedUser) {
    user = deletedUser;
  } else if (activeUser) {
    user = activeUser;
  } else if (inactiveUser) {
    user = inactiveUser;
  }

  options = {
    isDeleted: !user || (deletedUser && !activeUser),
    isDeactivated: !!inactiveUser,
    ...options,
  };

  const name = !isNil(options?.customName) ? options?.customName : getUserFullName(user);

  return (
    <Container className="avatarWrapper" onClick={onClick ? () => onClick(user?.id) : undefined}>
      <ConditionalTooltip
        tooltip={tooltipMessage({ options, isAdmin, name })}
        interactive={options.isDeactivated}
      >
        <Avatar
          imgUrl={user?.avatarUrl}
          isAnonymous={options.isAnonymous}
          isDeleted={options.isDeleted}
        />
        <Name
          name={name}
          isAnonymous={options.isAnonymous}
          anonymityIndex={options.anonymityIndex}
          isDeactivated={options.isDeactivated}
          isDeleted={options.isDeleted}
          customColor={options.customColor}
        />
      </ConditionalTooltip>
    </Container>
  );
}

export { UserAvatar };
export type { UserAvatarProps };
