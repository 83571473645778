import React, { Dispatch, SetStateAction, useMemo } from 'react';

import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';

import { createSkillCategoryColumns } from '../columns/SkillCategory.columns';
import { convertSkillsDataToTable } from '../utils';

import type { IGeneralForm } from '../types';
import type { ISkillCategory, ISkillTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

interface ISkillTemplateTable {
  skillCategoryId?: string;
  skillTemplates: ISkillTemplate[];
  formMethods: UseFormReturn<IGeneralForm>;
  setSkillTemplate?: Dispatch<SetStateAction<ISkillTemplate | undefined>>;
  skillCategories: ISkillCategory[];
  isEditMode?: boolean;
}

const SkillTemplateTable = ({
  skillCategoryId,
  skillTemplates,
  formMethods,
  setSkillTemplate,
  skillCategories,
  isEditMode = false,
}: ISkillTemplateTable) => {
  const { watch } = formMethods;
  const watchSkillCategory = watch(`skills.${skillCategoryId}`);
  const { columns } = createSkillCategoryColumns({ isEditMode });

  const data = useMemo(() => {
    const skillCategory = skillCategories.find((item) => item.id === skillCategoryId);
    if (!skillCategory) {
      return [];
    }

    return convertSkillsDataToTable({
      skillTemplates,
      formMethods,
      skillCategory,
      setSkillTemplate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify([skillCategoryId, skillTemplates, watchSkillCategory, skillCategories])]);

  return (
    <Wrapper>
      <TableGrid thColumnPadding="16px 19px" data={data} columns={columns} />
    </Wrapper>
  );
};

export { SkillTemplateTable };
