export const transformToISOString = (date: Date | string | null) => {
  if (date === null) {
    return null;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  // set day from date in UTC
  date.setUTCDate(date.getDate());
  // set 0 UTC hours to keep same Day (not yesterday)
  date.setUTCHours(0, 0, 0, 0);

  return date.toISOString();
};
