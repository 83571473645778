import React from 'react';

import { UseFormReturn } from 'react-hook-form';

import { IQuestionForm, IRatingLabel } from '~/pages/ReviewThemeSetup/types';

import { useLanguageState } from '~/hooks/useLanguageState';

import { Options } from '../Options';

interface IProps {
  formMethods: UseFormReturn<IQuestionForm>;
  ratingLabels: IRatingLabel[];
}

const Ratings = ({ formMethods, ratingLabels }: IProps) => {
  const languageState = useLanguageState();

  return (
    <Options languageState={languageState} formMethods={formMethods} ratingLabels={ratingLabels} />
  );
};

export { Ratings };
