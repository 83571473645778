import React, { useEffect, useState, useRef } from 'react';

import { SIGNATURE_VIEWS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import { TemplateCardWithFooter } from '~/components/SetupTools/components/TemplateBaseComponents';
import SvgIcon from '~/components/SvgIcon';
import { Header3, Header7 } from '~/components/UI/Typographics/headers';
import LockedHint from '~/pages/ReviewUserPage/components/LockedHint';

import { getSignatureStatus } from './helpers';
import SignCard from './SignCard';

import DigitalSignThemeIcon from '~/assets/themes/ThemeIcons/DigitalSignThemeIcon.svg';
import toggleCloseIcon from '~/assets/toggle-close.svg';
import toggleOpenIcon from '~/assets/toggle-open.svg';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { addSignature, deleteSignatures } from '~/services/reviewsOld';
import { COLOR_PALETTE, COLOR_SET, COLORS } from '~/styles';
import { isTaskStartDateInThePast } from '~/utils/isTaskStartDateInThePast';
import pageBreak from '~/utils/pageBreak';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  ${Header3} {
    margin: 0;
  }
`;

const ColoredBlock = styled.div`
  background: linear-gradient(
    56deg,
    ${(props) => props.darkGradient},
    ${(props) => props.lightGradient}
  );
  width: 58px;
  min-width: 58px;
  height: 70px;
  border-top-left-radius: 6px;
  ${(props) => !props.$open && 'border-bottom-left-radius: 6px;'}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 24px 24px 8px;
  display: flex;
  justify-content: space-between;
`;

const DeleteSignaturesWrapper = styled.div`
  padding: 0 24px 24px 0;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 20px 12px 10px;
  width: 100%;
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

const HeaderText = styled.div`
  ${Header7} {
    color: ${COLORS.TEXT_SECONDARY};
    margin: 0;
    text-transform: uppercase;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
  color: ${COLORS.TEXT_BLACK};
`;

const LeftColumn = styled.div`
  width: 100%;
`;

const RightColumn = styled.div`
  width: 100%;
  margin-left: 16px;
`;

const RightHeaderCol = styled.div`
  display: flex;
  align-items: center;
`;

const Section = styled.div`
  display: block;
  @media print {
    margin-top: 40px;
    ${(props) => props.$break && 'page-break-before: always;'};
    ${(props) => props.$pdfView && 'break-inside: avoid;'};
  }
`;

const Wrapper = styled.div`
  ${(props) => !props.$pdfView && 'margin-bottom: 24px'};
`;

const SignCardWrapper = styled.div`
  margin-bottom: 16px;
`;

function DigitallySign({
  review,
  isOpen,
  pdfView,
  handleOnSignaturesUpdated,
  isDigitalSignModal,
  onCloseDigitalSignModal,
  isLocked,
}) {
  const { i18n } = useLingui();
  const $open = useBoolState(isOpen);
  const ref = useRef();
  const user = useSelector(getUser);
  const [breakPoint, setBreakPoint] = useState();

  useEffect(() => {
    const top = ref.current.getBoundingClientRect().top;
    const bottom = ref.current.getBoundingClientRect().bottom;
    setBreakPoint(pageBreak(top, bottom));
  }, []);

  const toggleSection = () => {
    $open.value ? $open.off() : $open.on();
  };

  const hintTaskAvailability = (startDate) => {
    const date = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    return i18n._(t`This task becomes available at ${date}`);
  };

  const findUserSignature = (userId, signatures = []) => {
    return signatures.find((s) => s.userId === userId);
  };

  const onSign = async (signature) => {
    const updatedReview = await addSignature(review.id, signature);
    if (updatedReview) {
      handleOnSignaturesUpdated(updatedReview.signatures);
    }
  };

  const onDeleteSignatures = async () => {
    await deleteSignatures(review.id);
    handleOnSignaturesUpdated([]);
  };

  const renderSignature = (item, key) => (
    <SignCardWrapper key={key}>
      <SignCard
        {...item}
        onSign={onSign}
        isDigitalSignModal={isDigitalSignModal}
        onCloseDigitalSignModal={onCloseDigitalSignModal}
        pdfView={pdfView}
        isLocked={isLocked}
        isActionDisabled={!isTaskStartDateInThePast(review?.startDateToSign)}
        actionTooltip={
          !isTaskStartDateInThePast(review?.startDateToSign) &&
          hintTaskAvailability(review?.startDateToSign)
        }
      />
    </SignCardWrapper>
  );

  const signatureItems = [
    {
      view: SIGNATURE_VIEWS.USER,
      userId: review.createdFor,
      digitalSign: review.digitalSign,
      userSignature: findUserSignature(review.createdFor, review.signatures),
      status: getSignatureStatus(SIGNATURE_VIEWS.USER, {
        signatures: review.signatures,
        conversationCoaches: review.conversationCoaches,
        userId: review.createdFor,
      }),
    },
    ...(review.conversationCoaches || []).map((coachId) => ({
      view: SIGNATURE_VIEWS.COACH,
      userId: coachId,
      digitalSign: review.digitalSign,
      userSignature: findUserSignature(coachId, review.signatures),
      status: getSignatureStatus(SIGNATURE_VIEWS.COACH, {
        signatures: review.signatures,
        conversationCoaches: review.conversationCoaches,
        userId: coachId,
      }),
    })),
  ];

  const isConversationCoach = review.conversationCoaches.includes(user.id);

  return (
    <Wrapper $pdfView={pdfView}>
      <Section ref={ref} $break={breakPoint} $pdfView={pdfView}>
        <TemplateCardWithFooter $noShadow={pdfView}>
          <CardContent>
            <HeaderSection onClick={toggleSection}>
              <ColoredBlock
                darkGradient={COLOR_SET.REVIEW_DARK_GRADIENT}
                lightGradient={COLOR_SET.REVIEW_LIGHT_GRADIENT}
                $open={$open.value}
              >
                <SvgIcon
                  url={DigitalSignThemeIcon}
                  width={'22px'}
                  height={'22px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.BLUE_DARK}
                />
              </ColoredBlock>
              <HeaderContent>
                <HeaderText>
                  <Header7>
                    <Trans>Final</Trans>
                  </Header7>
                  <HeaderTitle>
                    <Header3>
                      <Trans>Complete the conversation</Trans>
                    </Header3>
                  </HeaderTitle>
                </HeaderText>
                <RightHeaderCol>
                  {!pdfView && (
                    <div>
                      <SvgIcon
                        width="24px"
                        height="24px"
                        url={$open.value ? toggleOpenIcon : toggleCloseIcon}
                      />
                    </div>
                  )}
                </RightHeaderCol>
              </HeaderContent>
            </HeaderSection>
          </CardContent>
          {($open.value || isDigitalSignModal) && (
            <div>
              <ContentWrapper>
                <LeftColumn>
                  {signatureItems.filter((_i, index) => index % 2 === 0).map(renderSignature)}
                </LeftColumn>
                <RightColumn>
                  {signatureItems.filter((_i, index) => index % 2 !== 0).map(renderSignature)}
                </RightColumn>
              </ContentWrapper>
              {isConversationCoach && !pdfView && (
                <DeleteSignaturesWrapper>
                  {isLocked && (
                    <LockedHint
                      tooltip={i18n._(
                        t`It is no longer possible to delete signatures. Contact your HR manager for support.`,
                      )}
                    />
                  )}
                  <Button
                    type={Button.types.primaryBorder}
                    label={i18n._(t`Unlock conversation`)}
                    onClick={async () => {
                      const textConfirm = i18n._(
                        t`Are you sure you want to unlock the conversation? This will allow everyone to make updates to the conversation. However, this will also require everyone to complete the conversation again.`,
                      );
                      if (await confirm(i18n, textConfirm)) {
                        await onDeleteSignatures();
                      }
                    }}
                    disabled={isEmpty(review.signatures) || isLocked}
                    tooltip={i18n._(
                      t`If the conversation is completed by a coach, no changes can be made by anyone. Unlock the conversations to allow participants to update them.`,
                    )}
                  />
                </DeleteSignaturesWrapper>
              )}
            </div>
          )}
        </TemplateCardWithFooter>
      </Section>
    </Wrapper>
  );
}

DigitallySign.propTypes = {
  handleOnSignaturesUpdated: PropTypes.func,
  review: PropTypes.object,
  isOpen: PropTypes.bool,
  pdfView: PropTypes.bool,
  isDigitalSignModal: PropTypes.bool,
  onCloseDigitalSignModal: PropTypes.func,
  isLocked: PropTypes.bool,
};

export default DigitallySign;
