/**
 * @deprecated since Review 3.0 (we need to split tasks from next-steps)
 */
export enum FILTER_TASK_TYPES {
  SYSTEM = 'SYSTEM',
  NEXT_STEP = 'NEXT_STEP',
}

export enum TASK_SORT_OPTION {
  DEADLINE_ASC = 'deadline-asc',
  DEADLINE_DESC = 'deadline-desc',
}

export enum TASK_TYPE {
  // REVIEW 3.0
  REVIEW_SELF_EVALUATE = 'reviewSelfEvaluate',
  REVIEW_PEER_EVALUATE = 'reviewPeerEvaluate',
  REVIEW_COACH_EVALUATE = 'reviewCoachEvaluate',
  REVIEW_PEER_NOMINATE = 'reviewPeerNominate',
  // TODO add more
}

export enum TASK_LAST_STATUS_METHOD {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum TASK_STATUS {
  UPCOMING = 'upcoming', // task created, but startDate in future
  TODO = 'todo', // task visible to user, when startDate comes
  ACTIVE = 'active', // user start working on task
  COMPLETED = 'completed', // user finish working on task
  DECLINED = 'declined', // user declined the task

  // when review has status archived or signing, but task was not yet completed
  // when admin manually disables this task in the review cycle dashboard
  // - if review is part of a review cycle
  EXPIRED = 'expired',
}

export enum TASK_TARGET_COLLECTION {
  USER_REVIEWS = 'userReviews',
}

export enum TASK_FILTER_BY_USER_TYPE {
  ALL = 'all',
  EMPLOYEE = 'employee',
  COACH = 'coach',
}
