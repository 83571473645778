import { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONNECTION_STATUSES,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
  REVIEW_TYPES,
  CAREER_PLAN_STATUSES,
} from '@learned/constants';
import { useSelector } from 'react-redux';

import { ICONS } from '~/components/Icon';
import { useInactiveUsers } from '~/pages/Reviews/hooks/useInactiveUsers';
import { transformToISOString } from '~/pages/Reviews/utils';

import { useReviewTasks } from './useReviewTasks';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getUser, getSettingsRole } from '~/selectors/baseGetters';
import { getCareerPlansAsAdminCoach } from '~/services/careerPlans';
import { deleteReviewById, fetchReviewById, updateReviewById } from '~/services/reviews';
import { getUserReviews } from '~/services/userReviews';
import { getCompanyUsers } from '~/services/users';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IEmployee, IReviewSelfForm, PopulatedCareerPlan } from '../types';
import type { IReview, IUser, IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  reviewId: IReview['id'];
}

export const useReview = ({ formMethods, reviewId }: UseReviewProps) => {
  const {
    setValue,
    watch,
    formState: { dirtyFields },
  } = formMethods;
  const { removeInactiveUsers } = useInactiveUsers();
  const $isReviewLoading = useBoolState(true);
  const languageState = useLanguageState(true);
  const userRole = useSelector(getSettingsRole);
  const { setTasksDates } = useReviewTasks({ formMethods });

  const [item, setItem] = useState<IReview>();
  const user = useSelector(getUser);

  const $isReviewSaving = useBoolState(false);
  const [isAllowToDelete, setIsAllowToDelete] = useState(false);

  useEffect(() => {
    setIsAllowToDelete(item && (user.isAdmin || item?.createdBy === user.id));
  }, [item, user]);

  const fetchReview = async () => {
    const result = await fetchReviewById(reviewId);
    const review: IReview = result.data[API_RETURN_FIELDS.REVIEW];
    setItem(review);
    return review;
  };

  const fetchCareerPlans = async () => {
    const res = await getCareerPlansAsAdminCoach(
      { status: CAREER_PLAN_STATUSES.CURRENT, createdFor: user.id },
      { populate: ['jobProfile'] },
      userRole,
    );
    return Object.values(res as Record<string, PopulatedCareerPlan>);
  };

  const fetchCompanyUsers = async (users: string[]): Promise<IUser[]> => {
    const { data } = await getCompanyUsers(
      {
        statuses: [CONNECTION_STATUSES.ACTIVE],
        users,
      },
      ['teams', 'jobProfiles', 'coaches'],
    );

    return Object.values(data?.users ?? {});
  };

  const getEmployeesFromUserReviews = async (userReviews: IUserReview[]) => {
    const careerPlans = await fetchCareerPlans();
    const users = await fetchCompanyUsers(userReviews.map((review) => review.createdFor));
    const employees: Array<IEmployee> = [];

    userReviews.forEach((userReview) => {
      const createdForUser = users.find((user: IUser) => user.id === userReview.createdFor);
      const plansForUser = careerPlans.filter(
        (item) => item.createdFor === createdForUser?.id && item.primary,
      );
      const isEditFlow = userReview.meta.lastModifiedDate;
      // @ts-ignore
      const defaultCoaches = createdForUser?.coaches || [];

      // @ts-ignore
      employees.push({
        ...createdForUser,
        coaches: isEditFlow ? userReview.coaches : defaultCoaches,
        guests: isEditFlow ? userReview.guests : defaultCoaches,
        userReview: userReview.id,
        careerPlanIds: isEditFlow
          ? userReview.careerPlans
          : plansForUser.map((item) => item.id) || [],
      });
    });

    return employees;
  };

  const addEmployees = async (employees: IEmployee[]) => {
    const careerPlans = await fetchCareerPlans();

    setValue(
      'employees',
      employees.map((employee) => {
        const plansForUser = careerPlans.filter(
          (item) => item.createdFor === employee.id && employee.careerPlanIds?.includes(item.id),
        );
        const jobProfileIds = plansForUser?.map((plan) => plan.jobProfile.id);
        const jobProfiles = employee.jobProfiles.filter((item) => jobProfileIds.includes(item.id));

        return {
          ...employee,
          coaches: removeInactiveUsers(employee.coaches),
          guests: removeInactiveUsers(employee.guests) || [],
          availableCoaches: watch('employees')?.[0]?.availableCoaches || [],
          availableJobProfiles: employee.jobProfiles || [],
          jobProfiles: jobProfiles || [],
          careerPlans: plansForUser || [],
          availableCareerPlans: careerPlans,
        };
      }),
    );
  };

  const fetchUserReview = async () => {
    const result = await getUserReviews({ filters: { review: [reviewId] }, options: {} });
    const userReview: IUserReview = result.data[API_RETURN_FIELDS.USER_REVIEWS]?.[0];

    setValue('userReviews', [userReview.id]);
    const employees = await getEmployeesFromUserReviews([userReview]);

    await addEmployees(employees);

    return userReview;
  };

  const setFormValues = async () => {
    const review = await fetchReview();
    const userReview = await fetchUserReview();
    setValue('notifications', review.notifications);
    setValue('name', turnMultiLangIntoArray(review.name, languageState.companyLanguages));
    setValue('reviewTemplate', review.reviewTemplate);
    setValue('privacy', review.privacy);
    setValue('settings', {
      ...review.settings,
      startDate: new Date(review.settings.startDate),
      endDate: review.settings.endDate ? new Date(review.settings.endDate) : null,
    });
    setValue('status', review.status);
    setValue('userReviewId', userReview?.id);
    setTasksDates(review);
    setValue('fetchedCycle', review);
    $isReviewLoading.off();
  };

  const saveReview = async (status?: REVIEW_STATUS) => {
    $isReviewSaving.on();
    const evaluators = watch('evaluators').map(
      (evaluator: { value: REVIEW_QUESTION_EVALUATORS; icon: ICONS; title: string }) =>
        evaluator.value,
    );
    const review = {
      name: turnArrayIntoMultiLang(watch('name')),
      notifications: watch('notifications'),
      reviewTemplate: watch('reviewTemplate') || null,
      privacy: watch('privacy'),
      settings: {
        ...watch('settings'),
        startDate: transformToISOString(watch('settings.startDate')),
        endDate: transformToISOString(watch('settings.endDate')),
      },
      status: status ? status : watch('status'),
      description: {},
      tasks: {},
      type: REVIEW_TYPES.SELF,
      reviewInvitationTemplate: null,
    };

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER)) {
      // @ts-ignore
      review.tasks.reviewPeerEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewPeerEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerEvaluate.endDate')),
      };
      // @ts-ignore
      review.tasks.reviewPeerNominate = {
        startDate: transformToISOString(watch('tasks.reviewPeerNominate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewPeerNominate.endDate')),
        description: turnArrayIntoMultiLang(watch('tasks.reviewPeerNominate.description') || []),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH)) {
      // @ts-ignore
      review.tasks.reviewCoachEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewCoachEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewCoachEvaluate.endDate')),
      };
    }

    if (evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE)) {
      // @ts-ignore
      review.tasks.reviewSelfEvaluate = {
        startDate: transformToISOString(watch('tasks.reviewSelfEvaluate.startDate')),
        endDate: transformToISOString(watch('tasks.reviewSelfEvaluate.endDate')),
      };
    }

    const employee = watch('employees')?.[0];

    const editUserReviews = [];

    const isCoachesVisible = evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
    const isJobsVisible = watch('reviewQuestionTypes').includes(
      REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
    );
    const coaches = isCoachesVisible ? employee.coaches : [];
    const careerPlans = employee.careerPlans?.map((careerPlan) => careerPlan.id) || [];

    editUserReviews.push({
      id: watch('userReviewId'),
      coaches,
      guests: employee.guests,
      careerPlans: isJobsVisible ? careerPlans : [],
    });

    let result;

    try {
      result = await updateReviewById(reviewId, {
        // @ts-ignore
        review,
        editUserReviews: dirtyFields.employees ? editUserReviews : [],
      });
    } finally {
      // turn off loading modal if error or success
      $isReviewSaving.off();
    }

    return result;
  };

  const deleteReview = async () => {
    if (item && isAllowToDelete) {
      await deleteReviewById(item.id);
    }
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  return {
    deleteReview,
    saveReview,
    isAllowToDelete,
    isReviewLoading: $isReviewLoading.value,
    isReviewSaving: $isReviewSaving.value,
  };
};
