import React from 'react';

import { getHeatmapColors } from '~/components/TableGrid/utils';

import { SingleRow, RowTitle, RowValue, Wrapper } from './MiniHeatmap.design';

import { HeatmapResponse } from '~/services/reports';

type HeatmapTypes = Pick<HeatmapResponse, 'rows'>;

interface IProps extends HeatmapTypes {
  isCompanyColor: boolean;
}
const MiniHeatmap = ({ rows, isCompanyColor }: IProps): JSX.Element => {
  const scores = rows?.map((row) => {
    const cells = row.cells.filter((cell) => cell.measure === 'average');
    return {
      name: row.name,
      value: cells.length > 0 ? cells[0].value : 0,
    };
  });

  return (
    <Wrapper>
      {scores.map((score, index) => (
        <SingleRow key={`score-${index + 1}`}>
          <RowTitle>
            <span>{score.name}</span>
          </RowTitle>
          <RowValue
            opacity={isCompanyColor ? (scores.length - index) / scores.length : 1}
            gradients={isCompanyColor ? [] : getHeatmapColors(score.value)}
          >
            {score.value}%
          </RowValue>
        </SingleRow>
      ))}
    </Wrapper>
  );
};

export { MiniHeatmap };
