import React, { useState, useCallback, useMemo } from 'react';

import { NEXT_STEP_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '~/components/Button';
import Comments from '~/components/Comments';
import InfoIcon from '~/components/Icons/Info';
import { NextSteps } from '~/components/NextSteps';
import Placeholder from '~/components/Placeholder';
import SvgIcon from '~/components/SvgIcon';
import Tabs from '~/components/Tabs';
import UploadArea from '~/components/UploadArea';

import AttachPlaceholderIcon from '~/assets/attach-paperclip-symbol.svg';
import NotePlaceholderIcon from '~/assets/notes-1.svg';
import trashIcon from '~/assets/trash.svg';

import { REVIEW_STAGES, REVIEW_STATUSES, ROLES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getRelevantSteps } from '~/services/nextSteps';
import { uploadReviewAttachment, deleteReviewAttachment } from '~/services/reviewsOld';
import { COLORS, COLOR_PALETTE } from '~/styles';

const AttachmentRow = styled.div`
  display: flex;
  margin: 0 0 10px;
  padding: 13px 17px 13px 28px;
  min-height: 48px;
  border-radius: 6px;
  background-color: ${COLORS.BG_PAGE};
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;
`;

const AttachmentName = styled.a`
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: ${COLOR_PALETTE.BLACK};
`;

const Attachments = styled.div`
  margin-top: 18px;
  overflow: auto;
  max-height: calc(100vh - 160px);
`;

const AttachmentWrapper = styled.div`
  margin: 20px;
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${COLOR_PALETTE.DARK_GRAY};
  margin: 8px 24px 0;
  display: flex;
  span {
    margin-left: 5px;
  }
`;

const TABS = {
  nextSteps: 'nextSteps',
  shared: 'shared',
  private: 'private',
  attachments: 'attachments',
};

const Attachment = ({ file, isCanDelete, onDelete }) => {
  return (
    <AttachmentRow>
      <AttachmentName href={file.url} target="_blank" rel="noopener noreferrer">
        {file.name}
      </AttachmentName>
      {isCanDelete && (
        <SvgIcon
          width="26px"
          height="26px"
          url={trashIcon}
          isDefaultColor={true}
          defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
          onClick={onDelete}
        />
      )}
    </AttachmentRow>
  );
};

const ReviewNotes = ({
  i18n,
  review,
  comments,
  files,
  updateReviewComments,
  updateReviewAttachments,
}) => {
  const user = useSelector(getUser);
  const $loading = useBoolState();
  const reviewNotes = filter(comments || [], (c) => !c.isPrivate).sort((a, b) => {
    const dateA = get(a, 'meta.createdDate');
    const dateB = get(b, 'meta.createdDate');
    return moment(dateB).isAfter(dateA) ? 1 : -1;
  });
  const reviewPrivateNotes = filter(
    comments || [],
    (c) => c.isPrivate && c.author === user.id,
  ).sort((a, b) => {
    const dateA = get(a, 'meta.createdDate');
    const dateB = get(b, 'meta.createdDate');
    return moment(dateB).isAfter(dateA) ? 1 : -1;
  });

  const [notes, setNotes] = useState(reviewNotes);
  const [nextStepsCounter, setNextStepsCounter] = useState(0);
  const [privateNotes, setPrivateNotes] = useState(reviewPrivateNotes);
  const [attachments, setAttachments] = useState(!isEmpty(files) ? files : []);
  const currentRole = useSelector(getSelectedRole);
  const [activeTab, setActiveTab] = useState(TABS.nextSteps);

  const isLockedFeedback = get(review, `lockedStages[${REVIEW_STAGES.FEEDBACK}]`, false);
  const isLockedSignatures = !isEmpty(get(review, 'signatures', []));
  const allowEdits =
    !isLockedFeedback &&
    !isLockedSignatures &&
    review.status !== REVIEW_STATUSES.DONE.key &&
    (review.conversationCoaches?.includes(user.id) || review.createdFor === user.id) &&
    currentRole !== ROLES.ADMIN;
  const isCanSeeAttachments =
    review.conversationCoaches?.includes(user.id) ||
    review.coaches?.includes(user.id) ||
    review.createdFor === user.id ||
    currentRole === ROLES.ADMIN;
  const isAllowDeleteAttachments =
    !isLockedFeedback &&
    !isLockedSignatures &&
    review.status !== REVIEW_STATUSES.DONE.key &&
    (review.conversationCoaches?.includes(user.id) ||
      review.coaches?.includes(user.id) ||
      review.createdFor === user.id ||
      currentRole === ROLES.ADMIN);

  const tabItems = useMemo(
    () =>
      [
        {
          key: TABS.nextSteps,
          label: t`Next steps (${nextStepsCounter})`,
        },
        {
          key: TABS.shared,
          label: t`Notes (${notes.length})`,
        },
        {
          key: TABS.private,
          label: t`Private Notes (${privateNotes.length})`,
        },
        isCanSeeAttachments && {
          key: TABS.attachments,
          label: t`Attachments`,
        },
      ].filter((t) => t),
    [isCanSeeAttachments, nextStepsCounter, notes.length, privateNotes.length],
  );

  const deleteNote = (noteId, isPrivate = false) => {
    if (isPrivate) {
      setPrivateNotes([...privateNotes.filter((note) => note.id !== noteId)]);
    } else {
      setNotes([...notes.filter((note) => note.id !== noteId)]);
    }
    // update state of main component
    updateReviewComments(comments.filter((note) => note.id !== noteId));
  };

  const handleComment = (comment, isUpdate = false, isPrivate = false) => {
    if (isUpdate) {
      if (isPrivate) {
        const updatedNotes = privateNotes.map((n) => (n.id === comment.id ? comment : n));
        setPrivateNotes(updatedNotes);
      } else {
        const updatedNotes = notes.map((n) => (n.id === comment.id ? comment : n));
        setNotes(updatedNotes);
      }
      // update state of main component
      updateReviewComments(comments.map((n) => (n.id === comment.id ? comment : n)));
    } else {
      if (isPrivate) {
        setPrivateNotes([comment, ...privateNotes]);
      } else {
        setNotes([comment, ...notes]);
      }
      // update state of main component
      updateReviewComments([comment, ...comments]);
    }
  };

  const uploadHandler = useCallback((file) => uploadReviewAttachment(file, review.id), [review.id]);

  const handleUpload = useCallback(
    (file) => {
      $loading.off();
      setAttachments([...attachments, file]);
      updateReviewAttachments([...attachments, file]);
    },
    [$loading, setAttachments, attachments, updateReviewAttachments],
  );

  const deleteAttachment = async (fileId) => {
    $loading.on();
    await deleteReviewAttachment(fileId, review.id);
    setAttachments(attachments.filter((f) => f.id !== fileId));
    updateReviewAttachments(attachments.filter((f) => f.id !== fileId));
    $loading.off();
  };

  const [currentSteps, setCurrentSteps] = useState([]);
  const [previousUncompletedSteps, setPreviousUncompletedSteps] = useState([]);

  const fetchSteps = async () => {
    const { data: fetchedSteps } = await getRelevantSteps({
      type: NEXT_STEP_TYPES.REVIEW,
      targetId: review.id,
    });

    setNextStepsCounter(fetchedSteps.currentConvSteps.length);
    setPreviousUncompletedSteps(fetchedSteps.previousUncompletedSteps || []);
    setCurrentSteps(fetchedSteps.currentConvSteps || []);
  };

  return (
    <NotesWrapper>
      <Tabs
        noMarginBottom={true}
        currentItem={activeTab}
        marginBetweenTabs="12px"
        items={tabItems}
        handleChangeTab={(tab) => setActiveTab(tab)}
        isSmall
        border
      />
      {activeTab === TABS.nextSteps && (
        <NextSteps
          currentSteps={currentSteps}
          previousUncompletedSteps={previousUncompletedSteps}
          fetchSteps={fetchSteps}
          participants={[review.createdFor, ...review.conversationCoaches]}
          targetId={review.id}
          isLocked={isLockedFeedback || isLockedSignatures || !allowEdits}
          type={NEXT_STEP_TYPES.REVIEW}
          viewOnly={!allowEdits}
        />
      )}
      {activeTab === TABS.shared && (
        <>
          <Comments
            borderTopOfComments={false}
            comments={notes}
            commentPlaceHolder={i18n._(t`Type your notes here…`)}
            source="review"
            sourceId={review.id}
            saveButton={true}
            onCommentUpdated={(comment) => handleComment(comment, true)}
            onCommentCreated={(comment) => handleComment(comment)}
            onCommentDeleted={(commentId) => deleteNote(commentId)}
            readOnly={!allowEdits}
            isNotes
            isNewCommentOnTop
            listStyles={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 255px)',
              paddingBottom: '40px',
            }}
          />
          {isEmpty(notes) && (
            <Placeholder
              title={i18n._(t`No notes yet`)}
              subTitle={i18n._(t`No notes have been created.`)}
              styles={{ marginTop: '50px' }}
              Icon={() => (
                <SvgIcon
                  url={NotePlaceholderIcon}
                  width={'50px'}
                  height={'50px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
            />
          )}
        </>
      )}
      {activeTab === TABS.private && (
        <>
          <SubTitle>
            <InfoIcon />
            <span>
              <Trans>Private notes are only visible to you and do not show in the pdf.</Trans>
            </span>
          </SubTitle>
          <Comments
            borderTopOfComments={false}
            comments={privateNotes}
            commentPlaceHolder={i18n._(t`Type your private note...`)}
            source="review"
            sourceId={review.id}
            saveButton={true}
            onCommentUpdated={(comment) => handleComment(comment, true, true)}
            onCommentCreated={(comment) => handleComment(comment, false, true)}
            onCommentDeleted={(commentId) => deleteNote(commentId, true)}
            readOnly={!allowEdits}
            isNotes
            isPrivateNote
            isNewCommentOnTop
            listStyles={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 255px)',
              paddingBottom: '40px',
            }}
          />
          {isEmpty(privateNotes) && (
            <Placeholder
              title={i18n._(t`No notes yet`)}
              styles={{ marginTop: '50px' }}
              Icon={() => (
                <SvgIcon
                  url={NotePlaceholderIcon}
                  width={'50px'}
                  height={'50px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(
                t`Use (private) notes to write down your preparation or to capture next steps`,
              )}
            />
          )}
        </>
      )}
      {activeTab === TABS.attachments && (
        <AttachmentWrapper>
          <UploadArea
            onStartUpload={$loading.on}
            onUpload={handleUpload}
            uploadHandler={uploadHandler}
            onFinishUpload={$loading.off}
            onError={$loading.off}
          >
            <Button
              label={i18n._(t`+ Attachment`)}
              type="primary-border-wide"
              loading={$loading.value}
            />
          </UploadArea>
          {isEmpty(attachments) ? (
            <Placeholder
              title={i18n._(t`No attachments`)}
              styles={{ marginTop: '50px' }}
              Icon={() => (
                <SvgIcon
                  url={AttachPlaceholderIcon}
                  width={'50px'}
                  height={'50px'}
                  isDefaultColor
                  defaultColor={COLOR_PALETTE.GRAY_MIDDLE}
                />
              )}
              subTitle={i18n._(t`Use attachments to upload examples you would like to discuss`)}
            />
          ) : (
            <Attachments>
              {attachments.map((file) => (
                <Attachment
                  key={file.id}
                  file={file}
                  isCanDelete={isAllowDeleteAttachments && file.user === user.id}
                  onDelete={() => deleteAttachment(file.id)}
                />
              ))}
            </Attachments>
          )}
        </AttachmentWrapper>
      )}
    </NotesWrapper>
  );
};

export default ReviewNotes;
