import React, { useState } from 'react';

import { t } from '@lingui/macro';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import ProgressBar from '~/components/ProgressBar';
import {
  ProgressContainer,
  HoverContainer,
  SkillMatchReportButton,
} from '~/pages/PassportPage/design';

const SkillMatchProgressAndReport = ({
  value,
  viewReport,
}: {
  value: number;
  viewReport: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <HoverContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isHovering ? (
        <SkillMatchReportButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={t`View skill report`}
          onClick={viewReport}
        />
      ) : (
        <ProgressContainer>
          <ProgressBar styles={undefined} isHideValue value={value} height={8} width={125} />
          <span>{value}%</span>
        </ProgressContainer>
      )}
    </HoverContainer>
  );
};

export { SkillMatchProgressAndReport };
