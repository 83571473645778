import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { IReviewInvitationTemplate } from '@learned/types';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { size } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Input } from '~/components/Input';
import Modal from '~/components/Modal';

import useBoolState from '~/hooks/useBoolState';
import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { createReviewInvitationTemplate } from '~/services/reviewInvitationTemplates';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { InputContainer, Title } from '../../../design';
import { Footer, Label } from '../design';

import type { IReviewCycleForm } from '../../../types';

interface InvitationTemplateModalProps {
  formMethods: UseFormReturn<IReviewCycleForm>;
  onClose: () => void;
  refreshInvitations: () => void;
  invitationTemplates: Array<IReviewInvitationTemplate>;
}

const InvitationTemplateModal = ({
  onClose,
  formMethods,
  refreshInvitations,
  invitationTemplates,
}: InvitationTemplateModalProps) => {
  const { i18n } = useLingui();
  const { control, register, unregister, watch, setValue } = formMethods;
  const languageState = useLanguageState();
  const $isLoading = useBoolState(false);
  const [hasError, setHasError] = useState(false);

  const nameFieldArray = useMultiLangFieldArray({
    name: 'reviewInvitationTemplateName',
    control,
    unregister,
    languageState,
  });

  const handleTemplateCreation = async () => {
    const templateName = watch('reviewInvitationTemplateName') || [];

    $isLoading.on();
    const result = await createReviewInvitationTemplate({
      name: turnArrayIntoMultiLang(templateName),
      description: turnArrayIntoMultiLang(watch('description') || []),
    });
    $isLoading.off();
    const templateId = result.data[API_RETURN_FIELDS.REVIEW_INVITATION_TEMPLATE].id;
    setValue('reviewInvitationTemplate', templateId, { shouldDirty: true });
    refreshInvitations();
    onClose();
  };

  useEffect(() => {
    const hasAnyError = nameFieldArray.fields.some((field) => {
      const value = watch(`reviewInvitationTemplateName.${field.index}.value`);
      return invitationTemplates.some((template) => {
        if (template.name[field.locale] === '') {
          return false;
        }
        return template.name[field.locale] === value;
      });
    });
    setHasError(hasAnyError);
  }, [nameFieldArray.fields, watch, invitationTemplates]);

  return (
    <Modal
      onClose={onClose}
      width={500}
      showDivider={false}
      footerStyles={{ paddingBottom: 25, paddingTop: 0, height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      headerStyles={{ paddingTop: 23, display: 'flex', alignItems: 'center' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={handleTemplateCreation}
            label={i18n._(t`Save`)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            isLoading={$isLoading.value}
            disabled={hasError}
          />
        </Footer>
      }
      title={<Title>{i18n._(t`Create invitation message`)}</Title>}
    >
      <InputContainer>
        <Label>
          <Trans>Name</Trans>
        </Label>
        {nameFieldArray.fields.map((field) => {
          return (
            <Controller
              key={field.id}
              {...register(`reviewInvitationTemplateName.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                const isError = invitationTemplates.find((template) => {
                  if (template.name[field.locale] === '') {
                    return false;
                  }
                  return template.name[field.locale] === value;
                });
                return (
                  <Input
                    value={value}
                    onChange={(args) => {
                      onChange(args);
                    }}
                    key={field.id}
                    width="437px"
                    height="38px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                    error={isError ? i18n._(t`This name already exists`) : false}
                  />
                );
              }}
            />
          );
        })}
      </InputContainer>
    </Modal>
  );
};

export { InvitationTemplateModal };
