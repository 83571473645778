import { REVIEW_PARTICIPATION_COLUMNS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const columnsNames: Record<REVIEW_PARTICIPATION_COLUMNS, (i18n: I18n) => string> = {
  [REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW]: (i18n: I18n) => i18n._(t`Prepare self review`),
  [REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW]: (i18n: I18n) => i18n._(t`Received peer input`),
  [REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW]: (i18n: I18n) => i18n._(t`Received coach input`),
  [REVIEW_PARTICIPATION_COLUMNS.NOMINATED_PEERS]: (i18n: I18n) => i18n._(t`Ask peers for input`),
  [REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW]: (i18n: I18n) => i18n._(t`Completed review`),
  [REVIEW_PARTICIPATION_COLUMNS.PEER_REVIEW_PROVIDED]: (i18n: I18n) =>
    i18n._(t`Provide peer input`),
  [REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED]: (i18n: I18n) =>
    i18n._(t`Provide coach input`),
  [REVIEW_PARTICIPATION_COLUMNS.SIGNED]: (i18n: I18n) => i18n._(t`Signed`),
};
