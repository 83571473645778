export enum REVIEW_QUESTION_TYPES {
  TEXT = 'text',
  RATING = 'rating',
  CUSTOM_SKILL = 'custom-skill',
  SKILL_CATEGORY = 'skill-category',
  GOAL_PLAN = 'goal-plan',

  // TODO in v2
  // TRAFFIC = 'traffic',
  // STARS = 'stars',
  // SMILEYS = 'smileys',
}

export enum REVIEW_QUESTION_EVALUATORS {
  EMPLOYEE = 'employee',
  PEER = 'peer',
  COACH = 'coach',
}
