import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${COLORS.WHITE};
  color: ${COLORS.TEXT_MAIN};
  border-radius: 6px;
  font-size: 12px;
  gap: 4px;
  width: 100%;
  height: 100%;
  min-height: 150px;
  display: flex;
`;

const TableCtr = styled.div<{
  maxCharacters: number;
}>`
  display: grid;
  grid-template-columns: ${({ maxCharacters }) => `${maxCharacters}ch`} auto;
  align-items: start;
  align-content: start;
  width: 100%;
`;

const RowTitle = styled.div<{
  isActive: boolean;
  mHeight: string;
  isClickable: boolean;
  maxCharacters: number;
}>`
  padding: 5px 10px;
  border-radius: 6px 0 0 6px;
  box-sizing: border-box;
  background: ${({ isActive }) => (isActive ? `${COLORS.HOVER}` : 'transparent')};
  max-width: ${({ maxCharacters }) => `${maxCharacters}ch`};
  height: ${({ mHeight }) => mHeight};
  display: flex;
  align-items: center;
  & > span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DataRaw = styled.div<{ isActive: boolean; mHeight: string }>`
  padding: 5px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: default;
  background: ${({ isActive }) => (isActive ? `${COLORS.HOVER}` : 'transparent')};
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
  height: ${({ mHeight }) => mHeight};
`;

const NumberLineBorder = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

const PrimaryDimension = styled.div`
  position: absolute;
  left: 10px;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SecondaryDimension = styled.div`
  position: absolute;
  left: 0;
  top: -20px;
  transform: translateY(-100%);
  display: flex;
  gap: 5px;
  align-items: center;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomTooltip = styled.div`
  position: absolute;
  left: -5px;
  top: 0;
  transform: translateX(-100%);
  border: 1px solid ${COLORS.BORDERS};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background: ${COLORS.WHITE};
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  font-size: 10px;
  border-radius: 6px;
  z-index: 100;
`;

const DataMultiOuterCtr = styled.div<{ mHeight: string }>`
  position: relative;
  border-radius: 6px;
  height: calc(${({ mHeight }) => mHeight} - 10px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 100%;
  &:hover ${CustomTooltip} {
    display: flex;
  }
`;

const DataMultiCtr = styled.div<{
  barWidth: string;
  deviation: number;
  isActive: boolean;
  value: string;
}>`
  width: ${(props) => props.barWidth};
  height: 20%;
  position: relative;
  border-radius: 20px;
`;

const DataMultiCtrBackground = styled.div<{ isActive: boolean; bgClr: string }>`
  position: absolute;
  inset: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  background: ${({ bgClr }) => bgClr};
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
`;

const DataCtr = styled.div<{
  barWidth: string;
  mHeight: string;
  deviation: number;
  isActive: boolean;
}>`
  position: relative;
  width: ${({ barWidth }) => barWidth};
  border-radius: 6px;
  height: calc(${({ mHeight }) => mHeight} - 10px);
  box-sizing: border-box;
  padding: 5px 0;
  &::after {
    content: '${({ deviation }) =>
      deviation === 0 ? '' : deviation > 0 ? `+${deviation}%` : `${deviation}%`}';
    color: ${({ deviation }) =>
      deviation > 0 ? COLORS.CONFIRMATION_MODAL_SUCCESS : COLORS.CONFIRMATION_MODAL_DELETE};
    position: absolute;
    right: -10px;
    top: 0;
    transform: translateX(100%);
    height: 100%;
    align-items: center;
    justify-content: center;
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  }
  &:hover ${CustomTooltip} {
    display: flex;
  }
`;

const DataCtrBackground = styled.div<{ isActive: boolean }>`
  position: absolute;
  inset: 0;
  background: ${COLORS.COMPANY};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  border-radius: 6px;
  overflow: hidden;
`;

const ItemNr = styled.div<{ isActive: boolean }>`
  color: ${COLORS.WHITE};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

const Numbers = styled.div<{ leftAlign: string }>`
  position: absolute;
  left: ${(props) => props.leftAlign};
  bottom: -20px;
  line-height: 16px;
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
  transform: translateX(-50%);
`;

const Line = styled.div<{ leftAlign: string }>`
  border-sizing: border-box;
  position: absolute;
  left: ${(props) => props.leftAlign};
  top: 0;
  bottom: 0;
  border-left: 1px solid ${COLORS.BORDERS};
  z-index: -1;
`;

const GraphLineContainer = styled.div<{
  maxCharacters: number;
}>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - ${({ maxCharacters }) => `${maxCharacters}ch`});
  isolation: isolate;
`;

export {
  GraphLineContainer,
  ItemNr,
  DataCtrBackground,
  DataCtr,
  Container,
  TableCtr,
  DataRaw,
  NumberLineBorder,
  RowTitle,
  PrimaryDimension,
  SecondaryDimension,
  SortIcons,
  Numbers,
  Line,
  DataMultiOuterCtr,
  DataMultiCtr,
  DataMultiCtrBackground,
};
