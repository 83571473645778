import { Locals_all } from '@learned/constants';

import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function generateTextTranslation(texts: string[], locales: string[]) {
  return cloudRequest(
    serverRoutes.completions.generateTextTranslation as IServerRoute,
    {},
    { texts, locales },
  );
}

export function generateSkillDescription(
  skillName: string,
  skillCategoryId: string,
  locale: Locals_all,
) {
  return cloudRequest(
    serverRoutes.completions.generateSkillDescription as IServerRoute,
    {},
    { skillName, skillCategoryId, locale },
  );
}

export function generateSkillDescriptionSuperAdmin(skillName: string, skillCategoryId: string) {
  return cloudRequest(
    serverRoutes.completions.generateSkillDescriptionSuperAdmin as IServerRoute,
    {},
    { skillName, skillCategoryId },
  );
}

export function generateSkillFocusAreas(
  skillName: string,
  skillCategoryId: string,
  locale: Locals_all,
) {
  return cloudRequest(
    serverRoutes.completions.generateSkillFocusAreas as IServerRoute,
    {},
    { skillName, skillCategoryId, locale },
  );
}

export function generateSkillFocusAreasSuperAdmin(skillName: string, skillCategoryId: string) {
  return cloudRequest(
    serverRoutes.completions.generateSkillFocusAreasSuperAdmin as IServerRoute,
    {},
    { skillName, skillCategoryId },
  );
}
