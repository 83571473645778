import FileSaver from 'file-saver';
import isEmpty from 'lodash/isEmpty';

import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export function getGoal(
  id,
  populate,
  { isFillParent, isGetProgress, childrenMaxOrder, isParentTeamPopulate } = {},
) {
  return cloudRequest(serverRoutes.goals.getGoal(id), {
    populate,
    isFillParent,
    isGetProgress,
    childrenMaxOrder,
    isParentTeamPopulate,
  });
}

export function getGoals({
  limit,
  skip,
  types,
  isMyGoalsOnly,
  isGetProgress,
  isGetChildren,
  search,
  goalCycles,
  statuses,
  teams,
  owners,
  join,
  forUser,
  sortBy,
  sort,
} = {}) {
  return cloudRequest(serverRoutes.goals.getGoals, null, {
    limit,
    skip,
    isMyGoalsOnly,
    types,
    isGetProgress,
    isGetChildren,

    // filters
    ...(search && { search }),
    ...(!isEmpty(goalCycles) && { goalCycles }),
    ...(!isEmpty(statuses) && { statuses }),
    ...(!isEmpty(owners) && { owners }),
    ...(teams && { teams }),
    ...(!isEmpty(join) && { join }),
    ...(sort && { sort }),

    // public pages (only for coach and admin roles)
    ...(forUser && { forUser }),
    sortBy,
  });
}

// !important: body structure the same as for getGoals
export function downloadGoalCSV(body) {
  return cloudRequest(serverRoutes.goals.downloadGoalCSV, null, body).then((response) => {
    FileSaver.saveAs(response, 'goals.csv');
  });
}

export function getUserGoalsBySkills(skillsIds) {
  return cloudRequest(serverRoutes.goals.getUserGoalsBySkills, {
    skills: skillsIds.join(','),
  });
}

/**
 *
 * @param data - goal data
 * @param activities goal activities
 * @param [isDraft]
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createGoal(data, activities) {
  return cloudRequest(serverRoutes.goals.createGoal, {}, { ...data, activities });
}

/**
 *
 * @param id - goal id
 * @param {object} [update] - updates for goal object
 * @param {string} [update.name]
 * @param {string} [update.description]
 * @param {string} [update.startDate]
 * @param {string} [update.deadline]
 * @param {string[]} [update.skills]
 * @param {string} [update.status]
 * @params {string} [update.coverImage]
 * @param {string[]} [update.users]
 * @param {object[]} updatedActivities
 * @param {object[]} newActivities
 * @param {string[]} deletedActivities
 * @param {boolean} publish
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateGoal(id, update, activities, publish) {
  return cloudRequest(serverRoutes.goals.updateGoal(id), { publish }, { update, activities });
}

export function updateGoalPublished(id, update, activities) {
  return cloudRequest(serverRoutes.goals.updateGoalPublished(id), {}, { ...update, activities });
}

export function updateGoalProgress(id, update) {
  return cloudRequest(serverRoutes.goals.updateGoalProgress(id), {}, { ...update });
}

export function deleteGoal(id) {
  return cloudRequest(serverRoutes.goals.deleteGoal(id));
}

export function getGoalsByCycles(goalCycles = []) {
  return cloudRequest(serverRoutes.goals.getGoalsByCycles, {}, { goalCycles });
}

export function getGoalForReview(
  id,
  reviewId,
  { populate, isFillParent, isNotSharedFeedback = false },
) {
  return cloudRequest(serverRoutes.goals.getGoalForReview(id, reviewId), {
    populate,
    isFillParent,
    isNotSharedFeedback,
  });
}

export function getGoalsForReviewImport({ forUser, goalType, reviewId }) {
  return cloudRequest(serverRoutes.goals.getGoalsForReviewImport, { forUser, goalType, reviewId });
}

export function updateGoalCycles(id, update) {
  return cloudRequest(serverRoutes.goals.updateGoalCycles(id), null, update);
}

export function addGoalCycles(id, goalCyclesIds) {
  return cloudRequest(serverRoutes.goals.addGoalCycles(id), null, goalCyclesIds);
}

export function getAvailableParentGoals(id, { type, tabType, populate, filters, options = {} }) {
  return cloudRequest(serverRoutes.goals.getAvailableParentGoals(id), null, {
    populate,
    filters,
    type,
    tabType,
    options,
  });
}

export function duplicateGoal(id) {
  return cloudRequest(serverRoutes.goals.duplicateGoal(id));
}

export function copyGoal(id, users, isMakeCreators = false) {
  return cloudRequest(serverRoutes.goals.copyGoal(id), {}, { users, isMakeCreators });
}

export function getGoalSuggestions(payload) {
  return cloudRequest(serverRoutes.goals.getGoalSuggestions, null, payload);
}

export function updateGoalArchiveStatus(id, shouldArchive) {
  const route = shouldArchive
    ? serverRoutes.goals.archiveGoal(id)
    : serverRoutes.goals.unarchiveGoal(id);

  return cloudRequest(route, {}, {});
}
