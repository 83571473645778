import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLORS } from '~/styles';

export const SubHeaderWrap = styled.div`
  background: ${COLORS.SUBTEXT};
  height: 46px;
  box-sizing: border-box;
  color: ${COLORS.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  letter-spacing: -0.18px;
`;

export const LinkStyled = styled.span`
  color: ${COLORS.WHITE};
  text-decoration: underline;
  cursor: pointer;
`;

interface BannerCalibrationProps {
  onClick?: () => void;
}

const BannerCalibration = ({ onClick }: BannerCalibrationProps) => {
  const Link = ({ children }: { children: React.ReactNode }) => {
    return <LinkStyled onClick={onClick}>{children}</LinkStyled>;
  };

  return (
    <SubHeaderWrap>
      <Text>
        <Trans
          id="Calibration is enabled. <0>Go to Setup</0> to share coach input with the employees."
          // @ts-ignore
          components={[<Link key={0} />]}
        />
      </Text>
    </SubHeaderWrap>
  );
};

export { BannerCalibration };
