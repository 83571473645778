import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, REVIEW_THEME_STATUS } from '@learned/constants';
import { IReviewQuestion } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { ThemeTemplateDashboard } from './components/ThemeTemplateDashboard';
import { DASHBOARD_TYPE } from './constants';

import routes from '~/constants/routes';
import { useLanguageState } from '~/hooks/useLanguageState';
import { checkModuleReviews } from '~/selectors/baseGetters';
import { getReviewTheme, updateReviewTheme } from '~/services/reviewThemes';

import type { PopulatedReviewTheme } from './types';

const ReviewThemeView = () => {
  const params: Record<string, string | undefined> = useParams();
  const { reviewThemeId } = params;
  const [theme, setTheme] = useState<PopulatedReviewTheme | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
  const languageState = useLanguageState();
  const history = useHistory();
  const isModuleReviewEnabled = useSelector(checkModuleReviews);
  const onForceFetch = () => setForceUpdateCounter(forceUpdateCounter + 1);

  useEffect(() => {
    if (!isModuleReviewEnabled) {
      history.push(routes.HOME);
    }
  }, [isModuleReviewEnabled, history]);

  useEffect(() => {
    let mounted = true;
    const fetchTheme = async () => {
      if (!reviewThemeId) {
        return;
      }
      setIsLoading(true);
      const { data } = await getReviewTheme(reviewThemeId, ['questions']);

      const reviewTheme: PopulatedReviewTheme = data[API_RETURN_FIELDS.REVIEW_THEME];
      if (mounted && reviewTheme) {
        setTheme(reviewTheme);
      }
      setIsLoading(false);
    };
    fetchTheme();

    return () => {
      mounted = false;
    };
  }, [reviewThemeId, forceUpdateCounter]);

  const onPublish = async () => {
    const themeToPublish = {
      ...pick(theme, ['name', 'description', 'icon', 'iconColor', 'isHideOnUserDashboard']),
      status: REVIEW_THEME_STATUS.PUBLISHED,
      questions: theme?.questions.map((q: IReviewQuestion) => q.id),
    };

    setIsLoading(true);
    await updateReviewTheme(reviewThemeId, themeToPublish);
    onForceFetch();
  };

  const onEdit = () => routes.REVIEW_THEME_UPDATE.go({}, { reviewThemeId, isBackPath: true });

  const themePublishValidationErrorCheck = () => {
    return (
      isEmpty(theme?.name[languageState.companyPrimaryLanguage.locale]) ||
      isEmpty(theme?.icon) ||
      isEmpty(theme?.iconColor) ||
      (theme?.questions || [])?.map((q) => q.id).filter((q) => q).length < 1
    );
  };

  const isPublishDisabled = themePublishValidationErrorCheck();

  return (
    <>
      {isModuleReviewEnabled && (
        <ThemeTemplateDashboard
          isLoading={isLoading}
          onPublish={onPublish}
          onEdit={onEdit}
          item={theme}
          isPublishDisabled={isPublishDisabled}
          dashboardType={DASHBOARD_TYPE.THEME}
        />
      )}
    </>
  );
};

export { ReviewThemeView };
