import React, { ReactNode, useEffect, useState } from 'react';

import { JOB_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import find from 'lodash/find';
import { UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { MultiSelectMultiLangualDropdown } from '~/components/Dropdown/MultiLangualDropdown';
import { ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ActionItemBlock, ActionsWrap } from '~/pages/SurveyUpdate/design';

import { Wrapper } from './design';
import { useSkillCategories } from './hooks/useSkillCategories';
import { StepDetails } from './StepDetails';
import { StepSkillCategory } from './StepSkillCategory';
import { StepSummary } from './StepSummary';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useFromQuery } from '~/hooks/useFromQuery';
import { IBaseLanguageStateReturn, useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';

import type { IGeneralForm } from './types';

interface ISuperAdminJobTemplateFormProps {
  title: ReactNode;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  onSubmit: (data: IGeneralForm & { status: JOB_TEMPLATE_STATUS }) => void;
  onCoverImageDelete?: () => void;
  currentStatus?: JOB_TEMPLATE_STATUS;
}

const SuperAdminJobTemplateForm = ({
  title,
  onSubmit,
  onCoverImageDelete,
  formMethods,
  currentStatus = JOB_TEMPLATE_STATUS.DRAFT,
}: ISuperAdminJobTemplateFormProps) => {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();

  const [isLoading, _setIsLoading] = useState(false);
  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const { handleSubmit, formState } = formMethods;
  const { skillCategories } = useSkillCategories();

  const sectionState = useSectionState([]);

  useEffect(() => {
    let result: Array<{ id?: string; title: string }> = [{ title: i18n._(t`Job Details`) }];

    skillCategories.forEach((category) => {
      result.push({
        id: category.id,
        title: (category.name[languageState.primaryLanguage.locale] ||
          find(category.name)) as string,
      });
    });

    result = [...result, { title: i18n._(t`Summary`) }];

    sectionState.setSections(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillCategories]);

  const summaryIndex = skillCategories.length + 1;

  useEffect(() => {
    const nameError = formState.errors?.name;
    const jobFamilyError = formState.errors?.jobFamily;

    if (nameError || jobFamilyError) {
      sectionState.setErrorSection(0, true);
    } else {
      sectionState.setErrorSection(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors]);

  const onFail = () => {
    sectionState.setTriedToSubmit();

    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    sectionState.goToFirstErrorSection();
  };

  const onSave = async (data: IGeneralForm) => {
    return onSubmit({ ...data, status: currentStatus });
  };

  const onPublish = async (data: IGeneralForm) => {
    return onSubmit({ ...data, status: JOB_TEMPLATE_STATUS.PUBLISHED });
  };

  return (
    <>
      <DashboardHeader
        title={title}
        onBack={goBack}
        actions={
          <ActionsWrap>
            <ActionItemBlock>
              <MultiSelectMultiLangualDropdown
                languages={languageState.defaultLanguages}
                setLanguages={languageState.setLanguages}
                primaryLanguage={languageState.primaryLanguage}
                preSelectedLanguages={languageState.languages}
                hideAction
              />
            </ActionItemBlock>
            {currentStatus === JOB_TEMPLATE_STATUS.DRAFT && (
              <ActionItemBlock minWidth="70px">
                <Tooltip size={TOOLTIP_SIZES.BIG} tooltip={i18n._(t`Save as Draft`)}>
                  <span>
                    <Button
                      icon={ICONS.SAVE}
                      size={ButtonSize.MEDIUM}
                      variant={ButtonVariant.ICON}
                      isLoading={isLoading}
                      onClick={handleSubmit(onSave, onFail)}
                    />
                  </span>
                </Tooltip>
              </ActionItemBlock>
            )}
            <ActionItemBlock>
              <Button
                label={
                  currentStatus === JOB_TEMPLATE_STATUS.DRAFT ? i18n._(t`Publish`) : i18n._(t`Save`)
                }
                size={ButtonSize.MEDIUM}
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                onClick={handleSubmit(onPublish, onFail)}
              />
            </ActionItemBlock>
          </ActionsWrap>
        }
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isLoading}>
          {sectionState.currentSection === 0 && (
            <StepDetails
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              onCoverImageDelete={onCoverImageDelete}
            />
          )}
          {sectionState.currentSection > 0 && sectionState.currentSection < summaryIndex && (
            <StepSkillCategory
              key={`skill-category-${sectionState.currentSection}`}
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              skillCategories={skillCategories}
            />
          )}
          {sectionState.currentSection === summaryIndex && (
            <StepSummary
              skillCategories={skillCategories}
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              onPublish={onPublish}
              onSave={onSave}
              onFail={onFail}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
};

export { SuperAdminJobTemplateForm };
