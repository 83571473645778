import type { IServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { ICreateOrUpdateReviewRatingRequest, IReviewRating } from '@learned/types';

export function createOrUpdateReviewRatings(data: ICreateOrUpdateReviewRatingRequest) {
  return cloudRequest(
    serverRoutes.reviewRatings.createOrUpdate as IServerRoute,
    {},
    data,
  ) as Promise<{
    code: number;
    data: { reviewRatings: IReviewRating[] };
  }>;
}
