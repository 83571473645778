import React, { useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AutocompleteFilter from '~/components/AutocompleteFilter';
import Button from '~/components/Button';
import SortDownIcon from '~/components/Icons/SortDown';
import SortUpIcon from '~/components/Icons/SortUp';
import SearchSelectButton from '~/components/SearchSelectButton';
import { SearchField } from '~/components/Text';
import TextField from '~/components/TextField';

import { JOB_PROFILE_STATUSES, SORT_DIRECTION } from '~/constants';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { checkModuleCareer } from '~/selectors/baseGetters';
import { getJobProfiles } from '~/services/jobProfiles';
import { getTeams } from '~/services/teams';

const NameSearch = styled(SearchField)`
  width: 328px;
  margin-right: 9px;
  ${TextField} {
    border-radius: 6px;
    font-size: 14px;
    height: 32px;
  }
`;

const RolesFilter = styled(AutocompleteFilter)`
  height: 32px;
  width: 160px;
  margin-right: 8px;
`;

const TeamsFilter = styled(AutocompleteFilter)`
  height: 32px;
  width: 160px;
  margin-right: 8px;
`;

const SortBySelect = styled(SearchSelectButton)`
  width: 160px;
  margin-right: 6px;
`;

const SearchRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

const ApplyButton = styled(Button)`
  margin-left: auto;
  height: 20px;
`;

const SortContainer = styled.span`
  height: 20px;
`;

const fetchTeams = (search) => {
  return getTeams({
    limit: 20,
    search,
  });
};

const sortIcon = {
  [SORT_DIRECTION.ASC]: SortUpIcon,
  [SORT_DIRECTION.DESC]: SortDownIcon,
};

const FiltersBar = ({ filters, sortOptions, applyFilters = async () => {}, i18n }) => {
  const [newFilters, setNewFilters] = useState(filters);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    setFiltersChanged(!isEqual(filters, newFilters));
  }, [filters, newFilters]);

  const isModuleCareerEnabled = useSelector(checkModuleCareer);
  const SortIcon = sortIcon[newFilters.sortDirection];

  const onApplyClick = async () => {
    setLoading(true);
    await applyFilters(newFilters);
    setLoading(false);
  };

  const onSortByChange = (sortBy) => {
    if (sortBy.length) {
      setNewFilters({ ...newFilters, sortBy });
    }
  };

  const fetchJobProfiles = async (search) => {
    const jobProfiles = await getJobProfiles(
      { search, status: JOB_PROFILE_STATUSES.ACTIVE.key },
      { limit: 20 },
      [],
      ['users'],
    );

    // Override correct multi lang string for name
    Object.values(jobProfiles).forEach((jp) => {
      jp.name = getMultiLangString(jp.name);
    });

    return jobProfiles;
  };

  return (
    <SearchRow>
      <NameSearch
        onChange={(e) => setNewFilters({ ...newFilters, search: e.target.value })}
        placeholder={i18n._(t`Search`)}
        value={newFilters.search}
      />
      <TeamsFilter
        placeholder={<Trans>Filter for teams</Trans>}
        checkedList={newFilters.selectedTeams}
        onChange={(selectedTeams) => setNewFilters({ ...newFilters, selectedTeams })}
        fetch={fetchTeams}
      />
      {isModuleCareerEnabled && (
        <RolesFilter
          placeholder={<Trans>Filter for roles</Trans>}
          checkedList={newFilters.selectedRoles}
          onChange={(selectedRoles) => setNewFilters({ ...newFilters, selectedRoles })}
          fetch={fetchJobProfiles}
        />
      )}
      <SortBySelect
        title={i18n._(t`Sort by`)}
        checkedList={newFilters.sortBy}
        handleChange={onSortByChange}
        options={sortOptions}
        minWidth="80px"
        isRadio
        noHeader
      />
      <SortContainer
        onClick={() =>
          setNewFilters({
            ...newFilters,
            sortDirection:
              newFilters.sortDirection === SORT_DIRECTION.ASC
                ? SORT_DIRECTION.DESC
                : SORT_DIRECTION.ASC,
          })
        }
      >
        <SortIcon fill={'var(--company-color)'} size={18} />
      </SortContainer>
      <ApplyButton
        disabled={!filtersChanged}
        loading={loading}
        onClick={onApplyClick}
        styles={{
          height: '32px',
          fontSize: '14px',
          width: 'auto',
        }}
        label={<Trans>Apply filters</Trans>}
      />
    </SearchRow>
  );
};

export default withI18n()(FiltersBar);
