import React, { useState } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';

import { CustomOptionComponent } from './CustomOptionComponent';
import {
  ConnectModalContent,
  Footer,
  Header,
  StyledButton,
  Title,
  TitleWrapper,
  Label,
} from './design';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';

import { StyledSearchSelect } from '../../design';
import { useManageJobTemplates } from '../../hooks/useManageJobTemplates';

import type { IJobTemplate } from '@learned/types';

const JobCreateModal = ({
  onClose,
  jobLevelGroupId,
  jobFamilyId,
  levelTo,
}: {
  onClose: () => void;
  jobLevelGroupId?: string | null;
  jobFamilyId?: string | null;
  levelTo?: number;
}) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const { jobTemplates, search, setSearch } = useManageJobTemplates();
  const [selectedJobTemplate, setSelectedJobTemplate] = useState<IJobTemplate>();
  const goToJobCreatePage = (props?: { templateId?: string; name?: string }) => {
    routes.JOB_CREATE.go(
      {},
      {
        query: {
          createFromJobTemplateId: props?.templateId,
          name: props?.name,
          jobLevelGroupId,
          jobFamilyId,
          levelTo,
        },
        isBackPath: true,
      },
    );
  };

  return (
    <Modal
      width={500}
      onClose={onClose}
      showDivider={false}
      isHideHeader
      footerStyles={{ padding: '0 24px 32px 25.5px', height: 'unset', minHeight: 'unset' }}
      contentStyles={{ padding: '9px 24px 32px 25.5px', overflowY: 'unset' }}
      footerRight={
        <Footer>
          <Button
            onClick={onClose}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />

          <Button
            onClick={() => goToJobCreatePage({ templateId: selectedJobTemplate?.id, name: search })}
            label={<Trans>Start</Trans>}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </Footer>
      }
    >
      <Header>
        <TitleWrapper>
          <Title>
            <Trans>Create a job</Trans>
          </Title>
          <StyledButton onClick={onClose} variant={ButtonVariant.CLOSE} size={ButtonSize.MEDIUM} />
        </TitleWrapper>
      </Header>
      <ConnectModalContent>
        <span>
          <Trans>
            Search for the job you want to add. Use a job template from the database and customise
            this template.
          </Trans>
        </span>
        <div>
          <Label>
            <Trans>Search for job template</Trans>
          </Label>
          <StyledSearchSelect
            placeholder={i18n._(t`Data manager`)}
            noResultsPlaceholder={i18n._(t`No Jobs were found`)}
            items={jobTemplates}
            hashItem={(item) => item.id}
            selectedItem={selectedJobTemplate}
            onChange={setSelectedJobTemplate}
            onSearchChange={setSearch}
            stringifyItem={(item) => getMultiLangString(item.name)}
            CustomOptionComponent={CustomOptionComponent}
            actions={[
              {
                handler: () => goToJobCreatePage({ name: search }),
                name: `${i18n._(t`Create a new job for`)} ${search}`,
                icon: ICONS.ADD_PLUS,
              },
            ]}
            isSingleSelect
          />
        </div>
      </ConnectModalContent>
    </Modal>
  );
};

export { JobCreateModal };
