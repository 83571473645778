import { createAction } from 'redux-act';

export const setJobProfile = createAction('set job profile');
export const setJobProfiles = createAction<object>('finish set job profiles');
export const updateSkillsToJobProfile = createAction('set jobprofiles');

export const startUpdateJobProfiles = createAction('start update jobProfiles');
export const finishUpdateJobProfiles = createAction<object>('finish update jobProfiles');

import * as jobProfilesService from '~/services/jobProfiles';

import type { Dispatch } from 'redux';

/*
 * data example [[JobProfile1Id, updateData1{}], [JobProfile2Id, updateData2{}], ...]
 * */
export function updateJobProfiles(data: Array<[string, object]>, callback: () => void) {
  return async function (dispatch: Dispatch) {
    dispatch(startUpdateJobProfiles());
    const jobProfiles = await jobProfilesService.updateJobProfiles(data);
    dispatch(finishUpdateJobProfiles(jobProfiles));
    callback();
    return jobProfiles;
  };
}
