import { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';

import { LastSavedStatus } from '~/constants/lastSaved';

import type { DebouncedFunc } from 'lodash';

const DELAY = 10000;
let debounced: DebouncedFunc<(arg: any) => void> | null = null;
let lastId: string | null = null;

export type useAutoSaveFunc = {
  state: IState;
  run: (id: string) => DebouncedFunc<(arg: any) => void> | null;
  flush: () => void;
  cancel: () => void;
};

const initState = {
  time: undefined,
  status: undefined,
  message: undefined,
};

interface IState {
  time?: Date | undefined;
  status: LastSavedStatus | undefined;
  message: string | undefined;
}

const useAutoSave = (
  Fn: (id: string) => Promise<void>,
  { onSuccess: onSuccessFunc = () => {}, onFail: onFailFunc = () => {} } = {},
): useAutoSaveFunc => {
  const { i18n } = useLingui();
  const [state, setState] = useState<IState>(initState);

  const onSuccess = () => {
    onSuccessFunc();
    setState({
      time: new Date(),
      status: LastSavedStatus.SUCCESS,
      message: undefined,
    });
  };

  const onFail = (message?: string | null) => {
    onFailFunc();
    setState({
      status: LastSavedStatus.ERROR,
      message: message || i18n._(t`Auto save is failed!`),
    });
  };

  const onNetworkOffline = () => {
    setState({
      status: LastSavedStatus.ERROR,
      message: i18n._(t`Attempting to reconnect. Please check your connection.`),
    });
  };

  const run = (id: string) => {
    const isInit = id !== lastId;
    if (isInit) {
      if (debounced) {
        // execute immediately
        debounced.flush();
      }

      // init autosave
      debounced = debounce(
        async (arg) => {
          try {
            if (!navigator.onLine) {
              onNetworkOffline();
            } else {
              await Fn(arg);
              onSuccess();
            }
          } catch (e) {
            let message = null;
            if ((e as Error).message === 'Network Error') {
              // This is a network error.
              message = i18n._(t`Network error`);
            }
            onFail(message);
          }
        },
        DELAY,
        { leading: false, trailing: true },
      );
    }

    // run Fn with DELAY
    debounced && debounced(id);
    lastId = id;

    return debounced;
  };

  // cancel waiting request
  const cancel = () => {
    debounced && debounced.cancel();
    lastId = null;
    debounced = null;
  };

  // run waiting request immediately
  const flush = () => {
    debounced && debounced.flush();
    lastId = null;
    debounced = null;
  };

  return {
    state,
    run,
    cancel,
    flush,
  };
};

export { useAutoSave };
