import styled from 'styled-components';

import { COLORS } from '~/styles';

const baseStyle = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 3px;
  font-size: 14px;
`;

export const User = styled(baseStyle)`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;

  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }

  & svg {
    margin-right: 21px;
  }
`;

export const Comment = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.TEXT_MAIN};
  padding: 21.1px 16px 15px 17px;
  border-radius: 1px;
  background-color: ${COLORS.BG_LIST};
`;

export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
