/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, SetStateAction } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';

import Editor from '~/components/Editor';
import { ICONS, Icon } from '~/components/Icon';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import { ImageWrapper } from '~/pages/SuperAdminDashboard/SuperAdminJobTemplateForm/components/ImageWrapper';

import { RecommendedCareerPathTable } from './components/RecommendedCareerPathTable';
import { StepFooter } from './components/StepFooter';
import {
  Section,
  Form,
  InputContainer,
  Label,
  Title,
  CombinedSection,
  StyledDropdown,
  JobDescriptionHeader,
  AdvancedSettingsWrapper,
  StyledJobFamilyDropdown,
  StyledJobLevelGroupsDropdown,
  Description,
  FakeInput,
  FakeInputLabel,
} from './design';
import { useJobFamilies } from './hooks/useJobFamilies';

import { EDUCATION_LEVELS, WORK_EXPERIENCE_LEVELS } from '~/constants';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import type { IGeneralForm, IJobLevelGroupPopulated } from './types';
import type { IJobTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  jobTemplate?: IJobTemplate;
  isAdvanceSettingsVisible?: boolean;
  setIsAdvanceSettingsVisible: Dispatch<SetStateAction<boolean>>;
  jobLevelGroupsPopulated: IJobLevelGroupPopulated[];
  onCoverImageDelete?: () => void;
};

const StepDetails = ({
  sectionState,
  formMethods,
  languageState,
  jobTemplate,
  isAdvanceSettingsVisible = false,
  setIsAdvanceSettingsVisible,
  jobLevelGroupsPopulated,
  onCoverImageDelete,
}: StepDetailsProps) => {
  const getMultiLangString = useMultiLangString();
  const { jobFamilies } = useJobFamilies();

  const { i18n } = useLingui();

  const { register, unregister, control, trigger, handleSubmit, getValues, formState, setValue } =
    formMethods;
  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const onSubmit = () => sectionState.setCurrentSection(1);

  const workExperienceLevels = Object.values(WORK_EXPERIENCE_LEVELS).map(({ value, name }) => ({
    id: value,
    name,
  }));

  const educationLevels = Object.values(EDUCATION_LEVELS).map(({ value, name }) => ({
    id: value,
    name,
  }));

  const handleCoverImageDelete = () => {
    setValue('coverImage', '');
    onCoverImageDelete && onCoverImageDelete();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Job details</Trans>
      </Title>
      <CombinedSection>
        <Section>
          {jobTemplate && (
            <Section $marginTop="14px">
              <Label>
                <Trans>Job template</Trans>
              </Label>
              <InputContainer>
                <FakeInput>
                  <FakeInputLabel>{getMultiLangString(jobTemplate.name)}</FakeInputLabel>
                </FakeInput>
              </InputContainer>
            </Section>
          )}
          <Section $marginTop="14px">
            <Label>
              <Trans>Customise name</Trans>
            </Label>
            <InputContainer>
              {nameFieldArray.fields.map((field) => (
                <Controller
                  key={field.id}
                  {...register(`name.${field.index}.value`)}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        value={value}
                        error={sectionState.triedToSubmit && !!formState.errors?.name}
                        onChange={(args) => {
                          onChange(args);
                          trigger('name');
                        }}
                        key={field.id}
                        width="318px"
                        height="38px"
                        leftIcon={
                          size(languageState.languages) > 1
                            ? getUnicodeFlagIcon(
                                field.locale.substring(field.locale.indexOf('_') + 1),
                              )
                            : undefined
                        }
                      />
                    );
                  }}
                />
              ))}
            </InputContainer>
          </Section>
        </Section>
        <Section $marginTop="14px" $width="100%">
          <Label>
            <Trans>Image</Trans>
          </Label>
          <Controller
            {...register('coverImage')}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ImageWrapper
                image={value}
                onChange={onChange}
                onDelete={handleCoverImageDelete}
                alt={getMultiLangString(convertLanguageValue(getValues('name'))).substring(0, 2)}
              />
            )}
          />
        </Section>
      </CombinedSection>

      <Section $marginTop="47px">
        <JobDescriptionHeader>
          <Label>
            <Trans>Job description</Trans>
          </Label>
        </JobDescriptionHeader>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  error={sectionState.triedToSubmit && !!formState.errors?.description}
                  minHeight={'231px'}
                  value={convertMarkdownStarsToStrong(value)}
                  compact
                  onChange={onChange}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </Section>
      <AdvancedSettingsWrapper isCollapsed={isAdvanceSettingsVisible}>
        <div
          className="head"
          onClick={() => setIsAdvanceSettingsVisible((prevState) => !prevState)}
        >
          <div className="title">
            <Trans>Advanced settings</Trans>
          </div>
          <div className="icon">
            <Icon icon={ICONS.BACK} />
          </div>
        </div>
        <div className="content">
          <CombinedSection>
            <Section $marginTop="14px">
              <Label>
                <Trans>Job family</Trans>
              </Label>
              <InputContainer>
                <Controller
                  {...register('jobFamily')}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledJobFamilyDropdown
                      placeholder={i18n._(t`Example: Sales`)}
                      $error={sectionState.triedToSubmit && !!formState.errors?.jobFamily}
                      selectedItem={jobFamilies.find((item) => item.id === value)}
                      items={jobFamilies}
                      onChange={(item) => {
                        onChange(item?.id);
                      }}
                      stringifyItem={(item) => getMultiLangString(item.name)}
                      isSingleSelect
                    />
                  )}
                />
              </InputContainer>
            </Section>
            <Section $marginTop="14px">
              <Label>
                <Trans>Job level(s)</Trans>
              </Label>
              <InputContainer>
                <Controller
                  {...register('jobLevelGroup')}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledJobLevelGroupsDropdown
                      placeholder={i18n._(t`Example: Senior Management`)}
                      $error={sectionState.triedToSubmit && !!formState.errors?.jobLevelGroup}
                      // @ts-ignore
                      selectedItem={jobLevelGroupsPopulated.find(
                        (item) => value && item.id === value.id,
                      )}
                      items={jobLevelGroupsPopulated}
                      onChange={(item) =>
                        item &&
                        onChange({
                          id: item.id,
                          groupId: item.groupId,
                          level: item.level,
                        })
                      }
                      stringifyItem={(item) =>
                        getMultiLangString(item.name) + ` (${item.realLevel + 1})`
                      }
                      skipSort
                      isSingleSelect
                    />
                  )}
                />
              </InputContainer>
            </Section>
          </CombinedSection>
          <CombinedSection>
            <Section $marginTop="30px">
              <Label>
                <Trans>Work experience</Trans>
              </Label>
              <InputContainer>
                <Controller
                  {...register('careerLevel')}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledDropdown
                      placeholder={i18n._(t`1-3 years`)}
                      $error={sectionState.triedToSubmit && !!formState.errors?.careerLevel}
                      selectedItem={workExperienceLevels.find((item) => item.id === value)}
                      items={workExperienceLevels}
                      onChange={(item) => {
                        onChange(item?.id);
                      }}
                      stringifyItem={(item) => item.name(i18n)}
                      isSingleSelect
                    />
                  )}
                />
              </InputContainer>
            </Section>
            <Section $marginTop="30px">
              <Label>
                <Trans>Educational (desired minimum level)</Trans>
              </Label>
              <InputContainer>
                <Controller
                  {...register('educationLevel')}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <StyledDropdown
                      placeholder={i18n._(t`University of Applied Siences`)}
                      $error={sectionState.triedToSubmit && !!formState.errors?.educationLevel}
                      selectedItem={educationLevels.find((item) => item.id === value)}
                      items={educationLevels}
                      onChange={(item) => {
                        onChange(item?.id);
                      }}
                      stringifyItem={(item) => item.name(i18n)}
                      isSingleSelect
                    />
                  )}
                />
              </InputContainer>
            </Section>
          </CombinedSection>
          <Section $marginTop="40px" $paddingTop="20px" $isBorderTop>
            <Label $paddingBottom="0">
              <Trans>Add recommended career path</Trans>
            </Label>
            <Description marginBottom="10px">
              <Trans>Select max 10 roles in a particular order (drag and drop)</Trans>
            </Description>
          </Section>
          <RecommendedCareerPathTable formMethods={formMethods} isEditMode />
          {/* TODO: we need it in the future. */}
          {/* <Section $marginTop="32px" $paddingTop="32px" $isBorderTop>
            <Label $paddingBottom="10px">
              <Switch />
              <span>
                <Trans>
                  This is a <i>secondary role</i>
                </Trans>
              </span>
            </Label>
            <Description color={COLORS.TEXT_MAIN}>
              <Trans>How to use this?</Trans>{' '}
              <a href="/" target="_blank" rel="noopener noreferrer">
                <Trans>Read more </Trans>
              </a>
            </Description>{' '}
          </Section>
          <Section $paddingTop="20px">
            <Label $paddingBottom="10px">
              <Switch />
              <span>
                <Trans>
                  Recommend this secondary role to anyone. Disable to limit to selected jobs.
                </Trans>
              </span>
            </Label>
          </Section> */}
        </div>
      </AdvancedSettingsWrapper>
      <StepFooter isPrev={false} />
    </Form>
  );
};

export { StepDetails };
