import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInDays } from 'date-fns';
import moment from 'moment';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { TASK_STATUS_ITEMS } from '~/constants/tasks';
import { COLORS } from '~/styles';

import type { ITask } from '@learned/types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

const Overdue = styled.div`
  font-size: 12px;
  color: ${COLORS.ACCENT_ERROR};
`;

const DaysLeft = styled.div`
  font-size: 12px;
  color: ${COLORS.ACCENT_WARNING};
`;

const Deadline = styled.div`
  font-size: 12px;
  color: ${COLORS.SUBTEXT};
`;

interface ITaskStatusProps {
  task: ITask;
}

const TaskDeadline = ({ task }: ITaskStatusProps) => {
  const { i18n } = useLingui();
  const item = task.status && TASK_STATUS_ITEMS[task.status];

  const getDeadlineLabel = (deadline: ITask['deadline']) => {
    const daysLeft = differenceInDays(new Date(deadline), new Date());

    if (daysLeft < 0) {
      return <Overdue>{i18n._(t`Overdue`)}</Overdue>;
    }

    if (daysLeft <= 7) {
      return (
        <DaysLeft>
          {daysLeft === 1 ? i18n._(t`${daysLeft} Day left`) : i18n._(t`${daysLeft} Days left`)}
        </DaysLeft>
      );
    }

    return <Deadline>{moment(deadline).format('DD-MM-YYYY')}</Deadline>;
  };

  return (
    <Wrapper>
      <StyledIcon
        icon={item.icon as ICONS}
        size={ICON_SIZES.SMALL}
        rotate={item.key === TASK_STATUS.TODO ? -90 : 0}
        color={item.color}
      />
      {getDeadlineLabel(task.deadline)}
    </Wrapper>
  );
};

export { TaskDeadline };
