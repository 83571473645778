import React from 'react';

import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { StepFooter } from './components/StepFooter';
import { SummarySection } from './components/SummarySection';
import { ExplanationText } from './components/TimelineSection/design';
import { FinalCheckWrapper, Form, Title } from './design';

import type { IReviewCycleForm } from './types';

interface StepFinalCheckProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewCycleForm>;
  onPublish: () => void;
}

const StepFinalCheck = ({ formMethods, sectionState, onPublish }: StepFinalCheckProps) => {
  return (
    <FinalCheckWrapper>
      <Form>
        <Title>
          <Trans>Summary</Trans>
        </Title>
        <SummarySection formMethods={formMethods} sectionState={sectionState} />
      </Form>
      <Form>
        <Title>
          <Trans>Final check</Trans>
        </Title>
        <ExplanationText>
          <Trans>
            After pressing publish, the review cycle will be available for all participants from the
            selected start date.
          </Trans>
        </ExplanationText>
        <StepFooter
          isPrev={true}
          isNext={false}
          isPublish={true}
          onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
          onPublish={onPublish}
        />
      </Form>
    </FinalCheckWrapper>
  );
};

export { StepFinalCheck };
