import React, { useEffect, useState } from 'react';

import { SKILL_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import nanoid from 'nanoid';
import { useForm } from 'react-hook-form';

import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { useSkillTemplate } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateView/hooks';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { updateSkillTemplateSuperAdmin } from '~/services/skillTemplates';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { SuperAdminSkillTemplateForm } from '../SuperAdminSkillTemplateForm';
import { useSkillCategories } from '../SuperAdminSkillTemplateForm/hooks';
import { removeEmptyValues } from '../SuperAdminSkillTemplateForm/utils';
import { resolver } from '../SuperAdminSkillTemplateForm/validations';

import type { IGeneralForm } from '../SuperAdminSkillTemplateForm/types';
import type { ISkillCategory } from '@learned/types';

function SuperAdminSkillTemplateEdit() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const { skillTemplate } = useSkillTemplate();
  const [skillCategory, setSkillCategory] = useState<ISkillCategory>();
  const { skillCategories } = useSkillCategories();
  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      focusAreas: [],
    },
  });
  const { setValue } = generalFormMethods;

  useEffect(() => {
    if (!skillTemplate || isEmpty(skillCategories)) {
      return;
    }

    setValue('name', turnMultiLangIntoArray(skillTemplate.name, languageState.defaultLanguages));
    setValue(
      'description',
      turnMultiLangIntoArray(skillTemplate.description, languageState.defaultLanguages),
    );
    setValue(
      'focusAreas',
      skillTemplate.focusAreas.map((area) => ({
        ...area,
        values: area.values.map((value) => ({ name: value.name, _id: value.id })),
      })),
    );
    setValue(
      'aliases',
      skillTemplate.aliases.map((alias) => ({ ...alias, id: nanoid() })),
    );
    setValue('skillCategory', skillTemplate.skillCategory);
    setSkillCategory(skillCategories.find((item) => item.id === skillTemplate.skillCategory));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillTemplate, skillCategories]);

  const skillTemplateName = generalFormMethods
    .watch('name')
    .find(({ locale }) => locale === languageState.primaryLanguage.locale)?.value;
  const title = i18n._(t`Edit a skill: ${skillTemplateName}`);

  const onSubmit = async (data: IGeneralForm & { status: SKILL_TEMPLATE_STATUS }) => {
    const predicate = ({ value }: { value: string }) => value !== '';
    const transformedData = {
      ...data,
      name: convertLanguageValue(data.name.filter(predicate)),
      aliases: data.aliases.map(({ _id, ...alias }) => removeEmptyValues(alias)),
      description: convertLanguageValue(data.description.filter(predicate)),
      focusAreas: data.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ name, _id }) => ({
            name: removeEmptyValues(name),
            id: _id,
          })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };

    const result = await updateSkillTemplateSuperAdmin(skillTemplate!.id, transformedData);

    if (result.code === 200) {
      let subtitle;
      if (result.data.skillTemplate.status === SKILL_TEMPLATE_STATUS.DRAFT) {
        subtitle = i18n._(t`Skill template saved as draft`);
      } else if (skillTemplate?.status === SKILL_TEMPLATE_STATUS.PUBLISHED) {
        subtitle = i18n._(t`Skill template saved`);
      } else {
        subtitle = i18n._(t`Skill template published`);
      }

      addToast({
        title: i18n._(t`Success`),
        subtitle,
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  return (
    <SuperAdminSkillTemplateForm
      title={title}
      formMethods={generalFormMethods}
      languageState={languageState}
      onSubmit={onSubmit}
      goBack={goBack}
      skillCategory={skillCategory}
      setSkillCategory={setSkillCategory}
      currentStatus={skillTemplate?.status}
    />
  );
}

export { SuperAdminSkillTemplateEdit };
