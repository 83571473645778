import React, { useEffect, useState, useCallback } from 'react';

import { API_RETURN_FIELDS, TASK_FILTER_BY_USER_TYPE, TASK_TYPE } from '@learned/constants';
import { ITaskWithId, TASKS_STATUSES } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';

import { StyledTableList, TableWrapper, StatusDropDown } from './design';
import { COLUMNS } from './taskColumns';
import { StatusDropDownItem, IFilter } from './types';

import { TASKS_FILTER_TYPES } from '~/constants';
import useDebounce from '~/hooks/useDebounce';
import {
  checkModuleRTFeedbacks,
  checkModuleReviews,
  checkModuleSurvey,
} from '~/selectors/baseGetters';
import { getTasks } from '~/services/tasks';
import getTaskURL from '~/utils/getTaskURL';

const PAGE_SIZE = 5;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };

const initialFilters: IFilter = {
  search: '',
  status: TASKS_STATUSES.ACTIVE,
  pagination: DEFAULT_PAGINATION,
};

const STATUS_DROPDOWN_ITEMS: Array<StatusDropDownItem> = [
  { name: 'Open tasks', value: TASKS_STATUSES.ACTIVE },
  { name: 'Completed tasks', value: TASKS_STATUSES.COMPLETED },
  { name: 'All tasks', value: '' },
];

type TasksSectionProps = {
  type: TASK_FILTER_BY_USER_TYPE;
  isTab?: boolean;
};

const TasksSection = ({ type, isTab = true }: TasksSectionProps) => {
  const { search: searchFromQuery }: { search?: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [currentFilters, setCurrentFilters] = useState<IFilter>({
    ...initialFilters,
    search: searchFromQuery || initialFilters.search,
  });
  const [selectedStatus, setSelectedStatus] = useState(
    STATUS_DROPDOWN_ITEMS.find((item) => item.value === TASKS_STATUSES.ACTIVE),
  );
  const [totalTasksCount, setTotalTaskCount] = useState<number>(0);
  const [allTasks, setAllTasks] = useState<ITaskWithId[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { i18n } = useLingui();
  const history = useHistory();

  const debCurrentFilters = useDebounce(currentFilters, 500);

  const [showNominatePeersModal, setShowNominatePeersModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ITaskWithId | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getTasks({
        // @ts-ignore
        filterTaskTypes: [TASKS_FILTER_TYPES.TASKS.key],
        taskStatus: currentFilters.status,
        search: currentFilters.search,
        limit: currentFilters.pagination.limit,
        skip: currentFilters.pagination.skip,
        userType: type,
      });

      setAllTasks(data.tasks);
      setTotalTaskCount(data.totals[API_RETURN_FIELDS.TOTAL_FILTERED_TASKS]);
      setIsLoading(false);
    };
    if (isModuleSurveyEnabled || isModuleRTFeedbacksEnabled || isModuleReviewsEnabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters({ ...currentFilters, search: value, pagination: DEFAULT_PAGINATION });
    },
  };

  const onSelectStatusesFilter = (selectedItem?: StatusDropDownItem) => {
    if (selectedItem) {
      setSelectedStatus(selectedItem);
      setCurrentFilters({
        ...currentFilters,
        status: selectedItem.value,
        pagination: DEFAULT_PAGINATION,
      });
    }
  };

  const onResetFilters = (): void => {
    setCurrentFilters(initialFilters);
    setSelectedStatus(STATUS_DROPDOWN_ITEMS.find((item) => item.value === TASKS_STATUSES.ACTIVE));
  };

  const navigateToTask = useCallback(
    (task) => {
      // If task is nominate peer show Modal
      if (
        task.type === TASK_TYPE.REVIEW_PEER_NOMINATE &&
        task.status !== TASKS_STATUSES.COMPLETED
      ) {
        setSelectedTask(task);
        setShowNominatePeersModal(true);
        return;
      }

      // Navigate to path
      const path = getTaskURL(task);
      if (!isEmpty(task)) {
        history.push(path!);
      }
    },
    [history],
  );

  return (
    <>
      <TableWrapper>
        <StyledTableList
          isTab={isTab}
          columns={COLUMNS}
          data={allTasks}
          onRowClick={navigateToTask}
          isLoading={isLoading}
          filtersProps={{
            filters,
            resetFilters: onResetFilters,
            isFiltered:
              !!currentFilters.search ||
              (!!currentFilters.status && currentFilters.status !== TASKS_STATUSES.ACTIVE),
            filterComponents: (
              <>
                <StatusDropDown
                  placeholder={i18n._(t`Status`)}
                  items={STATUS_DROPDOWN_ITEMS}
                  selectedItem={selectedStatus}
                  onChange={onSelectStatusesFilter}
                  stringifyItem={(item: StatusDropDownItem) => `${i18n._(t`${item.name}`)}`}
                  isSingleSelect
                />
              </>
            ),
          }}
          paginationProps={{
            pagination: currentFilters.pagination,
            changePagination: ({ skip, limit, index }) =>
              setCurrentFilters({
                ...currentFilters,
                pagination: { ...currentFilters.pagination, skip, limit, index },
              }),
            totalCount: totalTasksCount,
            paginationItemLabel: i18n._(t`tasks`),
          }}
          isHideHeader
          placeholderProps={{
            emptyStateText: i18n._(t`No open tasks found!`),
            noResultText: i18n._(t`This search did not produce any results..`),
          }}
        />
      </TableWrapper>
      {showNominatePeersModal && selectedTask && (
        <NominatePeers
          taskId={selectedTask.id || selectedTask.target.taskId || ''}
          closeModal={() => {
            setShowNominatePeersModal(false);
            setSelectedTask(undefined);
          }}
        />
      )}
    </>
  );
};

export { TasksSection };
