import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export interface IStyledEditorProps {
  $error: boolean;
  $resize: boolean;
  $minHeight: string;
  $maxHeight: string;
  $small: boolean;
  $big: boolean;
  $hideToolbar: boolean;
  $leftIcon: boolean;
  $isFocused?: boolean;
  $isFlexible?: boolean;
}

export const LeftIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 60px;
`;

export const EditorWrapper = styled.div<{ $isFlexible?: boolean }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  ${({ $isFlexible }) =>
    $isFlexible &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}
`;

export const StyledEditor = styled(ReactQuill)<IStyledEditorProps>`
  ${({ $isFlexible }) =>
    $isFlexible &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}

  ${({ $resize }) => {
    return (
      $resize &&
      css`
        .ql-editor {
          resize: vertical;
          min-height: 9em;
        }
        .ql-container {
          .ql-editor {
            height: 120px;
            max-height: unset;
          }
          height: 120px;
          max-height: unset;
        }
      `
    );
  }}
  ${({ $minHeight }) => {
    return (
      $minHeight &&
      css`
        .ql-container {
          .ql-editor {
            min-height: ${$minHeight};
          }
          min-height: ${$minHeight};
        }
      `
    );
  }}
  ${({ $maxHeight }) => {
    return (
      $maxHeight &&
      css`
        .ql-container {
          .ql-editor {
            max-height: ${$maxHeight};
          }
          max-height: ${$maxHeight};
        }
      `
    );
  }}
  ${({ $small }) => {
    return (
      $small &&
      css`
        .ql-container {
          min-height: 6.2em;
        }
      `
    );
  }}
  ${({ $big }) => {
    return (
      $big &&
      css`
        .ql-container {
          .ql-editor {
            max-height: 300px;
          }
          max-height: 300px;
        }
      `
    );
  }}
  ${({ $hideToolbar }) => {
    return (
      $hideToolbar &&
      css`
        .ql-toolbar {
          display: none;
        }
      `
    );
  }}
  
  ${({ $leftIcon }) => {
    return (
      $leftIcon &&
      css`
        .ql-container {
          padding-left: 25px;
        }
        .ql-editor.ql-blank::before {
          padding-left: 25px;
        }
      `
    );
  }}
  
  .ql-editor ul li {
    font-family: Poppins;
  }

  .ql-editor {
    color: ${COLORS.TEXT_HOVER};
    background-color: ${COLORS.WHITE};
    border-radius: 6px;

    ${({ $isFlexible }) =>
      $isFlexible &&
      css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /* We need !important to ignore the default max-height of .ql-editor */
        max-height: unset !important;
      `}
  }

  & .ql-toolbar {
    border: none;
    background-color: ${COLORS.BG_LIST};
    border-radius: 100px;
    height: unset;

    & .ql-formats > button {
      width: 24px;
      padding: 3px;
      margin: 0 1px;
    }

    & button.ql-selected,
    .ql-picker-label:hover {
      color: ${COLORS.TEXT_HOVER} !important;
      background-color: ${COLORS.HOVER};
      & .ql-fill {
        fill: ${COLORS.COMPANY} !important;
      }
      & .ql-stroke {
        stroke: ${COLORS.COMPANY} !important;
      }
    }

    & .ql-formats button:hover {
      color: ${COLORS.TEXT_HOVER} !important;
      background-color: ${COLORS.HOVER};
      & .ql-fill {
        fill: ${COLORS.TEXT_HOVER} !important;
      }
      & .ql-stroke {
        stroke: ${COLORS.TEXT_HOVER} !important;
      }
    }

    & .ql-size .ql-picker-options {
      padding: 0;
      & .ql-picker-item {
        padding: 8px;
        &:hover {
          background-color: ${COLORS.HOVER};
          color: ${COLORS.TEXT_HOVER} !important;
        }
      }
      & .ql-selected {
        background-color: ${COLORS.COMPANY} !important;
        color: ${COLORS.WHITE} !important;
      }
    }

    & .ql-align .ql-picker-options {
      padding: 0;
      & .ql-picker-item {
        width: unset;
        &:hover {
          background-color: ${COLORS.HOVER};
          & .ql-fill {
            fill: ${COLORS.TEXT_HOVER} !important;
          }
          & .ql-stroke {
            stroke: ${COLORS.TEXT_HOVER} !important;
          }
        }
      }
      & .ql-picker-item.ql-selected * {
        & .ql-fill {
          fill: ${COLORS.WHITE} !important;
        }
        & .ql-stroke {
          stroke: ${COLORS.WHITE} !important;
        }
      }
      & .ql-selected {
        background-color: ${COLORS.COMPANY} !important;
        color: ${COLORS.WHITE} !important;
      }
    }

    & .ql-active {
      color: ${COLORS.COMPANY} !important;
      background-color: ${COLORS.BG_ELEMENTS};
      border-radius: 2px;

      & .ql-fill {
        fill: ${COLORS.COMPANY} !important;
      }
      & .ql-stroke:not(.ql-transparent) {
        stroke: ${COLORS.COMPANY} !important;
      }
    }
  }

  & .ql-container {
    margin-top: 10px;
    border-radius: 6px;
    ${({ $isFlexible }) =>
      $isFlexible &&
      css`
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: unset;
        /* We need !important to ignore the default height of .ql-container */
        height: unset !important;
      `}

    ${({ $isFocused }) =>
      $isFocused
        ? css`
            border: 1px solid ${COLORS.COMPANY} !important;
          `
        : css`
            border: 1px solid ${COLORS.BORDERS} !important;
          `};

    ${({ $error }) => {
      return $error && css`border:  1px solid ${COLORS.ACCENT_ERROR} !important}`;
    }};
  }
`;
