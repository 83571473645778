import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { ITask } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';

import { DateRow } from './design';
import { SectionHeaderWithIcon } from './SectionHeaderWithIcon';

import type { IColumnTable } from '~/@types/table';
import convertToTimeString from '~/utils/convertToTimeString';

import type { I18n } from '@lingui/core';

const NameColumn = ({ item }: { item: TaskItem }) => {
  const { i18n } = useLingui();
  return (
    <SectionHeaderWithIcon
      title={item.title(i18n)}
      icon={item.icon}
      marginTop="19px"
      marginBottom="14px"
    />
  );
};

const ActionColumn = ({ item: _item, onClick }: { item: TaskItem; onClick: () => void }) => {
  const { i18n } = useLingui();
  return (
    <Button
      size={ButtonSize.MEDIUM}
      variant={ButtonVariant.TEXT_PRIMARY}
      label={i18n._(t`Lock task `)}
      onClick={onClick}
    />
  );
};

type TaskItem = {
  type: ITask['type'];
  title: (i18n: I18n) => string;
  icon: ICONS;
  startDate: ITask['startDate'];
  endDate: ITask['deadline'];
};

const COLUMNS: IColumnTable<TaskItem>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    minWidth: '260px',
    renderCell: (item) => <NameColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Start date`),
    accessor: 'start-date',
    maxWidth: '95px',
    renderCell: (item) => {
      const createdDate = item.startDate;
      return <DateRow>{createdDate && `${convertToTimeString(createdDate)}`}</DateRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Deadline`),
    accessor: 'deadline',
    maxWidth: '95px',
    renderCell: (item) => {
      const createdDate = item.endDate;
      return <DateRow>{createdDate && `${convertToTimeString(createdDate)}`}</DateRow>;
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Actions`),
    accessor: 'action',
    maxWidth: '135px',
    renderCell: (item, onClick) => {
      return (
        item.type === TASK_TYPE.REVIEW_PEER_NOMINATE && (
          /* eslint-disable-next-line */
          /* @ts-ignore*/
          <ActionColumn item={item} onClick={() => onClick?.(item)} />
        )
      );
    },
  },
];

export { COLUMNS };
