import { UPDATED_TASK_ACTIONS } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

/**
 * @deprecated Should not be used anymore (use STATUS_ITEMS in constants/tasks.ts)
 */
export const STATUS_ITEMS = {
  [UPDATED_TASK_ACTIONS.TODO]: {
    key: UPDATED_TASK_ACTIONS.TODO,
    text: (i18n: I18n) => i18n._(t`To Do`),
    icon: ICONS.DROPDOWN,
    color: '#5c76ff',
    backGroundColor: '#e8ecff',
  },
  [UPDATED_TASK_ACTIONS.IN_PROGRESS]: {
    key: UPDATED_TASK_ACTIONS.IN_PROGRESS,
    text: (i18n: I18n) => i18n._(t`In progress`),
    icon: ICONS.IN_PROGRESS,
    color: '#95a100',
    backGroundColor: '#f2f4d5',
  },
  [UPDATED_TASK_ACTIONS.COMPLETED]: {
    key: UPDATED_TASK_ACTIONS.COMPLETED,
    text: (i18n: I18n) => i18n._(t`Completed`),
    icon: ICONS.CHECKMARK,
    color: COLORS.ACCENT_SUCCESS,
    backGroundColor: '#cef1ea',
  },
  [UPDATED_TASK_ACTIONS.PROBLEM]: {
    key: UPDATED_TASK_ACTIONS.PROBLEM,
    text: (i18n: I18n) => i18n._(t`Problem`),
    icon: ICONS.WARNING_2,
    color: COLORS.ERROR,
    backGroundColor: '#f6d0d0',
  },
  [UPDATED_TASK_ACTIONS.ARCHIVED]: {
    key: UPDATED_TASK_ACTIONS.ARCHIVED,
    text: (i18n: I18n) => i18n._(t`Archived`),
    icon: ICONS.ARCHIVE_2,
    color: COLORS.LIGHT_GRAY,
    backGroundColor: COLORS.ANY_OTHER_PRODUCTS,
  },
};
