import styled from 'styled-components';

import { COLORS } from '~/styles';

export const LastSavedWrapper = styled.div`
  position: absolute;
  padding: 3px 10px 6px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  z-index: 1000;
  background-color: ${COLORS.WHITE};
  border-radius: 0 0 6px 6px;
`;
