import React, { useEffect, useMemo, useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import BaseLayout from '~/layouts/BaseLayout';

import { DetailsSection } from './components/DetailsSection';
import Content from './Content';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { getCareerPlans, getUserCareerPlans } from '~/services/careerPlans';
import { getJobFamily } from '~/services/jobFamilies';
import { getJobLevelGroup } from '~/services/jobLevelGroups';
import { getJobProfile } from '~/services/jobProfiles';
import { COLORS } from '~/styles';

const DownloadPdf = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.SUBTEXT};
`;

const DownloadButton = styled(Button)`
  color: ${COLORS.SUBTEXT};
  box-shadow: none;
  border: none;
  background-color: transparent;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin: auto;
  overflow: hidden;
`;

const LeftSide = styled.div`
  background-color: ${COLORS.WHITE};
  width: 750px;
  border-radius: 10px;
  padding: 30px 40px 40px 39px;
`;

const RightSide = styled.div`
  background-color: ${COLORS.WHITE};
  width: 404px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 26px 21px 36px 27px;
`;

export const StyledButton = styled(Button)`
  margin-right: 20px;
`;

function RolePage({ match }) {
  const { i18n } = useLingui();
  const params = useParams();
  const [jobProfile, setJobProfile] = useState();
  const [careerPlans, setCareerPlans] = useState([]);
  const userId = get(params, 'userId');
  const getMultiLangString = useMultiLangString();

  const loading = useBoolState(true);
  const isCurrentRole = useBoolState(false);
  const $enableDownloadPDF = useBoolState(false);
  const currentUser = useSelector(getUser);
  const { goBack } = useFromQuery({ includeHash: true });

  useEffect(() => {
    async function retrieveData() {
      // Download job profile, focus areas and like for this job profile.
      const [response] = await Promise.all([getJobProfile(match.params.roleId, ['skills'])]);
      const jp = response?.data?.[API_RETURN_FIELDS.JOB_PROFILE];

      // Check if job profile is part of current career plans.
      const careerPlans = userId ? await getUserCareerPlans(userId) : await getCareerPlans();
      setCareerPlans(careerPlans);

      isCurrentRole.set(
        Object.values(careerPlans)
          .map((careerPlan) => careerPlan.jobProfile)
          .includes(jp.id),
      );

      const jobFamilyResponsePromise = jp?.jobFamily
        ? getJobFamily(jp?.jobFamily)
        : Promise.resolve(null);
      const jobLevelGroupResponsePromise = jp?.jobLevelGroup
        ? getJobLevelGroup(jp?.jobLevelGroup?.id)
        : Promise.resolve(null);

      const [jobFamily, jobLevelGroup] = await Promise.allSettled([
        jobFamilyResponsePromise.then((res) => res?.data?.[API_RETURN_FIELDS.JOB_FAMILIES]),
        jobLevelGroupResponsePromise.then((res) => res?.data?.[API_RETURN_FIELDS.JOB_LEVEL_GROUPS]),
      ]);

      if (!isEmpty(jp.skills)) {
        // Determine highest level for each skill
        jp.skills.forEach((skill) => {
          skill.maxLevel = skill.levelsEnabled?.filter((l) => l).length;
          skill.numberOfEnabledLevels = [];
          skill.levelsEnabled?.forEach((l, i) => {
            if (l) {
              skill.numberOfEnabledLevels = [...skill.numberOfEnabledLevels, i + 1];
            }
          });
        });
      }

      // Save data in local state
      setJobProfile({ ...jp, jobFamily, jobLevelGroup });

      // Done!
      loading.off();
    }
    if (!jobProfile) {
      retrieveData();
    }
  }, [isCurrentRole, loading, match.params.roleId, userId, jobProfile]);

  const careerPlan = useMemo(() => {
    return Object.values(careerPlans).find((item) => item.jobProfile === jobProfile?.id);
  }, [careerPlans, jobProfile?.id]);

  return (
    <>
      <DashboardHeader
        title={getMultiLangString(jobProfile?.name || '')}
        onBack={goBack}
        isBackIcon
        actions={
          <>
            {$enableDownloadPDF.value && (
              <DownloadPdf>
                <DownloadButton
                  variant={ButtonVariant.SECONDARY}
                  icon={ICONS.IMPORT}
                  label={i18n._(t`Download pdf`)}
                />
              </DownloadPdf>
            )}
            {currentUser.isAdmin && (
              <StyledButton
                type="button"
                label={<Trans>Edit</Trans>}
                variant={ButtonVariant.TEXT_PRIMARY}
                icon={ICONS.EDIT_PENCIL}
                size={ButtonSize.MEDIUM}
                onClick={() => {
                  routes.JOB_UPDATE.go(
                    { role: ROLES.ADMIN },
                    {
                      roleId: jobProfile.id,
                      isBackPath: true,
                    },
                  );
                }}
              />
            )}
          </>
        }
      />
      <BaseLayout loading={loading.value}>
        <Wrapper>
          <LeftSide>
            <Content userId={userId} jobProfile={jobProfile} />
          </LeftSide>
          <RightSide>
            <DetailsSection careerPlan={careerPlan} />
          </RightSide>
        </Wrapper>
      </BaseLayout>
    </>
  );
}

export default RolePage;
