import { REPORT_TYPES } from '@learned/constants';
import styled from 'styled-components';

import { COLORS } from '~/styles';

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 32px;
  box-sizing: border-box;
  font-size: 14px;
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 16px 32px;
  box-sizing: border-box;
  height: auto;
`;

const LabelTextWrap = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  gap: 26px;
  padding: 16px 0px 16px 0px;
  box-sizing: border-box;
  color: ${COLORS.ICONS_PRIMARY};
  height: auto;
`;

const FirstTitle = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  width: max-content;
  position: relative;
  padding-left: 26px;
  box-sizing: border-box;
  &::before {
    content: '';
    background: ${COLORS.COMPANY};
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px;
    box-sizing: border-box;
  }
`;

const FirstText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PercentageContainer = styled.span`
  font-weight: 500;
  font-size: 22px;
  color: ${COLORS.TEXT_HOVER};
`;

const ChangeContainer = styled.span`
  color: ${COLORS.ACCENT_SUCCESS};
  border: 1px solid ${COLORS.ACCENT_SUCCESS};
  background: ${COLORS.SUCCESS_LIGHT};
  border-radius: 6px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
`;

const BenchMarkContainer = styled.span`
  color: ${COLORS.ACCENT_ERROR};
  border: 1px solid ${COLORS.ACCENT_ERROR};
  background: ${COLORS.ERROR_LIGHT};
  border-radius: 6px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
`;

const LucaHeaderTable = styled.div<{ columnCount: number; rowCount: number }>`
  display: grid;
  gap: 2px;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-row: span ${({ rowCount }) => rowCount};
  grid-column: span ${({ columnCount }) => columnCount};
  height: 36px;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
`;

const LucaOuterTable = styled.div<{ columnCount: number; reportType?: REPORT_TYPES }>`
  display: grid;
  gap: 2px;
  // Assumption engagement reports has only four columns yet
  grid-template-columns: ${({ reportType, columnCount }) =>
    reportType === REPORT_TYPES.ENGAGEMENT
      ? '125px 2fr 4fr 100px 4fr'
      : reportType === REPORT_TYPES.PERFORMANCE
      ? '125px 1fr 2fr 4fr 100px 4fr'
      : `repeat(${columnCount}, auto)`};
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
`;

const LucaTable = styled.div<{ columnCount: number; rowCount: number; isLoading: boolean }>`
  display: grid;
  gap: 2px;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-row: span ${({ rowCount }) => rowCount};
  grid-column: span ${({ columnCount }) => columnCount};
  box-sizing: border-box;
  position: relative;
  &::after {
    content: '';
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: calc(100% + 20px);
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

const TableItemValue = styled.div`
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
`;

const TableItem = styled.div<{ isPositionedOdd?: boolean }>`
  padding: 10px 20px;
  background: ${({ isPositionedOdd }) => (isPositionedOdd ? COLORS.BG_LIST : 'none')};
  box-sizing: border-box;
`;

const TableHeaderItem = styled.div`
  padding: 8px 20px;
  background: ${COLORS.PERFORMANCE_LIGHT};
  color: ${COLORS.SUBTEXT};
  text-transform: uppercase;
  border-radius: 6px;
  font-size: 12px;
  box-sizing: border-box;
`;

const Label = styled.div<{ color: string; backgroundColor: string }>`
  color: ${({ color }) => color};
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
`;

export {
  LoadingContainer,
  Label,
  LabelTextWrap,
  DescriptionContainer,
  FirstContainer,
  FirstTitle,
  FirstText,
  PercentageContainer,
  ChangeContainer,
  BenchMarkContainer,
  LucaTable,
  TableItem,
  TableItemValue,
  TableHeaderItem,
  LucaHeaderTable,
  LucaOuterTable,
};
