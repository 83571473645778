import styled, { css } from 'styled-components';

import { ButtonWhite } from '~/components/Button';
import { Marker } from '~/components/Marker';

import { COLORS } from '~/styles';

import { Footer } from '../Questions/design';

export const Form = styled.form`
  border-radius: 10px;
  box-sizing: border-box;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

export const IconPreview = styled.div<{ colors: string[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 46px;
  min-height: 46px;
  ${({ colors }) =>
    colors?.length && colors.length > 1
      ? css`
          background: linear-gradient(to bottom, ${colors[0]}, ${colors[1]});
        `
      : css`
          background-color: ${colors[0]};
        `}
  border-radius: 6px;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.ACTIVITY_GREY};
`;

export const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Content = styled.div<{ customizing: boolean }>`
  padding-top: 27px;
  padding-bottom: ${(props) => (props.customizing ? '33px' : '80px')};
`;

export const StyledInput = styled.input<{ error: boolean }>`
  width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.BORDER};
  border-radius: 6px;
  background-color: ${COLORS.WHITE};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  color: ${COLORS.BLACK};
  line-height: 1.86;
  letter-spacing: -0.16px;
  height: 38px;
  box-sizing: border-box;
  padding: 0;
  padding-right: 15px;
  padding-top: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }
  &:focus {
    ${({ error }) =>
      !error &&
      css`
        border-color: var(--company-color);
      `}
  }
  &:hover {
    background-color: ${COLORS.HOVER};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${COLORS.ERROR};
    `}
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ThemeWeightRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
`;

export const ThemeWeightsContainer = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 31px;
`;

export const Percentage = styled.span`
  position: absolute;
  right: 12px;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
`;

export const StyledFooter = styled(Footer)`
  margin-top: 0;
`;

export const WeightTotal = styled.div<{ error: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  border-top: solid 2.4px ${COLORS.BORDER_HARD};
  padding-top: 19px;

  ${({ error }) =>
    error &&
    css`
      color: ${COLORS.ERROR};
    `}
`;

export const StyledMarker = styled(Marker)`
  position: absolute;
  left: 100px;
`;

export const RowHeader = styled.span`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.33;
  color: ${COLORS.MIDDLE_GRAY};
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 74px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const BackButton = styled(ButtonWhite)`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: var(--company-color);
  gap: 4px;

  &:hover {
    color: var(--company-color);
    background-color: unset;
  }
`;

export const EqualizeButton = styled(BackButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
