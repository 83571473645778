import React, { ChangeEvent } from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { LastSaved } from '~/components/LastSaved';
import { DraggableModal } from '~/components/Modals/DraggableModal';
import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';
import { SelectCoachesModal } from '~/pages/Reviews/Modals/SelectCoachesModal';
import { SelectGuestsModal } from '~/pages/Reviews/Modals/SelectGuestsModal';

import { StatusLabel } from './components/StatusLabel';
import { Summaries } from './components/Summaries';
import { LastSavedWrapper } from './design';
import { useSummary } from './hooks/useSummary';
import { useUserReview } from './hooks/useUserReview';
import { EmployeeTasksSidebarSection } from './sidebarLeft/sections/EmployeeTasks';
import { OthersTasksSidebarSection } from './sidebarLeft/sections/OthersTasks';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { DashboardLayout } from '../../components/DashboardLayout';

import type { IReviewDashboardUserForm } from './types';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  languageState: ILanguageStateReturn;
}

// TODO: remove this when real content is ready
const Content = styled.div`
  padding: 16px;
  font-size: 14px;
  height: 200px;
`;

const ReviewDashboardUserForm = ({ formMethods }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const userReviewId = params.userReviewId as string;

  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;

  const nominatePeersTask = watch('taskNominatePeers');
  const employee = watch('employee');

  const {
    isLoading,
    isEmployee,
    employeeFirstName,
    // delete
    isShowToDelete,
    isDisableToDelete,
    // edit
    isShowToEdit,
    isDisableToEdit,
    // archive
    isAllowToArchive,
    isAllowToUnarchive,
    // export PDF
    isAllowToExportPDF,
    // sign
    isShowToSign,
    isDisableToSign,
    goToReviews,
    onEdit,
    onDelete,
    onArchive,
    onUnarchive,
    onExportPDF,
    onSign,
    isAllowToEditSummary,
    isAllowToGiveSelfReview,
    isAllowToNominatePeers,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    onGiveSelfReview,
    isPeerInputTasks,
    isCoachInputTasks,
    isEmployeeTasks,
    isOthersTasks,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests: _isAllowToDeleteGuests,
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests: _isAllowToAddGuests,
    onDeleteCoach,
    onDeletePeer,
    onDeleteGuest: _onDeleteGuest,
    onAddCoaches,
    onAddGuests,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    showSelectGuestsModal,
    openSelectGuestsModal: _openSelectGuestsModal,
    closeSelectGuestsModal,
  } = useUserReview({
    formMethods,
    userReviewId,
  });

  const {
    isSummaryModalVisible,
    formMethods: summaryFormMethods,
    autoSaveState,
    reviewSummaries,
    openMySummaryTooltip,
  } = useSummary({
    userReviewId,
    status: watch('status'),
  });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const iconButtons = [
    isShowToEdit && {
      icon: ICONS.EDIT_PENCIL,
      onClick: (_e: ChangeEvent<HTMLInputElement>) => onEdit(),
      isLoading,
      disabled: isDisableToEdit,
      tooltip: isDisableToEdit
        ? i18n._(t`This review is part of a review cycle and can only be edited there`)
        : i18n._(t`Edit`),
    },
    isAllowToArchive && {
      icon: ICONS.ARCHIVE,
      onClick: onArchive,
      isLoading,
      tooltip: i18n._(t`Archive`),
    },
    isAllowToUnarchive && {
      icon: ICONS.UNARCHIVE,
      onClick: onUnarchive,
      isLoading,
      tooltip: i18n._(t`Unarchive`),
    },
    isShowToDelete && {
      icon: ICONS.DELETE_BIN,
      onClick: onDelete,
      isLoading,
      disabled: isDisableToDelete,
      tooltip: isDisableToDelete
        ? i18n._(
            t`This review is part of a review cycle. To delete the review, delete the employee there.`,
          )
        : i18n._(t`Delete`),
    },
    isAllowToExportPDF && {
      icon: ICONS.EXPORT,
      onClick: onExportPDF,
      isLoading,
      tooltip: i18n._(t`Export PDF`),
    },
  ].filter((i) => i) as unknown as HeaderIconButtons[];

  const sidebarConfig = {
    items: [
      isEmployeeTasks
        ? {
            title: isEmployee ? i18n._(t`Your tasks`) : i18n._(t`${employeeFirstName} tasks`),
            icon: ICONS.CHECKBOX_2,
            content: (
              <EmployeeTasksSidebarSection
                tasks={watch('tasksSelf')}
                isAllowToGiveSelfReview={isAllowToGiveSelfReview}
                isAllowToNominatePeers={isAllowToNominatePeers}
                onGiveSelfReview={onGiveSelfReview}
                onNominatePeers={openNominatePeersModal}
              />
            ),
            isContentVisibleByDefault: watch('tasksSelf').some((task) =>
              [TASK_STATUS.UPCOMING, TASK_STATUS.TODO, TASK_STATUS.ACTIVE].includes(task.status),
            ),
          }
        : undefined,
      isOthersTasks
        ? {
            title: i18n._(t`Input by others`),
            icon: ICONS.USER_CHECK,
            content: (
              <OthersTasksSidebarSection
                tasksPeers={watch('tasksReceivedPeers')}
                tasksCoaches={watch('tasksReceivedCoaches')}
                isPeerInputTasks={isPeerInputTasks}
                isCoachInputTasks={isCoachInputTasks}
                formMethods={formMethods}
                isAllowToDeletePeers={isAllowToDeletePeers}
                isAllowToDeleteCoaches={isAllowToDeleteCoaches}
                isAllowToAddPeers={isAllowToAddPeers}
                isAllowToAddCoaches={isAllowToAddCoaches}
                onDeletePeer={onDeletePeer}
                onDeleteCoach={onDeleteCoach}
                onAddPeers={openNominatePeersModal}
                onAddCoaches={openSelectCoachesModal}
              />
            ),
          }
        : undefined,
      {
        title: i18n._(t`Signatures`),
        icon: ICONS.SIGNATURES,
        content: <Content>Signatures</Content>,
      },
      {
        title: i18n._(t`Review details`),
        icon: ICONS.DETAILS,
        content: <Content>Review details</Content>,
      },
    ].filter((i) => i),
    buttons: [
      {
        title: i18n._(t`Next Steps`),
        icon: ICONS.ARROW_DOUBLE_RIGHT,
        color: COLORS.SKY_BLUE,
        onClick: () => {},
      },
      {
        title: i18n._(t`My Summary`),
        icon: ICONS.SUMMARY,
        color: COLORS.CYAN,
        disabled: !isAllowToEditSummary,
        tooltip: !isAllowToEditSummary ? openMySummaryTooltip : '',
        onClick: () => {
          isSummaryModalVisible.on();
        },
      },
    ],
  };

  return (
    <>
      <HeaderFocusMode
        title={i18n._(t`Title: ${getMultiLangString(nameMultiLang)}`)}
        goBack={goToReviews}
        iconButtons={iconButtons}
        status={<StatusLabel status={watch('status')} />}
        isBackIcon={true}
        submitButton={
          isShowToSign
            ? {
                title: i18n._(t`Sign`),
                type: ButtonVariant.PRIMARY,
                onClick: onSign,
                disabled: isDisableToSign,
                tooltip: isDisableToSign
                  ? i18n._(t`You can sign the review after your coach has signed`)
                  : i18n._(t`Sign`),
              }
            : undefined
        }
      />
      <DashboardLayout
        isLoading={isLoading}
        // @ts-ignore
        sidebar={sidebarConfig}
        main={
          <>
            <Summaries
              reviewSummaries={reviewSummaries}
              onOpenMySummary={isSummaryModalVisible.on}
              isOpenMySummaryDisabled={!isAllowToEditSummary}
              openMySummaryTooltip={openMySummaryTooltip}
            />
          </>
        }
      />
      {isSummaryModalVisible.value && isAllowToEditSummary && (
        <DraggableModal
          title={i18n._(t`Summary`)}
          description={i18n._(t`Public summary visible at the end of the report.`)}
          onClose={isSummaryModalVisible.off}
        >
          <Controller
            {...summaryFormMethods.register('content')}
            control={summaryFormMethods.control}
            render={({ field: { value, onChange } }) => (
              <>
                <Editor
                  onChange={onChange}
                  onBlur={() => autoSaveState.run('reviewSummary')?.flush()}
                  value={convertMarkdownStarsToStrong(value)}
                  compact
                  isFlexible
                />
                {autoSaveState.state && (
                  <LastSavedWrapper>
                    <LastSaved
                      time={autoSaveState.state.time}
                      status={autoSaveState.state.status}
                      errorMessage={autoSaveState.state.message}
                    />
                  </LastSavedWrapper>
                )}
              </>
            )}
          />
        </DraggableModal>
      )}
      {showNominatePeersModal && nominatePeersTask && (
        <NominatePeers
          taskId={nominatePeersTask.id}
          closeModal={(isSubmit) => closeNominatePeersModal(isSubmit)}
        />
      )}

      {showSelectCoachesModal && (
        <SelectCoachesModal
          userId={employee?.id}
          onSave={onAddCoaches}
          onClose={closeSelectCoachesModal}
          usersToHide={[employee?.id, ...watch('coaches')].filter((i) => i)}
        />
      )}
      {showSelectGuestsModal && (
        <SelectGuestsModal
          onSave={onAddGuests}
          onClose={closeSelectGuestsModal}
          usersToHide={[employee?.id, ...watch('guests')].filter((i) => i)}
        />
      )}
    </>
  );
};

export { ReviewDashboardUserForm };
