import { ROLES } from '~/constants';
import { pageDashboard, pageOutside, pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'reviews';
const defaultReviewId = ':reviewId';

export const REVIEWS = new LinkConstructor([ROLES.USER], () => `${pageOverview(routeName)}`);

export const REVIEW_DASHBOARD = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = defaultReviewId }) => pageDashboard(routeName, { id: reviewId }),
);

export const UPDATE_REVIEW_CYCLE = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = defaultReviewId }) => `${pageDashboard(routeName, { id: reviewId })}/review-cycle`,
);

export const UPDATE_REVIEW_INDIVIDUAL = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = defaultReviewId }) => `${pageDashboard(routeName, { id: reviewId })}/individual`,
);

export const UPDATE_REVIEW_SELF = new LinkConstructor(
  [ROLES.USER],
  ({ reviewId = defaultReviewId }) => `${pageDashboard(routeName, { id: reviewId })}/self`,
);

// give feedback
export const REVIEW_GIVE_FEEDBACK_PEER = new LinkConstructor(
  [ROLES.USER],
  ({ taskId = ':taskId' }) =>
    `${pageDashboard('tasks', { id: taskId })}/reviews/give-feedback-peer`,
);

export const REVIEW_GIVE_FEEDBACK_COACH = new LinkConstructor(
  [ROLES.USER, ROLES.COACH],
  ({ taskId = ':taskId' }) =>
    `${pageDashboard('tasks', { id: taskId })}/reviews/give-feedback-coach`,
);

export const REVIEW_GIVE_FEEDBACK_SELF = new LinkConstructor(
  [ROLES.USER],
  ({ taskId = ':taskId' }) =>
    `${pageDashboard('tasks', { id: taskId })}/reviews/give-feedback-self`,
);

export const REVIEW_GIVE_FEEDBACK_OUTSIDE = new LinkConstructor(
  [],
  () => `${pageOutside(routeName)}/give-feedback-outside`,
);
