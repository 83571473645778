import React from 'react';

import styled from 'styled-components';

import { TableGrid } from '~/components/TableGrid';

import { SKILL_CATEGORY_COLUMNS } from './SkillCategory.columns';

import type { ISelectedFocusArea } from '~/@types/job';

import { ISkillCategoryColumn } from '../utils';

const Wrapper = styled.div`
  margin-top: 23px;
`;

export interface ISelectedSkillTemplate {
  skill: any;
  selectedFocusAreas: ISelectedFocusArea[];
}

interface ISkillTemplateTable {
  data: Omit<ISkillCategoryColumn, 'onDelete' | 'onEdit'>[];
  onRowClick: ((item: any) => void) | undefined;
}

const SkillTemplateTable = ({ data, onRowClick }: ISkillTemplateTable) => {
  return (
    <Wrapper>
      <TableGrid
        thColumnPadding="16px 19px"
        onRowClick={onRowClick}
        data={data}
        columns={SKILL_CATEGORY_COLUMNS}
      />
    </Wrapper>
  );
};

export { SkillTemplateTable };
