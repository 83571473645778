import React, { useEffect, useState } from 'react';

import { SKILL_TEMPLATE_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import qs from 'qs';
import { useForm } from 'react-hook-form';

import { useToasts, TOAST_TYPES } from '~/components/Toast';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { createSkillTemplateSuperAdmin } from '~/services/skillTemplates';
import {
  getSkillTemplatePendingSuperAdmin,
  deleteSkillTemplatesPendingSuperAdmin,
} from '~/services/skillTemplatesPending';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';

import { SuperAdminSkillTemplateForm } from '../SuperAdminSkillTemplateForm';
import { removeEmptyValues } from '../SuperAdminSkillTemplateForm/utils';
import { resolver } from '../SuperAdminSkillTemplateForm/validations';

import type { IGeneralForm } from '../SuperAdminSkillTemplateForm/types';
import type { ISkillCategory, ISkillTemplatePending } from '@learned/types';

function SuperAdminCreateSkillTemplate() {
  const { i18n } = useLingui();
  const { goBack } = useFromQuery({ includeHash: true });
  const { addToast } = useToasts();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [skillCategory, setSkillCategory] = useState<ISkillCategory>();
  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });
  const generalFormMethods = useForm<IGeneralForm>({
    mode: 'all',
    resolver,
    defaultValues: {
      name: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      description: languageState.defaultLanguages.map(({ locale }) => ({ locale, value: '' })),
      focusAreas: new Array(5).fill(0).map((_, index) => ({
        values: [],
        level: index,
      })),
      aliases: [],
    },
  });

  const createFromSkillPendingId = query?.createFromSkillPendingId as string | undefined;
  const skillTemplateName = generalFormMethods
    .watch('name')
    .find(({ locale }) => locale === languageState.primaryLanguage.locale)?.value;
  const title = i18n._(t`Create skill or KPI: ${skillTemplateName}`);

  useEffect(() => {
    let mounted = true;
    const fetchPendingSkillTemplate = async () => {
      if (!createFromSkillPendingId) {
        return;
      }

      const result = await getSkillTemplatePendingSuperAdmin(createFromSkillPendingId);

      const skillPending = result.data.skillTemplate as ISkillTemplatePending;
      if (mounted && result.code === 200) {
        generalFormMethods.reset({
          name: languageState.defaultLanguages.map(({ locale }) => ({
            locale,
            value: skillPending.name[locale] ?? '',
          })),
          description: languageState.defaultLanguages.map(({ locale }) => ({
            locale,
            value: skillPending.description[locale] ?? '',
          })),
          focusAreas: new Array(5).fill(0).map((_, index) => ({
            values: [],
            level: index,
          })),
          aliases: [],
        });
      }
    };

    fetchPendingSkillTemplate();

    return () => void (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFromSkillPendingId]);

  const onSubmit = async (data: IGeneralForm & { status: SKILL_TEMPLATE_STATUS }) => {
    const predicate = ({ value, locale }: { value: string; locale: string }) =>
      value !== '' || locale === '_id';
    const transformedData = {
      ...data,
      skillTemplatePendingId: createFromSkillPendingId,
      aliases: data.aliases.map(({ _id, ...alias }) => removeEmptyValues(alias)),
      name: convertLanguageValue(data.name.filter(predicate)),
      description: convertLanguageValue(data.description.filter(predicate)),
      focusAreas: data.focusAreas
        .map(({ values, level }) => ({
          values: values.map(({ name }) => ({
            name: removeEmptyValues(name),
          })),
          level,
        }))
        .slice(0, skillCategory?.skillLevels?.length ?? 5),
    };
    const result = await createSkillTemplateSuperAdmin(transformedData);
    if (createFromSkillPendingId) {
      deleteSkillTemplatesPendingSuperAdmin([createFromSkillPendingId]);
    }

    if (
      result.code === 200 &&
      result.data.skillTemplate.status === SKILL_TEMPLATE_STATUS.PUBLISHED
    ) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill template published`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    } else if (
      result.code === 200 &&
      result.data.skillTemplate.status === SKILL_TEMPLATE_STATUS.DRAFT
    ) {
      addToast({
        title: i18n._(t`Success`),
        subtitle: i18n._(t`Skill template saved as draft`),
        type: TOAST_TYPES.SUCCESS,
      });
      goBack();
    }
  };

  return (
    <SuperAdminSkillTemplateForm
      title={title}
      formMethods={generalFormMethods}
      languageState={languageState}
      onSubmit={onSubmit}
      goBack={goBack}
      skillCategory={skillCategory}
      setSkillCategory={setSkillCategory}
      onDelete={goBack}
    />
  );
}

export { SuperAdminCreateSkillTemplate };
