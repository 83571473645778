import {
  GOAL_TYPES as GOAL_TYPES_CONST,
  GOAL_STATUSES as GOAL_STATUSES_CONST,
  FILTER_TASK_TYPES,
  NOTIFICATION_TYPES as NOTIFICATION_TYPES_CONST,
  SURVEY_TYPE,
  INTEGRATIONS,
} from '@learned/constants';
import { t } from '@lingui/macro';
import get from 'lodash/get';

import articleIcon from '~/assets/a-article-icn.svg';
import bookIcon from '~/assets/a-book-icn.svg';
import otherIcon from '~/assets/a-other-icn.svg';
import videoIcon from '~/assets/a-video-icn.svg';
import FeedbackIcon from '~/assets/main-menu-icons/communications.svg';
import GoalIcon from '~/assets/main-menu-icons/goal.svg';
import ReviewIcon from '~/assets/main-menu-icons/star.svg';
import coursesIcon from '~/assets/mdi-projector-screen.svg';
import savedIcon from '~/assets/turned_in_not-24px.svg';

import { COLORS, COLOR_SET } from '~/styles';

export const SSO_METHODS = {
  ALL: { key: 'all', name: (i18n) => i18n._(t`Password or SSO`) },
  GOOGLE: { key: 'google-oauth2', name: (i18n) => i18n._(t`SSO Google`) },
  AZURE_AD: { key: 'learned-azure-ad', name: (i18n) => i18n._(t`SSO Azure AD`) },
};

export const ROLES = {
  USER: 'user',
  COACH: 'coach',
  ADMIN: 'admin',
  OWNER: 'owner',
};

export const LANGUAGES = {
  EN: 'en',
  NL: 'nl',
};

export const ROLE_LIBRARY_TABS = {
  RECOMMENDED: {
    key: 'recommended',
    label: (i18n) => i18n._(t`Recommended`),
  },
  ALL: {
    key: 'all',
    label: (i18n) => i18n._(t`All`),
  },
  SAVED: {
    key: 'saved',
    label: (i18n) => i18n._(t`Ambitions`),
    icon: savedIcon,
  },
};

export const CONVERSATION_COLLECTION_TYPES = {
  ALL: {
    key: 'all',
    name: (i18n) => i18n._(t`All types`),
  },
  CONVERSATION: {
    key: 'conversation',
    name: (i18n) => i18n._(t`1-1`),
  },
  REVIEW: {
    key: 'review',
    name: (i18n) => i18n._(t`Conversations`),
  },
};

export const TALKING_POINT_TYPES = {
  CUSTOM: {
    key: 'custom',
    name: (i18n) => i18n._(t`custom`),
    color: COLOR_SET.CORAL,
    subColor: COLOR_SET.ROSE_WHITE,
  },
  TEMPLATES: {
    key: 'templates',
    name: (i18n) => i18n._(t`templates`),
    color: COLOR_SET.CORAL,
    subColor: COLOR_SET.ROSE_WHITE,
  },
  GOALS: {
    key: 'goals',
    name: (i18n) => i18n._(t`goals`),
    color: COLOR_SET.FUCHSIA,
    subColor: COLOR_SET.ROSE_LIGHT,
  },
  REVIEWS: {
    key: 'reviews',
    name: (i18n) => i18n._(t`reviews`),
    color: COLOR_SET.MIDNICHT_BLUE,
    subColor: COLOR_SET.PURPLE_LIGHT,
  },
  PATHS: {
    key: 'paths',
    name: (i18n) => i18n._(t`paths`),
    color: COLOR_SET.CORNFLOWER_BLUE,
    subColor: COLOR_SET.LAVENDER,
  },
  ACTIVITIES: {
    key: 'activities',
    name: (i18n) => i18n._(t`activities`),
    color: COLOR_SET.CORNFLOWER_BLUE,
    subColor: COLOR_SET.LAVENDER,
  },
  RTFEEDBACK: {
    key: 'rtfeedback',
    name: (i18n) => i18n._(t`realtime feedback`),
    color: COLOR_SET.CYAN_GREEN,
    subColor: COLOR_SET.CYAN_LIGHT,
  },
};

export const COMMENT_SOURCES = {
  TALKING_POINT: 'talkingPoint',
  CONVERSATION: 'conversation',
};

export const RATING_TYPES = {
  SELF_RATING: 1,
  OTHER_RATING: 2,
  FINAL_RATING: 3,
  COACH_RATING: 4,
  OUTSIDE_RATING: 5,
};

export const RATING_SUB_TYPES = {
  FEEDBACK: 'feedback',
};

export const REQUEST_TYPES = {
  FEEDBACK_FROM_PEER: 'feedbackFromPeer',
  FEEDBACK_FROM_COACH: 'feedbackFromCoach',
  FEEDBACK_FROM_OUTSIDE_PEER: 'feedbackFromOutsidePeer',
  RT_FEEDBACK: 'rt_feedback', // real-time feedback запрос для пользоватетей
  RT_FEEDBACK_OUTSIDE: 'rt_feedback_outside', // real-time feedback запрос по email
  NOMINATE_CONVERSATION_COACH: 'nominateConversationCoach',
};

export const REQUEST_STATUSES = {
  REQUESTED: {
    key: 'requested',
    color: COLORS.COMPANY,
    label: t`Not started`,
  },
  CANCELLED: {
    key: 'cancelled',
    color: COLORS.ERROR,
    label: t`Cancelled`,
  },
  ACCEPTED: {
    key: 'accepted',
    color: COLORS.COMPANY,
    label: t`Done`,
  },
  REJECTED: {
    key: 'rejected',
    color: COLORS.ERROR,
    label: t`Rejected`,
  },
  STARTED: {
    key: 'started',
    color: COLORS.COMPANY,
    label: t`Started`,
  },
  SHARED: {
    key: 'shared',
    color: COLORS.COMPANY,
    label: t`Done`,
  },
  EXPIRED: {
    key: 'expired',
    color: COLORS.DISABLED,
    label: t`Expired`,
  },
  CALIBRATE: {
    key: 'calibrate',
    color: COLORS.PEER_COVERAGE_STATUS,
    label: t`Calibrate`,
  },
};

export const NOMINATE_TYPE = {
  peer: 'peer',
  coach: 'coach',
  conversationCoach: 'conversationCoach',
};

export const REVIEW_STATUSES = {
  DRAFT: {
    key: 'draft',
    keyIntl: (i18n) => i18n._(t`draft`),
    title: (i18n) => i18n._(t`Draft`),
  },
  ACTIVE: {
    key: 'active',
    keyIntl: (i18n) => i18n._(t`active`),
    title: (i18n) => i18n._(t`Active`),
  },
  DONE: {
    key: 'done',
    keyIntl: (i18n) => i18n._(t`done`),
    title: (i18n) => i18n._(t`Done`),
  },
};

export const GIVE_FEEDBACK_STATUSES = {
  DRAFT: {
    key: 'draft',
    color: COLORS.ERROR,
    label: t`Draft`,
  },
  CALIBRATE: {
    key: 'calibrate',
    color: COLORS.COMPANY,
    label: t`Calibrate`,
  },
  SHARED: {
    key: 'shared',
    color: COLORS.SUCCESS,
    label: t`Done`,
  },
};

export const PATH_STATUSES = {
  DRAFT: {
    key: 'draft',
    keyIntl: (i18n) => i18n._(t`draft`),
    title: (i18n) => i18n._(t`Draft`),
  },
  ACTIVE: {
    key: 'active',
    keyIntl: (i18n) => i18n._(t`active`),
    title: (i18n) => i18n._(t`Active`),
  },
};

export const REVIEW_STAGES = {
  NOMINATE: 'nominate',
  FEEDBACK: 'feedback',
};

export const REVIEW_REMINDERS = {
  SELF_REVIEW: 'selfReview',
  PEER_REVIEW: 'peerReview',
  COACH_REVIEW: 'coachReview',
  NOMINATE_PEERS: 'nominatePeers',
  CONVERSATION: 'conversation',
  DIGITAL_SIGN: 'digitalSign',
};

export const REVIEW_LOGS = {
  CREATE_USER_REVIEW: 'createUserReview',
  DELETE_USER_REVIEW: 'deleteUserReview',
};

export const EVENT_DURATIONS = [
  { value: 30, name: '30m' },
  { value: 45, name: '45m' },
  { value: 60, name: '1h' },
  { value: 90, name: '1h 30m' },
  { value: 120, name: '2h' },
];

export const TASKS_FILTER_TYPES = {
  TASKS: { key: FILTER_TASK_TYPES.SYSTEM, label: (i18n) => i18n._(t`Tasks`) },
  NEXT_STEP: { key: FILTER_TASK_TYPES.NEXT_STEP, label: (i18n) => i18n._(t`Next steps`) },
};

export const LEARNING_ACTIVITIES_STATUSES = {
  ALL: {
    key: 'all',
    name: (i18n) => i18n._(t`All`),
  },
  ACTIVE: {
    key: 'active',
    name: (i18n) => i18n._(t`Active`),
  },
  COMPLETED: {
    key: 'completed',
    name: (i18n) => i18n._(t`Completed`),
  },
  DRAFT: {
    key: 'draft',
    name: (i18n) => i18n._(t`Draft`),
  },
};

export const GOAL_STATUSES = {
  ON_TRACK: {
    key: GOAL_STATUSES_CONST.ON_TRACK,
    title: (i18n) => i18n._(t`On track`),
    color: '#005efe',
  },
  COMPLETED: {
    key: GOAL_STATUSES_CONST.COMPLETED,
    title: (i18n) => i18n._(t`Completed`),
    color: COLOR_SET.CYAN_GREEN,
  },
  DRAFT: {
    hideInList: true,
    key: GOAL_STATUSES_CONST.DRAFT,
    title: (i18n) => i18n._(t`Draft`),
    color: COLOR_SET.CYAN_SHADE,
  },
  PROBLEM: {
    key: GOAL_STATUSES_CONST.PROBLEM,
    title: (i18n) => i18n._(t`Problem`),
    color: COLOR_SET.ORANGE2,
  },
  ARCHIVED: {
    key: GOAL_STATUSES_CONST.ARCHIVED,
    title: (i18n) => i18n._(t`Archived`),
  },
};

export const GOAL_TYPES = {
  PERSONAL: {
    key: GOAL_TYPES_CONST.PERSONAL,
    title: (i18n) => i18n._(t`Personal goals`),
  },
  BUSINESS: {
    key: GOAL_TYPES_CONST.BUSINESS,
    title: (i18n) => i18n._(t`Business goals`),
  },
  TEAM: {
    key: GOAL_TYPES_CONST.TEAM,
    title: (i18n) => i18n._(t`Team goals`),
  },
  COMPANY: {
    key: GOAL_TYPES_CONST.COMPANY,
    title: (i18n) => i18n._(t`Company goals`),
  },
};

export const VIEW_MODE = {
  USER: 'user',
  COACH: 'coach',
  ADMIN: 'admin',
};

export const CONVERSATION_STATUSES = {
  TODO: {
    key: 'todo',
    name: (i18n) => i18n._(t`Todo`),
  },
  ACTIVE: {
    key: 'active',
    name: (i18n) => i18n._(t`active`),
  },
  EXPIRED: {
    key: 'expired',
    name: (i18n) => i18n._(t`Expired`),
  },
  DONE: {
    key: 'done',
    name: (i18n) => i18n._(t`done`),
  },
  OVERDUE: {
    key: 'overdue',
    name: (i18n) => i18n._(t`overdue`),
  },
  DRAFT: {
    key: 'draft',
    name: (i18n) => i18n._(t`draft`),
  },
  DONE_ADMIN: {
    key: 'doneAdmin',
    name: (i18n) => i18n._(t`done`),
  },
};

export const CONVERSATION_FILTER_STATUSES = {
  ALL: {
    key: 'all',
    id: 'all',
    name: (i18n) => i18n._(t`All`),
  },
  ACTIVE: {
    key: 'active',
    id: 'active',
    name: (i18n) => i18n._(t`Active`),
  },
  ARCHIVED: {
    key: 'done',
    id: 'done',
    name: (i18n) => i18n._(t`Archived`),
  },
};

export const CONNECTION_STATUSES = {
  ACTIVE: {
    key: 'active',
    name: (i18n) => i18n._(t`Active`),
  },
  INACTIVE: {
    key: 'inactive',
    name: (i18n) => i18n._(t`Disabled`),
  },
  WAITING: {
    key: 'waiting',
    name: (i18n) => i18n._(t`Invited`),
  },
};

export const INVITE_STATUSES = {
  SENT: {
    key: 'sent',
  },
  ACCEPTED: {
    key: 'accepted',
  },
  CANCELED: {
    key: 'canceled',
  },
};

export const ACTIVITY_TYPES = {
  VIDEO: {
    key: 'video',
    name: 'Video',
    icon: videoIcon,
    color: COLOR_SET.LIGHT_RED,
    translated: (i18n) => i18n._(t`Video`),
  },
  BOOK: {
    key: 'book',
    name: 'Book',
    icon: bookIcon,
    color: COLOR_SET.YELLOW,
    translated: (i18n) => i18n._(t`Book`),
  },
  ARTICLE: {
    key: 'article',
    name: 'Article',
    icon: articleIcon,
    color: COLOR_SET.CYAN_GREEN,
    translated: (i18n) => i18n._(t`Article`),
  },
  COURSE: {
    key: 'course',
    name: 'Course',
    icon: coursesIcon,
    color: COLOR_SET.PURPLE,
    translated: (i18n) => i18n._(t`Course`),
  },
  PODCAST: {
    key: 'podcast',
    name: 'Podcast',
    icon: articleIcon,
    color: COLOR_SET.CYAN_GREEN,
    translated: (i18n) => i18n._(t`Podcast`),
  },
  WEBINAR: {
    key: 'webinar',
    name: 'Webinar',
    icon: articleIcon,
    color: COLOR_SET.CYAN_GREEN,
    translated: (i18n) => i18n._(t`Webinar`),
  },
  OTHER: {
    key: 'other',
    name: 'Other',
    icon: otherIcon,
    color: COLOR_SET.GRAY,
    translated: (i18n) => i18n._(t`Other`),
  },
};

export const GOAL_CREATED_BY_FILTER = {
  ADMIN: {
    id: 'admin',
    label: (i18n) => i18n._(t`Admin`),
  },
  COACH: {
    id: 'coach',
    label: (i18n) => i18n._(t`Coach`),
  },
  USER: {
    id: 'user',
    label: (i18n) => i18n._(t`User`),
  },
};

export const ACTIVITIES_LIBRARY_TYPE_FILTER = {
  VIDEO: {
    id: 'video',
    label: (i18n) => i18n._(t`Video`),
  },
  BOOK: {
    id: 'book',
    label: (i18n) => i18n._(t`Book`),
  },
  ARTICLE: {
    id: 'article',
    label: (i18n) => i18n._(t`Article`),
  },
  COURSE: {
    id: 'course',
    label: (i18n) => i18n._(t`Course`),
  },
  PODCAST: {
    id: 'podcast',
    label: (i18n) => i18n._(t`Podcast`),
  },
  WEBINAR: {
    id: 'webinar',
    label: (i18n) => i18n._(t`Webinar`),
  },
  OTHER: {
    id: 'other',
    label: (i18n) => i18n._(t`Other`),
  },
};

export const ACTIVITY_SOURCE = {
  LEARNED: {
    key: 'learned',
    name: 'Learned',
  },
};

export const JOB_PROFILE_STATUSES = {
  ACTIVE: {
    key: 'active',
  },
  INACTIVE: {
    key: 'inactive',
  },
};

export const BROWSERS_SUPPORTED = {
  CHROME: {
    id: 0,
    key: 'chrome',
    name: 'Google Chrome',
  },
  SAFARI: {
    id: 1,
    key: 'safari',
    name: 'Safari',
  },
  FIREFOX: {
    id: 2,
    key: 'firefox',
    name: 'Firefox',
  },
  EDGE: {
    id: 3,
    key: ['edge', 'edge-chromium'],
    name: 'Microsoft Edge',
  },
};

export const RT_FEEDBACK_TYPES = {
  ASK_FEEDBACK: {
    key: 'ask_feedback',
    name: (i18n) => i18n._(t`Ask feedback`),
  },
  GIVE_FEEDBACK: {
    key: 'give_feedback',
    name: (i18n) => i18n._(t`Give feedback`),
  },
};

export const RT_FEEDBACK_SUBTYPES = {
  GOAL: {
    key: 'goal',
    name: (i18n) => i18n._(t`Goal`),
  },
  ACTIVITY: {
    key: 'activity',
    name: (i18n) => i18n._(t`Activity`),
  },
  SKILLS: {
    key: 'skills',
    name: (i18n) => i18n._(t`Skills`),
  },
};

export const RT_FEEDBACK_STATUSES = {
  DELETED: {
    key: 'deleted',
  },
};

export const REVIEW_OPTIONS = {
  IS_SELF_REVIEW: {
    key: 'isSelfReview',
  },
  IS_USERS_REVIEW: {
    key: 'isUsersReview',
  },
  IS_COACHES_REVIEW: {
    key: 'isCoachesReview',
  },
};

export const TEMPLATE_NEW_REVIEW = {
  [REVIEW_OPTIONS.IS_SELF_REVIEW.key]: true,
  [REVIEW_OPTIONS.IS_USERS_REVIEW.key]: true,
  [REVIEW_OPTIONS.IS_COACHES_REVIEW.key]: true,
  deadlineOfPlanConversation: null,
  deadlineNominate: null,
  deadlineSelfReview: null,
  deadlineUsersRequest: null,
  deadlineCoachesRequest: null,
  deadlineToSign: null,
  skills: [],
  template: null,
  dateOfConversation: null,
  dateOfConversationEnd: null,
};

export const FOCUSAREA_LEVELS = {
  1: {
    key: 1,
    label: (i18n) => i18n._(t`Novice`),
    desc: (i18n) => i18n._(t`{years} years experience`, { years: '0 - 1' }),
  },
  2: {
    key: 2,
    label: (i18n) => i18n._(t`Intermediate`),
    desc: (i18n) => i18n._(t`{years} years experience`, { years: '1 - 3' }),
  },
  3: {
    key: 3,
    label: (i18n) => i18n._(t`Professional`),
    desc: (i18n) => i18n._(t`{years} years experience`, { years: '3 - 5' }),
  },
  4: {
    key: 4,
    label: (i18n) => i18n._(t`Advanced`),
    desc: (i18n) => i18n._(t`{years} years experience`, { years: '5 - 10' }),
  },
  5: {
    key: 5,
    label: (i18n) => i18n._(t`Expert`),
    desc: (i18n) => i18n._(t`{years} years experience`, { years: '10+' }),
  },
};

export const OUTSIDE_PAGES = {
  RT_FEEDBACK: 'RTFeedbackOutside',
  REVIEW: 'reviewOutside',
};

export const EDUCATION_LEVELS = {
  1: {
    key: 1,
    value: '1',
    name: (i18n) => i18n._(t`None`),
    shortName: (i18n) => i18n._(t`None`),
  },
  2: {
    key: 2,
    value: '2',
    name: (i18n) => i18n._(t`Secondary education`),
    shortName: (i18n) => i18n._(t`2nd ed.`),
  },
  3: {
    key: 3,
    value: '3',
    name: (i18n) => i18n._(t`Intermediate vocational education (MBO)`),
    shortName: (i18n) => i18n._(t`MBO+`),
  },
  4: {
    key: 4,
    value: '4',
    name: (i18n) => i18n._(t`University of applied sciences (HBO)`),
    shortName: (i18n) => i18n._(t`HBO+`),
  },
  5: {
    key: 5,
    value: '5',
    name: (i18n) => i18n._(t`University bachelor (WO)`),
    shortName: (i18n) => i18n._(t`WO Bachelor`),
  },
  6: {
    key: 6,
    value: '6',
    name: (i18n) => i18n._(t`University Masters (WO)`),
    shortName: (i18n) => i18n._(t`WO Master`),
  },
};

export const WORK_EXPERIENCE_LEVELS = {
  1: {
    key: 1,
    value: '1',
    name: (i18n) => i18n._(t`Not applicable`),
    shortName: (i18n) => i18n._(t`N/A`),
    labelName: (i18n) => i18n._(t`Not applicable`),
  },
  2: {
    key: 2,
    value: '2',
    name: (i18n) => i18n._(t`Starter (0-1)`),
    shortName: (i18n) => i18n._(t`0 - 1 years`),
    labelName: (i18n) => i18n._(t`Starter`),
  },
  3: {
    key: 3,
    value: '3',
    name: (i18n) => i18n._(t`Medior (1-3)`),
    shortName: (i18n) => i18n._(t`1 - 3 years`),
    labelName: (i18n) => i18n._(t`Medior`),
  },
  4: {
    key: 4,
    value: '4',
    name: (i18n) => i18n._(t`Senior (3-5)`),
    shortName: (i18n) => i18n._(t`3 -5 years`),
    labelName: (i18n) => i18n._(t`Senior`),
  },
  5: {
    key: 5,
    value: '5',
    name: (i18n) => i18n._(t`Management (5-10)`),
    shortName: (i18n) => i18n._(t`5 - 10 years`),
    labelName: (i18n) => i18n._(t`Management (Advanced)`),
  },
  6: {
    key: 6,
    value: '6',
    name: (i18n) => i18n._(t`Leader (10+)`),
    shortName: (i18n) => i18n._(t`10+ years`),
    labelName: (i18n) => i18n._(t`Leader`),
  },
};

export const TASKS_STATUSES = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const TASKS_ACTIONS = {
  START: { key: 'start', text: (i18n) => i18n._(t`Start`) },
  CONTINUE: { key: 'continue', text: (i18n) => i18n._(t`Continue`) },
  UPDATE: { key: 'update', text: (i18n) => i18n._(t`Update`) },
  SHOW: { key: 'show', text: (i18n) => i18n._(t`View`) },
  SHARED: { key: 'shared', text: (i18n) => i18n._(t`Shared`) },
  SHARE: { key: 'share', text: (i18n) => i18n._(t`Share`) },
  NO_ACTION: { key: 'no_action', text: '' },
  MARK_AS_DONE: { key: 'mark_as_done', text: (i18n) => i18n._(t`Mark as done`) },
  VIEW: { key: 'view', text: (i18n) => i18n._(t`View`) },
};

export const TASKS_TYPES_OLD = {
  RT_FEEDBACK: {
    key: 'rtFeedback',
    text: (task, i18n) => i18n._(t`Answer the feedback request of ${task.target.userName}'s`),
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  SELF_REVIEW: {
    key: 'selfReview',
    text: (task, i18n) => i18n._(t`Prepare for your ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  NOMINATE_PEERS: {
    key: 'nominatePeers',
    text: (task, i18n) => i18n._(t`Ask peers for input for your ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  PEER_REVIEW: {
    key: 'peerReview',
    text: (task, i18n) =>
      i18n._(t`Provide input for ${task.target.userName}'s ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  COACH_REVIEW: {
    key: 'coachReview',
    text: (task, i18n) =>
      i18n._(t`Provide input for ${task.target.userName}'s ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  PLAN_CONVERSATION: {
    key: 'planConversation',
    text: (task, i18n) =>
      i18n._(t`Set a date and time for ${task.target.userName}'s ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  DIGITAL_SIGN_SELF: {
    key: 'digitalSignSelf',
    text: (task, i18n) => i18n._(t`Complete your ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  DIGITAL_SIGN_COACH: {
    key: 'digitalSignCoach',
    text: (task, i18n) => i18n._(t`Complete ${task.target.userName}'s ${task.target.name}`),
    color: COLOR_SET.MIDNICHT_BLUE,
    icon: ReviewIcon,
  },
  GOAL_TASK: {
    key: 'goalTask',
    text: (_task, i18n) => i18n._(t`Update your goals`),
    color: COLOR_SET.FUCHSIA,
    icon: GoalIcon,
  },
  ASK_FEEDBACK: {
    key: 'ask_feedback',
    text: (_task, i18n) => i18n._(t`Ask someone for feedback`),
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  GIVE_FEEDBACK: {
    key: 'give_feedback',
    text: (_task, i18n) => i18n._(t`Give someone feedback or a compliment`),
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  NEXT_STEP_CONVERSATION: {
    key: 'next_step_conversation',
    text: (task) => task.target.name,
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  NEXT_STEP_REVIEW: {
    key: 'next_step_review',
    text: (task) => task.target.name,
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  ANSWER_SURVEY_TRADITIONAL: {
    key: 'answer_survey_traditional',
    text: (task) => task.target.name,
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
  ANSWER_SURVEY_PULSE: {
    key: 'answer_survey_pulse',
    text: (task) => task.target.name,
    color: COLOR_SET.CYAN_GREEN,
    icon: FeedbackIcon,
  },
};

export const NOTIFICATION_TYPES = {
  REQUEST_RTFEEDBACK: {
    key: 'request_rtfeedback',
    text: (notification, i18n) =>
      i18n._(t`${notification.options.fromUser} has asked you for feedback`),
  },
  COACH_REVIEW_REQUEST: {
    key: 'coach_request_review',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Provide input for ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}.`,
          )
        : i18n._(
            t`Provide input for ${notification.options.userName}'s ${notification.options.reviewName}.`,
          ),
  },
  REQUEST_REVIEW: {
    key: 'request_review',
    text: (notification, i18n) =>
      i18n._(
        t`Provide input for ${notification.options.fromUser}'s ${notification.options.reviewName}`,
      ),
  },
  USER_PATH_REMINDER: {
    key: 'user_path_reminder',
    text: (notification, i18n) =>
      i18n._(t`${notification.options.fromUser} reminds you to update your path progress`),
  },
  USER_PATH_CREATED: {
    key: 'user_path_created',
    text: (notification, i18n) =>
      i18n._(t`You have been assigned to the path: ${notification.options.pathName}`),
  },
  REQUEST_CONVERSATION: {
    key: 'request_conversation',
    text: (notification, i18n) =>
      i18n._(t`You have been invited for the 1-1: ${notification.options.conversationName}.`),
  },
  CONVERSATION_PLANNED: {
    key: 'conversation_planned',
    text: (notification, i18n) =>
      i18n._(
        t`${notification.options.fromUser} has set a date for the 1-1: ${notification.options.conversationName}`,
      ),
  },
  CONVERSATION_COMPLETED: {
    key: 'conversation_completed',
    text: (notification, i18n) =>
      i18n._(
        t`The 1-1 ${notification.options.conversationName} has now been completed and can no longer be updated`,
      ),
  },
  CONVERSATION_COMPLETE_REMINDER: {
    key: 'conversation_complete_reminder',
    text: (notification, i18n) =>
      i18n._(t`You are reminded to complete the 1-1 ${notification.options.conversationName}`),
  },
  CONVERSATION_SCHEDULE_REMINDER: {
    key: 'conversation_schedule_reminder',
    text: (notification, i18n) =>
      i18n._(t`You are reminded to schedule:  ${notification.options.conversationName}`),
  },
  CONVERSATION_USER_COMPLETED: {
    key: 'conversation_user_completed',
    text: (notification, i18n) =>
      i18n._(
        t`${notification.options.fromUser} has completed the 1-1 ${notification.options.conversationName}`,
      ),
  },
  CONVERSATION_COACH_COMPLETED: {
    key: 'conversation_coach_completed',
    text: (notification, i18n) =>
      i18n._(
        t`Participant has completed the 1-1 ${notification.options.conversationName}. Please approve the 1-1`,
      ),
  },
  CONVERSATION_COACH_UNCOMPLETED: {
    key: 'conversation_coach_uncompleted',
    text: (notification, i18n) =>
      i18n._(
        t`Participant has updated the 1-1 ${notification.options.conversationName}. Please approve the 1-1`,
      ),
  },
  GOAL_CREATED: {
    key: 'goal_created',
    text: (notification, i18n) => {
      const goalName = get(notification, 'options.goalName', '');
      return i18n._(t`You have been assigned a new goal: ${goalName}`);
    },
  },
  GOAL_USER_COMPLETED: {
    key: 'goal_user_completed',
    text: (notification, i18n) =>
      i18n._(
        t`${notification.options.fromUser} has completed goal ${notification.options.goalName}`,
      ),
  },
  FEEDBACK_PROVIDED: {
    key: 'feedback_provided',
    text: (notification, i18n) =>
      notification.options.reviewName
        ? i18n._(
            t`${notification.options.fromUser} has completed your review for ${notification.options.reviewName}`,
          )
        : i18n._(t`${notification.options.fromUser} has provided you with feedback`),
  },
  RTFEEDBACK_PROVIDED: {
    key: 'rt_feedback_provided',
    text: (notification, i18n) =>
      i18n._(t`${notification.options.fromUser} has provided you with feedback`),
  },
  REVIEW_INVITE: {
    key: 'review_invite',
    text: (notification, i18n) =>
      i18n._(t`You were invited to review round ${notification.options.reviewName}`),
  },
  TEAM_INVITE: {
    key: 'team_invite',
    text: (notification, i18n) =>
      i18n._(t`You were invited to team ${notification.options.teamName}`),
  },
  REVIEW_NOMINATE_REMINDER: {
    key: 'review_nominate_reminder',
    text: (notification, i18n) =>
      notification.options.reviewName
        ? i18n._(t`Reminder:  Ask peers for input for your ${notification.options.reviewName}`)
        : notification.options.fromUser
        ? i18n._(
            t`${notification.options.fromUser} reminds you to ask peers for input for your review`,
          )
        : i18n._(t`Reminder:  Ask peers for input for your review`),
    text_alt: (i18n) => i18n._(t`Reminder:  Ask peers for input for your review`),
  },
  REVIEW_FEEDBACK_REMINDER: {
    key: 'review_feedback_reminder',
    text: (notification, i18n) =>
      i18n._(t`${notification.options.fromUser} reminds you to give feedback for their review`),
    text_alt: (i18n) =>
      i18n._(
        t`Reminder: you have incoming feedback requests. Please help your co-workers by providing them with feedback.`,
      ),
  },
  REVIEW_COACH_REMINDER: {
    key: 'review_coach_reminder',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Reminder: Provide input for ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}.`,
          )
        : i18n._(
            t`Reminder: Provide input for ${notification.options.userName}'s ${notification.options.reviewName}.`,
          ),
  },
  REVIEW_PEER_REMINDER: {
    key: 'review_peer_reminder',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Reminder: Provide input for ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}.`,
          )
        : i18n._(
            t`Reminder: Provide input for ${notification.options.userName}'s ${notification.options.reviewName}.`,
          ),
  },
  REVIEW_SELF_FEEDBACK_REMINDER: {
    key: 'review_self_feedback_reminder',
    text: (notification, i18n) =>
      notification.options.reviewName
        ? i18n._(t`Reminder: Prepare for your ${notification.options.reviewName}`)
        : notification.options.fromUser
        ? i18n._(t`${notification.options.fromUser} reminds you to complete your self review`)
        : i18n._(t`Reminder: Prepare for your self review`),
    text_alt: (i18n) => i18n._(t`Reminder: Prepare for your self review`),
  },
  REVIEW_CONVERSATION_REMINDER: {
    key: 'review_conversation_reminder',
    text: (notification, i18n) => {
      const numberOfReviews = notification.options.numberOfReviews - 1;
      const reviewName = notification.options.reviewName;

      return numberOfReviews
        ? i18n._(
            t`Reminder: Set a date and time for ${reviewName} and ${numberOfReviews} other review.`,
          )
        : i18n._(t`Reminder: Set a date and time for ${reviewName}.`);
    },
  },
  REVIEW_USER_SELF_COMPLETED: {
    key: 'review_user_self_completed',
    text: (notification, i18n) =>
      i18n._(
        t`${notification.options.fromUser} has completed his/her self review for ${notification.options.reviewName}`,
      ),
  },
  REVIEW_SELF_REVIEW_START_DATE: {
    key: 'review_self_review_start_date',
    text: (notification, i18n) => i18n._(t`Prepare for your ${notification.options.reviewName}`),
  },
  REVIEW_NOMINATE_START_DATE: {
    key: 'review_nominate_start_date',
    text: (notification, i18n) =>
      i18n._(t`Ask peers for input for your ${notification.options.reviewName}`),
  },
  REVIEW_PLAN_CONVERSATION_START_DATE: {
    key: 'review_plan_conversation_start_date',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Set a date and time for ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}`,
          )
        : i18n._(
            t`Set a date and time for ${notification.options.userName}'s ${notification.options.reviewName}`,
          ),
  },
  REVIEW_DIGITAL_SIGN_START_DATE: {
    key: 'review_digital_sign_start_date',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Complete ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}`,
          )
        : i18n._(
            t`Complete your ${notification.options.reviewName} with ${notification.options.userName}`,
          ),
  },
  REVIEW_PEER_START_DATE: {
    key: 'review_peer_start_date',
    text: (notification, i18n) =>
      notification.options.numberOfUsers !== 0
        ? i18n._(
            t`Provide input for ${notification.options.userName}'s and ${notification.options.numberOfUsers} other member's ${notification.options.reviewName}.`,
          )
        : i18n._(
            t`Provide input for ${notification.options.userName}'s ${notification.options.reviewName}.`,
          ),
  },

  /* old type, leave it, because we still have some notification in DB with this type */
  REVIEW_CONVERSATION_REMINDER_COACH: {
    key: 'review_conversation_reminder_coach',
    text: (notification, i18n) =>
      i18n._(
        t`You are reminded to schedule a conversation with ${notification.options.userName} to discuss ${notification.options.reviewName}`,
      ),
  },
  REVIEW_CONVERSATION_SET_DATE: {
    key: 'review_conversation_set_date',
    text: (notification, i18n) =>
      i18n._(t`The review: ${notification.options.reviewName} has been scheduled.`),
  },
  REVIEW_CONVERSATION_UPDATE_DATE: {
    key: 'review_conversation_update_date',
    text: (notification, i18n) =>
      i18n._(t`The date for the review: ${notification.options.reviewName} is updated.`),
  },
  REVIEW_DIGITAL_SIGN_REMINDER: {
    key: 'review_digital_sign_reminder',
    text: (notification, i18n) =>
      i18n._(t`Reminder: Complete your ${notification.options.reviewName} `),
  },
  REVIEW_DIGITAL_SIGN: {
    key: 'review_digital_sign',
    text: (notification, i18n) =>
      notification.options.userName
        ? i18n._(
            t`Complete your ${notification.options.reviewName} with ${notification.options.userName} `,
          )
        : i18n._(t`Complete your ${notification.options.reviewName} `),
  },
  ACTIVITY_ASSIGNED: {
    key: 'activity_assigned',
    text: (notification, i18n) =>
      i18n._(
        t`You have been assigned a new learning activity: ${notification.options.activityName} `,
      ),
  },
  ACTIVITY_USER_COMPLETED: {
    key: 'activity_user_completed',
    text: (notification, i18n) =>
      i18n._(
        t`${notification.options.fromUser} has completed activity ${notification.options.activityName} `,
      ),
  },
  GOAL_TASK_REMINDER: {
    key: 'goal_task_reminder',
    text: (_notification, i18n) => i18n._(t`It is time to update your goals`),
  },
  GOAL_TASK_DEADLINE_REMINDER: {
    key: 'goal_task_deadline_reminder',
    text: (_notification, i18n) => i18n._(t`Reminder: it is time to update your goals`),
  },
  ASK_FEEDBACK_TASK_REMINDER: {
    key: 'ask_feedback_task_reminder',
    text: (_notification, i18n) => i18n._(t`Ask someone for feedback`),
  },
  ASK_FEEDBACK_TASK_DEADLINE_REMINDER: {
    key: 'ask_feedback_task_deadline_reminder',
    text: (_notification, i18n) => i18n._(t`Reminder: ask someone for feedback`),
  },
  GIVE_FEEDBACK_TASK_REMINDER: {
    key: 'give_feedback_task_reminder',
    text: (_notification, i18n) => i18n._(t`Give someone feedback or a compliment`),
  },
  GIVE_FEEDBACK_TASK_DEADLINE_REMINDER: {
    key: 'give_feedback_task_deadline_reminder',
    text: (_notification, i18n) => i18n._(t`Reminder: give someone feedback or a compliment`),
  },
  SURVEY_REMINDER: {
    key: 'survey_reminder',
    text: (notification, i18n) =>
      notification.options.surveyType === SURVEY_TYPE.TRADITIONAL
        ? i18n._(t`You are invited to participate in ${notification.options.surveyName} `)
        : i18n._(t`Let us know what you think in your ${notification.options.surveyName} `),
  },
  COACH_CALIBRATED_INPUT_SHARED: {
    key: 'coach_calibrated_input_shared',
    text: (notification, i18n) => {
      const { numberOfUsers, reviewName, firstUsersFullName } = notification.options;

      if (numberOfUsers > 0) {
        return i18n._(
          t`The "${reviewName}" has been shared by the admin.Your input for ${firstUsersFullName} and ${numberOfUsers} others has been shared.`,
        );
      }

      return i18n._(
        t`The "${reviewName}" has been shared by the admin.Your input for ${firstUsersFullName} has been shared.`,
      );
    },
  },
  CALENDAR_INTEGRATION_DISCONNECTED: {
    key: NOTIFICATION_TYPES_CONST.CALENDAR_INTEGRATION_DISCONNECTED,
    text_alt: (i18n) =>
      i18n._(
        t`Warning: your calendar integration has been deactivated because your calendar email account did not match your Learned email address. Please connect your calendar again.`,
      ),
  },
  TRIAL_EXPIRED: {
    key: 'trial_expired',
    text: (notification, i18n) =>
      i18n._(t`Free trial for "${notification?.options?.productName}" product ended.`),
  },
};

export const COMPANY_MODULE_SECTIONS = {
  PRIVACY: { key: 'privacy', title: (i18n) => i18n._(t`Privacy`) },
  GENERAL: { key: 'general', title: (i18n) => i18n._(t`General`) },
  REVIEWS: { key: 'reviews', title: (i18n) => i18n._(t`Reviews`) },
  FEEDBACK: { key: 'feedback', title: (i18n) => i18n._(t`Feedback`) },
  CONVERSATIONS: { key: 'conversations', title: (i18n) => i18n._(t`1:1 & team meetings`) },
  GOALS: { key: 'goals', title: (i18n) => i18n._(t`Goals`) },
  LEARNING: { key: 'learning', title: (i18n) => i18n._(t`Learning`) },
  SURVEY: { key: 'survey', title: (i18n) => i18n._(t`Surveys`) },
  CAREER: { key: 'career', title: (i18n) => i18n._(t`Career`) },
  ADMINDASHBOARD: { key: 'adminDB', title: (i18n) => i18n._(t`Admin dashboard`) },
  REPORTS: { key: 'reports', title: (i18n) => i18n._(t`Report`) },
};

// TODO remove after learned 3.0 is done
// @deprecated use company.products
export const COMPANY_MODULES = {
  REVIEWS: {
    key: 'reviews',
    title: (i18n) => i18n._(t`Reviews`),
    description: (i18n) => i18n._(t`Enable the review module`),
    section: COMPANY_MODULE_SECTIONS.REVIEWS.key,
  },
  COACH_CREATE_REVIEW: {
    key: 'coachCreateReview',
    title: (i18n) => i18n._(t`Coach reviews`),
    description: (i18n) => i18n._(t`Enable coaches to schedule review's`),
    section: COMPANY_MODULE_SECTIONS.REVIEWS.key,
  },
  IS_SHOW_ROLES_IN_REVIEW: {
    key: 'isShowRolesInReview',
    title: (i18n) => i18n._(t`Roles`),
    description: (i18n) => i18n._(t`Show all current roles of the employee in the Review module`),
    section: COMPANY_MODULE_SECTIONS.REVIEWS.key,
  },
  RTFEEDBACKS: {
    key: 'rtfeedbacks',
    title: (i18n) => i18n._(t`Real-time feedback`),
    description: (i18n) => i18n._(t`Enable employees to ask feedback to other employees`),
    section: COMPANY_MODULE_SECTIONS.FEEDBACK.key,
  },
  REVIEW_QUICK_SELECT: {
    key: 'reviewQuickSelect',
    title: (i18n) => i18n._(t`Quick select`),
    description: (i18n) => i18n._(t`Enable users to quickly select other employees`),
    section: COMPANY_MODULE_SECTIONS.PRIVACY.key,
  },
  CONVERSATIONS: {
    key: 'conversations',
    title: (i18n) => i18n._(t`1:1 & team meetings`),
    description: (i18n) => i18n._(t`Enable the 1:1 & team meetings module`),
    section: COMPANY_MODULE_SECTIONS.CONVERSATIONS.key,
  },
  GOALS: {
    key: 'goals',
    title: (i18n) => i18n._(t`Goals`),
    description: (i18n) => i18n._(t`Enable the goal module`),
    section: COMPANY_MODULE_SECTIONS.GOALS.key,
  },
  USER_REVIEW_CONVERSATION: {
    key: 'isUserCanCreateConversation',
    title: (i18n) => i18n._(t`User conversations`),
    description: (i18n) => i18n._(t`Allow user to create conversations`),
    section: COMPANY_MODULE_SECTIONS.REVIEWS.key,
  },
  GOALS_IS_TEAM: {
    key: 'goalsIsTeam',
    title: (i18n) => i18n._(t`Team goals`),
    description: (i18n) => i18n._(t`Enable team goals`),
    section: COMPANY_MODULE_SECTIONS.GOALS.key,
  },
  GOALS_IS_ORGANISATION: {
    key: 'goalsIsOrganisation',
    title: (i18n) => i18n._(t`Organisation goals`),
    description: (i18n) => i18n._(t`Enable organisation goals`),
    section: COMPANY_MODULE_SECTIONS.GOALS.key,
  },
  LEARNING: {
    key: 'learning',
    title: (i18n) => i18n._(t`Learning`),
    description: (i18n) => i18n._(t`Enable the learning module`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_CREATE_ACTIVITY_USER: {
    key: 'learningCreateActivityUser',
    title: (i18n) => i18n._(t`Learning`),
    description: (i18n) => i18n._(t`Allow members to create activities`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_CREATE_ACTIVITY_COACH: {
    key: 'learningCreateActivityCoach',
    title: (i18n) => i18n._(t`Learning`),
    description: (i18n) => i18n._(t`Allow coaches to create activities`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_CREATE_PATH_USER: {
    key: 'learningCreatePathUser',
    title: (i18n) => i18n._(t`Learning`),
    description: (i18n) => i18n._(t`Allow members to create paths`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_CREATE_PATH_COACH: {
    key: 'learningCreatePathCoach',
    title: (i18n) => i18n._(t`Learning`),
    description: (i18n) => i18n._(t`Allow coaches to create paths`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_ACTIVITIES: {
    key: 'learningActivities',
    title: (i18n) => i18n._(t`Learning library`),
    description: (i18n) => i18n._(t`Enable the free Learned content items`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  CAREER: {
    key: 'career',
    title: (i18n) => i18n._(t`Career module`),
    description: (i18n) =>
      i18n._(
        t`Enable the career module. Provide employees insight in their skill gap with their current role`,
      ),
    section: COMPANY_MODULE_SECTIONS.CAREER.key,
  },
  USER_ROLE_ASSIGN: {
    key: 'userRoleAssign',
    title: (i18n) => i18n._(t`Assign role`),
    description: (i18n) =>
      i18n._(t`Allow users to assign themselves and remove themselves from current roles`),
    section: COMPANY_MODULE_SECTIONS.CAREER.key,
  },
  AMBITIONS: {
    key: 'ambitions',
    title: (i18n) => i18n._(t`Ambitions`),
    description: (i18n) =>
      i18n._(
        t`Enable employees to set career ambitions and analyse the skill gap with their ambition`,
      ),
    section: COMPANY_MODULE_SECTIONS.CAREER.key,
  },
  LEARNING_USER_DASHBOARD: {
    key: 'learningUserDashboard',
    title: (i18n) => i18n._(t`Learning DB`),
    description: (i18n) => i18n._(t`Enable the learning block on the member dashboard`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  LEARNING_CAREER: {
    key: 'learningCareer',
    title: (i18n) => i18n._(t`Career`),
    description: (i18n) => i18n._(t`Show progress of relevant learning activities for this role`),
    section: COMPANY_MODULE_SECTIONS.LEARNING.key,
  },
  SURVEY: {
    key: 'survey',
    title: (i18n) => i18n._(t`Surveys`),
    description: (i18n) => i18n._(t`Enable the survey module`),
    section: COMPANY_MODULE_SECTIONS.SURVEY.key,
  },
  ADMIN_ADMIN_DASHBOARD: {
    key: 'adminDashboard',
    title: (i18n) => i18n._(t`Dashboard`),
    description: (i18n) => i18n._(t`Show the admin dashboard`),
    section: COMPANY_MODULE_SECTIONS.ADMINDASHBOARD.key,
  },
  QUICK_START: {
    key: 'quickStart',
    title: (i18n) => i18n._(t`Quick start`),
    description: (i18n) => i18n._(t`Show the quick start instructions`),
    section: COMPANY_MODULE_SECTIONS.ADMINDASHBOARD.key,
  },
  ADMIN_SKILL_COVERAGE: {
    key: 'adminSkillCoverage',
    title: (i18n) => i18n._(t`Skill coverage`),
    description: (i18n) => i18n._(t`Show the average skill coverage`),
    section: COMPANY_MODULE_SECTIONS.ADMINDASHBOARD.key,
  },
  ADMIN_PERFORMANCE: {
    key: 'adminPerformance',
    title: (i18n) => i18n._(t`Performance`),
    description: (i18n) => i18n._(t`Show the average performance`),
    section: COMPANY_MODULE_SECTIONS.ADMINDASHBOARD.key,
  },
  ADMIN_CONVERSATIONS: {
    key: 'adminConversations',
    title: (i18n) => i18n._(t`Conversations`),
    description: (i18n) => i18n._(t`Show the total number of conversations`),
    section: COMPANY_MODULE_SECTIONS.ADMINDASHBOARD.key,
  },
  SKILL_TWO: {
    key: 'skillTwo',
    title: (i18n) => i18n._(t`Skill II`),
    description: (i18n) => i18n._(t`Allows usage of skill II reports`),
    section: COMPANY_MODULE_SECTIONS.REPORTS.key,
  },
  DEVELOPMENT_CYCLE: {
    key: 'developmentCycle',
    title: (i18n) => i18n._(t`Development cycle`),
    description: (i18n) =>
      i18n._(t`Enable the preview of the development cycle on the user dashboard`),
    section: COMPANY_MODULE_SECTIONS.GENERAL.key,
  },
};

export const RATE_TYPES = {
  RATE: 'rate',
  LEVEL: 'level',
};

export const INTEGRATIONS_CONN_ERROR_MSG = t`There seems to be a problem with your integration. Please check in the integration settings if it still has a valid connection.`;

export const SDWORX_URLS = {
  None: {
    key: 'none',
    name: (i18n) => i18n._(t`None`),
  },
  CTBPS: {
    key: 'ctbps',
    name: (i18n) => i18n._(t`https://api.ctbps.nl/v3`),
  },
  HELIPORTAL: {
    key: 'heliportal',
    name: (i18n) => i18n._(t`https://api.heliportal.nl/v1`),
  },
};

export const LOGS_CATEGORIES = {
  USER: {
    key: 'user',
    label: (i18n) => i18n._(t`User`),
  },
};

export const LOGS_FIXED_ADMINS = {
  AUTO: {
    key: 'auto',
    label: (i18n) => i18n._(t`Automatically`),
  },
};

const LOGS_KEYS = {
  INVITE_USER: 'inviteUser',
  REMOVE_USER_FROM_COMPANY: 'removeUserFromCompany',
  UPDATE_USER_USERNAME: 'updateUserUsername',
  UPDATE_USER_FIRST_NAME: 'updateUserFirstName',
  UPDATE_USER_LAST_NAME: 'updateUserLastName',
  UPDATE_USER_STATUS: 'updateUserStatus',
  ADD_USER_TO_TEAM: 'addUserToTeam',
  REMOVE_USER_FROM_TEAM: 'removeUserFromTeam',
  ADD_USER_AS_TEAM_COACH: 'addUserAsTeamCoach',
  REMOVE_USER_AS_TEAM_COACH: 'removeUserAsTeamCoach',
  ADD_USER_TO_JOB_PROFILE: 'addUserToJobProfile',
  REMOVE_USER_FROM_JOB_PROFILE: 'removeUserFromJobProfile',
  UPDATE_USER_INTEGRATION_CONNECTION: 'updateConnectionToIntegration',
  CREATE_TEAM: 'createTeam',
  UPDATE_TEAM_NAME: 'updateTeamName',
  DELETE_TEAM: 'deleteTeam',
  CREATE_JOB_PROFILE: 'createJobProfile',
  UPDATE_JOB_PROFILE_NAME: 'updateJobProfileName',
  DELETE_JOB_PROFILE: 'deleteJobProfile',
};

export const LOGS_TYPES = {
  USERNAME: {
    key: LOGS_KEYS.UPDATE_USER_USERNAME,
    label: (i18n) => i18n._(t`Username`),
  },
  FIRSTNAME: {
    key: LOGS_KEYS.UPDATE_USER_FIRST_NAME,
    label: (i18n) => i18n._(t`First name`),
  },
  LASTNAME: {
    key: LOGS_KEYS.UPDATE_USER_LAST_NAME,
    label: (i18n) => i18n._(t`Last Name`),
  },
  USER_INTEGRATION: {
    key: LOGS_KEYS.UPDATE_USER_INTEGRATION_CONNECTION,
    label: (i18n) => i18n._(t`Integration`),
  },
  USER_REMOVED: {
    key: LOGS_KEYS.REMOVE_USER_FROM_COMPANY,
    label: (i18n) => i18n._(t`Removed`),
  },
  USER_INVITED: {
    key: LOGS_KEYS.INVITE_USER,
    label: (i18n) => i18n._(t`Invited`),
  },
};

export const LOGS_DESCRIPTIONS = {
  INVITE_USER: {
    key: LOGS_KEYS.INVITE_USER,
    label: (i18n, { data = {} }) => i18n._(t`Invited member ${data.newValue}`),
  },
  REMOVE_USER_FROM_COMPANY: {
    key: LOGS_KEYS.REMOVE_USER_FROM_COMPANY,
    label: (i18n, { data = {} }) => i18n._(t`Removed member ${data.newValue}`),
  },
  USERNAME_CHANGE: {
    key: LOGS_KEYS.UPDATE_USER_USERNAME,
    label: (i18n, { data = {} }) =>
      i18n._(t`Updated username from ${data.oldValue} to ${data.newValue}`),
  },
  FIRST_NAME_CHANGE: {
    key: LOGS_KEYS.UPDATE_USER_FIRST_NAME,
    label: (i18n, { data = {} }) => {
      const oldValue = data.oldValue ? data.oldValue : i18n._(t`<EMPTY>`);
      return i18n._(t`Updated First name from ${oldValue} to ${data.newValue}`);
    },
  },
  LAST_NAME_CHANGE: {
    key: LOGS_KEYS.UPDATE_USER_LAST_NAME,
    label: (i18n, { data = {} }) => {
      const oldValue = data.oldValue ? data.oldValue : i18n._(t`<EMPTY>`);
      return i18n._(t`Updated Last name from ${oldValue} to ${data.newValue}`);
    },
  },
  USER_INTEGRATION_CHANGE: {
    key: LOGS_KEYS.UPDATE_USER_INTEGRATION_CONNECTION,
    label: (i18n, { data = {} }) => {
      if (data.newValue) {
        return i18n._(t`Connected to integration: ${data.newValue}`);
      }
      if (data.oldValue) {
        return i18n._(t`Disconnected from integration: ${data.oldValue}`);
      }
      return '';
    },
  },
};

export const DISABLED_DELETE_INTEGRATIONS = [INTEGRATIONS.NMBRS, INTEGRATIONS.SDWORX];
export const DISABLED_TEAMS_INTEGRATIONS = [INTEGRATIONS.AZURE_AD];
export const DISABLED_ROLES_INTEGRATIONS = [INTEGRATIONS.AZURE_AD];

export const SYNC_MEMBERS_DESCRIPTIONS = {
  STATUS_CHANGE: {
    key: 'status',
    label: (i18n) => i18n._(t`Status`),
    description: (i18n, item) => {
      if (item.oldValue && !item.newValue) {
        return i18n._(t`From active to inactive`);
      }
      return i18n._(t`From inactive to active`);
    },
  },
  FIRST_NAME_CHANGE: {
    key: 'firstName',
    label: (i18n) => i18n._(t`First name`),
    description: (i18n, item) => i18n._(t`From ${item.oldValue} to ${item.newValue}`),
  },
  LAST_NAME_CHANGE: {
    key: 'lastName',
    label: (i18n) => i18n._(t`Last name`),
    description: (i18n, item) => i18n._(t`From ${item.oldValue} to ${item.newValue}`),
  },
  USERNAME_CHANGE: {
    key: 'email',
    label: (i18n) => i18n._(t`Username`),
    description: (i18n, item) => i18n._(t`From ${item.oldValue} to ${item.newValue}`),
  },
  TEAM_REMOVED: {
    key: 'teamsRemoved',
    label: (i18n) => i18n._(t`Team`),
    description: (i18n, item) =>
      i18n._(t`Removed from team${item.teams.length > 1 ? 's' : ''}: ${item.teams.join(', ')}`),
  },
  TEAM_ADDED: {
    key: 'teamsAdded',
    label: (i18n) => i18n._(t`Team`),
    description: (i18n, item) =>
      i18n._(t`Added to team${item.teams.length > 1 ? 's' : ''}: ${item.teams.join(', ')}`),
  },
  TEAM_COACH_REMOVED: {
    key: 'coachTeamsRemoved',
    label: (i18n) => i18n._(t`Coach team`),
    description: (i18n, item) =>
      i18n._(
        t`Removed as coach from team${item.teams.length > 1 ? 's' : ''}: ${item.teams.join(', ')}`,
      ),
  },
  TEAM_COACH_ADDED: {
    key: 'coachTeamsAdded',
    label: (i18n) => i18n._(t`Coach team`),
    description: (i18n, item) =>
      i18n._(
        t`Added as coach to team${item.teams.length > 1 ? 's' : ''}: ${item.teams.join(', ')}`,
      ),
  },
  ROLES_ADDED: {
    key: 'rolesAdded',
    label: (i18n) => i18n._(t`Roles`),
    description: (i18n, item) =>
      i18n._(t`Added to role${item.roles.length > 1 ? 's' : ''}: ${item.roles.join(', ')}`),
  },
  ROLES_REMOVED: {
    key: 'rolesRemoved',
    label: (i18n) => i18n._(t`Roles`),
    description: (i18n, item) =>
      i18n._(t`Removed from role${item.roles.length > 1 ? 's' : ''}: ${item.roles.join(', ')}`),
  },
};

export const USER_INTEGRATION_TYPES = {
  azure: 'azure',
  google: 'google',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const SORT_BY = {
  LAST_UPDATED: {
    id: 'last_updated',
    label: (i18n) => i18n._(t`Last updated`),
  },
  A_Z: {
    id: 'a_z',
    label: (i18n) => i18n._(t`A-Z Alphabetic`),
  },
  Z_A: {
    id: 'z_a',
    label: (i18n) => i18n._(t`Z-A Alphabetic`),
  },
  CREATED_NEW_OLD: {
    id: 'created_new_old',
    label: (i18n) => i18n._(t`Created new - old`),
  },
  CREATED_OLD_NEW: {
    id: 'created_old_new',
    label: (i18n) => i18n._(t`Created old - New`),
  },
};

export const KPI_TYPES = {
  NOMINATED_PEERS: {
    id: 'nominatePercentage',
    label: (i18n) => i18n._(t`Nominated Peer(s)`),
    legend: (i18n) => i18n._(t`Nominated Peer(s)`),
    isPercentage: true,
  },
  AVG_NOMINATED_PEERS: {
    id: 'avgPeersNominated',
    label: (i18n) => i18n._(t`Avg. Nominated Peer(s)`),
    legend: (i18n) => i18n._(t`Avg. Nominated Peer(s)`),
    isPercentage: false,
  },
  AVG_NOMINATED_COACHES: {
    id: 'avgCoachesNominated',
    label: (i18n) => i18n._(t`Avg. Nominated Coach(es)`),
    legend: (i18n) => i18n._(t`Avg. Nominated Coach(es)`),
    isPercentage: false,
  },
  SELF_REVIEW_COMPLETED: {
    id: 'selfReviewPercentage',
    label: (i18n) => i18n._(t`Self Reviews Completed`),
    legend: (i18n) => i18n._(t`Self Reviews Completed`),
    isPercentage: true,
  },
  PEER_REVIEWS_COMPLETED: {
    id: 'peerReviewPercentage',
    label: (i18n) => i18n._(t`Peer Reviews Completed`),
    legend: (i18n) => i18n._(t`Peer Reviews Completed`),
    isPercentage: true,
  },
  COACH_REVIEWS_COMPLETED: {
    id: 'coachReviewPercentage',
    label: (i18n) => i18n._(t`Coach Reviews Completed`),
    legend: (i18n) => i18n._(t`Coach Reviews Completed`),
    isPercentage: true,
  },
};

export const TEMPLATE_TYPES = {
  CONVERSATION_TEMPLATES: 'conversation',
  REVIEW_TEMPLATES: 'review',
};

export const REVIEW_QUESTIONS_TABS = {
  OWN_ANSWER: { label: t`Your answer`, key: 'ownAnswer' },
  SELF_REVIEW: { label: t`Self review`, key: 'selfReview' },
  PEER_REVIEW: { label: t`Peer review`, key: 'peerReview' },
  COACH_REVIEW: { label: t`Coach review`, key: 'coachReview' },
};

export const CONVERSATION_REPEAT_TYPES = {
  NO_REPEAT: 'no-repeat',
  WEEK: 'week',
  YEAR: 'year',
  CUSTOM: 'custom',
};
