import React from 'react';

import { Trans } from '@lingui/macro';

import { StyledDivider } from '~/components/GoalDashboardModal/design';
import { IPopulatedReviewTask } from '~/pages/ReviewGiveFeedback/types';

import { AdvancedSettingsSection } from './AdvancedSettingsSection';
import { Title, Form } from './design';
import { NameSection } from './NameSection';
import { OwnersSection } from './OwnersSection';
import { PrivacyAndDeadlineSection } from './PrivacyAndDeadlineSection';

interface IProps {
  isUpdate: boolean;
  reviewTask?: IPopulatedReviewTask;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StepOne = ({ isUpdate }: IProps) => {
  return (
    <Form>
      <Title>
        <Trans>Goal details</Trans>
      </Title>
      <NameSection />
      <PrivacyAndDeadlineSection />
      <StyledDivider />
      <OwnersSection />
      <AdvancedSettingsSection />
    </Form>
  );
};
