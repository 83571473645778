import React, { useMemo } from 'react';

import { REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { UserAvatar } from '~/components/UserAvatar';

import { Comment, Rating, User } from './FocusAreaComments.design';

import { Comments, CommentsContainer } from '../../Questions/design';

import type {
  IPartialReviewRating,
  IQuestionCustomSkillData,
  IQuestionSkillCategoryData,
} from '../../../types';

function FocusAreaComments({
  focusAreaQuestion,
}: {
  focusAreaQuestion?:
    | IQuestionCustomSkillData['subQuestions'][0]
    | IQuestionSkillCategoryData['subQuestions'][0];
}) {
  const allRatings = useMemo(() => {
    return [
      ...(focusAreaQuestion?.otherCoachRatings ?? []),
      ...(focusAreaQuestion?.otherPeerRatings ?? []),
      focusAreaQuestion?.otherSelfRating,
    ].filter((rating) => rating && rating.comment) as IPartialReviewRating[];
  }, [focusAreaQuestion]);

  return (
    <>
      {allRatings.length > 0 && (
        <CommentsContainer>
          <Trans>All comments</Trans>
          <Comments>
            {allRatings?.map((rating) => (
              <Rating key={rating.id}>
                <User>
                  <UserAvatar userId={rating.createdBy.id!} />{' '}
                  <span>
                    {rating.type === REVIEW_RATING_TYPE.COACH && (
                      <>
                        (<Trans>coach</Trans>)
                      </>
                    )}
                    {(rating.type === REVIEW_RATING_TYPE.PEER ||
                      rating.type === REVIEW_RATING_TYPE.PEER_EMAIL) && (
                      <>
                        (<Trans>peer</Trans>)
                      </>
                    )}
                    {rating.type === REVIEW_RATING_TYPE.SELF && (
                      <>
                        (<Trans>self review</Trans>)
                      </>
                    )}
                  </span>
                </User>
                <Comment>{rating.comment!}</Comment>
              </Rating>
            ))}
          </Comments>
        </CommentsContainer>
      )}
    </>
  );
}

export { FocusAreaComments };
