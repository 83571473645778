import React, { useEffect } from 'react';

import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import { IQuestionForm, IRatingLabel } from '~/pages/ReviewThemeSetup/types';

import { QuestionDescription } from './design';

import { useLanguageState } from '~/hooks/useLanguageState';

import { Options } from '../Options';

interface IProps {
  formMethods: UseFormReturn<IQuestionForm>;
  ratingLabels: IRatingLabel[];
}

const SkillCategory = ({ formMethods, ratingLabels }: IProps) => {
  const languageState = useLanguageState();
  const { setValue } = formMethods;
  const formData = formMethods.getValues();

  useEffect(() => {
    setValue('settings.skillCategory', formData.type?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QuestionDescription>
        <Trans>
          A separate question will be generated for each skill or KPI of the selected category{' '}
          <span>{formData.type?.value}</span> in the job profile of the member
        </Trans>
      </QuestionDescription>
      <Options
        languageState={languageState}
        formMethods={formMethods}
        ratingLabels={ratingLabels}
      />
    </>
  );
};

export { SkillCategory };
