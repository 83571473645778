import React, { useCallback, useEffect } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { IconOld } from '~/components/IconOld';
import type { IconNames } from '~/components/IconOld';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';
import { Errors } from '~/pages/ReviewTemplateSetupNew/validations';

import {
  Content,
  EqualizeButton,
  Form,
  IconPreview,
  InputWrapper,
  NameContainer,
  Percentage,
  RowHeader,
  StyledFooter,
  StyledInput,
  StyledMarker,
  SubTitle,
  ThemeWeightRow,
  ThemeWeightsContainer,
  Title,
  ToggleContainer,
  WeightTotal,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ITheme, IGeneralForm } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

export interface IThemeWeightProps {
  formMethods: UseFormReturn<IGeneralForm>;
  themesMap: Record<string, ITheme>;
  sectionState: ISectionState;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  autosave: () => void;
  equalizeWeights: () => void;
}

const ThemeWeights = ({
  formMethods,
  themesMap,
  sectionState,
  onSubmit,
  autosave,
  equalizeWeights,
}: IThemeWeightProps) => {
  const { i18n } = useLingui();
  const {
    register,
    control,
    trigger,
    formState: { errors },
    watch,
    setValue,
  } = formMethods;
  const { triedToSubmit, setCurrentSection } = sectionState;
  const getMultiLangString = useMultiLangString();
  const themeWeightsFieldArray = useFieldArray({
    name: 'themeWeights',
    control,
  });
  const themeWeightsWatch = useWatch({ control, name: 'themeWeights' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateTemplate = useCallback(
    debounce(() => autosave(), 10_000),
    [],
  );

  useEffect(() => {
    const currentThemes = themeWeightsFieldArray.fields.map((field) => field.themeId);
    const themeIds = Object.keys(themesMap);
    const themeIntersection = intersection(currentThemes, themeIds);
    const hasNewTheme =
      themeIntersection.length !== themeIds.length ||
      themeIntersection.length !== currentThemes.length;
    if (hasNewTheme) {
      equalizeWeights();
      autosave();
    }

    // eslint-disable-next-line
  }, [themesMap]);

  const createLabel = (missingAmount: number): string => {
    if (missingAmount > 0) {
      return `${missingAmount.toFixed(2)}% ${i18n._(t`over`)}`;
    } else {
      return `${(missingAmount * -1).toFixed(2)}% ${i18n._(t`short`)}`;
    }
  };

  const currentThemeTotal = themeWeightsWatch.reduce((acc, field) => acc + field.weight, 0);
  const missingAmount = currentThemeTotal - 100;

  const goBack = () => setCurrentSection(1);

  return (
    <Form onSubmit={onSubmit}>
      <Title>
        <Trans>Theme Weight</Trans>
      </Title>
      <SubTitle>
        <Trans>
          Determine the weight of the themes for inclusion in the reporting. By default, each theme
          weighs equally.
        </Trans>
      </SubTitle>
      <Content customizing={watch('isCustomWeight')}>
        <ToggleContainer>
          <Switch
            onChange={() => setValue('isCustomWeight', !watch('isCustomWeight'))}
            checked={watch('isCustomWeight')}
          />
          <Trans>Customise weight per theme</Trans>
        </ToggleContainer>
        {watch('isCustomWeight') && (
          <ThemeWeightsContainer>
            <ThemeWeightRow>
              <RowHeader>
                <Trans>Theme</Trans>
              </RowHeader>
              <RowHeader>
                <Trans>Weighting</Trans>
              </RowHeader>
            </ThemeWeightRow>
            {themeWeightsFieldArray.fields.map((f, index) => {
              const theme = themesMap[f.themeId];
              const icon = (theme.icon as IconNames) ?? 'Chatbubbles';
              const colors = (theme.iconColor ?? '#f7f9ff-#ebf1fe').split('-');
              const registeredField = register(`themeWeights.${index}.weight`, {
                valueAsNumber: true,
              });
              const fieldError =
                Object.keys(errors.themeWeights || {})
                  ?.map?.((key) => `themeWeights.${key}.weight`)
                  .find((name) => name === registeredField.name) !== undefined;

              return (
                <ThemeWeightRow key={f.id}>
                  <NameContainer>
                    <IconPreview colors={colors}>
                      <IconOld name={icon} width={23} height={23} />
                    </IconPreview>
                    {getMultiLangString(theme.name)}
                  </NameContainer>
                  <InputWrapper>
                    <StyledInput
                      error={fieldError && triedToSubmit}
                      type="number"
                      inputMode="numeric"
                      height="38px"
                      width="54px"
                      {...registeredField}
                      onChange={async (e) => {
                        await registeredField.onChange(e);
                        await trigger('themeWeights');
                        debounceUpdateTemplate();
                      }}
                      onBlur={async (e) => {
                        await registeredField.onBlur(e);
                        await trigger('themeWeights');
                        autosave();
                      }}
                      step="0.01"
                    />
                    <Percentage>%</Percentage>
                  </InputWrapper>
                </ThemeWeightRow>
              );
            })}
            <ThemeWeightRow>
              <EqualizeButton type="button" onClick={() => equalizeWeights()}>
                <IconOld name="Arrows" width={16} height={16} className="icon" />
                <Trans>Make all themes even</Trans>
              </EqualizeButton>
              <WeightTotal
                error={
                  errors.themeWeights?.root?.message === Errors.themeWeightsTotalIncorrect &&
                  triedToSubmit
                }
              >
                {!isNaN(currentThemeTotal) && parseFloat(currentThemeTotal.toFixed(2))}%
                {errors.themeWeights?.root?.message === Errors.themeWeightsTotalIncorrect &&
                  triedToSubmit &&
                  !isNaN(missingAmount) && (
                    <StyledMarker
                      label={createLabel(missingAmount)}
                      backgroundColor={COLORS.ERROR}
                    />
                  )}
              </WeightTotal>
            </ThemeWeightRow>
          </ThemeWeightsContainer>
        )}
      </Content>
      <StyledFooter>
        <Button
          label={i18n._(t`Back`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.TEXT_PRIMARY}
          onClick={goBack}
          icon={ICONS.BACK}
          iconSize={ICON_SIZES.SMALL}
        />
        <Button
          label={i18n._(t`Publish`)}
          size={ButtonSize.MEDIUM}
          variant={ButtonVariant.PRIMARY}
          type="submit"
        />
      </StyledFooter>
    </Form>
  );
};

export { ThemeWeights };
