import React from 'react';

export enum BANNER_TYPES {
  INFO = 'info',
  DELETE = 'delete',
}

export interface IBannerProps {
  type?: BANNER_TYPES;
  actions?: React.ReactNode;
  title?: string;
  subTitle?: string;
}
