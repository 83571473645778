import React, { useState } from 'react';

import { SkillMatrixCommon } from './SkillMatrixCommon';

import { SkillCreateModal } from '../SkillCreateModal';

const EditSkillMatrix = () => {
  const [showSkillCreateModal, setShowSkillCreateModal] = useState(false);
  const onSkillCreate = () => {
    setShowSkillCreateModal(true);
  };
  return (
    <>
      <SkillMatrixCommon expanded={true} isEditable={true} onSkillCreate={onSkillCreate} />
      {showSkillCreateModal && <SkillCreateModal onClose={() => setShowSkillCreateModal(false)} />}
    </>
  );
};

export { EditSkillMatrix };
