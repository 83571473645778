import React, { ReactNode } from 'react';

import { Icon, ICONS } from '~/components/Icon';
import { UserAvatar } from '~/components/UserAvatar';
import { getGradient } from '~/pages/ReviewGiveFeedback/utils';

import { FocusAreaContainer, Score, ScoreText, UserAvatarName } from './FocusAreaAnswers.design';

import type { IPartialReviewRating } from '../../../types';
import type { IUser } from '@learned/types';

function FocusArea({
  rating,
  total,
  children,
}: {
  rating: IPartialReviewRating;
  total: number;
  children?: ReactNode;
}) {
  return (
    <FocusAreaContainer>
      <UserAvatarName>
        {rating.createdBy.id ? (
          <UserAvatar userId={rating.createdBy.id} />
        ) : (
          <UserAvatar user={{ email: rating.createdBy.email! } as IUser} />
        )}{' '}
        <span>({children})</span>
      </UserAvatarName>
      <Score gradient={rating.answer ? getGradient(rating.answer as number, total) : undefined}>
        <Icon icon={ICONS.QUESTION_STAR} />
        <ScoreText>
          <span>{rating.answer ?? '-'}</span>/{total}
        </ScoreText>
      </Score>
    </FocusAreaContainer>
  );
}

export { FocusArea };
