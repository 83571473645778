import { joiResolver } from '@hookform/resolvers/joi';
import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import Joi from 'joi';
import { isNumber } from 'lodash';
import isEmpty from 'lodash/isEmpty';

import { IPopulatedUserReview } from '~/pages/ReviewGiveFeedback/types';

const Errors = {
  missingAnswer: 'missingAnswer',
  missingComment: 'missingComment',
};

const schema = Joi.object({
  ratings: Joi.when(Joi.ref('shouldValidate'), {
    is: true,
    then: Joi.array()
      .items(
        Joi.object({
          // id is an internal identifier for react hook form, we don't use it
          id: Joi.string().allow(null),
          ratingId: Joi.string().allow(null),
          question: Joi.string().required(),
          isNotApplicable: Joi.boolean().required(),

          answer: Joi.required().custom((value: string, helpers) => {
            const userReview: IPopulatedUserReview = helpers.prefs!.context!.userReview;

            const rating = helpers.state.ancestors[0];
            const relevantQuestion = userReview?.questions?.find(
              (question) => question.id === rating.question,
            );
            let missingAnswer = false;
            switch (relevantQuestion?.type) {
              case REVIEW_QUESTION_TYPES.TEXT:
                if (relevantQuestion.settings.isAnswerObligated) {
                  missingAnswer = isEmpty(rating?.answer);
                } else {
                  missingAnswer = rating?.isNotApplicable === false && isEmpty(rating?.answer);
                }
                break;
              case REVIEW_QUESTION_TYPES.SKILL_CATEGORY:
              case REVIEW_QUESTION_TYPES.CUSTOM_SKILL:
              case REVIEW_QUESTION_TYPES.RATING:
                if (relevantQuestion.settings.isAnswerObligated) {
                  missingAnswer = !isNumber(rating?.answer) || rating!.answer < 1;
                } else {
                  missingAnswer =
                    rating?.isNotApplicable === false &&
                    (!isNumber(rating?.answer) || rating!.answer < 0);
                }
                break;
            }

            if (missingAnswer) {
              throw new Error('Answer is obligated');
            }

            return value;
          }),
          comment: Joi.custom((value: string, helpers) => {
            const userReview: IPopulatedUserReview = helpers.prefs!.context!.userReview;

            const rating = helpers.state.ancestors[0];
            const relevantQuestion = userReview?.questions?.find(
              (question) => question.id === rating.question,
            );
            if (
              isEmpty(value) &&
              (relevantQuestion?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL ||
                relevantQuestion?.type === REVIEW_QUESTION_TYPES.RATING ||
                relevantQuestion?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) &&
              relevantQuestion?.settings?.isCommentsObligated === true
            ) {
              throw new Error('Comment is obligated');
            }

            return value;
          }),
        }),
      )
      .required(),
    otherwise: Joi.array(),
  }),
  shouldValidate: Joi.boolean(),
});

const resolver = joiResolver(schema);

export { resolver, Errors };
