import React, { useEffect, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { ErrorIconContainer } from '../design';

import type { IReviewCycleForm } from '../types';
import type { IJobProfile, IMultiLangString } from '@learned/types';

interface IErrorIconProps {
  watch: UseFormWatch<IReviewCycleForm>;
  jobs: IJobProfile[];
}

const ErrorIcon = ({ watch, jobs }: IErrorIconProps) => {
  const getMultiLangString = useMultiLangString();
  const [errorMessage, setErrorMessage] = useState('');
  const skills = watch('skills');
  const skillCategories = watch('skillCategories');

  const generateErrorMessage = () => {
    const categorizedSkills = {} as Record<
      string,
      { skillCategory: string; skillCategoryName: IMultiLangString; skills: Array<string> }
    >;

    Object.values(skillCategories).forEach((skillCategory) => {
      categorizedSkills[skillCategory.id] = {
        skillCategory: skillCategory.id,
        skillCategoryName: skillCategory.name,
        skills: [],
      };
    });

    skills.forEach((skill: any) => {
      const { id, skillCategory } = skill;

      categorizedSkills[skillCategory].skills.push(id);
    });
    const categorizedSkillsArray = Object.values(categorizedSkills);

    let message = '';
    jobs.forEach((job) => {
      const jobSkillIds = job.skills.map((item) => item.skill);
      categorizedSkillsArray.forEach((category) => {
        if (!category.skills.some((skill) => jobSkillIds.includes(skill))) {
          message += `${getMultiLangString(
            job.name,
          )} has no skills in category ${getMultiLangString(category.skillCategoryName)} \n`;
        }
      });
    });

    setErrorMessage(message);
  };

  useEffect(() => {
    generateErrorMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, skills]);

  return (
    <ErrorIconContainer>
      <ToolTip tooltip={errorMessage} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
        <span>
          <Icon icon={ICONS.ERROR_SIGN} size={ICON_SIZES.SMALL} />
        </span>
      </ToolTip>
    </ErrorIconContainer>
  );
};

export { ErrorIcon };
