import React, { useEffect, useState } from 'react';

import { REVIEW_PARTICIPATION_COLUMNS } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { CircularProgressCard, CircularProgressCardProps } from '~/components/CircularProgressCard';

import { fetchReviewParticipationTotal } from '~/services/reviews';
import { COLORS } from '~/styles';

const Wrapper = styled.div<{ $size: number }>`
  max-width: 1000px;
  margin-top: 24px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) => `repeat(${props.$size}, minmax(312px, 484px))`};
`;

type ICell = {
  columnId:
    | REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW
    | REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED
    | REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW;
  value: string;
  name: string;
  isMinimizedView: boolean;
};

interface TotalSectionProps {
  isExpandMode: boolean;
}

// we simulate 3 cells to show spinner while we load data, when we fetch data, this cells will be replaced with real data
const defaultCells = [
  {} as CircularProgressCardProps,
  {} as CircularProgressCardProps,
  {} as CircularProgressCardProps,
];

const TotalSection = ({ isExpandMode }: TotalSectionProps) => {
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const [isLoading, setIsLoading] = useState(true);
  const [cells, setCells] = useState<CircularProgressCardProps[]>(defaultCells);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetchReviewParticipationTotal(reviewId, { signal });
      if (response && !isEmpty(response.data.cells)) {
        const formatedData = response.data.cells.map((cell: ICell) => ({
          progress: Number(cell.value) || 0,
          title: cell.name,
          color: CELL_STYLE[cell.columnId].color,
        }));
        if (isMounted) {
          setCells(formatedData);
        }
      }
      setIsLoading(false);
    };

    fetchData();

    return () => {
      isMounted = false;
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line
  }, [reviewId]);

  const CELL_STYLE = {
    [REVIEW_PARTICIPATION_COLUMNS.SELF_REVIEW]: {
      color: COLORS.REVIEW_SELF,
    },
    [REVIEW_PARTICIPATION_COLUMNS.COACH_REVIEW_PROVIDED]: {
      color: COLORS.REVIEW_COACH,
    },
    [REVIEW_PARTICIPATION_COLUMNS.COMPLETED_REVIEW]: {
      color: COLORS.ACCENT_SUCCESS,
    },
  };

  return (
    <Wrapper $size={cells.length}>
      {cells.map((item, key) => (
        <CircularProgressCard
          key={key}
          {...item}
          isMinimizedView={isExpandMode}
          isLoading={isLoading}
        />
      ))}
    </Wrapper>
  );
};

export { TotalSection };
