import React, { Component } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import intersection from 'lodash/intersection';
import reverse from 'lodash/reverse';
import uniqby from 'lodash/uniqBy';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import DropdownButton from '~/components/DropdownButton';
import HeadingNavigation from '~/components/HeadingNavigation';
import ExpandMoreIcon from '~/components/Icons/ExpandMore';
import WaitModal from '~/components/Modals/WaitModal';
import PaginationBar from '~/components/PaginationBar';
import ReviewOptions from '~/components/ReviewOptions';
import Tabs from '~/components/Tabs';
import { withToasts, TOAST_TYPES } from '~/components/Toast';
import BoxWithBorder from '~/components/UI/BoxWithBorder';
import Divider from '~/components/UI/Divider';
import { Header2 } from '~/components/UI/Typographics/headers';
import BaseLayout from '~/layouts/BaseLayout';
import LoadingPage from '~/pages/LoadingPage';

import Info from './tabs/Info';
import ReportsTab from './tabs/ReportsTab';
import StatusTab from './tabs/Status';

import {
  REQUEST_TYPES,
  REQUEST_STATUSES,
  REVIEW_STAGES,
  REVIEW_REMINDERS,
  SORT_DIRECTION,
} from '~/constants';
import routes from '~/constants/routes';
import getAllUsers from '~/selectors/getAllUsers';
import { getKpis } from '~/services/kpis';
import { sendReviewFeedbackReminder } from '~/services/notifications';
import { sendRequestReminder } from '~/services/requests';
import {
  getFilteredReview,
  getStatistics,
  deleteReview,
  lockReviewStage,
  downloadReviewCSV,
  updateReviewConversationCoaches,
  sendDigitalSignReminder,
  deleteSignatures,
  sendReviewConversationReminder,
  shareCoachesCalibratedReviews,
} from '~/services/reviewsOld';
import * as teamActions from '~/store/teams/actions';
import getUserFullName from '~/utils/getUserFullName';

const PublishingWaitModal = styled(WaitModal)`
  padding-top: 50vh;
`;

const ReviewHeader = styled(BoxWithBorder)`
  margin-bottom: 16px;
  ${Header2} {
    margin: 0;
    padding: 18px 24px;
  }
`;

const ReviewTabs = styled(Tabs)`
  margin-bottom: 0;
  border: none;
`;

const TABS_ENUM = {
  status: 'status',
  reports: 'reports',
  info: 'info',
};

const TABS = [
  { label: t`Status`, key: TABS_ENUM.status },
  { label: t`Reports`, key: TABS_ENUM.reports },
  { label: t`Info`, key: TABS_ENUM.info },
];

async function onCompletePeopleSelection(review, onReviewStageLocked) {
  await lockReviewStage(
    review.id,
    REVIEW_STAGES.NOMINATE,
    !review.lockedStages[REVIEW_STAGES.NOMINATE],
  );
  onReviewStageLocked(REVIEW_STAGES.NOMINATE, !review.lockedStages[REVIEW_STAGES.NOMINATE]);
}

async function onCompleteReviews(review, lockStage, onReviewStageLocked, i18n) {
  const alertString = lockStage
    ? i18n._(
        t`Are you sure you want to lock the review? This will lock all review reports and cancel all outstanding review requests`,
      )
    : i18n._(
        t`Are you sure you want to unlock this stage and the review round? All open feedback requests are currently canceled and will need to be sent again.`,
      );

  if (await confirm(i18n, alertString)) {
    const updatedReview = await lockReviewStage(
      review.id,
      REVIEW_STAGES.FEEDBACK,
      !review.lockedStages[REVIEW_STAGES.FEEDBACK],
    );
    onReviewStageLocked(REVIEW_STAGES.FEEDBACK, updatedReview.lockedStages[REVIEW_STAGES.FEEDBACK]);
    onReviewStageLocked(REVIEW_STAGES.NOMINATE, updatedReview.lockedStages[REVIEW_STAGES.NOMINATE]);
  }
}

const defaultReviewFilters = {
  selectedRoles: [],
  selectedTeams: [],
  sortBy: ['name'],
  sortDirection: SORT_DIRECTION.ASC,
  search: '',
};

const PAGE_SIZE = 25;
const defaultReviewPagination = { skip: 0, limit: PAGE_SIZE, index: 1 };

/**
 * @deprecated since Review 3.0 (delete it before deploy)
 */
class ReviewAdminPage extends Component {
  constructor() {
    super();
    this.state = {
      review: {},
      currentTab: TABS_ENUM.status,
      reviewFilters: defaultReviewFilters,
      reviewPagination: defaultReviewPagination,
      reviewStatistics: null,
      loading: true,
      isShowPublishingModal: false,
      loadingLabel: null,
      kpis: [],
    };
  }

  componentDidMount = async () => {
    const { dispatch } = this.props;
    this.applyFilters().then(() =>
      this.setState({
        currentTab: TABS_ENUM[window.location.hash.substr(1)] || TABS_ENUM.status,
        loading: false,
      }),
    );

    // Update teams, we use them to display/update conversation coaches
    dispatch(teamActions.getTeams());

    // fetch kpis
    const response = await getKpis({ filters: { isDeleted: { $in: [true, false] } } });
    if (response) {
      this.setState({ kpis: response.data[API_RETURN_FIELDS.KPIS] });
    }
  };

  refreshData = async () => this.applyFilters();

  applyFilters = async (filters, pagination = defaultReviewPagination) => {
    const reviewId = this.props.match.params.reviewId;
    const { skip, limit } = pagination;
    const { selectedTeams, selectedRoles, search, sortBy, sortDirection } =
      filters || this.state.reviewFilters;

    let filterUserIds;
    const teams = selectedTeams.map((team) => team.members).flat();
    const roles = selectedRoles.map((role) => role.users).flat();

    if (!selectedTeams.length && !selectedRoles.length) {
      filterUserIds = null;
    } else if (!selectedTeams.length) {
      filterUserIds = roles;
    } else if (!selectedRoles.length) {
      filterUserIds = teams;
    } else {
      filterUserIds = intersection(teams, roles);
    }

    const filtersObject = { users: filterUserIds, search };

    const [review, statistics] = await Promise.all([
      getFilteredReview(
        reviewId,
        {
          join: ['requests', 'ratings'],
          isQuestions: true,
          isSubReviews: true,
          isSubReviewsTotalCount: true,
          isConversations: true,
          isAvg: true,
          isWithCalibrateRatings: true,
          isDontGetSkillsJobProfiles: true,
        },
        {
          filters: filtersObject,
          sort: { field: sortBy[0], direction: sortDirection },
          pagination: { skip, limit },
        },
      ),
      (filters || !this.state.reviewStatistics) && getStatistics(reviewId, filtersObject),
    ]);

    const newState = { review, reviewPagination: pagination };
    if (filters) {
      newState.reviewFilters = filters;
    }
    if (statistics) {
      newState.reviewStatistics = statistics;
    }
    this.setState(newState);
  };

  onReviewStageLocked = (stage, newValue) => {
    this.setState((prevState) => ({
      ...prevState,
      review: {
        ...prevState.review,
        lockedStages: { ...prevState.review.lockedStages, [stage]: newValue },
      },
    }));
  };

  onReviewReminderSend = async (reminderType) => {
    this.setState((prevState) => ({
      ...prevState,
      review: {
        ...prevState.review,
        reminders: { ...prevState.review.reminders, [`${reminderType}`]: moment().format() },
      },
    }));
  };

  deleteThisReview = async () => {
    const { review } = this.state;
    const { i18n, history } = this.props;
    this.closeOptionsModal();
    if (
      await confirm(
        i18n,
        i18n._(t`Are you sure you want to delete this review round? This cannot be undone.`),
      )
    ) {
      this.setState({
        isShowPublishingModal: true,
        loadingLabel: i18n._(t`We are deleting your review`),
      });
      await deleteReview(review.id);
      this.setState({ isShowPublishingModal: false, loadingLabel: null });
      history.push(routes.REVIEWS.build({ role: ROLES.USER }, { hash: 'review-cycles' }));
    }
  };

  onDeleteSignatures = async (reviewId) => {
    const { review } = this.state;
    await deleteSignatures(reviewId);
    this.setState({
      review: {
        ...review,
        subReviews: review.subReviews.map((r) =>
          r.id === reviewId ? { ...r, signatures: [] } : r,
        ),
      },
    });
  };

  goBack = () => {
    const { history } = this.props;
    const url = new URL(window.location.href);
    const from = location.hash.slice(1)
      ? url.searchParams.get('from') + '#' + location.hash.slice(1)
      : url.searchParams.get('from');
    const backPath = !from
      ? from
      : routes.REVIEWS.build({ role: ROLES.USER }, { hash: 'review-cycles' });
    history.push(backPath);
  };

  handleChangeTab = (key) => {
    window.location.hash = key;
    this.setState({ currentTab: key });
  };

  handleOnRequestsCreated = async (newRequests) => {
    const { review } = this.state;

    const reviewId = newRequests[0].target;
    const coaches = newRequests
      .filter((r) => r.type === REQUEST_TYPES.FEEDBACK_FROM_COACH)
      .map((r) => r.toUser);
    this.setState({
      review: {
        ...review,
        subReviews: review.subReviews.map((r) => {
          if (r.id === reviewId) {
            const requests = r.requests || [];
            const allRequests = reverse(uniqby(reverse(requests.concat(newRequests)), 'id'));
            return {
              ...r,
              coaches: (r.coaches || []).concat(coaches),
              requests: allRequests,
            };
          }
          return r;
        }),
      },
    });
  };

  handleRequestCancel = async (request) => {
    const { review } = this.state;

    this.setState({
      review: {
        ...review,
        subReviews: review.subReviews.map((r) =>
          r.id === request.target
            ? {
                ...r,
                coaches:
                  request.type === REQUEST_TYPES.FEEDBACK_FROM_COACH
                    ? (r.coaches || []).filter((id) => id !== request.toUser)
                    : r.coaches,
                requests: r.requests.map((req) =>
                  req.id === request.id ? { ...req, status: REQUEST_STATUSES.CANCELLED.key } : req,
                ),
              }
            : r,
        ),
      },
    });
  };

  updateConversationCoaches = (id, conversationCoaches) => {
    const { review } = this.state;
    updateReviewConversationCoaches(id, conversationCoaches);
    this.setState({
      review: {
        ...review,
        subReviews: review.subReviews.map((r) => (r.id === id ? { ...r, conversationCoaches } : r)),
      },
    });
  };

  handleSendRemind = async (request) => {
    const { i18n, users } = this.props;
    const { review } = this.state;
    const reviewId = request.target;
    const userName = getUserFullName(users[request.toUser]);
    const isCoach = request.type === REQUEST_TYPES.FEEDBACK_FROM_COACH;
    await sendRequestReminder(request.id);
    this.showToastMessage(
      i18n._(t`Reminder sent to ${userName}`),
      i18n._(
        isCoach
          ? t`A reminder has been sent to ${userName} who have not completed his peer review.`
          : t`A reminder has been sent to ${userName} who have not completed his coach review.`,
      ),
    );
    this.setState({
      review: {
        ...review,
        subReviews: review.subReviews.map((r) =>
          r.id === reviewId
            ? {
                ...r,
                requests: r.requests.map((r) =>
                  r.id === request.id ? { ...r, reminderDate: moment().toISOString() } : r,
                ),
              }
            : r,
        ),
      },
    });
  };

  onPageChangeClick = ({ skip, limit, index }) => {
    return this.applyFilters(null, { skip, limit, index });
  };

  getTabContent = () => {
    const { currentTab, review, reviewFilters, reviewStatistics, kpis } = this.state;
    switch (currentTab) {
      case TABS_ENUM.info:
        return;
      case TABS_ENUM.reports:
        return (
          <ReportsTab
            review={review}
            filters={reviewFilters}
            statistics={reviewStatistics}
            applyFilters={this.applyFilters}
            kpis={kpis}
          />
        );
      case TABS_ENUM.status:
      default: {
        return (
          <StatusTab
            review={review}
            filters={reviewFilters}
            statistics={reviewStatistics}
            applyFilters={this.applyFilters}
            onRequestsCreated={this.handleOnRequestsCreated}
            onRequestCancel={this.handleRequestCancel}
            onFeedbackRemind={this.handleSendRemind}
            updateConversationCoaches={this.updateConversationCoaches}
            onDeleteSignatures={this.onDeleteSignatures}
          />
        );
      }
    }
  };

  sendSelfReviewReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendReviewFeedbackReminder(review.id, { isSelf: true });
    await this.onReviewReminderSend(REVIEW_REMINDERS.SELF_REVIEW);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their self review.`,
      ),
    );
  };

  sendNominatePeersReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendReviewFeedbackReminder(review.id, { isNominatePeers: true });
    await this.onReviewReminderSend(REVIEW_REMINDERS.NOMINATE_PEERS);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(t`A reminder has been sent to all employees whom have not nominated peers`),
    );
  };

  sendPeerReviewReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendReviewFeedbackReminder(review.id, { isPeers: true });
    await this.onReviewReminderSend(REVIEW_REMINDERS.PEER_REVIEW);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their peer review.`,
      ),
    );
  };

  sendCoachReviewReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendReviewFeedbackReminder(review.id, { isCoach: true });
    await this.onReviewReminderSend(REVIEW_REMINDERS.COACH_REVIEW);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all employees whom have not completed their coach review.`,
      ),
    );
  };

  sendScheduleConversationReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendReviewConversationReminder(review.id);
    await this.onReviewReminderSend(REVIEW_REMINDERS.CONVERSATION);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(
        t`A reminder has been sent to all participants whom have not scheduled their 1-1 conversation.`,
      ),
    );
  };

  sendDigitalSignReminder = async () => {
    const { review } = this.state;
    const { i18n } = this.props;
    await sendDigitalSignReminder(review.id);
    await this.onReviewReminderSend(REVIEW_REMINDERS.DIGITAL_SIGN);
    this.closeOptionsModal();
    this.showToastMessage(
      i18n._(t`Reminder(s) send`),
      i18n._(t`A reminder is sent to all participants that did not sign the report.`),
    );
  };

  shareCoachesCalibratedReviews = async () => {
    const { review } = this.state;
    const { i18n } = this.props;

    if (
      await confirm(
        i18n,
        i18n._(
          t`Are you sure you want to change the status of all coach input with status calibrate to status shared. This will make the coach input visible to the employee's. This action cannot be undone.`,
        ),
      )
    ) {
      await shareCoachesCalibratedReviews(review.id);
      await this.refreshData();

      this.showToastMessage(
        i18n._(t`Shared`),
        i18n._(t`All the coaches input with status calibrate has been shared.`),
      );
    }
  };

  toggleDisableReview = async () => {
    const { review } = this.state;
    await onCompletePeopleSelection(review, this.onReviewStageLocked);
  };

  toggleLockReview = async (isLockStage) => {
    const { review } = this.state;
    const { i18n } = this.props;
    await onCompleteReviews(review, isLockStage, this.onReviewStageLocked, i18n);
  };

  exportCSV = async () => {
    const { review } = this.state;
    const { i18n } = this.props;

    this.closeOptionsModal();

    // Keeps the toast opened and delay the bar animation proportional to the amount of subreviews
    const toastOptions = {};
    if (review.subReviewsTotalCount > 60) {
      toastOptions.autoDismiss = false;
      toastOptions.autoDismissTimeout = review.subReviewsTotalCount * 90;
    }

    const toastId = this.showToastMessage(
      i18n._(t`Exporting CSV`),
      i18n._(
        t`Your CSV is being downloaded. This can take a couple of minutes. It will download when it is ready.`,
      ),
      toastOptions,
    );

    // Request and download reviews CSV data
    await downloadReviewCSV(review.id, review.name);

    // Close the toast if an id for it was obtained
    if (toastId) {
      this.closeToastMessage(toastId);
    }
  };

  openUpdateReviewFlow = () => {
    const { review } = this.state;
    this.closeOptionsModal();
    routes.REVIEW_UPDATE.go({}, { reviewId: review.id, isBackPath: true });
  };

  closeOptionsModal = () => {
    this.popoverRef.current._tippy.hide();
  };

  showToastMessage = (title, message, options = {}) => {
    const { toasts } = this.props;

    const toastId = toasts.add({ title, subtitle: message, type: TOAST_TYPES.INFO }, options);
    if (options.autoDismiss === false) {
      return toastId;
    }
  };

  closeToastMessage = (toastId) => {
    const { toasts } = this.props;
    return toasts.remove(toastId);
  };

  popoverRef = React.createRef();

  render() {
    const { i18n } = this.props;
    const { review, currentTab, loading, reviewPagination, isShowPublishingModal, loadingLabel } =
      this.state;

    if (loading) {
      return <LoadingPage />;
    }

    return (
      <>
        {review && review.name && (
          <HeadingNavigation
            label={i18n._(t`Conversation cycle`)}
            onBack={this.goBack}
            actions={
              <DropdownButton
                maxWidth={526}
                popoverRef={this.popoverRef}
                content={
                  <ReviewOptions
                    sendNominatePeersReminder={this.sendNominatePeersReminder}
                    sendSelfReviewReminder={this.sendSelfReviewReminder}
                    sendPeerReviewReminder={this.sendPeerReviewReminder}
                    sendCoachReviewReminder={this.sendCoachReviewReminder}
                    sendDigitalSignReminder={this.sendDigitalSignReminder}
                    sendScheduleConversationReminder={this.sendScheduleConversationReminder}
                    toggleDisableReview={this.toggleDisableReview}
                    toggleLockReview={this.toggleLockReview}
                    exportCSV={this.exportCSV}
                    deleteReview={this.deleteThisReview}
                    updateReview={this.openUpdateReviewFlow}
                    shareCoachesCalibratedReviews={this.shareCoachesCalibratedReviews}
                    review={review}
                  />
                }
              >
                <Button
                  label={
                    <>
                      {i18n._(t`Options`)}
                      <ExpandMoreIcon fill="#fff" />
                    </>
                  }
                />
              </DropdownButton>
            }
          />
        )}
        <BaseLayout>
          <ReviewHeader>
            <Header2>{review.name}</Header2>
            <Divider />
            <ReviewTabs
              items={TABS}
              isSmall
              currentItem={currentTab}
              handleChangeTab={this.handleChangeTab}
            />
            {currentTab === TABS_ENUM.info && <Info review={review} />}
          </ReviewHeader>
          {this.getTabContent()}
          {currentTab !== TABS_ENUM.info ? (
            <PaginationBar
              pagination={reviewPagination}
              changePagination={this.onPageChangeClick}
              count={review.subReviewsTotalCount || 0}
              showCount
              noTopBorder
            />
          ) : null}
          {isShowPublishingModal && (
            <PublishingWaitModal subtitle={loadingLabel} marginTop="120px" />
          )}
        </BaseLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: getAllUsers(state),
  };
};

export default withI18n()(connect(mapStateToProps)(withToasts(withRouter(ReviewAdminPage))));
