import React from 'react';

import { THColumn } from './THColumn';

import type { IColumnTable } from '~/@types/table';

import { TH, THead, TRHeader } from '../../design';
import { TSecondaryHeaderColumn } from '../../types';

interface IHeaderProps {
  columns: IColumnTable[];
  // secondaryHeaderColumns field is to implement an additional header row.
  secondaryHeaderColumns?: TSecondaryHeaderColumn[];
  sortProps?: {
    sortBy?: string;
    handleSortBy: (col: string | undefined) => void;
  };
  isMenuVisible?: boolean;
  visibleColumn?: string;
  showSortBy?: boolean;
  thColumnPadding?: string;
  enableMultipleTableHeaderRows?: boolean;
}

const Header = ({
  columns,
  secondaryHeaderColumns,
  enableMultipleTableHeaderRows,
  sortProps,
  isMenuVisible,
  visibleColumn,
  showSortBy,
  thColumnPadding,
}: IHeaderProps) => {
  return (
    <THead>
      <TRHeader>
        {columns.map((column, key) => {
          return (
            <THColumn
              key={key}
              column={column}
              sortProps={sortProps}
              visibleColumn={
                visibleColumn || (columns.length - 1 === key ? column.accessor : undefined)
              }
              showSortBy={showSortBy}
              thColumnPadding={thColumnPadding}
              colSpan={column.colSpan}
            />
          );
        })}
        {isMenuVisible && <TH key={'th-menu'} minWidth="40px" />}
      </TRHeader>

      {enableMultipleTableHeaderRows && (
        <TRHeader>
          {secondaryHeaderColumns?.map(({ title }, key) => (
            <THColumn
              key={key}
              column={{ name: title }}
              sortProps={sortProps}
              thColumnPadding={thColumnPadding}
            />
          ))}
          {isMenuVisible && <TH key={'th-menu'} minWidth="40px" />}
        </TRHeader>
      )}
    </THead>
  );
};

export { Header };
