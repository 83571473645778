import styled from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionItemBlock = styled.div<{ minWidth?: string }>`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 0 14px;
  height: 20px;
  border-left: solid 1.2px ${COLORS.BORDERS};
  justify-content: center;
`;

export const SubmitButton = styled(Button)``;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  padding-bottom: 4px;
`;
