import {
  REVIEW_JOIN,
  REVIEW_PARTICIPATION_COLUMNS,
  REVIEW_PARTICIPATION_SORT_BY,
} from '@learned/constants';

import type { IGetServerRoute, IServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { IReview } from '@learned/types';

export function createReview(body: Partial<IReview>) {
  return cloudRequest(serverRoutes.reviews.createReview as IServerRoute, {}, body);
}

export function fetchReviewById(reviewId: IReview['id'], body?: { join?: REVIEW_JOIN[] }) {
  return cloudRequest(
    (serverRoutes.reviews.fetchReviewById as IGetServerRoute)(reviewId),
    {},
    body,
  );
}

export function updateReviewById(reviewId: IReview['id'], body: { review: Partial<IReview> }) {
  return cloudRequest(
    (serverRoutes.reviews.updateReviewById as IUpdateServerRoute)(reviewId),
    {},
    body,
  );
}

export function deleteReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.deleteById as IGetServerRoute)(reviewId), {}, {});
}

export function archiveReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.archiveById as IGetServerRoute)(reviewId), {}, {});
}

export function unarchiveReviewById(reviewId: IReview['id']) {
  return cloudRequest((serverRoutes.reviews.unarchiveById as IGetServerRoute)(reviewId), {}, {});
}

export function fetchReviewLogs(
  reviewId: IReview['id'],
  body: { options: { skip?: number; limit?: number } },
) {
  return cloudRequest((serverRoutes.reviews.getReviewLogs as IGetServerRoute)(reviewId), {}, body);
}

export function fetchReviewParticipationTotal(
  reviewId: IReview['id'],
  { signal }: { signal: AbortSignal },
) {
  return cloudRequest(
    (serverRoutes.reviews.getReviewParticipationTotal as IGetServerRoute)(reviewId),
    {},
    {},
    // @ts-ignore
    { signal },
  );
}

export function fetchReviewParticipationPerTeam(
  reviewId: IReview['id'],
  body: {
    columns?: REVIEW_PARTICIPATION_COLUMNS[];
    filters?: { search?: string };
    options?: { skip?: number; limit?: number; sortBy?: REVIEW_PARTICIPATION_SORT_BY };
  },
  { signal }: { signal?: AbortSignal },
) {
  return cloudRequest(
    (serverRoutes.reviews.getReviewParticipationPerTeam as IGetServerRoute)(reviewId),
    {},
    body,
    // @ts-ignore
    { signal },
  );
}

export function shareCalibrationInput(reviewId: IReview['id']) {
  return cloudRequest(
    (serverRoutes.reviews.shareCalibrationInput as IGetServerRoute)(reviewId),
    {},
    {},
  );
}
