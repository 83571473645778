import * as Sentry from '@sentry/react';
import axios from 'axios';
import jsonToFormData from 'json-form-data';
import get from 'lodash/get';

import { auth0 } from '~/auth0';
import AUTH_ERRORS from '~/constants/authErrors';
import ROUTES from '~/constants/routes';
import { axiosCloud, serverRoutes } from '~/server_config';
import store from '~/store';
import * as appActions from '~/store/app/actions';
import { initializeSelection } from '~/store/selected/actions';
import debounceWithMemo from '~/utils/debounceWithMemo';
import history from '~/utils/history';

const CancelToken = axios.CancelToken;
let activeCancelSource = CancelToken.source();

/**
 *
 * @param {object} requestObj
 * @param {'post'|'get'|'patch'|'put'|'delete'} requestObj.method request method
 * @param {string} requestObj.route request url
 * @param [params] - request params
 * @param [data] - request body
 * @param [options]
 * @returns {Promise<AxiosResponse<any>>}
 */
async function makeRequest(
  requestObj,
  params,
  data,
  { sendAsFormData, noLogin, forceRole, arrayBuffer, signal } = {},
) {
  const noTokenNeeded =
    noLogin ||
    axiosCloud.defaults.headers.common['token-outside'] ||
    !!axiosCloud.defaults.headers.common.token;
  const token = !noTokenNeeded && (await auth0.client.getTokenSilently());
  const company = store.getState().selected.company;
  const team = store.getState().selected.team;
  const role = store.getState().selected.role;

  // Skip sentry errors for routes with expected errors.
  const noErrorRoutes = [serverRoutes.users.login.route];

  try {
    // Send request
    const result = await axiosCloud({
      url: requestObj.route,
      responseType: arrayBuffer ? 'arraybuffer' : '',
      method: requestObj.method,
      params,
      data: sendAsFormData ? jsonToFormData(data) : data,
      cancelToken: activeCancelSource.token, // @deprecated - use signal instead
      signal, // from new AbortController() to cancel request
      ...(sendAsFormData && { headers: { 'Content-Type': 'multipart/form-data' } }),
      ...(requestObj.responseType && { responseType: requestObj.responseType }),
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
          role: forceRole || role,
          ...(company && { company }),
          ...(team && { team }),
          // description for security headers: https://infosec.mozilla.org/guidelines/web_security#x-frame-options
          // Block pages from loading when they detect reflected XSS attacks
          ['X-XSS-Protection']: '1; mode=block',

          // Disable the loading of any resources and disable framing, recommended for APIs to use
          // Block site from being framed with X-Frame-Options and CSP
          ['Content-Security-Policy']: "default-src 'none'; frame-ancestors 'none'",
          ['X-Frame-Options']: 'DENY',

          // Prevent browsers from incorrectly detecting non-scripts as scripts
          ['X-Content-Type-Options']: 'nosniff',
        }),
      },
    });

    return result.data;
  } catch (e) {
    // handle errors
    const code = get(e, 'response.status');
    const error =
      get(e, 'response.data.message') || // custom server message
      get(e, 'response.statusText') || // default error
      String(e); // String(e) to display error without code, for instance no connection;
    const errorMessage = code ? `[${code}]: ${error}` : error;
    const isCanceled = e.code === 'ERR_CANCELED';

    // skip cancelled requests
    if (isCanceled) {
      return;
    }

    // Sentry
    const sentrySkipErrors = [503];
    if (!sentrySkipErrors.includes(code) && !isCanceled) {
      // Filter canceled requests & filter network errors without status code
      if (!e.__CANCEL__ && code && e.config) {
        Sentry.withScope(function (scope) {
          scope.setFingerprint([e.config.method, e.config.url, decodeURIComponent()]);
          scope.setExtra('error', e);
          scope.setExtra('server-response', JSON.stringify(e?.response?.data || 'undefined'));
          scope.setExtra('role', role);
          scope.setExtra('company', company);
          scope.setExtra('team', team);
          e.message = errorMessage;
          Sentry.captureException(e);
        });
      }
    }

    // show error in toast
    const toastSkipErrors = [429]; // stop showing toast if too many requests error (429)
    if (requestObj.isErrorInToast && !toastSkipErrors.includes(code) && !isCanceled) {
      store.dispatch(
        appActions.setIsErrorInToast({
          value: true,
          error: errorMessage,
        }),
      );
      return Promise.reject(e);
    }

    // show crash pages
    if ((requestObj.handledErrors || []).includes(code)) {
      store.dispatch(appActions.setIsRequestError({ value: true, code, error }));
      return;
    }

    if (noErrorRoutes.includes(requestObj.route) || code === 404) {
      e.ignoreSentry = true;
      throw e;
    }

    // Filter canceled requests & filter network errors without status code
    if (!e.__CANCEL__ && code && e.config) {
      if (code === 401) {
        const code = get(e.response, 'data.errorCode');
        const user = await auth0.client.getUser();

        const skipResetInitSelection = [AUTH_ERRORS.NO_APP_SESSION];
        if (!skipResetInitSelection.includes(code)) {
          // clean company, team and set to role to user if we get 401
          store.dispatch(initializeSelection(true));
        }

        switch (code) {
          case AUTH_ERRORS.COMPANY_DISABLED: {
            history.push(`${ROUTES.DISABLED_ACCOUNT}${user.email ? `?email=${user.email}` : ''}`);
            break;
          }
          case AUTH_ERRORS.USER_NOT_FOUND:
          case AUTH_ERRORS.NO_ACTIVE_CONNECTIONS: {
            history.push(`${ROUTES.NO_ACCOUNT}${user.email ? `?email=${user.email}` : ''}`);
            break;
          }
          case AUTH_ERRORS.TEAM_NOT_FOUND:
          case AUTH_ERRORS.COMPANY_NOT_FOUND:
          case AUTH_ERRORS.NO_APP_SESSION:
          default: {
            auth0.client.logout({ returnTo: window.location.origin });
            break;
          }
        }
        return;
      }
    }
    e.ignoreSentry = true;
    store.dispatch(appActions.setIsRequestError({ value: true, code, error }));
    throw e;
  }
}

export function cancelAllRequests() {
  // temporary solution (good enough for now)
  // it'll ignore the previous cancel token and create a new source
  const _activeCancelSource = CancelToken?.source();

  if (_activeCancelSource) {
    // Cancel all requests attached to active cancel token
    _activeCancelSource.cancel();

    // Create new cancel token
    activeCancelSource = CancelToken.source();
  }
}

export default makeRequest;

export const makeSingleRequest = debounceWithMemo(makeRequest, 1000);
