import styled, { css } from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { TableList } from '~/components/TableList';

import { StatusDropDownItem } from './types';

import { COLORS } from '~/styles';

const StatusDropDown = styled(Dropdown<StatusDropDownItem>)`
  position: absolute;
  width: 150px;
  height: 32px;
  right: 30px;
`;

const TableWrapper = styled.div`
  tr {
    height: 90px;
    background-color: ${COLORS.BG_LIST};
    margin-bottom: 5px;
  }
`;

const StyledTableList = styled(TableList)<{ isTab?: boolean }>`
  padding: 16px 32px;
  ${({ isTab }) =>
    !isTab &&
    css`
      border-radius: 10px;
    `}
  tbody > tr:nth-child(2n) {
    background: ${COLORS.WHITE};
    :hover {
      background-color: ${COLORS.HOVER};
    }
  }
`;

export { StatusDropDown, TableWrapper, StyledTableList };
