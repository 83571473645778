import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS } from '@learned/constants';
import every from 'lodash/every';

import useBoolState from '~/hooks/useBoolState';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { getReviewInvitationTemplate } from '~/services/reviewInvitationTemplates';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IReviewIndividualForm } from '../types';
import type { ILanguageValue, IReviewInvitationTemplate } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface UseReviewInvitationProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
}

const useReviewInvitation = ({ formMethods, languageState }: UseReviewInvitationProps) => {
  const { setValue, watch } = formMethods;
  const [invitationTemplates, setInvitationTemplates] = useState<Array<IReviewInvitationTemplate>>(
    [],
  );
  const $isRefreshTemplate = useBoolState();

  const addCopySuffix = (values?: ILanguageValue[]) => {
    return values?.map(({ locale, value }) => ({
      locale,
      value: `${value ? value + '_copy' : ''}`,
    }));
  };

  const fetchReviewInvitationTemplates = async () => {
    const result = await getReviewInvitationTemplate({
      options: {
        sortBy: REVIEW_INVITATION_TEMPLATES_SORT_OPTIONS.UPDATED_NEW_OLD,
      },
    });
    const templates: Array<IReviewInvitationTemplate> =
      result.data[API_RETURN_FIELDS.REVIEW_INVITATION_TEMPLATES];
    return templates;
  };

  const autoSelectInvitationTemplate = async () => {
    const watchInvitationTemplate = watch('reviewInvitationTemplate');
    const watchDescription = watch('description');
    const templates = await fetchReviewInvitationTemplates();

    if (watchInvitationTemplate) {
      const selectedInvitation = templates.find(
        (item: IReviewInvitationTemplate) => item.id === watchInvitationTemplate,
      );
      if (!watchDescription || every(watchDescription, (item) => item.value === '')) {
        setValue(
          'description',
          selectedInvitation?.description
            ? turnMultiLangIntoArray(selectedInvitation.description, languageState.companyLanguages)
            : null,
        );
      }
      setValue(
        'reviewInvitationTemplateName',
        selectedInvitation?.name
          ? addCopySuffix(
              turnMultiLangIntoArray(selectedInvitation.name, languageState.companyLanguages),
            )
          : null,
      );
      return;
    }

    if (templates && templates[0]) {
      setValue('reviewInvitationTemplate', templates[0].id);
      setValue(
        'description',
        templates[0]?.description
          ? turnMultiLangIntoArray(templates[0].description, languageState.companyLanguages)
          : null,
      );

      setValue(
        'reviewInvitationTemplateName',
        addCopySuffix(turnMultiLangIntoArray(templates[0].name, languageState.companyLanguages)),
      );
    }
  };

  const setInvitationTemplatesState = async () => {
    const templates = await fetchReviewInvitationTemplates();
    setInvitationTemplates(templates);
  };

  useEffect(() => {
    setInvitationTemplatesState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$isRefreshTemplate.value]);

  return {
    autoSelectInvitationTemplate,
    invitationTemplates,
    refreshInvitationTemplates: $isRefreshTemplate.toggle,
  };
};

export { useReviewInvitation };
