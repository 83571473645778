import styled, { css, type FlattenSimpleInterpolation } from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

const baseStyle = styled.div`
  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 3px;
  padding: 10.6px 20px 10.6px 20px;
  font-size: 14px;
`;

export const UserRatings = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 57px;
  gap: 3px;
`;

export const FocusAreaContainer = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 3px;

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;
export const FocusAreaName = styled(baseStyle)`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
`;

export const UserAvatarName = styled(baseStyle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
`;

export const ScoreText = styled.span`
  display: flex;

  color: ${COLORS.PLACEHOLDERS};
  font-size: 12px;
  align-items: center;
  justify-content: center;

  > span {
    font-weight: 600;
    color: ${COLORS.TEXT_MAIN};
    font-size: 14px;
  }
`;

export const Score = styled.div<{ gradient?: FlattenSimpleInterpolation }>`
  min-width: 95.1px;
  max-width: 95.1px;

  border-radius: 6px;
  background-color: ${COLORS.BG_LIST};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 3px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 21px;
  font-size: 14px;

  ${(props) => {
    return props.gradient && props.gradient;
  }}

  align-items: start;
  justify-content: center;

  svg {
    margin-right: 10px;
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    color: ${COLORS.INACTIVE};
    fill: ${COLORS.INACTIVE};
  }
`;
export const Comment = styled(RickTextView)`
  display: flex;
  flex-direction: row;
`;
