import React, { useContext, useEffect, useState } from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { Loader } from '~/components/Buttons/components/Loader';
import { AreaGraph } from '~/components/Graphs/custom/AreaGraph';
import { ELabelType, GraphDataType, ILineGraphPoint } from '~/components/Graphs/types';

import { OvertimeCtr, BodyCtr, NoDataTextContainer } from './OvertimeStyles';

import { useOutsideClick } from '~/hooks/useOutsideClick';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getEngagementCharts, TLucaModalData } from '~/services/reports';
import { COLORS, PRIMARY_COLOR } from '~/styles';
import { toFixed } from '~/utils/math';
import { getDateForTimeFrame } from '~/utils/reports';

import ReFetchBtn from '../../Components/RefetchBtn';
import { EOptions, TOption } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

type TProps = { optionChangeHandler: (key: EOptions, value?: string | TOption[] | null) => void };

function Overtime({ optionChangeHandler }: TProps): JSX.Element {
  const { i18n } = useLingui();
  const { viewAs, reportType, filters, options, shouldShowLuca, isWaiting } =
    useContext(EngagementReportContext);

  const [companyAverage, setCompanyAverage] = useState<GraphDataType[]>([]);
  const [benchmark, setBenchmark] = useState<GraphDataType[]>([]);
  const [teamAverage, setTeamAverage] = useState<GraphDataType[]>([]);
  const [selfReviewAverage, setSelfReviewAverage] = useState<GraphDataType[]>([]);
  const [peerReviewAverage, setPeerReviewAverage] = useState<GraphDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentCompany = useSelector(getCurrentCompany);
  const companyColor = currentCompany.color || PRIMARY_COLOR;
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);

  const onItemClick = (data: { key: string; value: number }) => {
    setInformationModalData({
      primaryDimensionName:
        reportType === REPORT_TYPES.ENGAGEMENT
          ? `${i18n._(t`Average engagement`)}`
          : `${i18n._(t`Average performance`)}`,
      value: data.value,
      filters: {
        measureValue: data.key,
      },
    });
    setShowInformationModal(true);
  };

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const getSelectedKeys = (selectedOptions: TOption[]) => selectedOptions.map((item) => item.key);

  const getData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        viewAs,
        reportType,
        chartType: REPORT_CHART_TYPES.BY_MONTH,
        filters: {
          themes: getSelectedKeys(filters.themesOptionSelected),
          teams: getSelectedKeys(filters.teamsOptionSelected),
          surveys: getSelectedKeys(filters.surveysOptionSelected),
          jobs: getSelectedKeys(filters.jobsSelected),
          jobGroups: getSelectedKeys(filters.jobsGroupsSelected),
          genders: getSelectedKeys(filters.gendersSelected),
          ageGroups: getSelectedKeys(filters.ageGroupSelected),
          educationLevels: getSelectedKeys(filters.educationLevelsSelected),
          reviews: getSelectedKeys(filters.reviewsOptionSelected),
        },
        options: {
          includeCompanyAverage: options.includeCompanyAverage,
          includeBenchmark: options.includeBenchmark,
          includePeerReviewAverage: options.includePeerReviewAverage,
          includeSelfReviewAverage: options.includeSelfReviewAverage,
          ...(viewAs === ROLES.USER && { includeTeamAverage: options.includeTeamAverage }),
        },
        dateRange: getDateForTimeFrame(filters.monthSelected),
      };
      const chartData = await getEngagementCharts(payload);
      const series = chartData?.data?.averageEngagement?.series || [];
      setCompanyAverage(
        series.map((item) => ({
          key: item.month,
          value: toFixed(item.value || 0, 2),
          deviation: item.deviation || 0,
        })),
      );
      setBenchmark(
        series.map((item) => ({
          key: item.month,
          value: toFixed(item.benchmark || 0, 2),
          deviation: item.deviation || 0,
        })),
      );
      setTeamAverage(
        series.map((item) => ({
          key: item.month,
          value: toFixed(item.team || 0, 2),
          deviation: item.deviation || 0,
        })),
      );
      setSelfReviewAverage(
        series.map((item) => ({
          key: item.month,
          value: toFixed(item.self || 0, 2),
          deviation: item.deviation || 0,
        })),
      );
      setPeerReviewAverage(
        series.map((item) => ({
          key: item.month,
          value: toFixed(item.peer || 0, 2),
          deviation: item.deviation || 0,
        })),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getLabelText = () => {
    if (reportType === REPORT_TYPES.ENGAGEMENT) {
      return `${i18n._(t`Average engagement`)}`;
    } else if (reportType === REPORT_TYPES.PERFORMANCE) {
      return `${i18n._(t`Coach review`)}`;
    }
    return `${i18n._(t`Average`)}`;
  };

  const timeData: ILineGraphPoint[] = [
    {
      data: companyAverage || [],
      colors: [companyColor, COLORS.WHITE],
      label: getLabelText(),
      type: ELabelType.FILL,
      key: 'primary',
    },
    ...(options.includeCompanyAverage
      ? [
          {
            data: companyAverage,
            colors: [COLORS.CONFIRMATION_MODAL_SUCCESS],
            label: `${i18n._(t`Company`)}`,
            type: ELabelType.BORDER,
            key: 'company',
          },
        ]
      : []),
    ...(options.includeTeamAverage
      ? [
          {
            data: teamAverage || [],
            colors: [COLORS.PERFORMANCE],
            label: `${i18n._(t`Team`)}`,
            type: ELabelType.BORDER,
            key: 'team',
          },
        ]
      : []),
    ...(options.includePeerReviewAverage
      ? [
          {
            data: peerReviewAverage || [],
            colors: [COLORS.ACCENT_SUCCESS],
            label: `${i18n._(t`Peer review`)}`,
            type: ELabelType.BORDER,
            key: 'peer',
          },
        ]
      : []),
    ...(options.includeSelfReviewAverage
      ? [
          {
            data: selfReviewAverage || [],
            colors: [COLORS.TALENT],
            label: `${i18n._(t`Self review`)}`,
            type: ELabelType.BORDER,
            key: 'self',
          },
        ]
      : []),
    ...(options.includeBenchmark
      ? [
          {
            data: benchmark,
            colors: [COLORS.SUBTEXT],
            label: `${i18n._(t`Benchmark`)}`,
            type: ELabelType.BORDER,
            key: 'benchmark',
          },
        ]
      : []),
  ];

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includePeerReviewAverage,
    options.includeSelfReviewAverage,
    options.includeTeamAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    filters.reviewsOptionSelected,
    filters.skillOptionsSelected,
    filters.skillCategoryOptionsSelected,
    filters.memberOptionsSelected,
  ]);

  const isLoadingState = isWaiting || isLoading;

  if (!isLoadingState && companyAverage.length === 0) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <OvertimeCtr>
          <BodyCtr>
            <NoDataTextContainer>
              {i18n._(t`There is no data available yet. Please try again later.`)}
            </NoDataTextContainer>
            <ReFetchBtn optionChangeHandler={() => getData()} />
          </BodyCtr>
        </OvertimeCtr>
      </HeaderIncluded>
    );
  }

  return (
    <HeaderIncluded isLoading={isLoading}>
      <OvertimeCtr>
        {isLoadingState ? (
          <BodyCtr>
            <Loader />
          </BodyCtr>
        ) : (
          <>
            <AreaGraph
              timeData={timeData}
              height={478}
              stepSize={20}
              colorRatio={1}
              tooltipTitle={
                // TODO: need to find how to get the correct title based on the report type, your performance vs performance.
                reportType === REPORT_TYPES.ENGAGEMENT
                  ? `${i18n._(t`Average engagement - Overtime`)}`
                  : `${i18n._(t`Average performance - Overtime`)}`
              }
              bgColor={companyColor}
              onPointClick={onItemClick}
            />
            {shouldShowLuca && <Luca />}
          </>
        )}
      </OvertimeCtr>
      {showInformationModal && informationModalData && (
        <InformationModal
          data={informationModalData}
          onClose={setShowInformationModal}
          baseRef={baseRef}
        />
      )}
    </HeaderIncluded>
  );
}

export default Overtime;
