import styled from 'styled-components';

import { ButtonPrimary } from '~/components/Button';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  border: 1px solid ${COLORS.TAB_WRAPPER_GRAY};
  border-radius: 10px;
  background-color: ${COLORS.WHITE};

  & > div {
    margin: 0 25px;
  }

  & > div:first-child {
    margin-top: 25px;
  }

  & > div:last-child {
    margin-bottom: 25px;
  }

  & .full-width {
    margin: 0;
    padding: 10px 25px 16px 25px;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
  margin-bottom: 4px;
`;

export const Row = styled.div<{ paddingTop?: number; paddingBottom?: number }>`
  padding-top: ${({ paddingTop }) => paddingTop ?? 28}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 0}px;

  &:first-child {
    margin-top: unset;
  }

  & .inputWrapper {
    margin-bottom: 10px;
  }

  & label {
    color: ${COLORS.INPUT_TITLE};
  }
`;

export const AnswerTypeWrapper = styled.div`
  margin-top: 5px;
  width: 255px;

  & > div {
    min-height: 40px;
    max-width: unset;
    border-radius: 5px;

    & > div {
      font-size: 14px;
      color: ${COLORS.TEXT_HOVER} !important;

      & > div {
        width: 255px;
      }
    }
    & > div::before {
      background-color: unset;
      opacity: unset;
    }
  }
`;

export const QuestionDescription = styled.div`
  height: 26px;
  font-size: 14px;
  line-height: 1.86;
  color: ${COLORS.INPUT_TITLE};
`;

export const Button = styled(ButtonPrimary)`
  height: 31px;
  padding: 7px 24px 8px;
  border-radius: 16px;
  box-shadow: 0 2px 5px 0 rgba(145, 157, 165, 0.12);
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const AddSkillButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 8px;
  align-content: flex-end;
  gap: 18px;

  & > span {
    margin-bottom: 5px;
    align-content: flex-end;
    font-size: 12px;
    letter-spacing: -0.13px;
    color: ${COLORS.ACCENT_ERROR};
  }
`;

export const AnswerTypeContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const EditThemes = styled.button`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 11px 12px;
  font-size: 12px;
  color: ${COLORS.COMPANY};
  border-top: 1px solid ${COLORS.BORDER_HARD};
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.COMPANY};
    color: ${COLORS.WHITE};
  }
`;
