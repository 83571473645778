import React, { useState } from 'react';

import { SURVEY_THEME_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';

import { AutocompleteThemes } from '~/components/AutocompleteFilters';
import { ButtonShadow } from '~/components/Button';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { Input } from '~/components/Input';

import { Options } from './components/Options';
import { Smileys } from './components/Smileys';
import { Stars } from './components/Stars';
import { TrafficLights } from './components/TrafficLights';
import { Footer } from './Footer';

import type { IQuestionForm } from '~/@types/question';
import { QUESTION_TYPES } from '~/constants/questionsTypes';
import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { COLORS } from '~/styles';

interface IEditableQuestionProps {
  formMethods: UseFormReturn<IQuestionForm>;
  languageState: ILanguageStateReturn;
  onDelete?: () => void;
  onDuplicate?: () => void;
  isEditMode?: boolean;
  selectTheme?: boolean;
}

const Wrapper = styled.div`
  padding: 19px 19px 16px;
  border: 1px solid #dae4f7;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: ${COLORS.WHITE};
`;

const Label = styled.label`
  font-size: 14px;
  color: ${COLORS.BLACK};
  margin-bottom: 4px;
`;

const Row = styled.div`
  margin-bottom: 28px;
  & .inputWrapper {
    margin-bottom: 10px;
  }
`;

const AnswerTypeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const AnswerTypeButton = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  padding: 7px 14px;
  &.selected {
    background-color: var(--company-color);
    color: ${COLORS.WHITE};
  }
`;

const EditThemes = styled.button`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 11px 12px;
  font-size: 12px;
  color: var(--company-color);
  border-top: 1px solid ${COLORS.BORDER_HARD};
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: var(--company-color);
    color: ${COLORS.WHITE};
  }
`;

const EditableQuestion = ({
  languageState,
  formMethods,
  onDelete,
  onDuplicate,
  isEditMode,
  selectTheme,
}: IEditableQuestionProps) => {
  const { i18n } = useLingui();
  const { watch, control, register, unregister } = formMethods;
  const [isPreloaded, setIsPreloaded] = useState(isEditMode);

  const ANSWER_TYPES = [
    {
      key: QUESTION_TYPES.TEXT,
      title: i18n._(t`Text area`),
      icon: <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.MEDIUM} />,
    },
    {
      key: QUESTION_TYPES.SINGLE,
      title: i18n._(t`Single`),
      icon: <Icon icon={ICONS.SINGLE_SELECT} size={ICON_SIZES.MEDIUM} />,
    },
    {
      key: QUESTION_TYPES.MULTIPLE,
      title: i18n._(t`Multiple`),
      icon: <Icon icon={ICONS.MULTI_SELECT} size={ICON_SIZES.MEDIUM} />,
    },
    {
      key: QUESTION_TYPES.TRAFFIC,
      title: i18n._(t`Traffic lights`),
      icon: <Icon icon={ICONS.TRAFFIC} size={ICON_SIZES.MEDIUM} />,
    },
    {
      key: QUESTION_TYPES.STARS,
      title: i18n._(t`Stars`),
      icon: <Icon icon={ICONS.STAR} size={ICON_SIZES.MEDIUM} />,
    },
    {
      key: QUESTION_TYPES.SMILEYS,
      title: i18n._(t`Smileys's`),
      icon: <Icon icon={ICONS.SMILEY} size={ICON_SIZES.MEDIUM} />,
    },
  ];

  const prepareAnswer = (type: QUESTION_TYPES) => {
    switch (type) {
      case QUESTION_TYPES.TRAFFIC:
        return (
          <TrafficLights
            isPreloaded={isPreloaded}
            formMethods={formMethods}
            languageState={languageState}
          />
        );
      case QUESTION_TYPES.SMILEYS:
        return (
          <Smileys
            isPreloaded={isPreloaded}
            formMethods={formMethods}
            languageState={languageState}
          />
        );
      case QUESTION_TYPES.STARS:
        return (
          <Stars
            isPreloaded={isPreloaded}
            formMethods={formMethods}
            languageState={languageState}
          />
        );
      case QUESTION_TYPES.SINGLE:
      case QUESTION_TYPES.MULTIPLE:
        return (
          <Options
            isPreloaded={isPreloaded}
            formMethods={formMethods}
            languageState={languageState}
          />
        );
      case QUESTION_TYPES.TEXT:
      default:
        return null;
    }
  };

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  return (
    <Wrapper>
      <Row>
        {nameFieldArray.fields?.map(({ locale }, index) => (
          <div className="inputWrapper" key={`${locale}-${index}`}>
            <Input
              name={`name.${index}.value`}
              label={index === 0 ? i18n._(t`Question*`) : undefined}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={index === 0}
              placeholder={i18n._(t`Example: How would you rate your work environment?`)}
              height="38px"
              register={register(`name.${index}.value`, { required: true })}
              leftIcon={
                size(nameFieldArray.fields) > 1
                  ? getUnicodeFlagIcon(locale.substring(locale.indexOf('_') + 1))
                  : undefined
              }
            />
          </div>
        ))}
      </Row>
      <Row>
        <Label>
          <Trans>Answer type*</Trans>
        </Label>

        <Controller
          name="type"
          rules={{ required: true }}
          control={control}
          render={({ field: { value, onChange } }) => (
            <AnswerTypeWrapper>
              {ANSWER_TYPES.map(({ key, title, icon }) => (
                <AnswerTypeButton
                  type="button"
                  className={key === value ? 'selected' : ''}
                  onClick={() => {
                    setIsPreloaded(false);
                    onChange(key);
                  }}
                  key={key}
                >
                  {icon}
                  <span>{title}</span>
                </AnswerTypeButton>
              ))}
            </AnswerTypeWrapper>
          )}
        />
      </Row>
      <Row>{prepareAnswer(watch('type'))}</Row>
      {selectTheme && (
        <Row>
          <Label>
            <Trans>Select a theme for this question</Trans>*
          </Label>

          <Controller
            name="theme"
            rules={{ required: true }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <AutocompleteThemes
                onChange={onChange}
                checkedList={value ? [value] : []}
                isSingleSelect
                styles={{ width: '262px' }}
                status={SURVEY_THEME_STATUS.PUBLISHED}
                // @ts-ignore
                lastItem={
                  <EditThemes onClick={() => routes.SURVEYS_OVERVIEW.go({}, { hash: 'themes' })}>
                    <Icon icon={ICONS.EDIT_PENCIL} size={ICON_SIZES.SMALL} />
                    <span className="title">{i18n._(t`Edit themes`)}</span>
                  </EditThemes>
                }
              />
            )}
          />
        </Row>
      )}
      {watch('type') && (
        <Footer
          isSettingsVisible={watch('type') !== QUESTION_TYPES.TEXT}
          isLeftSideVisible={!!isEditMode}
          formMethods={formMethods}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      )}
    </Wrapper>
  );
};

export { EditableQuestion };
