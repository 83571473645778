import React from 'react';
import type { ReactNode } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled, { css } from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ $hideSeparator?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    !props.$hideSeparator &&
    css`
      border-top: solid 1px ${COLORS.BORDERS};
    `}
  padding-top: 20px;
  margin-top: ${(props) => (!props.$hideSeparator ? 14 : 0)}px;
  > div {
    flex: 1;
    display: flex;
  }
`;

export const RightActionContainer = styled.div`
  justify-content: end;
`;

interface IFooter {
  isPrev?: boolean;
  isNext?: boolean;
  isPublish?: boolean;
  onPublish?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
  children?: ReactNode;
  hideSeparator?: boolean;
}

const StepFooter = ({
  isPrev = true,
  isNext = true,
  isPublish = false,
  onPublish,
  onPrev,
  onNext,
  children,
  hideSeparator = false,
}: IFooter) => {
  const { i18n } = useLingui();

  return (
    <Wrapper $hideSeparator={hideSeparator}>
      <div>
        {isPrev && (
          <Button
            label={i18n._(t`Back`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            icon={ICONS.BACK}
            size={ButtonSize.MEDIUM}
            iconSize={ICON_SIZES.SMALL}
            onClick={onPrev}
          />
        )}
      </div>
      {children}
      <RightActionContainer>
        {isNext && (
          <Button
            label={i18n._(t`Next`)}
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            size={ButtonSize.MEDIUM}
            type={onNext ? 'button' : 'submit'}
            onClick={onNext}
          />
        )}
        {!isNext && isPublish && (
          <Button
            label={i18n._(t`Publish`)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            type="button"
            onClick={onPublish}
          />
        )}
      </RightActionContainer>
    </Wrapper>
  );
};

export { StepFooter };
