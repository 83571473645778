import styled, { css } from 'styled-components';

import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

export const AnswerWrapper = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  padding: 4px 28px 11px;
`;

export const Answer = styled(RickTextView)`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const AnswerPlaceholder = styled.div`
  font-size: 14px;
  color: ${COLORS.PLACEHOLDERS};
`;

export const Row = styled.div``;

export const AnswerContainer = styled.div<{ $answered?: boolean; clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  background-color: ${COLORS.BG_LIST};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  ${({ $answered }) =>
    $answered &&
    css`
      &:hover {
        & > div {
          & > div {
            & > div {
              & > div {
                & > span {
                  font-weight: 500;
                  color: ${COLORS.TEXT_HOVER};
                }
              }
            }
          }
        }
      }
    `}
`;
export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
`;

export const UserAvatarContainer = styled.div<{ $answered?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  ${({ $answered }) =>
    !$answered
      ? css`
          & > div {
            & > span {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.16px;
              color: ${COLORS.INACTIVE};
            }
          }
          & > span {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.16px;
            color: ${COLORS.INACTIVE};
          }
        `
      : ''}

  & svg {
    margin-right: 21px;
  }
`;

export const UserAvatarName = styled.span`
  margin-left: 3px;
  color: ${COLORS.SUBTEXT};
  align-self: center;
`;

export const Comment = styled(RickTextView)`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div<{ $answered: boolean }>`
  display: flex;
  gap: 16px;

  ${({ $answered }) =>
    !$answered &&
    css`
      color: ${COLORS.INACTIVE};
    `}
`;

export const LabelWrapper = styled.div`
  padding: 1px 5px 2px 7px;
  border-radius: 3px;
  border: solid 1px ${COLORS.ACCENT_WARNING};
  background-color: rgba(255, 130, 82, 0.1);
  font-size: 12px;
  letter-spacing: -0.13px;
  color: ${COLORS.ACCENT_WARNING};
`;
