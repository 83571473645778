import React from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';

import { ITabConfigurationResponse, TSortingOrder, TVisualType } from '~/services/reports';

import { MONTH_OPTIONS } from '../options';
import { EExportOptions, EOptions, TOption, TViewAs } from '../types';

interface IContext {
  themesOptions: TOption[];
  skillOptions: TOption[];
  learnedThemesOptions: TOption[];
  primaryRowSelected: TOption | null;
  viewAs: TViewAs;
  tabConfiguration: ITabConfigurationResponse | null;
  reportType: REPORT_TYPES;
  enabledOptions: EOptions[];
  exportOptionArr: EExportOptions[];
  shouldShowLuca: boolean;
  shouldBenchmarkDisabled: boolean;
  viewType: REPORT_CHART_TYPES | null;
  reportId: string | null;
  filters: IReportFilters;
  sorting:
    | {
        order: TSortingOrder;
        orderBy: string;
      }
    | {};
  options: {
    includeCompanyAverage: boolean;
    includeBenchmark: boolean;
    includeTeamAverage: boolean;
    includePeerReviewAverage: boolean;
    includeSelfReviewAverage: boolean;
    isHeatmapColored: boolean;
  };
  dimensions: {
    primary: string;
    secondary: string;
    measure: string;
    secondDimension?: string;
    thirdDimension?: string;
    fourthDimension?: string;
  };
  visualTypes: { [key: string]: TVisualType };
  isWaiting: boolean;
  showMenu: boolean;
}

export interface IReportFilters {
  monthSelected: string;
  themesOptionSelected: TOption[];
  teamsOptionSelected: TOption[];
  surveysOptionSelected: TOption[];
  reviewsOptionSelected: TOption[];
  jobsSelected: TOption[];
  jobsGroupsSelected: TOption[];
  gendersSelected: TOption[];
  ageGroupSelected: TOption[];
  educationLevelsSelected: TOption[];
  skillOptionsSelected: TOption[];
  skillCategoryOptionsSelected: TOption[];
  memberOptionsSelected: TOption[];
}

const initContext: IContext = {
  primaryRowSelected: null,
  themesOptions: [],
  skillOptions: [],
  learnedThemesOptions: [],
  tabConfiguration: null,
  viewAs: ROLES.USER,
  reportType: REPORT_TYPES.ENGAGEMENT,
  enabledOptions: [],
  exportOptionArr: [],
  shouldShowLuca: false,
  shouldBenchmarkDisabled: false,
  filters: {
    monthSelected: MONTH_OPTIONS[0].key,
    themesOptionSelected: [],
    teamsOptionSelected: [],
    surveysOptionSelected: [],
    jobsSelected: [],
    jobsGroupsSelected: [],
    gendersSelected: [],
    ageGroupSelected: [],
    educationLevelsSelected: [],
    reviewsOptionSelected: [],
    skillOptionsSelected: [],
    skillCategoryOptionsSelected: [],
    memberOptionsSelected: [],
  },
  sorting: {},
  options: {
    includeCompanyAverage: false,
    includeBenchmark: false,
    includeTeamAverage: false,
    includePeerReviewAverage: false,
    includeSelfReviewAverage: false,
    isHeatmapColored: true,
  },
  dimensions: {
    primary: '',
    secondary: '',
    measure: '',
  },
  visualTypes: {},
  isWaiting: true,
  showMenu: false,
  viewType: null,
  reportId: null,
};
export const EngagementReportContext = React.createContext<IContext>(initContext);
