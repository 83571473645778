import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import type { IReviewEmployeeOverviewForm } from '~/pages/Reviews/Modals/ReviewEmployeeOverviewModal/ReviewEmployeeOverviewForm/types';

import { List } from '../components/List';
import { ListRowPlaceholder } from '../components/ListRowPlaceholder';
import { ListRowSignature } from '../components/ListRowSignature';
import { Section } from '../components/Section';

import type { UseFormReturn } from 'react-hook-form';

interface ISignaturesSectionProps {
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
}

const SignaturesSection = ({ formMethods }: ISignaturesSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');
  const guests = watch('guests');
  const coaches = watch('coaches');
  const signatures = watch('signatures');

  const items = [employee.id, ...guests].filter((i) => i);

  const findUserRole = (userId: string) => {
    if (coaches.includes(userId)) {
      return i18n._(t`Coach`);
    }

    if (employee.id === userId) {
      return i18n._(t`Employee`);
    }

    return null;
  };

  return (
    <Section title={i18n._(t`Signatures`)}>
      <List>
        {isEmpty(items) ? (
          <ListRowPlaceholder text={i18n._(t`No guests selected.`)} />
        ) : (
          items.map((userId, key) => {
            const signature = signatures.find((item) => item.userId === employee.id);
            return (
              <ListRowSignature
                key={key}
                userId={userId}
                userRole={findUserRole(userId)}
                isGuest={guests.includes(userId)}
                isFirstSignature={!isEmpty(signatures)}
                signature={signature}
              />
            );
          })
        )}
      </List>
    </Section>
  );
};

export { SignaturesSection };
