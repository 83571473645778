import React, { useEffect, useMemo, useState } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_STATUS, TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import some from 'lodash/some';
import { useFieldArray, useForm } from 'react-hook-form';

import { useSectionState } from '~/components/TableOfContents/hooks';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { useAutoSave } from '~/hooks/useAutoSave';
import useBoolState from '~/hooks/useBoolState';
import { useFromQuery } from '~/hooks/useFromQuery';
import { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { createOrUpdateReviewRatings } from '~/services/reviewRatings';

import {
  createInitialSections,
  findAllRatings,
  findFirstUnansweredQuestion,
  groupQuestionsBasedOnType,
} from '../utils';
import { resolver } from '../validation';

import type {
  AnswerReviewForm,
  IPopulatedReviewTask,
  IPopulatedUserReview,
  IQuestionData,
} from '../types';

export function useReviewRatingsState({
  reviewTask,
  userReview,
  languageState,
  useMultiLangString,
}: {
  reviewTask: IPopulatedReviewTask;
  userReview: IPopulatedUserReview;
  languageState: ILanguageStateReturn;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}) {
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const { goBack } = useFromQuery({ includeHash: true });
  const { i18n } = useLingui();

  const $autosaveEnabled = useBoolState(reviewTask.status !== TASK_STATUS.COMPLETED);
  const [showOutro, setShowOutro] = useState(false);
  const $showExternalConfirmation = useBoolState(false);

  const groupedQuestions = useMemo(() => groupQuestionsBasedOnType(userReview), [userReview]);
  const initialSections = useMemo(
    () => createInitialSections(groupedQuestions, getMultiLangString, reviewTask, userReview),
    [getMultiLangString, groupedQuestions, reviewTask, userReview],
  );
  const firstUnansweredQuestion = findFirstUnansweredQuestion(groupedQuestions, reviewTask);
  const sectionState = useSectionState<IQuestionData>(initialSections, {
    currentSection: firstUnansweredQuestion === -1 ? undefined : firstUnansweredQuestion,
  });
  const questionAndSectionMap = useMemo(
    () =>
      initialSections.reduce((acc: Record<string, number>, section, i) => {
        if (
          section.data?.type === REVIEW_QUESTION_TYPES.TEXT ||
          section.data?.type === REVIEW_QUESTION_TYPES.RATING ||
          section.data?.type === REVIEW_QUESTION_TYPES.GOAL_PLAN
        ) {
          acc[section.data.question.id] = i;
        }
        if (section.data?.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
          section.data.subQuestions.forEach((subQuestion) => {
            acc[subQuestion.question.id] = i;
          });
        }
        if (section.data?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
          section.data.subQuestions.forEach((subQuestion) => {
            subQuestion.question.settings.duplicateQuestions?.forEach((dupe) => {
              acc[dupe.question.id] = i;
            });
            acc[subQuestion.question.id] = i;
          });
        }

        return acc;
      }, {}),
    [initialSections],
  );

  const autoSaveState = useAutoSave(async () => {
    const ratings = watch('ratings');

    const transformedData = ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: rating.isNotApplicable ? null : rating.answer,
      comment: rating.comment,
    }));

    const result = await createOrUpdateReviewRatings({
      taskId: reviewTask.id,
      reviewRatings: transformedData,
      isAutoSave: true,
    });

    if (result.code === 200 && some(ratings, (field) => field.ratingId === undefined)) {
      fields.forEach((field, index) => {
        update(index, {
          ...field,
          ratingId: result.data.reviewRatings.find(
            (rating) => rating.userReviewQuestion === field.question,
          )?.id,
        });
      });
    }
  });

  const formData = useForm<AnswerReviewForm>({
    mode: 'all',
    resolver,
    context: { reviewTask, userReview },
    defaultValues: {
      ratings: Array.from(findAllRatings(sectionState.sections))
        .filter(Boolean)
        .map((rating) => ({
          ratingId: rating.id,
          question: rating.userReviewQuestion,
          answer: rating.answer,
          comment: rating.comment,
          isNotApplicable: rating.answer === null,
        })),
      shouldValidate: true,
    },
  });
  const { watch, setValue, trigger, control, handleSubmit, formState } = formData;
  const { fields, update } = useFieldArray({
    name: 'ratings',
    control,
  });

  useEffect(() => {
    if ($autosaveEnabled.value) {
      autoSaveState.run('ratings')?.flush();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$autosaveEnabled.value]);

  useEffect(() => {
    const ratingErrors = formState.errors?.ratings;

    fields.forEach((field, index) => {
      const fieldError = ratingErrors?.[index];
      // find section that this rating belongs to
      const sectionWithError = questionAndSectionMap[field.question];

      if (fieldError?.comment?.type !== undefined || fieldError?.answer?.type !== undefined) {
        sectionState.setErrorSection(sectionWithError, true);
      } else {
        sectionState.setErrorSection(sectionWithError, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.errors.ratings]);

  useEffect(() => {
    trigger('ratings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (data: {
    questionId: string;
    answer?: string | undefined | number;
    comment?: string | undefined;
    isNotApplicable?: boolean | undefined;
  }) => {
    const ratingIndex = fields.findIndex((field) => field.question === data.questionId);
    const newData = {
      ...fields[ratingIndex],
      ...(data.answer !== undefined && { answer: data.answer }),
      ...(data.comment !== undefined && { comment: data.comment }),
      ...(data.isNotApplicable !== undefined && { isNotApplicable: data.isNotApplicable }),
    };
    update(ratingIndex, newData);

    if ($autosaveEnabled.value) {
      autoSaveState.run('ratings');
    }

    await trigger('ratings');
  };

  const onSuccess = async (data: AnswerReviewForm, isPublishing = false, isCalibrate = false) => {
    const transformedData = data.ratings.map((rating) => ({
      id: rating.ratingId,
      company: reviewTask.company,
      createdBy: reviewTask.userTo,
      createdFor: reviewTask.userFrom as string,
      userReview: userReview.id,
      userReviewQuestion: rating.question,
      answer: (rating.isNotApplicable ? null : rating.answer) as string | null,
      comment: rating.comment,
    }));

    const result = await createOrUpdateReviewRatings({
      taskId: reviewTask.id,
      status: isPublishing
        ? REVIEW_RATING_STATUS.PUBLISHED
        : isCalibrate
        ? REVIEW_RATING_STATUS.CALIBRATE
        : REVIEW_RATING_STATUS.TODO,
      reviewRatings: transformedData,
      isAutoSave: false,
    });

    if (
      isPublishing &&
      !isCalibrate &&
      result.code === 200 &&
      (reviewTask.status === TASK_STATUS.TODO || reviewTask.status === TASK_STATUS.ACTIVE)
    ) {
      addToast({ title: 'Your input has been shared!', type: TOAST_TYPES.SUCCESS });
      goBack();
    }

    if (
      result.code === 200 &&
      reviewTask.status === TASK_STATUS.COMPLETED &&
      isPublishing &&
      !isCalibrate
    ) {
      addToast({ title: 'Answers saved', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (
      result.code === 200 &&
      reviewTask.status === TASK_STATUS.COMPLETED &&
      !isPublishing &&
      !isCalibrate
    ) {
      addToast({ title: 'Input has been reset to draft', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (
      !isPublishing &&
      !isCalibrate &&
      result.code === 200 &&
      [TASK_STATUS.TODO, TASK_STATUS.ACTIVE].includes(reviewTask.status)
    ) {
      addToast({ title: 'Answers saved as draft', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (
      !isPublishing &&
      isCalibrate &&
      result.code === 200 &&
      [TASK_STATUS.TODO, TASK_STATUS.ACTIVE].includes(reviewTask.status)
    ) {
      addToast({ title: 'Answers saved as calibrate', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (
      result.code === 200 &&
      reviewTask.status === TASK_STATUS.COMPLETED &&
      !isPublishing &&
      isCalibrate
    ) {
      addToast({ title: 'Input has been reset to calibrate', type: TOAST_TYPES.INFO });
      goBack();
    }

    if (result.code === 403 || result.code === 400) {
      goBack();
    }

    setValue('shouldValidate', true);
  };

  const onFail = () => {
    sectionState.setTriedToSubmit();
    addToast({
      title: i18n._(t`Warning`),
      subtitle: i18n._(t`Please fill in all obligated fields`),
      type: TOAST_TYPES.INFO,
    });
    setShowOutro(false);
    sectionState.goToFirstErrorSection();
    setValue('shouldValidate', true);
  };

  const onPublish = async (e?: React.BaseSyntheticEvent) => {
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, true);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onExternalPublish = async (_e?: React.BaseSyntheticEvent) => {
    setShowOutro(false);
    $showExternalConfirmation.on();
  };

  const onSave = async (e?: React.BaseSyntheticEvent) => {
    setValue('shouldValidate', false);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const onSaveCalibrate = async (e?: React.BaseSyntheticEvent) => {
    setValue('shouldValidate', false);
    const submit = handleSubmit(
      (data) => {
        return onSuccess(data, false, true);
      },
      () => onFail(),
    );

    return submit(e);
  };

  const nextSection = () => {
    const hasNextSection = sectionState.goToNextSection();
    if (!hasNextSection) {
      setShowOutro(true);
    } else if ($autosaveEnabled.value) {
      autoSaveState.run('ratings')?.flush();
    }
  };

  const previousSection = () => {
    if (!showOutro) {
      sectionState.goToPreviousSection();
      if ($autosaveEnabled.value) {
        autoSaveState.run('ratings')?.flush();
      }
    }
    setShowOutro(false);
  };

  const currentQuestion = useMemo(() => {
    const data = sectionState.sections.at(sectionState.currentSection)!.data!;
    if (
      data.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL ||
      data.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY
    ) {
      return data.subQuestions[0].question;
    } else {
      return data.question;
    }
  }, [sectionState.currentSection, sectionState.sections]);

  const [hasError, hasCommentError] = useMemo(() => {
    let [hasAnswerError, hasCommentError] = [false, false];
    if (!sectionState.triedToSubmit) {
      return [hasAnswerError, hasCommentError];
    }

    const ratings = formState?.errors?.ratings ?? [];
    for (let index = 0; index < (ratings.length ?? 0); index++) {
      const rating = ratings[index];
      const sectionWithError = questionAndSectionMap[fields[index].question];
      if (sectionWithError !== sectionState.currentSection) {
        continue;
      }

      if (rating?.answer?.type !== undefined) {
        hasAnswerError = true;
      }
      if (rating?.comment?.type !== undefined) {
        hasCommentError = true;
      }
    }

    return [hasAnswerError, hasCommentError];
  }, [
    sectionState.triedToSubmit,
    sectionState.currentSection,
    formState?.errors?.ratings,
    questionAndSectionMap,
    fields,
  ]);

  return {
    currentQuestion,
    showOutro,
    setShowOutro,
    hasError,
    hasCommentError,
    sectionState,
    formData,
    onPublish,
    onSave,
    onChange,
    nextSection,
    previousSection,
    autoSaveState,
    languageState,
    autosaveEnabled: $autosaveEnabled,
    onSaveCalibrate,
    onExternalPublish,
    showExternalConfirmation: $showExternalConfirmation,
  };
}

export type ReturnTypeUseReviewRatingsState = ReturnType<typeof useReviewRatingsState>;
