import React, { useEffect, useState } from 'react';

import { type ITaskWithId } from '@learned/types';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { SubText } from './subText';

import type { IColumnTable } from '~/@types/table';
import { TASKS_TYPES_OLD } from '~/constants';
import { getUsers } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';
import getUserFullName from '~/utils/getUserFullName';

const DateRow = styled.div<{ isDeadlineClose?: boolean; isOverdue?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${({ isDeadlineClose }) => (isDeadlineClose ? COLORS.WARNING : COLORS.GRAVEL)};
  color: ${({ isOverdue }) => (isOverdue ? COLORS.ACCENT_ERROR : '')};
  text-align: center;
  justify-content: center;
`;

const Name = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${({ isGrayColor }) => (isGrayColor ? COLORS.TEXT_SECONDARY : COLORS.BLACK)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 420px;
`;

const Wrap = styled.div<{ isLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.isLeftMargin ? '15px' : '0px')};
  width: 100%;
`;

const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
  min-width: 420px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

interface INameWithSubText {
  task: ITaskWithId;
  isLeftMargin?: boolean;
}

const NameWithSubText = ({ task, isLeftMargin = true }: INameWithSubText) => {
  const { i18n } = useLingui();
  const taskType = Object.values(TASKS_TYPES_OLD).find((type) => type.key === task.type);

  const users = useSelector(getUsers);
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    if (users[task.target.userId]) {
      setUser(users[task.target.userId]);
    }
  }, [users, task.target.userId]);

  return (
    <Wrap isLeftMargin={isLeftMargin}>
      <Name>
        {taskType
          ? taskType?.text(
              { ...task, target: { ...task.target, userName: user ? getUserFullName(user) : '' } },
              i18n,
            )
          : task.target.name}
      </Name>
      <SubText task={task} user={user} />
    </Wrap>
  );
};

const TaskNameRow = ({ task }: { task: ITaskWithId }) => {
  return (
    <NameRow>
      <FlexRow>
        <NameWithSubText isLeftMargin={false} task={task} />
      </FlexRow>
    </NameRow>
  );
};

const getDeadline = (deadline: string) => {
  const currentDate = new Date();
  const deadlineDate = new Date(deadline);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  const differenceInMilliSeconds = deadlineDate.getTime() - currentDate.getTime();
  const differenceInDays = Math.ceil(differenceInMilliSeconds / millisecondsPerDay);
  if (differenceInDays > 7) {
    return convertToTimeString(deadline);
  }
  if (differenceInDays > 1) {
    return `${differenceInDays} days`;
  }
  if (differenceInDays < 0) {
    return 'overdue';
  }
  return `${differenceInDays} day`;
};

const COLUMNS: IColumnTable<ITaskWithId>[] = [
  {
    name: '',
    accessor: 'task',
    renderCell: (task) => {
      return <TaskNameRow task={task} />;
    },
  },
  {
    name: '',
    accessor: 'date',
    renderCell: (task: ITaskWithId) => {
      const deadline = getDeadline(task.deadline!);
      return (
        <DateRow isDeadlineClose={deadline.includes('day')} isOverdue={deadline === 'overdue'}>
          {deadline === 'overdue' ? <Trans>Overdue</Trans> : deadline}
        </DateRow>
      );
    },
  },
];

export { COLUMNS };
