import React, { useEffect } from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_STATUS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useHistory, useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { LoadingModal } from '~/pages/Reviews/components/LoadingModal';

import { Wrapper } from './design';
import { useReview } from './hooks/useReview';
import { useReviewTasks } from './hooks/useReviewTasks';
import { StepFinalCheck } from './StepFinalCheck';
import { StepGeneral } from './StepGeneral';
import { StepParticipants } from './StepParticipants';
import { StepSettings } from './StepSettings';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { useReviewTemplates } from '../../hooks/useReviewTemplates';

import type { IReviewSelfForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewSelfForm>;
  languageState: ILanguageStateReturn;
}

const ReviewSelfForm = ({ formMethods, languageState }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const sectionState = useSectionState([]);
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const getMultiLangString = useMultiLangString();
  const { addToast } = useToasts();
  const { goBack } = useFromQuery({ includeHash: true });

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;

  const { reviewTemplates } = useReviewTemplates();

  const {
    trigger,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = formMethods;

  const { saveReview, deleteReview, isAllowToDelete, isReviewLoading, isReviewSaving } = useReview({
    formMethods,
    reviewId,
  });
  const { resetGeneratedDates, handleReEnableAutoGenerate } = useReviewTasks({ formMethods });

  useEffect(() => {
    sectionState.setSections([
      {
        title: i18n._(t`General`),
      },
      {
        title: i18n._(t`Select job and participants`),
      },
      {
        title: i18n._(t`Settings`),
        fields: [
          i18n._(t`General`),
          i18n._(t`Notifications`),
          i18n._(t`Privacy`),
          i18n._(t`Timeline`),
        ],
      },
      {
        title: i18n._(t`Final check`),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validation
  useEffect(() => {
    sectionState.setErrorSection(0, !!errors?.name || !!errors.reviewTemplate);
    sectionState.setErrorSection(1, !!errors?.employees);
    sectionState.setErrorSection(
      2,
      !!errors?.settings || !!errors.notifications || !!errors.privacy,
    );

    if (errors) {
      sectionState.goToFirstErrorSection();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));
  const watchStatus = watch('status');
  const isDraft = watchStatus === REVIEW_STATUS.DRAFT;

  const onSaveReview = async () => {
    trigger('name');
    if (errors.name) {
      return;
    }

    const response = await saveReview();
    if (response.code === 200) {
      addToast({ title: i18n._(t`Review saved as draft`), type: TOAST_TYPES.SUCCESS });
      goBack();
    }
  };

  const onPublishReview = async () => {
    if (!isEmpty(errors)) {
      return;
    }
    const response = await saveReview(REVIEW_STATUS.PUBLISHED);
    if (response.code === 200) {
      addToast({ title: 'Review published', type: TOAST_TYPES.SUCCESS });
      goBack();
    }
  };

  const onClickPublish = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Publish review?`),
      description: i18n._(
        t`Are you sure you want to publish the review? From the start date, the review will be available to all participants.`,
      ),
    });
    if (isConfirmed) {
      sectionState.setTriedToSubmit();
      handleSubmit(onPublishReview)();
    }
  };

  const goToAboutYou = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'about-you' },
      ),
    );

  const onCloseButton = async () => {
    if (isEmpty(dirtyFields)) {
      goToAboutYou();
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.INFO,
        title: i18n._(t`Close without saving?`),
        description: i18n._(
          t`Are you sure you want to close without saving? This action cannot be undone.`,
        ),
      });
      if (isConfirmed) {
        onSaveReview();
      } else {
        goToAboutYou();
      }
    }
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToAboutYou();
    }
  };

  const iconsButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            tooltip: i18n._(t`Delete`),
            onClick: async () => {
              await onDelete();
            },
          } as HeaderIconButtons,
        ]
      : []),
    {
      icon: ICONS.SAVE,
      tooltip: i18n._(t`${isDraft ? 'Save draft' : 'Save'}`),
      onClick: onSaveReview,
    },
  ];

  return (
    <>
      <HeaderFocusMode
        title={i18n._(
          t`${isCreatingNew ? 'Create' : 'Edit'} review: ${getMultiLangString(nameMultiLang)}`,
        )}
        goBack={onCloseButton}
        languageState={languageState}
        submitButton={
          isDraft
            ? {
                title: i18n._(t`Publish`),
                type: ButtonVariant.PRIMARY,
                onClick: onClickPublish,
              }
            : undefined
        }
        iconButtons={iconsButtons}
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isReviewLoading}>
          {sectionState.currentSection === 0 && (
            <StepGeneral
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              reviewTemplates={reviewTemplates}
            />
          )}
          {sectionState.currentSection === 1 && (
            <StepParticipants formMethods={formMethods} sectionState={sectionState} />
          )}
          {sectionState.currentSection === 2 && (
            <StepSettings
              formMethods={formMethods}
              sectionState={sectionState}
              languageState={languageState}
              resetGeneratedDates={resetGeneratedDates}
              handleReEnableAutoGenerate={handleReEnableAutoGenerate}
            />
          )}
          {sectionState.currentSection === 3 && (
            <StepFinalCheck
              formMethods={formMethods}
              sectionState={sectionState}
              onPublish={onClickPublish}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
      {isReviewSaving && <LoadingModal title={i18n._(t`Saving, please wait...`)} />}
    </>
  );
};

export { ReviewSelfForm };
