import { joiResolver } from '@hookform/resolvers/joi';
import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_STATUS,
} from '@learned/constants';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import Joi from 'joi';

import type { PopulatedCareerPlan } from './types';
import type { IJobProfile, IMultiLangString, ISkillCategory } from '@learned/types';

const errors = {
  cycleStartDateInPast: 'cycleStartDateInPast',
  cycleStartAfterEndDate: 'cycleStartDateAfterEndDate',
  cycleEndBeforeStartDate: 'cycleEndDateBeforeStartDate',
  taskStartBeforeCycleStartDate: 'taskStartBeforeCycleStartDate',
  taskStartAfterEndDate: 'taskStartAfterEndDate',
  taskEndAfterCycleEndDate: 'taskEndAfterCycleEndDate',
  taskEndBeforeStartDate: 'taskEndBeforeStartDate',
  noJob: 'noJob',
  noCoach: 'noCoach',
  noSkill: 'noSkill',
};

const validateTaskStartDate = (value: any, helpers: Joi.CustomHelpers<any>) => {
  const cycleStartDate = helpers.state.ancestors[2].settings.startDate;
  const taskEndDate = helpers.state.ancestors[0].endDate;

  if (!value) {
    return value;
  }

  if (cycleStartDate && cycleStartDate > value) {
    return helpers.error(errors.taskStartBeforeCycleStartDate);
  }
  if (taskEndDate && taskEndDate < value) {
    return helpers.error(errors.taskStartAfterEndDate);
  }
  return value;
};

const validateTaskEndDate = (value: any, helpers: Joi.CustomHelpers<any>) => {
  const cycleEndDate = helpers.state.ancestors[2].settings.endDate;
  const taskStartDate = helpers.state.ancestors[0].startDate;

  if (!value) {
    return value;
  }

  if (cycleEndDate && cycleEndDate < value) {
    return helpers.error(errors.taskEndAfterCycleEndDate);
  }
  if (taskStartDate && taskStartDate > value) {
    return helpers.error(errors.taskEndBeforeStartDate);
  }
  return value;
};

const schema = Joi.object({
  id: Joi.string().optional(),
  name: Joi.array()
    .items(
      Joi.object({
        locale: Joi.string(),
        value: Joi.string().allow('').max(50),
      })
        .required()
        .min(1),
    )
    .custom((items: { locale: string; value: string }[]) => {
      let hasAtLeastOneName = false;
      items?.forEach((name) => {
        if (name.value !== '') {
          hasAtLeastOneName = true;
        }
      });
      if (!hasAtLeastOneName) {
        throw new Error('One translation is required');
      }

      return items;
    }),
  description: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  reviewTemplate: Joi.string(),
  reviewInvitationTemplate: Joi.string(),
  reviewTemplateName: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  reviewInvitationTemplateName: Joi.array().items(
    Joi.object({
      locale: Joi.string(),
      value: Joi.string().allow(''),
    }),
  ),
  reviewQuestionTypes: Joi.any(),
  skillCategories: Joi.any(),
  skills: Joi.any(),
  skill: Joi.any(),
  isExpandMode: Joi.bool(),
  notifications: Joi.object(),
  privacy: Joi.object(),
  employees: Joi.array()
    .items(
      Joi.object({
        coaches: Joi.array().custom((value, helpers) => {
          if (
            helpers.state.ancestors[2].evaluators.find(
              (evaluator: any) => evaluator.value === REVIEW_QUESTION_EVALUATORS.COACH,
            ) &&
            value.length < 1
          ) {
            return helpers.error(errors.noCoach);
          }
          return value;
        }),
        careerPlans: Joi.array().custom((value, helpers) => {
          if (
            helpers.state.ancestors[2].reviewQuestionTypes.includes(
              REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
            )
          ) {
            if (value.length < 1) {
              return helpers.error(errors.noJob);
            }

            const skills = helpers.state.ancestors[2].skills;
            const skillCategories: Record<string, ISkillCategory> =
              helpers.state.ancestors[2].skillCategories;
            const jobs: IJobProfile[] = value?.map((plan: PopulatedCareerPlan) => plan.jobProfile);
            const categorizedSkills = {} as Record<
              string,
              {
                skillCategory: string;
                skillCategoryName: IMultiLangString;
                skills: Array<string>;
              }
            >;

            Object.values(skillCategories).forEach((skillCategory) => {
              categorizedSkills[skillCategory.id] = {
                skillCategory: skillCategory.id,
                skillCategoryName: skillCategory.name,
                skills: [],
              };
            });

            skills.forEach((skill: any) => {
              const { id, skillCategory } = skill;
              categorizedSkills[skillCategory].skills.push(id);
            });

            const categorizedSkillsArray = Object.values(categorizedSkills);

            let isError = false;
            jobs.forEach((job) => {
              const jobSkillIds = job.skills.map((item) => item.skill);
              categorizedSkillsArray.forEach((category) => {
                if (!category.skills.some((skill) => jobSkillIds.includes(skill))) {
                  isError = true;
                }
              });
            });
            if (isError) {
              return helpers.error(errors.noSkill);
            }
          }
          return value;
        }),
      }).options({
        allowUnknown: true,
      }),
    )
    .min(1),
  settings: Joi.object({
    startDate: Joi.date()
      .custom((value, helpers) => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (now > value) {
          return helpers.error(errors.cycleStartDateInPast);
        }
        const cycleEndDate = helpers.state.ancestors[0].endDate;
        if (!value) {
          return value;
        }
        if (cycleEndDate && value > cycleEndDate) {
          return helpers.error(errors.cycleStartAfterEndDate);
        }
        return value;
      })
      .required(),
    endDate: Joi.date()
      .required()
      .custom((value, helpers) => {
        const cycleStartDate = helpers.state.ancestors[0].startDate;
        if (!value) {
          return value;
        }
        if (cycleStartDate && value < cycleStartDate) {
          return helpers.error(errors.cycleEndBeforeStartDate);
        }
        return value;
      })
      .required(),
    isCoachesAskedToScheduleReview: Joi.boolean(),
    isAutoArchive: Joi.boolean(),
    isCalibrate: Joi.boolean(),
    isDigitalSign: Joi.boolean(),
    isAutoTimeline: Joi.boolean(),
  }),
  tasks: Joi.object({
    reviewSelfEvaluate: Joi.object({
      startDate: Joi.date().custom(validateTaskStartDate).required(),
      endDate: Joi.date().custom(validateTaskEndDate).required(),
    }),
    reviewPeerEvaluate: Joi.object({
      startDate: Joi.date().custom(validateTaskStartDate).required(),
      endDate: Joi.date().custom(validateTaskEndDate).required(),
    }),
    reviewCoachEvaluate: Joi.object({
      startDate: Joi.date().custom(validateTaskStartDate).required(),
      endDate: Joi.date().custom(validateTaskEndDate).required(),
    }),
    reviewPeerNominate: Joi.object({
      startDate: Joi.date().custom(validateTaskStartDate).required(),
      endDate: Joi.date().custom(validateTaskEndDate).required(),
      description: Joi.array().items(
        Joi.object({
          locale: Joi.string(),
          value: Joi.string().allow(''),
        }),
      ),
    }),
    isPreviouslyAutoGenerateEnabled: Joi.boolean(),
  }),
  status: Joi.string().valid(...Object.values(REVIEW_STATUS)),
  evaluators: Joi.array(),
  fetchedReview: Joi.any(),
  userReview: Joi.any(),
}).options({
  messages: {
    [errors.cycleStartDateInPast]: i18n._(t`Start date cannot be in the past`),
    [errors.cycleStartAfterEndDate]: i18n._(t`Start date cannot be after the end date`),
    [errors.cycleEndBeforeStartDate]: i18n._(t`End date cannot be before the start date`),
    [errors.taskStartBeforeCycleStartDate]: i18n._(
      t`Start date cannot be before start date review`,
    ),
    [errors.taskStartAfterEndDate]: i18n._(t`Start date cannot be after the deadline`),
    [errors.taskEndAfterCycleEndDate]: i18n._(t`Deadline cannot be after end date review`),
    [errors.taskEndBeforeStartDate]: i18n._(t`Deadline cannot be before the start date`),
    [errors.noJob]: i18n._(t`Select at least one job`),
    [errors.noCoach]: i18n._(t`Select at least one coach`),
    [errors.noSkill]: i18n._(t`No Skill`),
    'date.base': i18n._(t`Cannot be empty`),
  },
});

const resolver = joiResolver(schema);

export { resolver };
