import React from 'react';

import { Trans } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';

import { ISectionState } from '~/components/SideBar/SectionStateHook';

import { ParticipantsSection } from './components/ParticipantsSection';
import { EmployeePlaceHolder } from './components/ParticipantsSection/EmployeePlaceHolder';
import { Form, Title } from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewIndividualForm } from './types';

interface StepEmployeeProps {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
}

const StepEmployee = ({ formMethods, sectionState, languageState }: StepEmployeeProps) => {
  const { watch } = formMethods;

  const watchEmployee = watch('employees')?.[0];

  return (
    <Form>
      <Title>
        <Trans>Select employee</Trans>
      </Title>
      {watchEmployee ? (
        <ParticipantsSection sectionState={sectionState} formMethods={formMethods} />
      ) : (
        <EmployeePlaceHolder
          sectionState={sectionState}
          formMethods={formMethods}
          languageState={languageState}
        />
      )}
    </Form>
  );
};

export { StepEmployee };
