import styled from 'styled-components';

import { DropdownTemp as Dropdown } from '~/components/Dropdown';

import { COLORS } from '~/styles';

export const Label = styled.div<{
  marginTop?: string;
}>`
  font-size: 14px;
  color: ${COLORS.INPUT_TITLE};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
`;

export const BottomRow = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 260px;
`;
