import React, { useMemo, useState } from 'react';

import { ProductName, ProductStatus } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { FooterRight, ItemsWrapper } from './design';
import { InvitationMessages } from './InvitationMessages';
import { RatingScaleItem } from './RatingScaleItem';
import { SettingItem } from './SettingItem';

import { IQuestionForm, IQuestionOption } from '~/@types/question';
import { useLanguageState } from '~/hooks/useLanguageState';
import { getCurrentCompanyId, getCurrentProducts, getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { productsUpdate } from '~/services/companies';
import { updateCompanyProductSettings } from '~/store/companies/actions';
import { setCompanyProductsToReduxStore } from '~/store/products/actions';

import type { IProductSettingsProps } from './types';

const ProductSettingsModal = ({ name, closeModal }: IProductSettingsProps) => {
  const { i18n } = useLingui();
  const products = useSelector(getCurrentProducts);
  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();
  const [productSettings, setProductSettings] = useState<{
    [key in string]: { isEnabled: boolean };
  }>(products[name].settings);
  const languageState = useLanguageState();
  const currentCompany = useSelector(getCurrentCompany);
  const [errorOnSave, setErrorOnSave] = useState(false);
  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;

  const ratingFormMethods = useForm<IQuestionForm>({
    mode: 'all',
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      options: ratingLabels.map(
        ({
          name,
        }: {
          name: {
            [key: string]: string;
          };
        }) => ({
          label: languageState.companyLanguages.map(({ locale }) => ({
            locale,
            value: name[locale] || '',
          })),
        }),
      ),
      settings: {
        isCommentsAllowed: false,
        isCommentsObligated: false,
        isMeasurementReversed: false,
      },
    },
  });

  const skillFormMethods = useForm<IQuestionForm>({
    mode: 'all',
    defaultValues: {
      name: languageState.companyLanguages.map(({ locale }) => ({ locale, value: '' })),
      options: skillLabels.map(
        ({
          name,
        }: {
          name: {
            [key: string]: string;
          };
        }) => ({
          label: languageState.companyLanguages.map(({ locale }) => ({
            locale,
            value: name[locale] || '',
          })),
        }),
      ),
      settings: {
        isCommentsAllowed: false,
        isCommentsObligated: false,
        isMeasurementReversed: false,
      },
    },
  });
  const { watch: ratingWatch } = ratingFormMethods;
  const { watch: skillWatch } = skillFormMethods;

  const { addToast } = useToasts();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const handleToggleSwitch = (value: boolean, key: string) => {
    setProductSettings((prevSettings) => ({
      ...prevSettings,
      [key]: {
        isEnabled: value,
      },
      labels: {
        isEnabled: true,
        ratingLabels,
        skillLabels,
      },
    }));
  };

  const getStatus = (key: string, value: { isEnabled: boolean }) => {
    const item = (items as any)?.[key];

    if (item?.parent) {
      return productSettings?.[item.parent].isEnabled && value?.isEnabled;
    }
    return value?.isEnabled;
  };

  const handleSave = async () => {
    try {
      const ratingFields = ratingWatch('options');
      const skillFields = skillWatch('options');
      const labelMap = (i: IQuestionOption) => {
        const label: {
          name: {
            [key: string]: string;
          };
        } = {
          name: {},
        };
        i.label.map((j) => {
          if (!j.value) {
            throw new Error(i18n._(t`Field cannot be empty`));
          }
          label.name[j.locale as string] = j.value;
        });
        return label;
      };

      const response = await productsUpdate({
        companyId: currentCompanyId,
        products: {
          [name]: {
            settings: Object.entries(productSettings)
              .map(([key, value]) => {
                return {
                  [key]:
                    key === 'labels'
                      ? {
                          isEnabled: true,
                          ratingLabels: ratingFields.map(labelMap),
                          skillLabels: skillFields.map(labelMap),
                        }
                      : {
                          isEnabled: getStatus(key, value),
                        },
                };
              })
              // convert array to object
              .reduce((prevItems, currentItem) => {
                const [key, value] = Object.entries(currentItem)[0];
                prevItems = {
                  ...prevItems,
                  [key]: value,
                };

                return prevItems;
              }, {}),
          },
        },
      });

      if (response) {
        dispatch(
          updateCompanyProductSettings(name, {
            ...productSettings,
            labels: {
              isEnabled: true,
              ratingLabels: ratingFields.map(labelMap),
              skillLabels: skillFields.map(labelMap),
            },
          }),
        );
        // update company products in redux-store
        if (response?.data?.company) {
          dispatch(setCompanyProductsToReduxStore(response?.data?.company));
        }
        addToast({
          title: i18n._(t`Success`),
          subtitle: i18n._(t`product updated`),
          type: TOAST_TYPES.SUCCESS,
        });
      }
      closeModal();
    } catch (error) {
      setErrorOnSave(true);
      addToast({
        title: i18n._(t`Error`),
        subtitle: i18n._(t`Please fill in all obligated fields`),
        type: TOAST_TYPES.INFO,
      });
    }
  };

  const { title, items } = useMemo(() => {
    switch (name) {
      default:
      case ProductName.CAREER:
        return {
          title: i18n._(t`Career Settings`),
          items: {
            employeeCanSetAmbitions: {
              title: i18n._(t`Members see potential career moves and can set career ambitions`),
            },
            recommendedPath: {
              title: i18n._(t`Enable career path - Recommended`),
              parent: 'employeeCanSetAmbitions',
            },
            verticalMove: {
              title: i18n._(t`Enable career path - Vertical move`),
              parent: 'employeeCanSetAmbitions',
            },
            customPath: {
              title: i18n._(t`Enable career path - Custom`),
              parent: 'employeeCanSetAmbitions',
            },
          },
        };
      case ProductName.PERFORMANCE:
        return {
          title: i18n._(t`Performance Settings`),
          items: {
            usersCanQuickSelectOtherUsersInReviews: {
              title: i18n._(t`Members can select other employees in feedback and reviews`),
            },
            previewDevelopmentCycle: {
              title: i18n._(t`Members can preview their development cycle on their Reviews page`),
            },
            coachCanCreateReviews: {
              title: i18n._(t`Coaches can schedule reviews`),
            },
            userCanCreateReviews: {
              title: i18n._(t`Users can schedule reviews`),
            },
            coachesCanCreateDevelopmentCycles: {
              title: i18n._(t`Coaches can create review cycles for their employees`),
            },
            showEmployeeRolesInReviews: {
              title: i18n._(t`Show all current roles of the employee in the Reviews module`),
            },
            realtimeFeedback: {
              title: i18n._(t`Members can ask feedback from other Members`),
            },
            conversationsAndTeamMeetings: {
              title: i18n._(t`Enable 1-1 & team meetings`),
            },
            goals: {
              title: i18n._(t`Enable goals`),
            },
            goalsInTeam: {
              title: i18n._(t`Enable team goals`),
              parent: 'goals',
            },
            goalsInOrganisation: {
              title: i18n._(t`Enable organisation goals`),
              parent: 'goals',
            },
            labels: {
              title: i18n._(t`Default rating scale`),
            },
          },
        };
      case ProductName.LEARNING_AND_ONBOARDING:
        return {
          title: i18n._(t`Learning & Onboarding Settings`),
          items: {
            employeeCanCreateActivity: {
              title: i18n._(t`Members can create activities`),
            },
            employeeCanCreatePath: {
              title: i18n._(t`Members can create learning paths`),
              parent: 'employeeCanCreateActivity',
            },
            coachCanCreateActivity: {
              title: i18n._(t`Coaches can create activities`),
            },
            coachCanCreatePath: {
              title: i18n._(t`Coaches can create learning paths`),
              parent: 'coachCanCreateActivity',
            },
            freeLearnedActivities: {
              title: i18n._(t`Show free Learned learning content`),
            },
            ...([ProductStatus.ACTIVE, ProductStatus.TRIAL_ACTIVE].includes(
              products[ProductName.CAREER].status,
            ) && {
              showActivityProgressForRole: {
                title: i18n._(t`Show progress of relevant learning activities for this role`),
              },
            }),
          },
        };
    }
  }, [i18n, name, products]);

  const isSettingEnabled = (key: string) => !!productSettings[key]?.isEnabled;

  return (
    <Modal
      title={title}
      onClose={closeModal}
      showDivider={false}
      centerModal
      minWidth={750}
      contentStyles={{ padding: '20px 40px 0', overflow: 'visible' }}
      headerStyles={{ padding: '20px 40px', fontSize: '26px', fontWeight: 'normal' }}
      borderRadius={6}
      hideFooter
    >
      <ItemsWrapper>
        {Object.entries(items)
          // filter out settings where parent setting is disabled
          .filter(([_key, item]) => {
            if (item.parent) {
              return isSettingEnabled(item.parent);
            }
            return true;
          })
          .map(([key, item]) => {
            return key === 'labels' ? (
              <RatingScaleItem
                ratingFormMethods={ratingFormMethods}
                skillFormMethods={skillFormMethods}
                languageState={languageState}
                errorOnSave={errorOnSave}
              />
            ) : (
              <SettingItem
                key={key}
                title={item.title}
                checked={isSettingEnabled(key)}
                onClick={(value) => handleToggleSwitch(value, key)}
                isSecondLevel={Boolean(item.parent)}
              />
            );
          })}
        {currentUser.isAdmin && name === ProductName.PERFORMANCE && <InvitationMessages />}
      </ItemsWrapper>
      <FooterRight>
        <Button
          label={i18n._(t`Cancel`)}
          onClick={closeModal}
          type="button"
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
        />
        <Button
          label={i18n._(t`Save`)}
          type="button"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={handleSave}
        />
      </FooterRight>
    </Modal>
  );
};

export { ProductSettingsModal };
