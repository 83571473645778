import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Button } from '~/components/Buttons/Button';
import { ICONS } from '~/components/Icon';
import OverviewHeading from '~/components/OverviewHeading';
import BaseLayout from '~/layouts/BaseLayout';

import { SkillMatrixCommon } from './AdminSkillMatrix/SkillMatrixCommon';
import { BodyWrapper, Wrapper } from './Main.design';
import { SkillCreateModal } from './SkillCreateModal';

const SkillMatrix = () => {
  const { i18n } = useLingui();
  const [expanded, setExpanded] = useState(true);
  const [showSkillCreateModal, setShowSkillCreateModal] = useState(false);

  const onExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Wrapper expanded={expanded}>
        <OverviewHeading title={i18n._(t`Skill and KPI matrix`)}>
          <Button
            icon={ICONS.ADD_PLUS}
            label={i18n._(t`Add skill or KPI`)}
            onClick={() => setShowSkillCreateModal(true)}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </OverviewHeading>
        <BaseLayout>
          <BodyWrapper isHavingPadding={true}>
            <SkillMatrixCommon onExpandClick={onExpandClick} expanded={expanded} />
          </BodyWrapper>
        </BaseLayout>
      </Wrapper>
      {showSkillCreateModal && <SkillCreateModal onClose={() => setShowSkillCreateModal(false)} />}
    </>
  );
};

export { SkillMatrix };
