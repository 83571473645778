import React from 'react';

import { REPORT_TYPES, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { INSTRUCTIONS } from '~/constants/instructions';
import getInstructionUrl from '~/utils/getInstructionUrl';
import { findContentViewRole } from '~/utils/reports';

import {
  Section,
  SectionHead,
  SectionBodySingleLine,
  Separator,
} from '../engagement/ControlPanelStyles';

interface IControlPanelAboutTabProps {
  viewAs: ROLES;
  reportType: REPORT_TYPES;
}

const prepareData = (
  i18n: I18n,
): {
  [key in REPORT_TYPES | 'default']: {
    [key in ROLES.ADMIN | ROLES.USER | ROLES.COACH]: {
      sectionHead: () => string;
      sectionBody: () => string;
      link?: { text: () => string; url: string };
    }[];
  };
} => ({
  [REPORT_TYPES.ENGAGEMENT]: {
    [ROLES.ADMIN]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee engagement on the themes measured in the engagement surveys. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension, secondary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/8120273-the-learned-engagement-report',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/8120273-the-learned-engagement-report',
        },
      },
    ],
    [ROLES.COACH]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee engagement on the themes measured in the engagement surveys. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension, secondary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626183-the-learned-engagement-report-for-coaches',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626183-the-learned-engagement-report-for-coaches',
        },
      },
    ],
    [ROLES.USER]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows your satisfaction and engagement on the themes measured in the engagement surveys.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626462-the-learned-engagement-report-for-employees',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a theme or survey to only view the data for the selected items. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`'here'`),
          url: 'https://intercom.help/learned/en/articles/9626462-the-learned-engagement-report-for-employees',
        },
      },
    ],
  },
  [REPORT_TYPES.PERFORMANCE]: {
    [ROLES.ADMIN]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee performance on the themes measured in the reviews. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension, secondary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9620377-the-learned-performance-report-for-admins',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9620377-the-learned-performance-report-for-admins',
        },
      },
    ],
    [ROLES.COACH]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee performance on the themes measured in the reviews. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension, secondary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626166-the-learned-performance-report-for-coaches',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626166-the-learned-performance-report-for-coaches',
        },
      },
    ],
    [ROLES.USER]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(t`This report shows your performance on the themes measured in the reviews.`),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement:`),
        sectionBody: () =>
          i18n._(
            t`Depending on the view type you can change the primary dimension, secondary dimension or measurement. This enables you to visualize the data in different ways. To learn more about all the different dimensions go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626170-the-learned-performance-report-for-employees',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters:`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a theme to only view the data for this theme. To learn more about all the different filters go to our helpdesk article.`,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: 'https://intercom.help/learned/en/articles/9626170-the-learned-performance-report-for-employees',
        },
      },
    ],
  },
  default: {
    [ROLES.ADMIN]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee engagement on the themes measured in the engagement surveys. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Graph`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find the average employee engagement over time. You can adjust the timeframe in the sidebar - settings tab.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Bar chart`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find the average engagement per theme and many other variables displayed in a bar chart. Use the sidebar - settings tab to customize the graph.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Table`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can make any analyses you want. Use the sidebar - settings tab to customize the table. For example: analyze the engagement met theme over time, or analyze the engagement per team per theme.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement`),
        sectionBody: () =>
          i18n._(
            t`By changing the primary dimension, secondary dimension or measurement you will change the structure of the graph or table. To learn more about all the different dimensions go to our helpdesk article`,
          ),
        link: {
          text: () => i18n._(t`'here'`),
          url: getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_DIMENSIONS) || '/',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article `,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_FILTERS) || '/',
        },
      },
    ],
    [ROLES.COACH]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows the employee engagement on the themes measured in the engagement surveys. Admins have access to all teams, for coaches the data is limited to teams where they are coach.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Graph`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find the average employee engagement over time. You can adjust the timeframe in the sidebar - settings tab.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Bar chart`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find the average engagement per theme and many other variables displayed in a bar chart. Use the sidebar - settings tab to customize the graph.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Table`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can make any analyses you want. Use the sidebar - settings tab to customize the table. For example: analyze the engagement met theme over time, or analyze the engagement per team per theme.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`The dimensions & measurement`),
        sectionBody: () =>
          i18n._(
            t`By changing the primary dimension, secondary dimension or measurement you will change the structure of the graph or table. To learn more about all the different dimensions go to our helpdesk article`,
          ),
        link: {
          text: () => i18n._(t`'here'`),
          url: getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_DIMENSIONS) || '/',
        },
      },
      {
        sectionHead: () => i18n._(t`The filters`),
        sectionBody: () =>
          i18n._(
            t`To limit the data used in the graph or table use any of the filters. For example. Select a team to only use the data generated by people in this team. To learn more about all the different filters go to our helpdesk article `,
          ),
        link: {
          text: () => i18n._(t`here`),
          url: getInstructionUrl(INSTRUCTIONS.REPORTS_ABOUT_SECTION_FILTERS) || '/',
        },
      },
    ],
    [ROLES.USER]: [
      {
        sectionHead: () => i18n._(t`The report:`),
        sectionBody: () =>
          i18n._(
            t`This report shows your engagement based on the answers you provided in the surveys. You have the option to compare your engagement with the average engagement of your team(s) and the company.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Graph`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find your average engagement compared to your teams' and company engagement over time.`,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Bar chart`),
        sectionBody: () =>
          i18n._(
            t`In this tab you can find your average engagement per theme compared to the engagement of your team and the company. `,
          ),
      },
      {
        sectionHead: () => i18n._(t`Tab: Table`),
        sectionBody: () =>
          i18n._(
            t`In this table you can compare your average engagement per theme compared to the engagement of your team and the company.`,
          ),
      },
    ],
  },
});

const ControlPanelAboutTabContent: React.FC<IControlPanelAboutTabProps> = React.memo(
  ({ viewAs, reportType }) => {
    const { i18n } = useLingui();

    const preparedData = React.useMemo(() => {
      return prepareData(i18n);
    }, [i18n]);

    const reportData = preparedData[reportType] || preparedData.default;

    const roleData = reportData[findContentViewRole(viewAs)];

    return (
      <>
        {roleData.map((sectionData) => {
          return (
            <>
              <Section>
                <SectionHead>{sectionData.sectionHead()}</SectionHead>
                <SectionBodySingleLine>
                  {sectionData.sectionBody()}
                  {sectionData.link && (
                    <a target="_blank" rel="noreferrer" href={sectionData.link.url}>
                      {sectionData.link.text()}
                    </a>
                  )}
                </SectionBodySingleLine>
              </Section>
              <Separator />
            </>
          );
        })}
      </>
    );
  },
);

export { ControlPanelAboutTabContent };
