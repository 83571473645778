import { t } from '@lingui/macro';

import { ICONS } from '~/components/Icon';

import { ROLES } from '~/constants';
import routes from '~/constants/routes';

import type { I18n } from '@lingui/core';

const mainMenuStructure = ({
  isModuleCareerEnabled,
  isModuleGoalsEnabled,
  isModuleLearningEnabled,
  isModuleSurveyEnabled,
  isModuleReviewsEnabled,
  isModuleMeetingsEnabled,
  isModuleRTFeedbacksEnabled,
}: {
  isModuleCareerEnabled: boolean;
  isModuleGoalsEnabled: boolean;
  isModuleLearningEnabled: boolean;
  isModuleSurveyEnabled: boolean;
  isModuleReviewsEnabled: boolean;
  isModuleMeetingsEnabled: boolean;
  isModuleRTFeedbacksEnabled: boolean;
}) =>
  [
    {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Home`),
          // @ts-ignore
          url: routes.DASHBOARD.build({ role: ROLES.USER }),
          role: ROLES.USER,
        },
      ],
      title: (i18n: I18n) => i18n._(t`Home`),
      icon: ICONS.HOME,
    },
    isModuleCareerEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Career paths`),
          // @ts-ignore
          url: routes.USER_CAREER.build({ role: ROLES.USER }),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.USER_CAREER.build({ role: ROLES.USER })],
        },
      ],
      title: (i18n: I18n) => i18n._(t`Career`),
      icon: ICONS.CAREER,
    },
    (isModuleReviewsEnabled || isModuleMeetingsEnabled || isModuleGoalsEnabled) && {
      children: [
        isModuleReviewsEnabled && {
          title: (i18n: I18n) => i18n._(t`Reviews`),
          // @ts-ignore
          url: routes.REVIEWS.build(
            // @ts-ignore
            { role: ROLES.USER },
            { hash: 'about-you', noHash: true },
          ),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.REVIEWS.build({ role: ROLES.USER })],
        },
        isModuleMeetingsEnabled && {
          title: (i18n: I18n) => i18n._(t`1:1 & Team meetings`),
          // @ts-ignore
          url: routes.CONVERSATIONS.build({ role: ROLES.USER }, { hash: 'meetings', noHash: true }),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.CONVERSATIONS.build({ role: ROLES.USER })],
          hash: 'meetings',
        },
        (isModuleMeetingsEnabled || isModuleReviewsEnabled) && {
          title: (i18n: I18n) => i18n._(t`Next steps`),
          // @ts-ignore
          url: routes.NEXT_STEPS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.NEXT_STEPS.build({ role: ROLES.USER })],
        },
        isModuleGoalsEnabled && {
          title: (i18n: I18n) => i18n._(t`Goals`),
          // @ts-ignore
          url: routes.GOALS.build({ role: ROLES.USER }, { hash: 'my_goals', noHash: true }),
          role: ROLES.USER,
          aliases: [
            // @ts-ignore
            routes.GOALS.build({ role: ROLES.USER }),
            // @ts-ignore
            routes.GOALS.build({ role: ROLES.USER }),
          ],
        },
        isModuleRTFeedbacksEnabled && {
          title: (i18n: I18n) => i18n._(t`Feedback`),
          // @ts-ignore
          url: routes.RTFEEDBACKS.build({ role: ROLES.USER }),
          role: ROLES.USER,
          aliases: [
            // @ts-ignore
            routes.RTFEEDBACKS.build({ role: ROLES.USER }),
            // @ts-ignore
            routes.FEEDBACK_CYCLES.build({ role: ROLES.ADMIN }),
          ],
        },
        isModuleLearningEnabled && {
          title: (i18n: I18n) => i18n._(t`Onboard & Learn`),
          // @ts-ignore
          url: routes.ONBOARD_AND_LEARN.build({ role: ROLES.USER }),
          role: ROLES.USER,
        },
      ].filter((c) => c),
      title: (i18n: I18n) => i18n._(t`Development`),
      icon: ICONS.DEVELOPMENT,
    },
    isModuleSurveyEnabled && {
      children: [
        {
          title: (i18n: I18n) => i18n._(t`Survey’s`),
          url: routes.SURVEYS_OVERVIEW.build(
            // @ts-ignore
            { role: ROLES.USER },
            {
              noHash: true,
              hash: 'personal',
            },
          ),
          role: ROLES.USER,
          // @ts-ignore
          aliases: [routes.SURVEYS_OVERVIEW.build({ role: ROLES.USER })],
        },
      ].filter((c) => c),
      title: (i18n: I18n) => i18n._(t`Survey’s`),
      icon: ICONS.SURVEYS,
    },
  ].filter((c) => c);

export default mainMenuStructure;
