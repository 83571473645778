import { ROLES } from '~/constants';
import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'job-matrix';

export const JOB_MATRIX_ADMIN = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${pageOverview(routeName)}`,
);

export const JOB_MATRIX_EDIT = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${pageOverview(routeName)}/edit`,
);
