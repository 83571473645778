// TODO move enums and consts to constants package not types
import { IMultiLangString } from './multiLangString';
import { IUserTo } from './tasks';
import { FILTER_TASK_TYPES, TASK_FILTER_BY_USER_TYPE, TASK_TYPE } from '@learned/constants';

export enum TASKS_STATUSES {
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export enum TASKS_ACTIONS {
  START = 'start',
  CONTINUE = 'continue',
  UPDATE = 'update',
  SHOW = 'show',
  NO_ACTION = 'no_action',
  SHARED = 'shared',
  SHARE = 'share',
  MARK_AS_DONE = 'mark_as_done',
  VIEW = 'view',
  PROBLEM = 'problem',
  ARCHIVED = 'archived',
  /* REVIEWS 3.0 */
  TODO = 'todo',
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
}

export enum UPDATED_TASK_ACTIONS {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  PROBLEM = 'problem',
  ARCHIVED = 'archived',
  MARK_AS_DONE = 'mark_as_done', // introducing this here to avoid type confusions (not a new task action)
}

export const TASK_ACTIONS_MAP = {
  [TASKS_ACTIONS.START]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.CONTINUE]: UPDATED_TASK_ACTIONS.IN_PROGRESS,
  [TASKS_ACTIONS.UPDATE]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.SHOW]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.NO_ACTION]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.SHARED]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.SHARE]: UPDATED_TASK_ACTIONS.IN_PROGRESS,
  [TASKS_ACTIONS.ARCHIVED]: UPDATED_TASK_ACTIONS.ARCHIVED,
  [TASKS_ACTIONS.VIEW]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.PROBLEM]: UPDATED_TASK_ACTIONS.PROBLEM,
  [TASKS_ACTIONS.MARK_AS_DONE]: TASKS_ACTIONS.MARK_AS_DONE,
  /* from REVIEWS 3.0*/
  [TASKS_ACTIONS.TODO]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.UPCOMING]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.COMPLETED]: UPDATED_TASK_ACTIONS.COMPLETED,
};

type TaskTargetType = {
  target: Pick<ITaskOld['target'], 'userName' | 'name'>;
};

export const TASKS_NAMES_BY_TYPE = {
  RT_FEEDBACK: {
    key: 'rtFeedback',
    text: (task: TaskTargetType) => `Answer the feedback request of ${task.target.userName}'s`,
  },
  SELF_REVIEW: {
    key: 'selfReview',
    text: (task: TaskTargetType) => `Prepare for your ${task.target.name}`,
  },
  NOMINATE_PEERS: {
    key: 'nominatePeers',
    text: (task: TaskTargetType) => `Ask peers for input for your ${task.target.name}`,
  },
  PEER_REVIEW: {
    key: 'peerReview',
    text: (task: TaskTargetType) =>
      `Provide input for ${task.target.userName}'s ${task.target.name}`,
  },
  COACH_REVIEW: {
    key: 'coachReview',
    text: (task: TaskTargetType) =>
      `Provide input for ${task.target.userName}'s ${task.target.name}`,
  },
  PLAN_CONVERSATION: {
    key: 'planConversation',
    text: (task: TaskTargetType) =>
      `Set a date and time for ${task.target.userName}'s ${task.target.name}`,
  },
  DIGITAL_SIGN_SELF: {
    key: 'digitalSignSelf',
    text: (task: TaskTargetType) => `Complete your ${task.target.name}`,
  },
  DIGITAL_SIGN_COACH: {
    key: 'digitalSignCoach',
    text: (task: TaskTargetType) => `Complete ${task.target.userName}'s ${task.target.name}`,
  },
  GOAL_TASK: {
    key: 'goalTask',
    text: (_task: TaskTargetType) => `Update your goals`,
  },
  ASK_FEEDBACK: {
    key: 'ask_feedback',
    text: (_task: TaskTargetType) => `Ask someone for feedback`,
  },
  GIVE_FEEDBACK: {
    key: 'give_feedback',
    text: (_task: TaskTargetType) => `Give someone feedback or a compliment`,
  },
  NEXT_STEP_CONVERSATION: {
    key: 'next_step_conversation',
    text: (task: TaskTargetType) => task.target.name,
  },
  NEXT_STEP_REVIEW: {
    key: 'next_step_review',
    text: (task: TaskTargetType) => task.target.name,
  },
  ANSWER_SURVEY_TRADITIONAL: {
    key: 'answer_survey_traditional',
    text: (task: TaskTargetType) => task.target.name,
  },
  ANSWER_SURVEY_PULSE: {
    key: 'answer_survey_pulse',
    text: (task: TaskTargetType) => task.target.name,
  },
};

/**
 * @deprecated since Review 3.0 (user ITask from constants/tasks.ts)
 */
export enum TASKS_TYPES_OLD {
  RT_FEEDBACK = 'rtFeedback',
  SELF_REVIEW = 'selfReview',
  NOMINATE_PEERS = 'nominatePeers',
  PEER_REVIEW = 'peerReview',
  COACH_REVIEW = 'coachReview',
  PlAN_CONVERSATION = 'planConversation',
  DIGITAL_SIGN_SELF = 'digitalSignSelf',
  DIGITAL_SIGN_COACH = 'digitalSignCoach',
  GOAL_TASK = 'goalTask',
  ASK_FEEDBACK = 'ask_feedback',
  GIVE_FEEDBACK = 'give_feedback',
  NEXT_STEP_REVIEW = 'next_step_review',
  NEXT_STEP_CONVERSATION = 'next_step_conversation',
  ANSWER_SURVEY_TRADITIONAL = 'answer_survey_traditional',
  ANSWER_SURVEY_PULSE = 'answer_survey_pulse',
}

/**
 * @deprecated since Review 3.0 (user ITask from type/tasks.ts)
 */
export interface ITaskOld {
  type: TASKS_TYPES_OLD;
  deadline: string | null;
  status: TASKS_ACTIONS; // status for correct rendering
  taskStatus: TASKS_STATUSES; // status to filter and count by active/completed
  createdDate?: string | null;
  repeat?: number;
  target: {
    name: string; // target item (review) name - for render and search
    targetId: string; // target item (review, rtFeedback) id
    requestId: string;
    targetName?: string;
    originalTargetId?: string;
    taskId?: string;
    userId: string; // for peers/coaches tasks - user for whom need to provide feedback
    userName?: string;
  };
}

export type ITaskWithId = ITaskOld & { id: string; name?: IMultiLangString; userTo?: IUserTo };

// Requests
export interface IGetTasksRequest {
  filterTaskTypes: FILTER_TASK_TYPES;
  taskStatus?: TASKS_STATUSES;
  userType: TASK_FILTER_BY_USER_TYPE;
  limit: string;
  skip: number;
  search: string;
}
