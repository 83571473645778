import React, { MouseEvent, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import size from 'lodash/size';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Dot6Icon from '~/components/Icons/Dot6';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { PreviewQuestionModal } from '~/components/Modals/PreviewQuestionModal';

import {
  AddQuestionHover,
  Button,
  ButtonText,
  CardWrapper,
  CreateNewQuestionWrapper,
  HoverIcon,
  ItemsContainer,
  SmallButton,
  SmallButtonContainer,
  SmallButtonText,
  StyledPreviewQuestionCard,
  SubTitle,
} from './design';
import { Footer } from './Footer';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { convertQuestionOptions } from '~/utils/questions';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import type { IMultiLangString, IReviewQuestion, IReviewQuestionDefaultData } from '@learned/types';

function QuestionsList({
  items,
  createNewQuestion,
  importQuestions,
  languageState,
  onReorderQuestions,
  setEditQuestion,
  removeQuestion,
  autosave,
}: {
  items: (IReviewQuestion & { themeName?: IMultiLangString })[];
  createNewQuestion: (index?: number) => void;
  importQuestions: (index?: number) => void;
  languageState: ILanguageStateReturn;
  onReorderQuestions: (oldIndex: number, _newIndex: number) => void;
  setEditQuestion: (questionId: string) => void;
  removeQuestion: (index: number, item: IReviewQuestion) => void;
  autosave: () => void;
}) {
  const { i18n } = useLingui();
  const [previewQuestion, setPreviewQuestion] = useState<IReviewQuestion | undefined>();
  const [isDragging, setIsDragging] = useState(false);

  function turnReviewQuestionIntoQuestionForm(
    question: IReviewQuestion,
    languageState: ILanguageStateReturn,
  ) {
    return {
      name: turnMultiLangIntoArray(question.name, languageState.companyLanguages),
      type: question.type,
      options: convertQuestionOptions(question, languageState.companyLanguages),
      settings: {
        isCommentsAllowed:
          (question?.settings as IReviewQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question?.settings as IReviewQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question?.settings as IReviewQuestionDefaultData)?.isMeasurementReversed ?? false,
      },
    };
  }

  return (
    <>
      <SubTitle>
        <Trans>
          Import questions from themes and change the order of the questions by dragging them.
        </Trans>
      </SubTitle>
      <ItemsContainer>
        <DragDropContext
          onDragEnd={(result) => {
            setIsDragging(false);
            if (!result.destination) {
              return;
            }
            onReorderQuestions(result.source.index, result.destination.index);
            autosave();
          }}
          onDragStart={() => void setIsDragging(true)}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <>
                        <CardWrapper
                          isDragging={isDragging}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <HoverIcon isDragging={isDragging} isActive={snapshot.isDragging}>
                            <div {...provided.dragHandleProps}>
                              <Dot6Icon size={32} />
                            </div>
                          </HoverIcon>
                          <StyledPreviewQuestionCard
                            key={index}
                            themeName={item.themeName}
                            languageState={languageState}
                            onClickCard={(e: MouseEvent) => {
                              setPreviewQuestion(item);
                              e.stopPropagation();
                            }}
                            index={index}
                            type={item.type}
                            name={Object.entries(item.name).map(([locale, value]) => ({
                              locale,
                              value,
                            }))}
                            isActive={snapshot.isDragging}
                          >
                            <Footer
                              onDelete={(e: MouseEvent) => {
                                e.stopPropagation();
                                removeQuestion(index, item);
                              }}
                              onEdit={async (e: MouseEvent) => {
                                e.stopPropagation();
                                const isConfirmed = await confirm({
                                  type: CONFIRMATION_MODAL_TYPE.WARNING,
                                  title: i18n._(t`Be aware`),
                                  description: i18n._(
                                    t`Changing this question will affect all reviews that use this question`,
                                  ),
                                });
                                if (isConfirmed) {
                                  setEditQuestion(item.id);
                                }
                              }}
                            />
                          </StyledPreviewQuestionCard>
                        </CardWrapper>

                        {size(items) - 1 > index && (
                          <AddQuestionHover isDragging={isDragging}>
                            <SmallButtonContainer>
                              <SmallButton
                                onClick={(e) => {
                                  createNewQuestion(index);
                                  e.preventDefault();
                                }}
                              >
                                <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                                <SmallButtonText>
                                  <Trans>Create Question</Trans>
                                </SmallButtonText>
                              </SmallButton>
                              <SmallButton
                                onClick={(e) => {
                                  importQuestions(index + 1);
                                  e.preventDefault();
                                }}
                              >
                                <Icon icon={ICONS.IMPORT} size={ICON_SIZES.SMALL} />
                                <SmallButtonText>
                                  <Trans>Import Question</Trans>
                                </SmallButtonText>
                              </SmallButton>
                            </SmallButtonContainer>
                          </AddQuestionHover>
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <CreateNewQuestionWrapper>
          <Button
            onClick={(e) => {
              createNewQuestion(size(items));
              e.preventDefault();
            }}
          >
            <Icon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
            <ButtonText>
              <Trans>Create Question</Trans>
            </ButtonText>
          </Button>
          <Button
            onClick={(e) => {
              importQuestions(size(items));
              e.preventDefault();
            }}
          >
            <Icon icon={ICONS.IMPORT} size={ICON_SIZES.SMALL} />
            <ButtonText>
              <Trans>Import Question</Trans>
            </ButtonText>
          </Button>
        </CreateNewQuestionWrapper>
      </ItemsContainer>
      {previewQuestion && (
        <PreviewQuestionModal
          defaultValues={turnReviewQuestionIntoQuestionForm(previewQuestion, languageState)}
          languageState={languageState}
          onClose={() => setPreviewQuestion(undefined)}
        />
      )}
    </>
  );
}

export { QuestionsList };
